import { BoundingBox, Vec } from "../geom";
import { modulo } from "../geom/math/scalar-math";
import { globalState } from "../global-state";
import { AnchorDefinition } from "./builtin-primitives";
import { Instance } from "./instance";
import { Node } from "./node";
import { registerClass } from "./registry";
import {
  Selectable,
  SelectableComponentParameter,
  SelectableInstance,
  SelectableNode,
  SelectableParameter,
  SelectableSegment,
} from "./selectable";

export class Selection<SelectableT extends Selectable = Selectable> {
  items: SelectableT[];

  constructor(items: SelectableT[] = []) {
    this.items = items;
  }

  clone() {
    return new Selection(this.items.slice());
  }

  add(...selectables: SelectableT[]) {
    this.items.push(...selectables);
    return this;
  }
  addUnique(...selectables: SelectableT[]) {
    for (let selectable of selectables) {
      if (!this.items.some((item) => selectable.equals(item))) {
        this.items.push(selectable);
      }
    }
    return this;
  }
  toggle(selectable: SelectableT, selected?: boolean) {
    const index = this.items.findIndex((item) => selectable.equals(item));
    if (index === -1 && (selected === undefined || selected)) {
      this.add(selectable);
    } else if (!selected) {
      this.items.splice(index, 1);
    }
    return this;
  }

  clear() {
    this.items = [];
    return this;
  }

  sortInDocumentOrder() {
    this.items.sort(compareSelectablesByDocumentOrder);
    return this;
  }
  reverse() {
    this.items.reverse();
    return this;
  }

  isEmpty() {
    return this.items.length === 0;
  }
  isSingle() {
    return this.items.length === 1;
  }
  isMultiple() {
    return this.items.length > 1;
  }

  contains(...selectables: Selectable[]) {
    return selectables.every((selectable) => {
      return this.items.some((item) => selectable.equals(item));
    });
  }

  isNodeDirectlySelected(node: Node) {
    return this.items.some((item) => {
      return item instanceof SelectableNode && node.equalsNode(item.node);
    });
  }
  isNodeInderectlySelected(node: Node) {
    return this.items.some((item) => {
      return item.allNodes().some((item) => node.equalsNode(item.node));
    });
  }
  isInstanceIndirectlySelected(instance: Instance) {
    return this.items.some(
      (item) =>
        (item instanceof SelectableInstance || item instanceof SelectableParameter) &&
        item.instance === instance
    );
  }

  isImmutable() {
    return this.items.some((item) => item.isImmutable());
  }

  isPositionLocked() {
    return this.items.some((item) => item.nodes().some((node) => node.source.isPositionLocked()));
  }
  isRotationLocked() {
    return this.items.some((item) => item.nodes().some((node) => node.source.isRotationLocked()));
  }
  isScaleLocked() {
    return this.items.some((item) => item.nodes().some((node) => node.source.isScaleLocked()));
  }
  isSkewLocked() {
    return this.items.some((item) => item.nodes().some((node) => node.source.isSkewLocked()));
  }

  isUniformScale() {
    return this.items.some((item) => item.nodes().some((node) => node.source.isUniformScale()));
  }

  hasAnchor() {
    return this.items.some((item) => item.nodes().some((node) => node.isAnchor()));
  }
  isAllAnchors() {
    return this.items.every((item) => item.nodes().every((node) => node.isAnchor()));
  }
  isAllAnchorHandles() {
    return this.items.every((item) => item instanceof SelectableParameter && item.node.isAnchor());
  }
  isPassThrough() {
    return this.items.some(
      (item) => item instanceof SelectableNode && item.node.source.isPassThrough()
    );
  }

  hasOnly<S extends Selectable>(constructor: { new (...args: any[]): S }): this is Selection<S> {
    return this.items.every((item) => item instanceof constructor);
  }
  hasAny<S extends Selectable>(constructor: { new (...args: any[]): S }) {
    return this.items.some((item) => item instanceof constructor);
  }

  filter(predicate: (selectable: SelectableT) => boolean) {
    return new Selection(this.items.filter(predicate));
  }
  filterByType<S extends Selectable>(constructor: { new (...args: any[]): S }) {
    const selection = new Selection<S>();
    for (let item of this.items) {
      if (item instanceof constructor) {
        selection.items.push(item);
      }
    }
    return selection;
  }

  directlySelectedNodes() {
    return this.filterByType(SelectableNode);
  }
  directlySelectedInstances() {
    return this.filterByType(SelectableInstance);
  }

  mutables() {
    return this.filter((item) => {
      return item.allNodes().every((item) => !item.node.isImmutable());
    });
  }

  /** If an ancestor and decendant node are both selected, make a new selection
   * filtered to only the highest ancestor nodes. */
  topNodes() {
    const nodesSelection = this.directlySelectedNodes();
    return nodesSelection.filter((selectable) =>
      nodesSelection.items.every((other) => !other.node.isAncestorOfNode(selectable.node))
    );
  }

  /**
   * Returns any selectable that can be transformed. SelectableSegments become
   * SelectableNodes. SelectableParameters are only included if their node is
   * not already in the set. Returned selectables are unique.
   */
  allTransformable() {
    const selection = new Selection();
    for (let item of this.items) {
      if (item instanceof SelectableNode) {
        selection.addUnique(item);
      } else if (item instanceof SelectableInstance || item instanceof SelectableSegment) {
        selection.addUnique(...item.allNodes());
      } else if (item instanceof SelectableComponentParameter) {
        if (item.isTransformable()) {
          selection.addUnique(item);
        }
      }
    }
    for (let item of this.items) {
      if (item instanceof SelectableParameter) {
        const isBase = item.instance === item.node.source.base;
        const isNodeDirectlySelected = selection.isNodeDirectlySelected(item.node);
        if (isBase) {
          // Base parameters can be transformed, but not if their node is
          // selected since this would double transform the parameter.
          if (!isNodeDirectlySelected) {
            selection.addUnique(item);
          }
        } else {
          // Point parameters of modifiers can be dragged independently, even if
          // their node is selected.
          if (!isNodeDirectlySelected || item.parameter.isPoint()) {
            selection.addUnique(item);
          }
        }
      }
    }
    return selection;
  }

  /**
   * Returns all parameters that should be displayed as handles. We don't worry
   * about culling non-Vec parameters here. Returned selectables are unique and
   * in document order.
   */
  allParametersToShow() {
    const items: (SelectableParameter | SelectableComponentParameter)[] = [];
    for (let item of this.items) {
      if (item instanceof SelectableNode || item instanceof SelectableParameter) {
        appendParametersForInstance(items, item.node, item.node.source.base);
        for (let modifier of item.node.source.modifiers) {
          appendParametersForInstance(items, item.node, modifier);
        }
        if (item.node.isAnchor()) {
          // Show handles adjacent to selected anchors
          appendHandleParametersAdjacentToAnchorNode(items, item.node);
        }
      } else if (item instanceof SelectableInstance) {
        if (item.instance === item.node.source.transform) continue; // Don't show handles for Transform instances
        appendParametersForInstance(items, item.node, item.instance);
      } else if (item instanceof SelectableSegment) {
        appendParametersForInstance(items, item.node, item.node.source.base);
        appendParametersForInstance(items, item.nextNode, item.nextNode.source.base);
      } else if (item instanceof SelectableComponentParameter) {
        for (let parameter of item.component.parameters) {
          items.push(new SelectableComponentParameter(item.component, parameter));
        }
      }
    }
    return new Selection(uniqueSelectables(items)).sortInDocumentOrder();
  }

  /**
   * Returns directly selected nodes and any indirectly selected instance.
   * Returned selectables are unique and in document order.
   */
  allNodesAndInstancesToShow() {
    const selection: Selection<SelectableNode | SelectableInstance> = this.directlySelectedNodes();
    for (let item of this.items) {
      for (let instanceItem of item.allInstances()) {
        if (instanceItem.instance.definition !== AnchorDefinition) {
          selection.addUnique(instanceItem);
        }
      }
    }
    return selection.sortInDocumentOrder();
  }

  /**
   * Returns all unique directly and inderectly selected nodes in this
   * selection.
   */
  allNodes() {
    const items = uniqueSelectables(this.items.flatMap((item) => item.allNodes()));
    return new Selection(items);
  }
  allNodesAndAncestorNodes() {
    const selection = this.allNodes();
    const appendAncestors = (node: Node) => {
      if (node.parent) {
        const selectableNode = new SelectableNode(node.parent);
        if (!selection.contains(selectableNode)) {
          selection.add(selectableNode);
          appendAncestors(node.parent);
        }
      }
    };
    for (let item of selection.items) {
      appendAncestors(item.node);
    }
    return selection;
  }
  allInstances() {
    const items = uniqueSelectables(this.items.flatMap((item) => item.allInstances()));
    return new Selection(items);
  }

  toNodes() {
    return this.items.flatMap((item) => item.nodes());
  }

  commonAncestorNode() {
    const nodesSelection = this.allNodes();
    if (nodesSelection.isEmpty()) return;
    let commonAncestor = nodesSelection.items[0].node.parent;
    while (commonAncestor) {
      const isCommonAncestor = nodesSelection.items.every((item) =>
        commonAncestor!.isAncestorOfNode(item.node)
      );
      if (isCommonAncestor) break;
      commonAncestor = commonAncestor.parent;
    }
    return commonAncestor ?? undefined;
  }

  worldBoundingBox() {
    const box = new BoundingBox(new Vec(Infinity), new Vec(-Infinity));
    for (let item of this.items) {
      const boundingBox = item.worldBoundingBox();
      if (boundingBox) {
        box.expandToIncludeBoundingBox(boundingBox);
      }
    }
    return box;
  }
}
registerClass("Selection", Selection);

const uniqueSelectables = <T extends Selectable>(selectables: T[]) => {
  const uniques: T[] = [];
  for (let selectable of selectables) {
    if (!uniques.some((uniqueSelectable) => selectable.equals(uniqueSelectable))) {
      uniques.push(selectable);
    }
  }
  return uniques;
};

const compareSelectablesByDocumentOrder = (a: Selectable, b: Selectable) => {
  // Component parameters sorted first
  if (a instanceof SelectableComponentParameter && b instanceof SelectableComponentParameter) {
    return (
      a.component.parameters.findIndex((param) => param === a.parameter) -
      b.component.parameters.findIndex((param) => param === b.parameter)
    );
  } else if (a instanceof SelectableComponentParameter) {
    return -1;
  } else if (b instanceof SelectableComponentParameter) {
    return 1;
  }

  const aNodes = a.nodes();
  if (aNodes.length === 0) return 0;
  const bNodes = b.nodes();
  if (bNodes.length === 0) return 0;

  if (aNodes[0].equalsNode(bNodes[0])) {
    // Node is the same, compare instances
    if (
      (a instanceof SelectableInstance || a instanceof SelectableParameter) &&
      (b instanceof SelectableInstance || b instanceof SelectableParameter)
    ) {
      if (
        a.instance === b.instance &&
        a instanceof SelectableParameter &&
        b instanceof SelectableParameter
      ) {
        // Instances are the same, compare parameters
        return (
          a.instance.definition.parameters.findIndex((param) => param.name === a.parameter.name) -
          b.instance.definition.parameters.findIndex((param) => param.name === b.parameter.name)
        );
      }
      const node = aNodes[0];
      const instances = node.source.allInstances();
      return instances.indexOf(a.instance) - instances.indexOf(b.instance);
    }
  }

  const aPath = aNodes[0].indexPath();
  const bPath = bNodes[0].indexPath();

  for (let i = 0, len = aPath.length; i < len; i++) {
    if (i >= bPath.length) return 1;
    if (aPath[i] < bPath[i]) return -1;
    if (aPath[i] > bPath[i]) return 1;
  }

  return -1;
};

const appendParametersForInstance = (items: Selectable[], node: Node, instance: Instance) => {
  for (let parameter of instance.definition.parameters) {
    items.push(new SelectableParameter(node, instance, parameter));
  }
};

const appendHandleParametersAdjacentToAnchorNode = (items: Selectable[], node: Node) => {
  const parentNode = node.parent;
  if (!parentNode) return;

  let pathClosed = false;
  if (parentNode.isPath()) {
    const pathTrace = globalState.traceForNode(parentNode);
    const pathClosedArg = pathTrace?.base.args.closed;
    if (pathClosedArg?.isSuccess && typeof pathClosedArg.evaluationResult === "boolean") {
      pathClosed = pathClosedArg.evaluationResult;
    }
  }

  const index = node.indexInParent();
  const count = parentNode.childCount();
  let prevNode: Node | undefined;
  let nextNode: Node | undefined;
  if (pathClosed) {
    prevNode = parentNode.childNodeAtIndex(modulo(index - 1, count));
    nextNode = parentNode.childNodeAtIndex(modulo(index + 1, count));
  } else {
    if (index > 0) {
      prevNode = parentNode.childNodeAtIndex(index - 1);
    }
    if (index < count - 1) {
      nextNode = parentNode.childNodeAtIndex(index + 1);
    }
  }

  if (prevNode && prevNode.isAnchor()) {
    items.push(SelectableParameter.fromName(prevNode, prevNode.source.base, "handleOut"));
  }
  if (nextNode && nextNode.isAnchor()) {
    items.push(SelectableParameter.fromName(nextNode, nextNode.source.base, "handleIn"));
  }
};
