export const mix = (a: number, b: number, t: number) => a + (b - a) * t;

/**
 * @param a A normalized angle (in degreees) between 0 and 360
 * @param b A normalized angle (in degreees) between 0 and 360
 *
 * @returns an interpolation between angles `a` and `b`, taking the shortest
 * path
 */
export const mixAngles = (a: number, b: number, t: number) => {
  let d = b - a;
  if (Math.abs(d) > 180) d -= 360;
  return a + d * t;
};

/**
 * Linearly interpolates between numbers `a` and `b` by the mixing factor `c`.
 * Assumes that that interpolation occurs in a closed loop and takes the
 * shortest path from `a` to `b`.
 *
 * This is useful when interpolating cyclic values like "time" in a closed path,
 * or angles.
 *
 * @param a The value when `t` is 0
 * @param b The value when `t` is 1
 * @param c The mixing factor
 * @param base The size of the cyclic domain. For angles specified in degrees
 * this would be 360.
 *
 * @returns a value between `a` and `b`
 */
export const mixCyclic = (a: number, b: number, t: number, base: number) => {
  let d = b - a;
  if (Math.abs(d) * 2 > base) d -= base;
  return a + d * t;
};

export const clamp = (x: number, min: number, max: number) => {
  return x < min ? min : x > max ? max : x;
};
export const saturate = (x: number) => (x < 0 ? 0 : x > 1 ? 1 : x);

export const smoothstep = (edge1: number, edge2: number, x: number) => {
  x = saturate((x - edge1) / (edge2 - edge1));
  return x * x * (3 - 2 * x);
};

export const fract = (x: number) => x - (x | 0);

export const modulo = (x: number, base: number) => {
  let result = x % base;
  if (result < 0) result += base;

  // If `x % base` is _very_ small, it's possible that adding `base` will set
  // `result === base` because of float imprecision.
  if (result === base) return 0;

  return result;
};
export const moduloDistance = (a: number, b: number, base: number) => {
  const diff = Math.abs(b - a) % base;
  return diff > base / 2 ? base - diff : diff;
};

export const angularDistance = (a: number, b: number) => {
  return moduloDistance(a, b, 360);
};

export const roundToMultiple = (x: number, factor: number) => {
  if (factor === 0) return x;
  return Math.round(x / factor) * factor;
};
