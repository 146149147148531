import { CubicSegment, GEOMETRIC_TOLERANCE, Geometry, LineSegment, Vec, saturate } from "..";
import { solveCubic } from "../math/numeric";

export const pointAtTimeOnCubic = (s: Vec, cs: Vec, ce: Vec, e: Vec, time: number, out: Vec) => {
  if (time === 0) return out.copy(s);
  if (time === 1) return out.copy(e);

  const oneMinusTime = 1 - time;
  const timeSq = time * time;
  const oneMinusTimeSq = oneMinusTime * oneMinusTime;

  const a = oneMinusTimeSq * oneMinusTime;
  const b = oneMinusTimeSq * time * 3;
  const c = oneMinusTime * timeSq * 3;
  const d = time * timeSq;

  return out.set(a * s.x + b * cs.x + c * ce.x + d * e.x, a * s.y + b * cs.y + c * ce.y + d * e.y);
};

export const timeAtPointOnCubic = (
  s: Vec,
  cs: Vec,
  ce: Vec,
  e: Vec,
  point: Vec,
  tolerance = GEOMETRIC_TOLERANCE
) => {
  // If the point is equal to one of the endpoints we can return early.
  if (point._almostEquals(s)) return 0;
  if (point._almostEquals(e)) return 1;

  const roots: number[] = [];
  const p = new Vec();

  // Check X roots
  let count = solveCubic(s.x, cs.x, ce.x, e.x, point.x, roots, 0, 1);
  for (let i = 0; i < count; ++i) {
    const t = roots[i];
    pointAtTimeOnCubic(s, cs, ce, e, t, p);
    if (point.distance(p) <= tolerance) {
      return t;
    }
  }

  // Check Y roots
  count = solveCubic(s.y, cs.y, ce.y, e.y, point.y, roots, 0, 1);
  for (let i = 0; i < count; ++i) {
    const t = roots[i];
    pointAtTimeOnCubic(s, cs, ce, e, t, p);
    if (point.distance(p) <= tolerance) {
      return t;
    }
  }

  // Check the endpoints again with the more generous tolerance used for points
  // along the cubic.
  if (point.distance(s) <= tolerance) return 0;
  if (point.distance(e) <= tolerance) return 1;

  return undefined;
};

export const timeAtPointOnLine = (s: Vec, e: Vec, point: Vec, tolerance = GEOMETRIC_TOLERANCE) => {
  // If the point is equal to one of the endpoints we can return early.
  if (point._almostEquals(s)) return 0;
  if (point._almostEquals(e)) return 1;

  const time = saturate(timeAtClosestPointOnAxis(s.x, s.y, e.x - s.x, e.y - s.y, point.x, point.y));
  const closestPoint = s.clone().mix(e, time);

  if (point.distance(closestPoint) <= tolerance) {
    return time;
  }

  return undefined;
};

/**
 * @returns the time at the closest point to `point` on an infinite line running
 * through points `s` and `e`.
 */
export const timeAtClosestPointOnAxis = (
  ox: number,
  oy: number,
  dx: number,
  dy: number,
  px: number,
  py: number
) => {
  const lenSq = dx * dx + dy * dy;

  // Avoid dividing by zero.
  if (lenSq === 0) return 0;

  return (dx * (px - ox) + dy * (py - oy)) / lenSq;
};

const evaluateQuadraticBezier = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  time: number
) => {
  const oneMinusTime = 1 - time;
  const timeSq = time * time;
  const oneMinusTimeSq = oneMinusTime * oneMinusTime;
  const a = oneMinusTimeSq;
  const b = oneMinusTime * time * 2;
  const c = timeSq;
  return new Vec(a * x1 + b * x2 + c * x3, a * y1 + b * y2 + c * y3);
};

// Bezier Bounding Box algorithm adapted from the Inigo Quilez article:
// https://iquilezles.org/www/articles/bezierbbox/bezierbbox.htm
export const boundingBoxForCubic = (s: Vec, cs: Vec, ce: Vec, e: Vec, outMin: Vec, outMax: Vec) => {
  outMin.copy(s).min(e);
  outMax.copy(s).max(e);

  const cx = cs.x - s.x;
  const cy = cs.y - s.y;
  const bx = s.x - 2.0 * cs.x + ce.x;
  const by = s.y - 2.0 * cs.y + ce.y;
  const ax = 3.0 * cs.x - 3.0 * ce.x + e.x - s.x;
  const ay = 3.0 * cs.y - 3.0 * ce.y + e.y - s.y;

  let hx = bx * bx - ax * cx;
  let hy = by * by - ay * cy;

  // When p0 === p1 we need to switch to an alternate formula for `t` to avoid
  // that degenerate case. The degeneracy with the alternate formula occurs when
  // p0.x === p3.x and p1.x === p2.x or p0.y === p3.y and p1.y === p2.y.
  //
  // TODO: Benchmark this and maybe branch once instead of 4x.
  const isDegenerate = cx === 0 && cy === 0;

  if (hx > 0.0) {
    hx = Math.sqrt(hx);
    let t = isDegenerate ? (-bx - hx) / ax : cx / (-bx - hx);
    if (t > 0.0 && t < 1.0) {
      const u = 1.0 - t;
      const q = u * u * u * s.x + 3.0 * u * u * t * cs.x + 3.0 * u * t * t * ce.x + t * t * t * e.x;
      outMin.x = Math.min(outMin.x, q);
      outMax.x = Math.max(outMax.x, q);
    }
    t = isDegenerate ? (-bx + hx) / ax : cx / (-bx + hx);
    if (t > 0.0 && t < 1.0) {
      const u = 1.0 - t;
      const q = u * u * u * s.x + 3.0 * u * u * t * cs.x + 3.0 * u * t * t * ce.x + t * t * t * e.x;
      outMin.x = Math.min(outMin.x, q);
      outMax.x = Math.max(outMax.x, q);
    }
  }

  if (hy > 0.0) {
    hy = Math.sqrt(hy);
    let t = isDegenerate ? (-by - hy) / ay : cy / (-by - hy);
    if (t > 0.0 && t < 1.0) {
      const u = 1.0 - t;
      const q = u * u * u * s.y + 3.0 * u * u * t * cs.y + 3.0 * u * t * t * ce.y + t * t * t * e.y;
      outMin.y = Math.min(outMin.y, q);
      outMax.y = Math.max(outMax.y, q);
    }
    t = isDegenerate ? (-by + hy) / ay : cy / (-by + hy);
    if (t > 0.0 && t < 1.0) {
      const u = 1.0 - t;
      const q = u * u * u * s.y + 3.0 * u * u * t * cs.y + 3.0 * u * t * t * ce.y + t * t * t * e.y;
      outMin.y = Math.min(outMin.y, q);
      outMax.y = Math.max(outMax.y, q);
    }
  }
};

export const isSegment = (geom: Geometry): geom is LineSegment | CubicSegment => {
  return geom instanceof LineSegment || geom instanceof CubicSegment;
};
