import { Color, Vec } from "../geom";
import { Expression } from "./expression";
import { Modifier } from "./modifier";
import {
  Parameter,
  makeAngleParameter,
  makeBooleanParameter,
  makeColorParameter,
  makeCountParameter,
  makeDistanceParameter,
  makeFontSelectParameter,
  makePercentageParameter,
  makePointParameter,
  makeScalarParameter,
  makeSelectParameter,
  makeSizeParameter,
  makeTextParameter,
  makeVectorParameter,
} from "./parameter";
import { registerBuiltin } from "./registry";

// Re-export legacy modifiers.
export * from "./builtin-modifiers-legacy";

export const BooleanUnionDefinition = new Modifier();
BooleanUnionDefinition.isImmutable = true;
BooleanUnionDefinition.name = "Boolean Union";
BooleanUnionDefinition.code = new Expression(
  `
if (!(input instanceof Group) || input.items.length === 0) {
  return input;
}

return CompoundPath.booleanUnion(input.items).copyStyle(input);
`.trim()
);
BooleanUnionDefinition.icon = "boolean_union";
registerBuiltin("BooleanUnionDefinition", BooleanUnionDefinition);

export const BooleanIntersectDefinition = new Modifier();
BooleanIntersectDefinition.isImmutable = true;
BooleanIntersectDefinition.name = "Boolean Intersect";
BooleanIntersectDefinition.code = new Expression(
  `
if (!(input instanceof Group) || input.items.length === 0) {
  return input;
}

return CompoundPath.booleanIntersect(input.items).copyStyle(input);
`.trim()
);
BooleanIntersectDefinition.icon = "boolean_intersection";
registerBuiltin("BooleanIntersectDefinition", BooleanIntersectDefinition);

export const BooleanDifferenceDefinition = new Modifier();
BooleanDifferenceDefinition.isImmutable = true;
BooleanDifferenceDefinition.name = "Boolean Difference";
BooleanDifferenceDefinition.code = new Expression(
  `
if (!(input instanceof Group) || input.items.length === 0) {
  return input;
}

return CompoundPath.booleanDifference(input.items).copyStyle(input);
`.trim()
);
BooleanDifferenceDefinition.icon = "boolean_difference";
registerBuiltin("BooleanDifferenceDefinition", BooleanDifferenceDefinition);

export const MaskDefinition = new Modifier();
MaskDefinition.isImmutable = true;
MaskDefinition.name = "Mask";
MaskDefinition.icon = "mask";
MaskDefinition.comment =
  "The top-most shape serves as the mask. Everything else is clipped so that only the portions within the mask remain.";
MaskDefinition.parameters = [
  makeBooleanParameter("invertMask", false).setComment(
    "When checked, only the contents outside the mask will be kept."
  ),
];
MaskDefinition.code = new Expression(
  `if (!(input instanceof Group) || input.items.length < 2) {
  console.error("Mask must be applied to a Group with at least 2 items.");
  return input;
}

const maskPath = CompoundPath.booleanUnion([input.items.pop()]);
const inputPaths = input.allPathsAndCompoundPaths();
const outPaths = [];

const shouldKeepPoint = (p) => {
  return maskPath.containsPoint(p) !== invertMask;
}

for (const path of inputPaths) {
  if (path.fill) {
    const fillPath = invertMask
      ? CompoundPath.booleanDifference([path, maskPath])
      : CompoundPath.booleanIntersect([path, maskPath]);
    fillPath.assignFill(path.fill);
    path.removeFill();
    outPaths.push(fillPath);
  }
  if (!path.stroke) continue;

  let contours;
  if (path instanceof CompoundPath) {
    contours = path.allPaths();
  }
  if (path instanceof Path) {
    contours = [path];
  }
  for (const contourPath of contours) {
    const xs = contourPath.intersectionsWith([maskPath]);

    // If there are no intersections, then all anchors are either in or out
    if (xs.length === 0) {
      const firstAnchorPos = contourPath.anchors[0].position;
      if (shouldKeepPoint(firstAnchorPos)) {
        outPaths.push(contourPath);
      }
      continue;
    }

    const times = xs.map((x) => x.primitive1.source.time + x.time1);
    const slices = contourPath.splitAtTimes(times);
    const maskedSlices = slices.filter((slice) => {
      const midTime = slice.timeAtDistance(slice.length() / 2);
      const mid = slice.positionAtTime(midTime);
      return shouldKeepPoint(mid);
    });

    if (maskedSlices.length) {
      if (maskedSlices.length === 1) {
        outPaths.push(maskedSlices[0].copyStyle(path));
      } else {
        outPaths.push(new CompoundPath(maskedSlices).copyStyle(path));
      }
    }
  }
}

return outPaths;`
);
registerBuiltin("MaskDefinition", MaskDefinition);

export const FlattenDefinition = new Modifier();
FlattenDefinition.isImmutable = true;
FlattenDefinition.name = "Flatten";
FlattenDefinition.icon = "flatten";
FlattenDefinition.parameters = [
  makeColorParameter("backgroundColor", new Color(1, 1, 1, 1)).setComment(
    "Anything filled with this color will be subtracted out of your result."
  ),
  makeBooleanParameter("strokeResult", false).setComment(
    "Replaces all fills with strokes in the result."
  ),
  makeBooleanParameter("distribute", false).setComment("Spaces out each colored layer separately."),
];
FlattenDefinition.code = new Expression(
  `
const layered = false;
const result = Group.flatten(input, backgroundColor, layered);
if (strokeResult) {
  result.items.forEach(shape => {
    shape.assignStroke(Stroke(shape.fill.color));
    shape.removeFill();
  });
}
if (distribute) {
  let left;
  result.items.forEach(shape => {
    const box = shape.boundingBox();
    if (left === undefined) {
      left = box.max.x;
    } else {
      shape.transform({ position: Vec(left - box.min.x, 0) });
      left += box.width();
    }
  });
}
if (result.items.length === 0) {
  console.warn("Flatten only works on shapes with a fill or non-hairline stroke.")
}
return result;
`.trim()
);
registerBuiltin("FlattenDefinition", FlattenDefinition);

export const LayeredStackDefinition = new Modifier();
LayeredStackDefinition.isImmutable = true;
LayeredStackDefinition.name = "Layered Stack";
LayeredStackDefinition.icon = "layered_stack";
LayeredStackDefinition.parameters = [
  makeColorParameter("backgroundColor", new Color(1, 1, 1, 1)),
  makeBooleanParameter("strokeResult", false).setComment(
    "Replaces all fills with strokes in the result."
  ),
  makeBooleanParameter("distribute", false).setComment("Spaces out each colored layer separately."),
];
LayeredStackDefinition.code = new Expression(
  `
const layered = true;
const result = Group.flatten(input, backgroundColor, layered);
if (strokeResult) {
  result.items.forEach(shape => {
    shape.assignStroke(Stroke(shape.fill.color));
    shape.removeFill();
  });
}
if (distribute) {
  let left;
  result.items.forEach(shape => {
    const box = shape.boundingBox();
    if (left === undefined) {
      left = box.max.x;
    } else {
      shape.transform({ position: Vec(left - box.min.x, 0) });
      left += box.width();
    }
  });
}
if (result.items.length === 0) {
  console.warn("Layered Stack only works on shapes with a fill or non-hairline stroke.")
}
return result;
`.trim()
);
registerBuiltin("LayeredStackDefinition", LayeredStackDefinition);

export const RemoveHolesDefinition = new Modifier();
RemoveHolesDefinition.isImmutable = true;
RemoveHolesDefinition.name = "Remove Holes";
RemoveHolesDefinition.icon = "remove_holes";
RemoveHolesDefinition.code = new Expression(
  `for (let compoundPath of input.allCompoundPaths()) {
  const union = CompoundPath.booleanUnion(compoundPath.paths);
  compoundPath.paths = union.paths;
}
return input;`
);
registerBuiltin("RemoveHolesDefinition", RemoveHolesDefinition);

export const OutlineStrokeDefinition = new Modifier();
OutlineStrokeDefinition.isImmutable = true;
OutlineStrokeDefinition.name = "Outline Stroke";
OutlineStrokeDefinition.parameters = [
  makeDistanceParameter("width", 0.1),
  makeScalarParameter("miterLimit", 4),
  makeSelectParameter("join", "round", ["miter", "round", "bevel"]),
  makeSelectParameter("cap", "round", ["butt", "round", "square"]),
];
OutlineStrokeDefinition.code = new Expression(
  `
return CompoundPath.stroke(input, {width, miterLimit, join, cap}).copyStyle(input);
`.trim()
);
OutlineStrokeDefinition.icon = "stroke";
registerBuiltin("OutlineStrokeDefinition", OutlineStrokeDefinition);

export const ExpandDefinition = new Modifier();
{
  ExpandDefinition.isImmutable = true;
  ExpandDefinition.name = "Expand";
  const distanceParam = makeDistanceParameter("distance", 0.1);
  distanceParam.comment = "For kerf compensation, set distance to half the kerf width.";
  ExpandDefinition.parameters = [
    distanceParam,
    makeScalarParameter("miterLimit", 4),
    makeSelectParameter("join", "round", ["miter", "round", "bevel"]),
  ];
  ExpandDefinition.code = new Expression(
    `if (distance === 0) return input;
if (distance < 0) return Contract({ distance: -distance, miterLimit, join }, input);

return CompoundPath.booleanUnion([
  input,
  CompoundPath.stroke(input, { width: distance * 2, miterLimit, join }),
]).copyStyle(input);`
  );
  ExpandDefinition.icon = "expand";
  registerBuiltin("ExpandDefinition1", ExpandDefinition);
}

export const ContractDefinition = new Modifier();
{
  ContractDefinition.isImmutable = true;
  ContractDefinition.name = "Contract";
  ContractDefinition.parameters = [
    makeDistanceParameter("distance", 0.1),
    makeScalarParameter("miterLimit", 4),
    makeSelectParameter("join", "round", ["miter", "round", "bevel"]),
  ];
  ContractDefinition.code = new Expression(
    `if (distance === 0) return input;
if (distance < 0) return Expand({ distance: -distance, miterLimit, join }, input);

return CompoundPath.booleanDifference([
  input,
  CompoundPath.stroke(input, { width: distance * 2, miterLimit, join }),
]).copyStyle(input);`
  );
  ContractDefinition.icon = "contract";
  registerBuiltin("ContractDefinition1", ContractDefinition);
}

export const MergePathsDefinition = new Modifier();
MergePathsDefinition.isImmutable = true;
MergePathsDefinition.name = "Merge Paths";
const connectDistanceParameter = makeDistanceParameter("connectDistance", 1);
connectDistanceParameter.comment =
  "Path endpoints that are closer than this distance will be connected together.";
MergePathsDefinition.parameters = [connectDistanceParameter];
MergePathsDefinition.icon = "merge_paths";
MergePathsDefinition.code = new Expression(
  `
// Pre-compute some squared distances for faster distance comparisons later.
const mergeDistanceSquared = GEOMETRIC_TOLERANCE * GEOMETRIC_TOLERANCE;
const connectDistanceSquared = connectDistance * connectDistance;
const maxDistanceSquared = Math.max(mergeDistanceSquared, connectDistanceSquared);

const output = [];
for (let paths of input.allPathsByColor()) {
  const endPoints = [];

  const removeEndPoint = (ep) => {
    const index = endPoints.indexOf(ep);
    if (index === -1) throw "EndPoint does not exist";
    endPoints.splice(index, 1);
  };
  const removePath = (path) => {
    const index = paths.indexOf(path);
    if (index === -1) throw "Path does not exist";
    paths.splice(index, 1);
  };

  for (let path of paths) {
    if (!path.closed && path.anchors.length > 0) {
      const startEndPoint = { path, start: true };
      const endEndPoint = { path, start: false };
      startEndPoint.other = endEndPoint;
      endEndPoint.other = startEndPoint;
      endPoints.push(startEndPoint, endEndPoint);
    }
  }

  const reversePathForEndPoint = (ep) => {
    ep.path.reverse();
    ep.start = !ep.start;
    ep.other.start = !ep.other.start;
  };

  const endPointAnchor = (ep) => {
    return ep.start ? ep.path.firstAnchor() : ep.path.lastAnchor();
  };

  const joinEndPoints = (ep1, ep2, isMerge) => {
    // ep1 and ep2 will no longer exist.
    removeEndPoint(ep1);
    removeEndPoint(ep2);

    if (ep1.path === ep2.path) {
      // Closing a single path.
      if (isMerge) {
        console.geometry(ep1.path.firstAnchor().clone());
        ep1.path.firstAnchor().handleIn.copy(ep1.path.lastAnchor().handleIn);
        ep1.path.anchors.splice(ep1.path.anchors.length - 1, 1);
      } else {
        console.geometry(Path([ep1.path.lastAnchor().clone(), ep2.path.firstAnchor().clone()]));
      }
      ep1.path.closed = true;
    } else {
      // ep2.path will no longer exist.
      removePath(ep2.path);

      if (ep1.start) {
        // We'll prepend ep2.path onto ep1.path.
        if (ep2.start) reversePathForEndPoint(ep2);
        if (isMerge) {
          console.geometry(ep1.path.firstAnchor().clone());
          ep1.path.firstAnchor().handleIn.copy(ep2.path.lastAnchor().handleIn);
          ep1.path.anchors.splice(0, 0, ...ep2.path.anchors.slice(0, -1));
        } else {
          console.geometry(Path([ep2.path.lastAnchor().clone(), ep1.path.firstAnchor().clone()]));
          ep1.path.anchors.splice(0, 0, ...ep2.path.anchors);
        }
      } else {
        // We'll append ep2.path onto ep1.path.
        if (!ep2.start) reversePathForEndPoint(ep2);
        if (isMerge) {
          console.geometry(ep1.path.lastAnchor().clone());
          ep1.path.lastAnchor().handleOut.copy(ep2.path.firstAnchor().handleOut);
          ep1.path.anchors.push(...ep2.path.anchors.slice(1));
        } else {
          console.geometry(Path([ep1.path.lastAnchor().clone(), ep2.path.firstAnchor().clone()]));
          ep1.path.anchors.push(...ep2.path.anchors);
        }
      }

      // Make others point to each other.
      const ep1other = ep1.other;
      const ep2other = ep2.other;
      ep1other.other = ep2other;
      ep2other.other = ep1other;
      ep2other.path = ep1other.path;
    }
  };

  // Consider each pair of endpoints, calculate their distance squared, and order them by this metric.
  const endPointPairs = [];
  for (let i = 0; i < endPoints.length; i++) {
    const ep1 = endPoints[i];
    const anchor1 = endPointAnchor(ep1);
    for (let j = i + 1; j < endPoints.length; j++) {
      const ep2 = endPoints[j];
      const anchor2 = endPointAnchor(ep2);
      if (anchor1 === anchor2) continue;
      const distSq = anchor1.position.distanceSquared(anchor2.position);
      if (distSq <= maxDistanceSquared) {
        endPointPairs.push({ ep1, ep2, distSq });
      }
    }
  }
  endPointPairs.sort((a, b) => a.distSq - b.distSq);

  for (let { ep1, ep2, distSq } of endPointPairs) {
    if (endPoints.indexOf(ep1) !== -1 && endPoints.indexOf(ep2) !== -1) {
      const isMerge = distSq <= mergeDistanceSquared;
      joinEndPoints(ep1, ep2, isMerge);
    }
  }

  output.push(...paths);
}
return output;  
`.trim()
);
registerBuiltin("MergePathsDefinition1", MergePathsDefinition);

export const MirrorRepeatDefinition = new Modifier();
MirrorRepeatDefinition.isRepeat = true;
MirrorRepeatDefinition.isImmutable = true;
MirrorRepeatDefinition.name = "Mirror Repeat";
MirrorRepeatDefinition.parameters = [
  makePointParameter("point1", new Vec(0, 0)),
  makePointParameter("point2", new Vec(0, 1)),
  makeBooleanParameter("reverse", false),
  // Hack, to be able to "customize each repetition" but not show this parameter
  new Parameter("repetitions", "return 2;\npoint1 // hide this parameter"),
];
MirrorRepeatDefinition.code = new Expression(
  `const angle = (point2 - point1).angle();
const matrix = AffineMatrix.fromTranslation(point1)
  .mul(AffineMatrix.fromRotation(angle))
  .mul(AffineMatrix.fromScale(Vec(1, -1)))
  .mul(AffineMatrix.fromRotation(-angle))
  .mul(AffineMatrix.fromTranslation(-1 * point1));

inputs[1].affineTransform(matrix);
if (reverse) {
  inputs[1].reverse();
}

console.guide(Axis(point1, point2 - point1));

return inputs;`
);
MirrorRepeatDefinition.icon = "mirror_repeat";
registerBuiltin("MirrorRepeatDefinition", MirrorRepeatDefinition);

export const LinearRepeatDefinition = new Modifier();
LinearRepeatDefinition.isRepeat = true;
LinearRepeatDefinition.isImmutable = true;
LinearRepeatDefinition.name = "Linear Repeat";
LinearRepeatDefinition.parameters = [
  makeVectorParameter("displacement", new Vec(1, 0)),
  makeCountParameter("repetitions", 6),
];
LinearRepeatDefinition.code = new Expression(
  `repetitions = trunc(repetitions);

if (repetitions > 1000) {
  throw new Error('Linear Repeat is limited to 1000 repetitions.');
}
for (let i = 0; i < repetitions; i++) {
  const matrix = AffineMatrix.fromTranslation(displacement * i);
  inputs[i].affineTransform(matrix);
}
return inputs;`
);
LinearRepeatDefinition.icon = "linear_repeat";
registerBuiltin("LinearRepeatDefinition", LinearRepeatDefinition);

export const RotationalRepeatDefinition = new Modifier();
RotationalRepeatDefinition.isRepeat = true;
RotationalRepeatDefinition.isImmutable = true;
RotationalRepeatDefinition.name = "Rotational Repeat";
RotationalRepeatDefinition.parameters = [
  makePointParameter("center", new Vec()),
  makeCountParameter("repetitions", 6),
  makeAngleParameter("endAngle", 360).setComment(
    "Set this lower than 360° to repeat partially around the center point."
  ),
  makeBooleanParameter("includeEnd", false).setComment(
    "Turn this on to include a repetition at the endAngle. You usually want this off if endAngle is 360°."
  ),
];
RotationalRepeatDefinition.code = new Expression(
  `repetitions = trunc(repetitions);

if (repetitions > 1000) {
  throw new Error("Rotational Repeat is limited to 1000 repetitions.");
}

console.guide(center);

const angleInterval = endAngle / (includeEnd ? repetitions - 1 : repetitions);
for (let i = 0; i < repetitions; i++) {
  const matrix = AffineMatrix.fromTranslation(center)
    .mul(AffineMatrix.fromRotation(i * angleInterval))
    .mul(AffineMatrix.fromTranslation(-center));
  inputs[i].affineTransform(matrix);
}
return inputs;`
);
RotationalRepeatDefinition.icon = "rotational_repeat";
registerBuiltin("RotationalRepeatDefinition", RotationalRepeatDefinition);

export const TileRepeatDefinition = new Modifier();
TileRepeatDefinition.isRepeat = true;
TileRepeatDefinition.isImmutable = true;
TileRepeatDefinition.name = "Tile Repeat";
TileRepeatDefinition.parameters = [
  makeVectorParameter("displacement1", new Vec(1, 0)),
  makeVectorParameter("displacement2", new Vec(0, 1)),
  makeCountParameter("repetitions1", 4),
  makeCountParameter("repetitions2", 4),
  makeBooleanParameter("zigzag", false),
  new Parameter("repetitions", "repetitions1 * repetitions2"),
];
TileRepeatDefinition.code = new Expression(
  `repetitions1 = trunc(repetitions1);
repetitions2 = trunc(repetitions2);

if (repetitions1 > 1000 || repetitions2 > 1000) {
  throw new Error('Tile Repeat is limited to 1000 repetitions.');
}

let getPosition = (i, j) => {
  return displacement1 * i + displacement2 * j;
}

if (zigzag) {
  // Split displacement1 into its parallel and perpendicular components relative to displacement2
  const d1 = displacement1.isZero() ? new Vec(1, 0) : displacement1;
  const d1parallel = displacement2.clone().projectOnto(d1);
  const d1perpendicular = displacement2 - d1parallel;
  getPosition = (i, j) => {
    return displacement1 * i + d1perpendicular * j + d1parallel * (j % 2);
  }
}

let index = 0;
for (let i = 0; i < repetitions1; i++) {
  for (let j = 0; j < repetitions2; j++) {
    const matrix = AffineMatrix.fromTranslation(getPosition(i, j));
    inputs[index++].affineTransform(matrix);
  }
}
return inputs;`
);
TileRepeatDefinition.icon = "tile_repeat";
registerBuiltin("TileRepeatDefinition", TileRepeatDefinition);

export const TransformRepeatDefinition = new Modifier();
TransformRepeatDefinition.isRepeat = true;
TransformRepeatDefinition.isImmutable = true;
TransformRepeatDefinition.name = "Transform Repeat";
TransformRepeatDefinition.parameters = [
  makeCountParameter("repetitions", 6),
  makePointParameter("position", new Vec(1, 0)),
  makeAngleParameter("rotation", 0),
  makeSizeParameter("scale", new Vec(1)),
  makeAngleParameter("skew", 0),
  makePointParameter("origin", new Vec(0, 0)),
  makeDistanceParameter("scaleStroke", 1),
];
TransformRepeatDefinition.icon = "transform_repeat";
TransformRepeatDefinition.code = new Expression(
  `repetitions = trunc(repetitions);

if (repetitions > 1000) {
  throw new Error('Transform Repeat is limited to 1000 repetitions.');
}
const result = [inputs[0]];
let transformMatrix = AffineMatrix();
let repeatMatrix = AffineMatrix.fromTransform({ position, rotation, scale, skew, origin });
let strokeScale = 1;
for (let i = 1; i < repetitions; i++) {
  transformMatrix.mul(repeatMatrix);
  strokeScale *= scaleStroke;
  inputs[i].affineTransform(transformMatrix);
  inputs[i].scaleStroke(strokeScale);
}
return inputs;`
);
registerBuiltin("TransformRepeatDefinition", TransformRepeatDefinition);

export const RoundCornersDefinition = new Modifier();
RoundCornersDefinition.isImmutable = true;
RoundCornersDefinition.name = "Round Corners";
RoundCornersDefinition.parameters = [makeDistanceParameter("radius", 0.25)];
RoundCornersDefinition.code = new Expression(
  `for (let path of input.allPaths()) {
  path.mergeAnchors();
  path.roundCorners(radius);
}
return input;`
);
RoundCornersDefinition.icon = "round_corners";
registerBuiltin("RoundCornersDefinition", RoundCornersDefinition);

export const DashedLinesDefinition = new Modifier();
DashedLinesDefinition.isImmutable = true;
DashedLinesDefinition.name = "Dashed Lines";
DashedLinesDefinition.parameters = [
  makeDistanceParameter("dashLength", 0.1).setComment(
    "Length of dashes and gaps along the path. To make a pattern with different dash and gap lengths use an array expression, for example: [0.2, 0.1]"
  ),
  makeDistanceParameter("offset", 0.0).setComment("Offsets the start of the dash pattern."),
];
DashedLinesDefinition.code = new Expression(
  `let dashLengths = dashLength;

if (typeof dashLength === "number") {
  dashLengths = [dashLength, dashLength];
}

if (!Array.isArray(dashLengths)) {
  throw Error("dashLength parameter has to be a number or array, like [2, 1]");
}

// Double the array if it's not even, to make offset easier to work with
if (dashLengths.length % 2 !== 0) {
  dashLengths = dashLengths.concat(dashLengths);
}

const dashLengthsSum = dashLengths
  .reduce((dash, sum) => dash + sum, 0);

if (dashLengthsSum <= 0) {
  throw Error("Dash lengths need to add up to more than 0.");
};

const dashLengthsCount = dashLengths.length;

const out = input.allPaths().map((path) => {
  const splitPath = [];
  const pathLength = path.length();

  if (pathLength / dashLengthsSum * dashLengthsCount > 10000) {
    throw Error("Too many dashes, try a bigger value in dashLength.");      
  }

  let i = 0;
  let head = 0;
  // We want to start the dash pattern on or before the start of the path 
  if (offset !== 0) {
    head = modulo(offset, dashLengthsSum) - dashLengthsSum;
  }
  while (head < pathLength) { 
    const dash = dashLengths[i % dashLengthsCount]; 
    const space = dashLengths[(i+1) % dashLengthsCount]; 
    let dashStart = head;
    let dashEnd = head + dash;
    if (dashStart < 0) {
      dashStart = 0;
    }
    if (dashEnd > pathLength) {
      dashEnd = pathLength;
    }
    if (
      dashStart >= 0 && dashEnd >= 0 && 
      dashStart < pathLength && dashEnd <= pathLength && 
      dashStart < dashEnd
    ) {
      splitPath.push(
        path.slice(
          path.timeAtDistance(dashStart), 
          path.timeAtDistance(dashEnd)
        )
      );
    }
    head += dash + space;
    i += 2;
  };
  // Merge last and first dash if needed
  if (path.closed) {
    const firstDash = splitPath[0];
    const firstAnchor = firstDash.anchors[0];
    const lastDash = splitPath[splitPath.length - 1];
    const lastAnchor = lastDash.anchors[lastDash.anchors.length - 1];
    if (
      firstAnchor.position.equals(lastAnchor.position)
    ) {
      const combinedDash = lastDash === firstDash 
        ? Path.fromFragments([firstDash]) 
        : Path.fromFragments([lastDash, firstDash]);
      splitPath.pop();
      splitPath.splice(0, 1, combinedDash);
    }
  }
  const compoundPath = CompoundPath(splitPath);
  // Ignores fill
  compoundPath.assignStroke(path.stroke);
  return compoundPath;
});

return out;`
);
DashedLinesDefinition.icon = "dashed_lines";
registerBuiltin("DashedLinesDefinition", DashedLinesDefinition);

export const TextAlongPathDefinition = new Modifier();
TextAlongPathDefinition.isImmutable = true;
TextAlongPathDefinition.name = "Text Along Path";
TextAlongPathDefinition.parameters = [
  makeTextParameter("text", "Text Along Path"),
  makeFontSelectParameter(
    "font",
    "https://fonts.gstatic.com/s/notosans/v26/o-0IIpQlx3QUlC5A4PNb4j5Ba_2c7A.ttf" // Noto Sans Regular
  ),
  makeDistanceParameter("size", 1),
  makeBooleanParameter("scaleToFit", false).setComment(
    `Fits text to the path exactly. Overrides the "size" and "align" parameters.`
  ),
  makeSelectParameter("align", "center", ["start", "center", "end"]),
  makePercentageParameter("offset", 0, 3).setComment(
    "Offsets text alignment by a percentage of the length of the path."
  ),
  makeSelectParameter("verticalAlign", "middle", ["top", "middle", "baseline", "bottom"]),
  makePercentageParameter("verticalOffset", 0, 3),
  makePercentageParameter("letterSpacing", 0, 3),
  makeBooleanParameter("flip", false),
];
TextAlongPathDefinition.code = new Expression(`const myFont = getFontFromURL(font);
if (!myFont) return [];

const rich = RichText(text);
const textLines = rich.split("\\n");
const glyphLines = textLines.map((line) => {
  return myFont.glyphsFromString(line, { letterSpacing });
});

if (glyphLines.length === 0) return [];

const allPaths = input.allPaths();

if (allPaths.length > 1 || glyphLines.length > 1) {
  console.info(
    "You can use line breaks to flow the text along more than one path."
  );
}

// Vertical Alignment
if (verticalAlign === "top") {
  verticalOffset -= myFont.ascenderHeight;
} else if (verticalAlign === "middle") {
  verticalOffset -= (myFont.ascenderHeight + myFont.descenderHeight) / 2;
} else if (verticalAlign === "bottom") {
  verticalOffset -= myFont.descenderHeight;
}

const out = allPaths.map((path, i) => {
  console.geometry(path);

  const glyphs = glyphLines[i];
  if (!glyphs || glyphs.length === 0) return [];

  const lastGlyph = glyphs[glyphs.length - 1];
  let combinedWidth = 0;
  for (const glyph of glyphs) {
    combinedWidth += glyph.advanceX;
  }
  const combinedWidthSized = combinedWidth * size;

  const pathLength = path.length();
  const offsetLength = offset * pathLength;

  if (!scaleToFit && combinedWidthSized > pathLength) {
    console.warn(
      "The text does not fit on the path. Try using a smaller size, or check scaleToFit."
    );
  }
  let scale = size;
  if (scaleToFit) {
    if (path.closed) {
      // Adds one letterSpacing to make the scaled text look better when wrapping around a closed path.
      scale = pathLength / (combinedWidth + letterSpacing);
    } else {
      scale = pathLength / combinedWidth;
    }
  }
  if (flip) {
    if (path.closed) {
      scale *= -1;
    } else {
      path.reverse();
    }
  }
  const combinedWidthScaled = combinedWidth * scale;

  // Show starting position
  let textOriginDistance = offsetLength;
  if (align === "center") {
    textOriginDistance += pathLength * 0.5;
  } else if (align === "end") {
    textOriginDistance += pathLength;
  }
  if (path.closed) {
    // Wrap around
    textOriginDistance = modulo(textOriginDistance, pathLength);
  } else {
    // Clamp to path
    textOriginDistance = clamp(textOriginDistance, 0, pathLength);
  }
  const textOriginTime = path.timeAtDistance(textOriginDistance);
  const textOriginPosition = path.positionAtTime(textOriginTime);
  const textOriginNormal = path.normalAtTime(textOriginTime);
  textOriginPosition.add(
    textOriginNormal.clone().mulScalar(verticalOffset * -1 * scale)
  );
  console.geometry(
    LineSegment(
      textOriginPosition +
        textOriginNormal.clone().mulScalar(myFont.ascenderHeight * -1 * scale),
      textOriginPosition +
        textOriginNormal.clone().mulScalar(myFont.descenderHeight * -1 * scale)
    )
  );

  let cursorX = 0;
  const output = [];
  for (let glyph of glyphs) {
    const { advanceX, advanceWidth, geometry } = glyph;
    const origin = advanceWidth * scale * 0.5;

    let distance = offsetLength + cursorX * scale + origin;

    if (align === "center") {
      distance += pathLength * 0.5 - combinedWidthScaled * 0.5;
    } else if (align === "end") {
      distance += pathLength - combinedWidthScaled;
    }
    if (path.closed) {
      distance = modulo(distance, pathLength);
    } else if (distance < 0 || distance > pathLength) {
      // Don't render glyphs that overflow from an open path
      continue;
    }

    const t = path.timeAtDistance(distance);
    const position = path.positionAtTime(t);
    const derivative = path.derivativeAtTime(t);

    output.push(
      geometry.clone().transform({
        // Unscaled origin
        origin: Vec(advanceWidth * 0.5, verticalOffset),
        scale,
        position,
        rotation: derivative.angle(),
      })
    );

    cursorX += advanceX;
  }
  return Group(output).copyStyle(path);
});

return out;`);
TextAlongPathDefinition.icon = "text_along_path";
registerBuiltin("TextAlongPathDefinition", TextAlongPathDefinition);

export const TextWithinBoxDefinition = new Modifier();
TextWithinBoxDefinition.isImmutable = true;
TextWithinBoxDefinition.name = "Text Within Box";
TextWithinBoxDefinition.icon = "text_within_box";
TextWithinBoxDefinition.parameters = [
  makeTextParameter("text", "Text"),
  makeFontSelectParameter(
    "font",
    "https://fonts.gstatic.com/s/notosans/v26/o-0IIpQlx3QUlC5A4PNb4j5Ba_2c7A.ttf" // Noto Sans Regular
  ),
  makeSelectParameter("align", "center", ["left", "center", "right"]),
  makeSelectParameter("verticalAlign", "middle", ["top", "middle", "bottom"]),
  makePercentageParameter("letterSpacing", 0, 3),
  makeScalarParameter("lineHeight", 1, 3),
  makeSelectParameter("scaling", "metrics", ["shrink", "metrics", "grow"])
    .setComment(`metrics - will size using the font’s metrics
shrink - will size using the font's metrics but then shrink if necessary to force text to always lie strictly within the box, even if the font has characters that overhang their boundaries (some script fonts)
grow - will ignore font's metrics and grow the text to be as large as possible so that it’s still fitting within the box.`),
];
TextWithinBoxDefinition.code = new Expression(`// === Generate the text ===

const myFont = getFontFromURL(font);
if (!myFont) return [];

const linesInfo = myFont.render(text, { letterSpacing });

let textWidth = 0;
const lineGroups = [];
for (let i = 0, len = linesInfo.length; i < len; i++) {
  const line = linesInfo[i];
  let { geometry, advanceX } = line;
  textWidth = max(textWidth, advanceX);

  const position = Vec();

  // Horizontal Alignment
  if (align === "center") position.x -= advanceX / 2;
  else if (align === "right") position.x -= advanceX;

  position.y = i * lineHeight + myFont.ascenderHeight;
  geometry.transform({ position });
  lineGroups.push(geometry);
}
const textGeometry = Group(lineGroups);

const position = Vec();
if (align === "center") position.x = textWidth / 2;
else if (align === "right") position.x = textWidth;
textGeometry.transform({ position });

let textHeight =
  myFont.ascenderHeight +
  (lineGroups.length - 1) * lineHeight -
  myFont.descenderHeight;

// === Alterations for scaling ===

if (scaling === "shrink") {
  const textGeometryBoundingBox = textGeometry.boundingBox();
  if (textGeometryBoundingBox) {
    const overhangMin = max(Vec(0, 0), -textGeometryBoundingBox.min);
    const overhangMax = max(
      Vec(0, 0),
      textGeometryBoundingBox.max - Vec(textWidth, textHeight)
    );
    if (align === "center") {
      overhangMin.x = overhangMax.x = max(overhangMin.x, overhangMax.x);
    }
    if (verticalAlign === "middle") {
      overhangMin.y = overhangMax.y = max(overhangMin.y, overhangMax.y);
    }
    textGeometry.transform({ position: overhangMin });
    textWidth += overhangMin.x + overhangMax.x;
    textHeight += overhangMin.y + overhangMax.y;
  }
} else if (scaling === "grow") {
  const textGeometryBoundingBox = textGeometry.boundingBox();
  if (textGeometryBoundingBox) {
    textGeometry.transform({ position: -textGeometryBoundingBox.min });
    textWidth = textGeometryBoundingBox.width();
    textHeight = textGeometryBoundingBox.height();
  }
}

// === Put it in the boxes ===

const allPaths = input.allPaths();

const warning =
  "The Text Within Box modifier can only be applied to Rectangles.";

if (allPaths.length === 0) {
  console.warn(warning);
  return;
}

const result = [];

allPaths.forEach((path) => {
  if (path.anchors.length !== 4) {
    console.warn(warning);
    return;
  }

  const p1 = path.anchors[0].position;
  const p2 = path.anchors[1].position;
  const p3 = path.anchors[3].position;

  const width = p1.distance(p2);
  const height = p1.distance(p3);

  const geometry = textGeometry.clone();
  let scale = min(width / textWidth, height / textHeight);
  const rotation = (p2 - p1).angle();

  let position = p1;
  if (align === "center") {
    position += (p2 - p1).normalize() * (width - textWidth * scale) * 0.5;
  } else if (align === "right") {
    position += (p2 - p1).normalize() * (width - textWidth * scale);
  }
  if (verticalAlign === "middle") {
    position += (p3 - p1).normalize() * (height - textHeight * scale) * 0.5;
  } else if (verticalAlign === "bottom") {
    position += (p3 - p1).normalize() * (height - textHeight * scale);
  }

  // Flip it over if the Rectangle is inverted.
  if ((p2 - p1).isClockwiseFrom(p3 - p1)) {
    scale *= Vec(1, -1);
  }

  geometry.transform({ position, rotation, scale });

  geometry.copyStyle(path);

  // Original Box
  console.geometry(path);
  // Text Bounding Box
  const textBoundingBox = BoundingBox(Vec(0, 0), Vec(textWidth, textHeight));
  console.geometry(
    Path.fromBoundingBox(textBoundingBox).transform({
      position,
      rotation,
      scale,
    })
  );

  result.push(geometry);
});

return result;`);
registerBuiltin("TextWithinBoxDefinition", TextWithinBoxDefinition);

export const WarpCoordinatesDefinition = new Modifier();
{
  WarpCoordinatesDefinition.isImmutable = true;
  WarpCoordinatesDefinition.name = "Warp Coordinates";
  WarpCoordinatesDefinition.icon = "warp_coordinates";
  WarpCoordinatesDefinition.parameters = [
    new Parameter(
      "mapping",
      `// Sinusoid
const wavelength = Vec(1.00, 1.00);
const amplitude = Vec(0.10, 0.10);
const frequency = 360 / wavelength;
return (p) => {
  const displacement = Vec(
    sin(p.y * frequency.y),
    sin(p.x * frequency.x)
  );
  return p + amplitude * displacement;
};

// Try out these other examples:

// Cartesian to Polar
// const startAngle = 90;
// const degreesPerUnit = -180;
// return (p) => {
//   const angle = startAngle + p.x * degreesPerUnit;
//   return Vec.fromAngle(angle) * p.y;
// }

// Lens
// const pinch = 0.50;
// const scale = 1.00;
// return (p) => {
//   const r = p.length() / scale;
//   return p * (pinch * r*r + (1 - pinch));
// }`
    ),
    makeScalarParameter("tolerance", 0.001),
  ];
  WarpCoordinatesDefinition.code = new Expression(`for (let path of input.allPaths()) {
  path.warpCoordinates(mapping, tolerance);
}
return input;`);
}
registerBuiltin("WarpCoordinatesDefinition", WarpCoordinatesDefinition);

export const RemoveOverlapsDefinition = new Modifier();
RemoveOverlapsDefinition.isImmutable = true;
RemoveOverlapsDefinition.name = "Remove Overlaps";
RemoveOverlapsDefinition.icon = "remove_overlaps";
RemoveOverlapsDefinition.parameters = [
  makeScalarParameter("tolerance", 0.0001).setComment(
    "Overlaps that are within this distance will be removed."
  ),
  makeColorParameter("overlapColor", "none").setComment(
    "Optionally re-color all overlapping segments with this color. Choose “none” if you don’t want to use this feature."
  ),
  makeBooleanParameter("mergePaths", true),
];
RemoveOverlapsDefinition.code = new Expression(`const spansUnion = (path, spans) => {
  // Sort the overlaps so we only need to iterate over them once.
  spans = spans.slice().sort((a, b) => a.time1 - b.time1);
  const endTime = path.endTime();
  const mergedSpans = [];
  for (const span1 of spans) {
    let merged = false;
    for (const span2 of mergedSpans) {
      // Merge the span into the existing merged span if they overlap.
      if (spansOverlapInTime(span1, span2)) {
        span2.time1 = Math.min(span1.time1, span2.time1);
        span2.time2 = Math.max(span1.time2, span2.time2);
        // If the span is already larger than the max time, clamp to the
        // canonical start and end times.
        if (spanLength(span2) >= endTime) {
          // A merged span already covers the whole path, so just return the
          // canonical full span.
          return [{ time1: 0, time2: endTime }];
        }
        merged = true;
      }
    }
    if (!merged) {
      mergedSpans.push({ time1: span1.time1, time2: span1.time2 });
    }
  }
  // Merge loop-connected span.
  if (path.closed) {
    if (mergedSpans.length >= 2) {
      const firstSpan = mergedSpans[0];
      const lastSpan = mergedSpans[mergedSpans.length - 1];
      if (lastSpan.time2 - endTime === firstSpan.time1) {
        lastSpan.time2 = firstSpan.time2 + endTime;
        mergedSpans.shift();
      }
    }
  }
  return mergedSpans;
};

const spansDifference = (path, spans, spansToSubtract) => {
  // Union spans before working with them so there are no overlaps. This also
  // sorts them by time.
  spans = spansUnion(path, spans);
  spansToSubtract = spansUnion(path, spansToSubtract);

  // Indentity
  if (spansToSubtract.length < 1) {
    return spans;
  }
  // Subtract everything
  const endTime = path.endTime();
  if (
    spansToSubtract.length === 1 &&
    spanLength(spansToSubtract[0]) >= endTime
  ) {
    return [];
  }

  const outputSpans = [];
  for (const span of spans) {
    let { time1, time2 } = span;
    if (path.closed) {
      // If there is a wrapping span to remove it will be the last one. Bump up
      // time1 to subtract the wrapping span.
      const lastSpanToSubtract = spansToSubtract[spansToSubtract.length - 1];
      if (
        lastSpanToSubtract.time1 < time2 &&
        lastSpanToSubtract.time2 > endTime &&
        lastSpanToSubtract.time2 > time1
      ) {
        time1 = path.normalizedTime(lastSpanToSubtract.time2);
      }
    }
    for (const spanToSubtract of spansToSubtract) {
      // Skip spans that don't overlap.
      if (!spansOverlapInTime(span, spanToSubtract)) continue;
      // Check if there is a new uncovered span.
      if (spanToSubtract.time1 > time1) {
        // The front is uncovered. Add it to the output.
        outputSpans.push({ time1, time2: spanToSubtract.time1 });
      }
      // Set the start of the next uncovered span.
      time1 = spanToSubtract.time2;
    }
    if (time1 < time2) {
      outputSpans.push({ time1, time2 });
    }
  }

  return spansUnion(path, outputSpans);
};

const spansInverse = (path, spans) => {
  const fullSpan = { time1: 0, time2: path.endTime() };
  return spansDifference(path, [fullSpan], spans);
};

const pathSlices = (path, spans) => {
  return spans.map((span) => path.slice(span.time1, span.time2));
};

// Remove Overlaps is designed to work with stroked paths, so we break apart any
// compound paths, copying their styles to their component paths.
const paths = input.allPathsAndCompoundPaths().flatMap((path) => {
  if (path instanceof CompoundPath) {
    return path.paths.map((p) => p.copyStyle(path));
  }
  return path;
});

const spansOverlapInTime = (span1, span2) => {
  return span1.time2 >= span2.time1 && span1.time1 <= span2.time2;
};
const spanIncludesTime = (span, time) => {
  return time >= span.time1 && time <= span.time2;
};
const spanLength = (span) => {
  return span.time2 - span.time1;
};

// Find overlaps with paths above each path and remove them.
const outputOverlapPaths = [];
const outputPaths = [];
const spansToRemove = new Map();
const ensureSpansToRemove = (path) => {
  let spans = spansToRemove.get(path);
  if (!spans) {
    spans = [];
    spansToRemove.set(path, spans);
  }
  return spans;
};
for (let i = 0; i < paths.length; ++i) {
  const path1 = paths[i]; // Current path
  const existingSpansToRemove = ensureSpansToRemove(path1);
  const newSpansToRemove = [];

  const areaOfInterest = path1.looseBoundingBox();
  areaOfInterest.expandScalar(tolerance);

  // Vist all paths above the current path.
  for (let j = i + 1; j < paths.length; ++j) {
    const path2 = paths[j]; // Path above
    const overlaps = path1.overlapsWith([path2], areaOfInterest, tolerance);

    const spans1 = spansUnion(
      path1,
      overlaps.map((o) => o.span1)
    );
    newSpansToRemove.push(...spans1);

    const spans2 = overlaps.map((o) => o.span2);
    const spansToRemove2 = ensureSpansToRemove(path2);
    spansToRemove2.push(...spans2);
  }

  if (overlapColor === "none") {
    const spansToKeep = spansInverse(path1, newSpansToRemove);
    const slices1 = pathSlices(path1, spansToKeep);
    outputPaths.push(...slices1);
  } else {
    const allSpansToRemove = spansUnion(path1, [
      ...existingSpansToRemove,
      ...newSpansToRemove,
    ]);
    const allSpansToKeep = spansInverse(path1, allSpansToRemove);
    const slices1 = pathSlices(path1, allSpansToKeep);
    outputPaths.push(...slices1);

    const overlapSpans = spansDifference(
      path1,
      newSpansToRemove,
      existingSpansToRemove
    );
    const overlapPaths = pathSlices(path1, overlapSpans);
    outputOverlapPaths.push(...overlapPaths);
  }
}

// Color the overlap paths.
for (const path of outputOverlapPaths) {
  if (path.stroke) {
    path.stroke.color = overlapColor;
  }
}

let output = Group([...outputPaths, ...outputOverlapPaths]);

// Optionally merge paths of the same style together.
if (mergePaths) {
  output = MergePaths({ connectDistance: 0 }, output);
}

return output;`);
registerBuiltin("RemoveOverlapsDefinition", RemoveOverlapsDefinition);

export const WeldAndScoreDefinition = new Modifier();
WeldAndScoreDefinition.isImmutable = true;
WeldAndScoreDefinition.name = "Weld And Score";
WeldAndScoreDefinition.icon = "weld_and_score";
WeldAndScoreDefinition.parameters = [
  makeColorParameter("scoreColor", new Color(0.0, 0.0, 1.0, 1.0)),
];
WeldAndScoreDefinition.code = new Expression(`const paths = input.allPathsAndCompoundPaths();

if (paths.length < 1) return;

let originalStroke = paths[0].stroke;
if (!originalStroke) {
  originalStroke = Stroke();
  if (paths[0].fill) {
    originalStroke.color = paths[0].fill.color;
  }
}

paths.forEach((p, i) => {
  p.removeStroke();
  p.assignFill(Fill(Color(i, 0, 0, 1)));
});

const flattened = Flatten({}, input);
flattened.removeFill();
flattened.assignStroke(originalStroke);

const output = RemoveOverlaps({
  overlapColor: scoreColor
}, flattened);

return output;`);
registerBuiltin("WeldAndScoreDefinition", WeldAndScoreDefinition);

export const WarpedTextDefinition = new Modifier();
WarpedTextDefinition.isImmutable = true;
WarpedTextDefinition.name = "Warped Text";
WarpedTextDefinition.parameters = [
  makeTextParameter("text", "Warp"),
  makeFontSelectParameter(
    "font",
    "https://fonts.gstatic.com/s/notosans/v26/o-0IIpQlx3QUlC5A4PNb4j5Ba_2c7A.ttf" // Noto Sans Regular
  ),
  makeSelectParameter("align", "left", ["left", "center", "right"]),
  makePercentageParameter("letterSpacing", 0, 3),
  makePercentageParameter("lineHeight", 1, 3),
  makeSelectParameter("scaling", "grow", ["grow", "metrics"])
    .setComment(`grow - will make the text as large as possible to fit within the warped box
metrics - will size the text using the font’s metrics (allows the use of spaces)`),
];
WarpedTextDefinition.code = new Expression(`const ensureCubicSegment = (s) => {
  if (s instanceof CubicSegment) return s;
  return CubicSegment(
    s.s,
    s.s.clone().mix(s.e, 1 / 3),
    s.s.clone().mix(s.e, 2 / 3),
    s.e
  );
};
const mixSegments = (sOut, s1, s2, t) => {
  s1 = ensureCubicSegment(s1);
  s2 = ensureCubicSegment(s2);
  sOut.s.copy(s1.s).mix(s2.s, t);
  sOut.cs.copy(s1.cs).mix(s2.cs, t);
  sOut.ce.copy(s1.ce).mix(s2.ce, t);
  sOut.e.copy(s1.e).mix(s2.e, t);
};

const tolerance = 0.001;

const myFont = getFontFromURL(font);
if (!myFont) {
  console.error("Couldn't load the font");
  return;
}

let textWidth = 0;
let textHeight = myFont.ascenderHeight;

const linesInfo = myFont.render(text, { letterSpacing });
const lineGroups = [];
for (let line of linesInfo) {
  let { geometry, advanceX } = line;
  textWidth = max(textWidth, advanceX);
  const position = Vec(0, textHeight);

  // Horizontal Alignment
  if (align === "center") position.x -= advanceX / 2;
  else if (align === "right") position.x -= advanceX;

  geometry.transform({ position });
  textHeight += lineHeight;
  lineGroups.push(geometry);
}
textHeight -= myFont.descenderHeight + lineHeight;

const geometry = Group(lineGroups);

{
  const position = Vec();
  if (align === "center") position.x = textWidth / 2;
  else if (align === "right") position.x = textWidth;
  geometry.transform({ position });
}

const bounds =
  scaling === "metrics"
    ? BoundingBox(Vec(0, 0), Vec(textWidth, textHeight))
    : geometry.boundingBox();
const boundsSize = bounds.size();
const output = [];
for (let box of input.allPaths()) {
  if (!box.closed || box.anchors.length !== 4) continue;

  const boxSegments = box.segments().map((s) => s.clone());
  boxSegments[2].reverse();
  boxSegments[3].reverse();

  const yAxis = ensureCubicSegment(boxSegments[3].clone());

  const warpedGeometry = geometry.clone();
  for (let path of warpedGeometry.allPaths()) {
    let warpedAnchors;
    for (let edge of path.edgePaths()) {
      const warpedEdge = Path.fromSmoothFunction(
        (t) => {
          const p = edge.positionAtTime(edge.timeAtDistance(t));
          const { x: u, y: v } = (p - bounds.min) / boundsSize;
          mixSegments(yAxis, boxSegments[3], boxSegments[1], u);
          const x0 = boxSegments[0].positionAtTime(u);
          const x1 = boxSegments[2].positionAtTime(u);
          yAxis.transform({ position: x0 - yAxis.s });
          const xf = AffineMatrix.fromCenterAndReferencePoints(
            x0,
            yAxis.e,
            x1,
            {
              allowRotation: true,
              allowScale: true,
            }
          );
          yAxis.affineTransform(xf);
          return yAxis.positionAtTime(v);
        },
        0,
        edge.length(),
        tolerance
      );
      if (warpedAnchors) {
        warpedAnchors[warpedAnchors.length - 1].handleOut = warpedEdge.anchors[0].handleOut;
        warpedEdge.anchors.shift();
        warpedAnchors.push(...warpedEdge.anchors);
      } else {
        warpedAnchors = warpedEdge.anchors;
      }
    }
    if (path.closed) {
      const closingAnchor = warpedAnchors.pop();
      warpedAnchors[0].handleIn = closingAnchor.handleIn;
    }
    path.anchors = warpedAnchors;
  }
  output.push(warpedGeometry);
}
return Group(output).copyStyle(input);`);
registerBuiltin("WarpedTextDefinition", WarpedTextDefinition);

export const FitWithinDefinition = new Modifier();
FitWithinDefinition.isImmutable = true;
FitWithinDefinition.name = "Fit Within";
FitWithinDefinition.icon = "fit_within";
FitWithinDefinition.parameters = [
  makePointParameter("position", new Vec()),
  makePercentageParameter("scale", 1, 3),
];
FitWithinDefinition.code =
  new Expression(`if (!(input instanceof Group && input.items.length > 1)) {
  console.error("Fit Within must be applied to a group with at least 2 items.");
  return input;
}

const angularResolution = 3.6;
const showDebug = false;
const gap = 0.001;

const largestScaleFromCenter = (
  container,
  contained,
  center,
  angle = 0,
  spread = 360,
  allowShrink = true,
) => {
  const count = Math.max(1, Math.round(spread / angularResolution));
  const angleIncr = spread / count;
  const halfSpread = spread / 2;
  const rays = range(angle - halfSpread + angleIncr / 2, angle + halfSpread, angleIncr)
    .map(a => Ray(center, Vec.fromAngle(a)));

  const innerXs = rays.map(ray => {
    const xs = ray.intersectionsWith([contained]);
    if (xs.length < 1) return undefined;
    xs.sort((a, b) => b.time1 - a.time1);
    return xs[0];
  });

  const outerXs = rays.map(ray => {
    const xs = ray.intersectionsWith([container]);
    if (xs.length < 1) return undefined;
    xs.sort((a, b) => a.time1 - b.time1);
    return xs[0];
  });

  let minRatio = Infinity;
  let minIndex = -1;
  for (let i = 0; i < count; ++i) {
    const innerX = innerXs[i];
    const outerX = outerXs[i];
    if (innerX && outerX) {
      if (showDebug) {
        console.geometry(LineSegment(outerX.position, innerX.position));
      }
      const ratio = outerX.time1 / innerX.time1;
      if (ratio < minRatio && (allowShrink || ratio >= 1)) {
        minRatio = ratio;
        minIndex = i;
      }
    }
  }
  
  if (minIndex < 0) return undefined;
  
  const minRay = rays[minIndex];
  const minOuterX = outerXs[minIndex];
  const minInnerX = innerXs[minIndex];
  
  const p1 = minInnerX.position;
  const p2 = minOuterX.position;
  const nextCenter = minRay.positionAtTime(minOuterX.time1 - gap * 0.5);
  const scale = minRatio;
  
  if (showDebug) {
    console.guide(LineSegment(p1, p2), nextCenter, minInnerX.primitive2.clone());
  }
  
  return { p1, p2, nextCenter, scale };
};

const container = input.items[0];
const contained = Group(input.items.slice(1));

const containedBox = contained.boundingBox();
if (!containedBox) {
  console.warn("Could not compute a bounding box for the inner (first) shape. It may not contain any geometry.");
  return input;
}

const containedCenter = containedBox.center();

const result = largestScaleFromCenter(container, contained, containedCenter);
if (!result) return input;
const matrix = AffineMatrix.fromTransform({
  scale: result.scale,
  position: containedCenter,
  origin: containedCenter,
});
contained.affineTransform(matrix);
containedCenter.affineTransform(matrix);

let angle2 = result.p1.clone().sub(result.p2).angle();
if (result.scale < 1) {
  angle2 += 180;
}
const result2 = largestScaleFromCenter(container, contained, result.nextCenter, angle2, 120, false);
if (!result2) return input;
const matrix2 = AffineMatrix.fromTransform({
  scale: result2.scale,
  position: result.nextCenter,
  origin: result.nextCenter,
});
contained.affineTransform(matrix2);
containedCenter.affineTransform(matrix2);

// Apply post-process position and scale tweaks.
contained.transform({
  scale: scale,
  position: containedCenter + position,
  origin: containedCenter,
});

return input;`);
registerBuiltin("FitWithinDefinition", FitWithinDefinition);
