import m from "mithril";

import { Editor as TiptapEditor } from "@tiptap/core";
import { EditHistory } from "./edit-history";
import { ExamplesData } from "./examples";
import { BoundingBox, Geometry, Group, Vec, assert } from "./geom";
import { imageManager } from "./image-manager";
import { externalSVGManager } from "./io/external-svg";
import { fontManager } from "./io/font-manager";
import { CanvasPoint } from "./model/canvas-point";
import { CodeComponent, Component } from "./model/component";
import { DependencyGraph } from "./model/dependency-graph";
import { EvaluateElementMemoizer } from "./model/evaluate-element-memoizer";
import { CodeComponentFocus, ComponentFocus } from "./model/focus";
import { GettingStartedState } from "./model/getting-started-state";
import { InstanceDefinition } from "./model/instance-definition";
import { Node } from "./model/node";
import { Parameter, ParameterFolder } from "./model/parameter";
import { Project } from "./model/project";
import { ProjectEvaluator } from "./model/project-evaluator";
import { SelectableInstance } from "./model/selectable";
import { Selection } from "./model/selection";
import { PortableProjectData, ProjectSnapshot } from "./model/snapshot";
import { ElementTrace, InstanceTrace, ProjectTrace } from "./model/trace";
import { transformedGraphicForNode } from "./model/transform-utils";
import { CanvasDimensions } from "./model/viewport";
import { logEvent } from "./shared/log-event";
import { modalState } from "./shared/modal";
import { deviceStorage } from "./storage/device-storage";
import { snapshotFromProjectId } from "./storage/save-utils";
import { storage } from "./storage/storage";
import type { SnapshotPayload } from "./storage/storage-types";
import { isDevelopmentServer, unescapeHyphen } from "./util";
import { CalibrateRealSize } from "./view/calibrate-real-size";
import { gridIntervalAndSubdivisions } from "./view/canvas-ui/canvas-grid";
import { CanvasInterfaceElement } from "./view/canvas-ui/canvas-interface-element";
import type { DefinitionDrag, GutsDrag } from "./view/definition-drag";
import { previewPNGForMainComponent } from "./view/export-utils";
import { pastePortableProjectData } from "./view/paste-portable-project-data";
import { PointerInitModal } from "./view/pointer-init-modal";
import { SnappingState } from "./view/snapping";
import { showTouchScreenWarningToast } from "./view/toast-message";
import { PenTool } from "./view/tool/pen-tool";
import { SelectTool } from "./view/tool/select-tool";
import { Tool, ToolName } from "./view/tool/shared";
import { TransformTool } from "./view/tool/transform-tool";
import { ViewportManager } from "./viewport-manager";

export interface OutlineReorder {
  nodes: Node[];
  hoveredInsertionParent?: Node;
  hoveredInsertionIndex?: number;
}
export interface ModifierReorder {
  selectableInstances: SelectableInstance[];
  hoveredInsertionNode?: Node;
  hoveredInsertionIndex?: number;
}

export interface ParameterReorder {
  definition: InstanceDefinition;
  parameter: Parameter;
  hoveredInsertionParent?: ParameterFolder;
  hoveredInsertionIndex?: number;
}
export interface ParameterFolderReorder {
  definition: InstanceDefinition;
  folder: ParameterFolder;
  hoveredInsertionIndex?: number;
}

export class CanvasDrag {
  startPoint: CanvasPoint;
  currentPoint: CanvasPoint;

  constructor(startPoint: CanvasPoint) {
    this.startPoint = startPoint;
    this.currentPoint = startPoint.clone();
  }

  distance() {
    return this.startPoint.worldPosition.distance(this.currentPoint.worldPosition);
  }

  isPrecise() {
    return this.startPoint.isPrecise() && this.currentPoint.isPrecise();
  }
  isPreciseH() {
    return this.startPoint.isPreciseH && this.currentPoint.isPreciseH;
  }
  isPreciseV() {
    return this.startPoint.isPreciseV && this.currentPoint.isPreciseV;
  }
}

/** To autofocus the comment input immediately after the comment is added. */
export class AutoSelectComment {
  constructor(public subject: InstanceDefinition | Parameter) {}
}

export const SELECTION_DISTANCE_PX = 7;
export const SNAPPING_DISTANCE_PX = SELECTION_DISTANCE_PX;

export class GlobalState {
  // Normally this is overwritten by loadProjectSnapshot after storage inits
  project = new Project();
  storage = storage;

  evaluateElementMemoizer: EvaluateElementMemoizer;
  evaluator: ProjectEvaluator;

  // Preferences that should persist per browser, not to backend
  deviceStorage = deviceStorage;

  // History and checkpointing
  editHistory: EditHistory;
  private shouldCheckpointAfterNextEvaluation = false;
  private shouldForceSaveAtNextCheckpoint = false;
  private lastCheckpointTimeMs = 0;

  tools: { [N in ToolName]: Tool } = {
    Select: new SelectTool(),
    Pen: new PenTool(),
    Scale: new TransformTool(false, true, true),
    "Scale 1D": new TransformTool(false, true, false),
    Rotate: new TransformTool(true, false, true),
  };
  activeToolName: ToolName = "Select";

  interfaceElements: CanvasInterfaceElement[] = [];
  hoveredInterfaceElement: CanvasInterfaceElement | undefined = undefined;
  downInterfaceElement: CanvasInterfaceElement | undefined = undefined;

  hoveredObjectInspectorGeometry: Geometry | Vec | undefined = undefined;

  expandedParameterFolders: WeakSet<ParameterFolder> = new WeakSet();

  outlineReorder: OutlineReorder | null = null;
  modifierReorder: ModifierReorder | null = null;
  parameterReorder: ParameterReorder | null = null;
  parameterFolderReorder: ParameterFolderReorder | null = null;
  definitionDrag: DefinitionDrag | GutsDrag | undefined = undefined;

  projectTrace: ProjectTrace | undefined = undefined;
  tracesByNode: { [hash: string]: ElementTrace } = {};
  tracesByDefinition: Map<InstanceDefinition, InstanceTrace> = new Map();

  isVersionHistoryOpen = false;

  canvasRectPixels = new DOMRect();
  canvasDimensions = new CanvasDimensions();
  canvasPointerPositionPixels: Vec | undefined = undefined;
  canvasPointerIsDown = false;
  canvasDrag?: CanvasDrag;
  isCanvasPanning = false;

  selectionBox: BoundingBox | null = null;
  selectionBoxParameters: Selection | undefined = undefined;

  ghostGeometry: Geometry | null = null;

  snapping = new SnappingState();

  viewportManager = new ViewportManager();

  isShiftDown = false;
  isControlDown = false;
  isAltDown = false;
  isMetaDown = false;
  isSpaceDown = false;

  isPickerOpen = false;

  isProjectParameterInspectorOpen = false;
  isComponentParameterInspectorOpen = false;
  isCanvasTextParameterOpen = false;

  // In some cases, canvases need to be re-rendered when switching back to the
  // tab. Instead of adding a document visibility listener for every thumbanil
  // we set this flag during the first redraw after the page becomes visible.
  // Thumbnails then know to redraw themselves.
  isFirstRedrawAfterBecameVisible = true;

  // Used for auto-selecting EditableText so that when you create a new
  // parameter, component, etc the default name is text selected so you can just
  // type to rename it.
  autoSelectForRename:
    | undefined
    | InstanceDefinition
    | Parameter
    | ParameterFolder
    | AutoSelectComment;

  // Used to prevent checkpointing when the pen tool is placing an anchor,
  // during drags, etc.
  gestureIds = new Set<Symbol>();
  debug_gestureTimeoutsById = new Map<Symbol, number>();

  private _editingMode: "editing" | "viewing" = "editing";

  // Set this manually from the console to begin validating if project geometry
  // remains the same after each upgrade
  debug_validateUpgradedProjectGeometry?: boolean;

  // Set this from the console to display debug information about the edit history.
  debug_hideInfoInspector?: boolean;
  debug_lastCheckpointDifference?: "material" | "immaterial" | "none";
  debug_lastCheckpointSaved?: boolean;

  // Assumes that we only have one DocEditor mounted at a time
  activeDocEditor: TiptapEditor | undefined;

  gettingStartedState: GettingStartedState | undefined;

  examplesData: ExamplesData | undefined;

  constructor() {
    this.evaluateElementMemoizer = new EvaluateElementMemoizer();
    fontManager.subscribe(() => this.evaluateElementMemoizer.globalChanged("getFontFromURL"));
    imageManager.subscribe(() => this.evaluateElementMemoizer.globalChanged("getImageFromURL"));
    externalSVGManager.subscribe(() => {
      this.evaluateElementMemoizer.globalChanged("getEmojiSVGFromURL");
      // RichText can depend on external SVGs
      this.evaluateElementMemoizer.globalChanged("getFontFromURL");
    });

    this.evaluator = new ProjectEvaluator(this.evaluateElementMemoizer);

    this.editHistory = new EditHistory(this.project);
  }

  /**
   * Evaluates the current project and populates global trace data with the
   * results.
   *
   * In general this should only be called once per animation frame in the main
   * app update loop. It is exposed here for debugging purposes.
   */
  updateEvaluation() {
    // Can be removed in production. This is just to sanity check that all the
    // project's selected Nodes, etc. are valid.
    if (isDevelopmentServer()) {
      this.project.assertIsValid();
      this.project.assertIdsAreUnique();
    }

    // Evaluate the project.
    this.projectTrace = this.evaluator.evaluateProject(this.project);

    // Clear the trace cache.
    this.tracesByNode = {};
    this.tracesByDefinition = new Map();

    // Populate component definition traces.
    for (let instanceTrace of this.projectTrace.definitions) {
      const definition = instanceTrace.source.definition;
      this.tracesByDefinition.set(definition, instanceTrace);
    }
    // Node traces are populated lazily in `traceForNode()`.

    // We checkpoint after we've successfully evaluated the project so that we
    // don't accidentally save an erroring project state to storage.
    this.evaluationHappenedSuccessfully();
  }

  traceForNode(node: Node): ElementTrace | undefined {
    const hash = node.hash();

    // First look for the trace in the cache.
    let trace = this.tracesByNode[hash];
    if (trace) return trace;

    // Look for the trace in the parent node's trace.
    if (node.parent) {
      const parentTrace = this.traceForNode(node.parent);
      if (parentTrace) {
        for (const childTrace of parentTrace.childTraces()) {
          if (childTrace.source === node.source) {
            this.tracesByNode[hash] = childTrace;
            return childTrace;
          }
        }
      }
    }
    // Find traces for top-level nodes in component definition traces.
    else if (this.projectTrace) {
      for (const { element } of this.projectTrace.definitions) {
        if (element && element.source === node.source) {
          this.tracesByNode[hash] = element;
          return element;
        }
      }
    }

    return undefined;
  }

  traceForComponent(component: Component | CodeComponent) {
    return this.tracesByDefinition.get(component);
  }

  getProjectSnapshot() {
    return this.editHistory.currentSnapshot();
  }
  loadProjectSnapshot(snapshot: ProjectSnapshot, projectId: string) {
    this.project = snapshot.toProject();
    this.project.projectId = projectId;
    this.project.ensureProjectDataExists(); // Defensive measure (toby)
  }
  async overwriteWithProject(projectId: string) {
    const thisProjectId = this.storage.getProjectId();
    const { snapshot } = await snapshotFromProjectId({
      getProjectOptions: { projectId },
      upgradeSnapshotOptions: { defaultProjectId: thisProjectId },
    });
    if (snapshot) {
      await this.saveVersion();
      this.loadProjectSnapshot(snapshot, thisProjectId);
      // If modifiers or components were created in the other project, overwrite
      // their projectId to point to this project.
      this.project.components.forEach((component) => {
        if (component.projectId === projectId) {
          component.projectId = thisProjectId;
        }
      });
      this.project.modifiers.forEach((modifier) => {
        if (modifier.projectId === projectId) {
          modifier.projectId = thisProjectId;
        }
      });
      this.checkpoint();
      m.redraw();
    }
  }
  /** Called once, after loadProjectSnapshot. With a new project, we don't call
   * loadProjectSnapshot, but we still call this to set the initial route.
   * Geometry traces are not available when this is called. */
  initFocus(urlInfo: { projectId?: string; edit: boolean; view: boolean; embed: boolean }) {
    const { projectId, edit, view, embed } = urlInfo;

    if (embed || projectId === "_intro" || projectId === "_staging") {
      // Don't change focus or mode
      return;
    }

    if (edit) {
      this.enterEditingMode();
      return;
    }

    if (view) {
      this.enterViewingMode();
      return;
    }

    if (this.project.documentation.hasContent()) {
      // If we're looking at a project that has content in the Read Me, open in
      // packaged view.
      this.enterViewingMode();
    } else {
      this.enterEditingMode();
    }
  }
  /**
   * In viewing mode, parameters in the project can be changed, but their
   * original values are cached as defaults and will be restored when the mode
   * is exited. History is disabled.
   */
  enterViewingMode() {
    this._editingMode = "viewing";
    this.project.setParameterDefaults();
    if (!this.project.focusedDocumentation()) {
      this.project.focusItem(this.project.documentation);
    }
  }
  /**
   * Editing mode is the typical mode for a Cuttle project. Parameters edits are
   * recorded and history is enabled.
   */
  enterEditingMode() {
    this._editingMode = "editing";
    this.project.revertParametersToDefault();
    this.project.ensureValidFocusedComponent();

    // Show a warning about touch screens not being supported.
    showTouchScreenWarningToast();
  }
  /**
   * A project will be view-only when it's either being previewed by its owner,
   * or viewed by another user.
   */
  isEditingMode() {
    return this._editingMode === "editing";
  }
  debug_loadProjectSnapshotJSON(json: any) {
    const snapshot = ProjectSnapshot.fromJSON(json);
    assert(snapshot, "Could not create project snapshot.");
    this.loadProjectSnapshot(snapshot, "debug");
  }
  /**
   * Returns true if Cuttle data is found in SVG string, so `ImportProject`
   * modal can be shown.
   */
  loadProjectFromSVG(text: string) {
    const match = /<!--([^-]*)-->/.exec(text);
    if (match) {
      const comment = match[1];
      const snapshotString = unescapeHyphen(comment);
      const snapshot = ProjectSnapshot.fromString(snapshotString);
      if (snapshot) {
        const project = snapshot.toProject();
        const graph = new DependencyGraph(project);
        graph.renameConflictingDefinitionsBeforeImportToProject(this.project);

        const projectData = new PortableProjectData();
        projectData.components = project.components;
        projectData.modifiers = project.modifiers;
        projectData.projectParameters = project.parameters;

        pastePortableProjectData(this.project, projectData);

        return true;
      }
    }
    return false;
  }
  resetEditHistory() {
    this.editHistory = new EditHistory(this.project);
  }

  checkpoint() {
    const difference = this.editHistory.checkpoint(this.project);
    this.debug_lastCheckpointDifference = difference;
    if (this.shouldForceSaveAtNextCheckpoint || difference === "material") {
      this.shouldForceSaveAtNextCheckpoint = false;
      this.savetoStorage();
      this.debug_lastCheckpointSaved = true;
    } else {
      this.debug_lastCheckpointSaved = false;
    }
    this.lastCheckpointTimeMs = Date.now();
  }
  checkpointAfterNextEvaluation() {
    this.shouldCheckpointAfterNextEvaluation = true;
  }
  evaluationHappenedSuccessfully() {
    if (this.shouldCheckpointAfterNextEvaluation && this.isEditingMode()) {
      let shouldPostponeCheckpointing = this.isMidGesture();
      if (shouldPostponeCheckpointing) {
        // Safety net to make sure we aren't losing user data. If it's been too
        // long since the last checkpoint, don't postpone any longer. This will
        // help us catch gestures that are going on for longer than intended.
        const timeSinceLastCheckpointMs = Date.now() - this.lastCheckpointTimeMs;
        if (timeSinceLastCheckpointMs > 30000) {
          // Override "isMidGesture" and checkpoint anyway.
          console.warn("It's been too long since the last checkpoint. Saving!");
          shouldPostponeCheckpointing = false;
        }
      }
      if (!shouldPostponeCheckpointing) {
        this.checkpoint();
        this.shouldCheckpointAfterNextEvaluation = false;
      }
    }
    this.activeTool().onAfterEvaluation?.();
  }

  hasUndo() {
    return this.editHistory.hasUndo();
  }
  hasRedo() {
    return this.editHistory.hasRedo();
  }
  undo() {
    if (this.hasUndo()) {
      this.project = this.editHistory.undo();
      this.shouldForceSaveAtNextCheckpoint = true;
      this.checkpointAfterNextEvaluation();
      this.snapping.clear();
    }
  }
  redo() {
    if (this.hasRedo()) {
      this.project = this.editHistory.redo();
      this.shouldForceSaveAtNextCheckpoint = true;
      this.checkpointAfterNextEvaluation();
      this.snapping.clear();
    }
  }

  deleteSelection() {
    this.project.deleteSelection();
    this.snapping.clear();
  }

  /**
   * This will return what you need to send to the parent frame for any kind of
   * save, namely: the project snapshot, preview png, and cover photo.
   */
  async snapshotPayload(): Promise<SnapshotPayload> {
    const projectSnapshot = this.editHistory.currentSnapshot();
    const previewPNG = await previewPNGForMainComponent();
    if (previewPNG === undefined) {
      console.warn("previewPNG undefined");
    }
    const coverPhoto = this.project.documentation.coverPhoto();
    return { projectSnapshot, previewPNG, coverPhoto };
  }

  async savetoStorage() {
    if (!this.isEditingMode()) {
      return;
    }
    const snapshotPayload = await this.snapshotPayload();
    this.storage.checkpoint(snapshotPayload);
  }

  resetStorage() {
    window.localStorage.clear();
    window.location.reload();
  }

  async saveVersion() {
    if (this.storage.hasWritePermission()) {
      const snapshotPayload = await this.snapshotPayload();
      const snapshotId = await this.storage.saveVersion(snapshotPayload);
      return snapshotId;
    }
    throw new Error("Unable to save a version");
  }

  openRealSizeCalibration() {
    modalState.open({ modalView: () => m(CalibrateRealSize) });
  }
  openPointerInitModal() {
    this.deviceStorage.pointerInitShown = true;
    modalState.open({
      modalView: () => m(PointerInitModal),
    });
  }
  openGettingStartedSequence() {
    this.gettingStartedState = new GettingStartedState();
  }
  closeGettingStartedSequence() {
    this.gettingStartedState = undefined;
  }

  activeTool() {
    return this.tools[this.activeToolName];
  }
  ensureEnabledActiveTool() {
    return this.activeTool();
    // First check to see if the active tool is disabled. Revert to the Select
    // tool to avoid making changes to a disabled tool, which could result in an
    // assertion fail.
    const activeTool = this.activeTool();
    if (activeTool.isDisabled?.()) {
      this.activateTool("Select");
    }
    return this.activeTool();
  }

  activateTool(toolName: ToolName) {
    this.activeTool().deactivate?.();
    this.activeToolName = toolName;

    // Clear snapping state between tool changes.
    this.snapping.clear();

    this.activeTool().activate?.();
  }

  updateGhostGeometry() {
    // Dragged geometry leaves a ghost in place that can be used as a visual
    // reference and snapped to
    const { project } = this;
    const geometry = new Group();
    for (let node of project.selection.allNodes().toNodes()) {
      const graphic = transformedGraphicForNode(node);
      if (graphic) {
        geometry.items.push(graphic);
      }
    }
    if (project.focus instanceof ComponentFocus && project.focus.node.isPath()) {
      const graphic = transformedGraphicForNode(project.focus.node);
      if (graphic) {
        geometry.items.push(graphic);
      }
    }
    this.ghostGeometry = geometry;
  }
  clearGhostGeometry() {
    this.ghostGeometry = null;
  }

  snappingDistanceWorld() {
    const focusedComponent = this.project.focusedComponent();
    assert(focusedComponent, "Snapping distance should only be used when a component is focused");
    const viewport = this.viewportManager.viewportForComponent(focusedComponent);
    return SNAPPING_DISTANCE_PX / viewport.pixelsPerUnit;
  }
  selectionDistanceWorld() {
    const focusedComponent = this.project.focusedComponent();
    assert(focusedComponent, "Selection distance should only be used when a component is focused");
    const viewport = this.viewportManager.viewportForComponent(focusedComponent);
    return SELECTION_DISTANCE_PX / viewport.pixelsPerUnit;
  }

  gridIncrement() {
    const focusedComponent = this.project.focusedComponent();
    assert(focusedComponent);

    const viewport = this.viewportManager.viewportForComponent(focusedComponent);
    const { interval, subdivisions } = gridIntervalAndSubdivisions(
      viewport,
      this.project.settings.gridDivisions
    );
    return interval / subdivisions;
  }

  /**
   * Gestures are used to pause checkpointing during operations that happen over
   * multiple evaluations, during which adding to the undo stack would be
   * undesirable.
   *
   * This includes:
   * - Placing an anchor with the pen tool
   * - Dragging
   * - Choosing a color
   *
   * This function will begin a gesture and return a unique gesture id. You must
   * hold on to that id, and pass it to `stopGesture`. If `stopGesture` is not
   * called checkpointing will be paused forever, so make sure to call it!
   */
  startGesture(gestureName: string) {
    const id = Symbol(gestureName);
    this.gestureIds.add(id);

    // Set a timeout to make sure we're not leaving gestures active for an
    // unreasonable amount of time. If you see this message it could be an
    // indication that `stopGesture` was not called.
    const debugTimeout = window.setTimeout(() => {
      console.warn(`Gesture "${id.description}" has been active for 30 seconds!`);
      logEvent("long gesture", { gestureName: id.description });

      // Set a second timeout that will stop the gesture after a very long time.
      // If this fires, we know there's a problem.
      const failsafeTimeout = window.setTimeout(() => {
        console.warn(
          `Gesture "${id.description}" has been active for a further 5 minutes! Stopping.`
        );
        this.stopGesture(id);
        logEvent("gesture failsafe", { gestureName: id.description });
      }, 5 * 60 * 1000);
      this.debug_gestureTimeoutsById.set(id, failsafeTimeout);
    }, 30 * 1000);
    this.debug_gestureTimeoutsById.set(id, debugTimeout);

    return id;
  }
  stopGesture(id: Symbol) {
    const timeout = this.debug_gestureTimeoutsById.get(id);
    clearTimeout(timeout);

    this.debug_gestureTimeoutsById.delete(id);
    this.gestureIds.delete(id);

    // Make sure we checkpoint in case we're (for example) called from a
    // pointermove event.
    this.checkpointAfterNextEvaluation();
  }

  /**
   * If any gestures are in progress, this will return `true`.
   */
  isMidGesture() {
    return this.gestureIds.size > 0;
  }

  isCanvasPanningOrPreparingToPan() {
    return this.isCanvasPanning || (this.isSpaceDown && this.canvasPointerPositionPixels);
  }

  zoomViewport(scale: number, quantize?: boolean): void {
    const focusedComponent = this.project.focusedComponent();
    if (!focusedComponent) return;

    const viewport = this.viewportManager.viewportForComponent(focusedComponent);
    this.viewportManager.zoomViewport(viewport, this.project.selection, scale, quantize);
  }
  zoomViewportToPixelsPerInch(pixelsPerInch: number) {
    const focusedComponent = this.project.focusedComponent();
    if (!focusedComponent) return;

    const viewport = this.viewportManager.viewportForComponent(focusedComponent);
    this.viewportManager.zoomViewportToPixelsPerInch(viewport, pixelsPerInch);
  }
  zoomViewportToFitSelected() {
    const focusedComponent = this.project.focusedComponent();
    if (!focusedComponent) return;

    const viewport = this.viewportManager.viewportForComponent(focusedComponent);
    this.viewportManager.zoomViewportToFitSelection(
      viewport,
      focusedComponent,
      this.project.selection
    );
  }
  zoomViewportToFitFocused() {
    const { focus } = this.project;
    if (focus instanceof ComponentFocus || focus instanceof CodeComponentFocus) {
      const viewport = this.viewportManager.viewportForComponent(focus.component);
      this.viewportManager.zoomViewportToFitFocus(viewport, focus);
    }
  }
  zoomViewportToFitAll() {
    const focusedComponent = this.project.focusedComponent();
    if (!focusedComponent) return;

    const viewport = this.viewportManager.viewportForComponent(focusedComponent);
    this.viewportManager.zoomViewportToFitAll(viewport, focusedComponent);
  }

  async ensureExamplesData() {
    if (this.examplesData) return this.examplesData;
    const data = (await import("./dynamic-import/examples-data")).generatedExamplesData;
    this.examplesData = new ExamplesData(data);
    return this.examplesData;
  }
}

export const globalState = new GlobalState();
