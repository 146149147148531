import m from "mithril";

import { globalState } from "../../global-state";
import { exportOptionsForComponents } from "../../io/export-options";
import { CodeComponent, Component } from "../../model/component";
import { accountState } from "../../shared/account";
import { ProFeatureButton } from "../../shared/feature-check";
import { Icon16, Icon20, IconButton } from "../../shared/icon";
import { MenuItem, Tooltipped, createPopupMenu } from "../../shared/popup";
import { classNames, domForVnode, isArray } from "../../shared/util";
import { ComponentDimensions } from "../component-dimensions";
import { openExportOptionsModal } from "../export-modal";
import { Inspector } from "../inspector/inspector";
import { PreviewCanvasAutosize } from "../preview-canvas";
import { isDragging } from "../start-drag";
import { Thumbnail } from "../thumbnail";
import { ThumbnailColor } from "../thumbnail-color";
import type { NodeViewRendererProps } from "@tiptap/core";
import type { ExportFormat } from "../../model/export-format";

const HAS_SEEN_CUSTOMIZE_HINT_KEY = "hasSeenCustomize";

export function mountComponentEmbed(
  el: HTMLElement,
  componentIds: string | string[],
  nodeViewRendererProps: NodeViewRendererProps
): void {
  m.mount(el, {
    view: function () {
      if (!isArray(componentIds)) componentIds = [componentIds];
      return m(ComponentEmbed, { componentIds, nodeViewRendererProps });
    },
  });
}

export function unmountComponentEmbed(el: HTMLElement): void {
  m.mount(el, null);
}

class ComponentEmbedContext {
  componentIds: string[];
  selectedComponentId: string;
  nodeViewRendererProps: NodeViewRendererProps | undefined;

  /** Account state user storage will override this, if logged in. */
  showCustomizeHint: boolean;

  hideActionsAndDimensions: boolean;
  hideRulers: boolean;
  hideParameters: boolean;

  isGallery: boolean;

  constructor(
    componentIds: string[],
    hideActionsAndDimensions: boolean,
    hideRulers: boolean,
    hideParameters: boolean,
    nodeViewRendererProps?: NodeViewRendererProps
  ) {
    this.componentIds = componentIds;
    this.selectedComponentId = componentIds[0];
    this.showCustomizeHint = true;
    this.nodeViewRendererProps = nodeViewRendererProps;
    this.hideActionsAndDimensions = hideActionsAndDimensions;
    this.hideRulers = hideRulers;
    this.hideParameters = hideParameters;
    // In editing mode, we want to show UI to clean up component ids that are
    // not found. In packaged view, we only want to show gallery if there are
    // multiple confirmed components.
    this.isGallery = globalState.isEditingMode()
      ? componentIds.length > 1
      : componentIds.filter((id) => Boolean(globalState.project.componentById(id))).length > 1;
  }

  selectComponent(componentId: string) {
    this.selectedComponentId = componentId;
  }
  selectedComponent() {
    return globalState.project.componentById(this.selectedComponentId);
  }
  /** The last component in a component gallery is the one that gets exported. */
  lastComponent() {
    const lastId = this.componentIds[this.componentIds.length - 1];
    return globalState.project.componentById(lastId);
  }
  /** The last component is found in the project, and its download isn't hidden. */
  hasDownloadableComponent() {
    const lastComponent = this.lastComponent();
    return Boolean(lastComponent && lastComponent.defaultExportFormat !== "none");
  }

  /** ProseMirror remounts the embed, which resets the context state. */
  addComponent(componentId: string) {
    const pos = this.getEditorPos();
    if (globalState.activeDocEditor && pos !== undefined) {
      globalState.activeDocEditor
        .chain()
        .focus()
        .makeOrAddToCuttleGallery({
          currentIds: this.componentIds,
          componentId,
          pos,
        })
        .run();
    }
  }
  removeComponent(componentId: string) {
    // If last component, don't remove it.
    if (this.componentIds.length === 1) {
      return;
    }
    const pos = this.getEditorPos();
    if (globalState.activeDocEditor && pos !== undefined) {
      globalState.activeDocEditor
        .chain()
        .focus()
        .removeFromCuttleGallery({
          currentIds: this.componentIds,
          componentId,
          pos,
        })
        .run();
    }
  }
  /** Used to replace the component embed in a ProseMirror transaction. */
  getEditorPos() {
    if (typeof this.nodeViewRendererProps?.getPos === "function") {
      return this.nodeViewRendererProps.getPos();
    }
    return undefined;
  }
}

interface ComponentEmbedAttrs {
  componentIds: string[];
  hideActionsAndDimensions?: boolean;
  hideRulers?: boolean;
  hideParameters?: boolean;
  nodeViewRendererProps?: NodeViewRendererProps;
}
export const ComponentEmbed: m.ClosureComponent<ComponentEmbedAttrs> = (initialVnode) => {
  const context = new ComponentEmbedContext(
    initialVnode.attrs.componentIds,
    Boolean(initialVnode.attrs.hideActionsAndDimensions),
    Boolean(initialVnode.attrs.hideRulers),
    Boolean(initialVnode.attrs.hideParameters),
    initialVnode.attrs.nodeViewRendererProps
  );
  return {
    view(vnode) {
      const { componentIds } = vnode.attrs;

      // Can't pass in the `component` directly, because it is recreated with undo/redo.
      const component = context.selectedComponent();
      if (!component) {
        return m(
          "figcaption",
          { class: "error" },
          "Component not found in this project. Remove this block, and embed the component again."
        );
      }

      const canEdit = globalState.storage.hasWritePermission() && globalState.isEditingMode();
      const showGallery = context.isGallery || canEdit;

      const hasParameters =
        !context.hideParameters &&
        (component.hasParameters() || globalState.project.hasParameters());

      const showHint =
        hasParameters &&
        !canEdit &&
        context.showCustomizeHint &&
        !accountState.storageGet(HAS_SEEN_CUSTOMIZE_HINT_KEY);

      const dismissHint = () => {
        context.showCustomizeHint = false;
        accountState.storageSet(HAS_SEEN_CUSTOMIZE_HINT_KEY, true);
      };

      const handleMetaEvents = (e: Event) => {
        e.stopPropagation();
        if (showHint) {
          dismissHint();
        }
      };

      const mActions: m.Children = [];
      if (!context.hideActionsAndDimensions) {
        const lastComponent = context.lastComponent();
        const showDownload = lastComponent && (context.hasDownloadableComponent() || canEdit);
        const showOptions = lastComponent && canEdit;
        const secondary = !context.hasDownloadableComponent();
        if (showDownload) {
          mActions.push(
            m(
              ".component-embed-download",
              m(DownloadButton, {
                component: lastComponent,
                className: classNames({
                  "component-embed-download-primary": true,
                  secondary,
                  "has-next-button": showOptions,
                }),
              })
            )
          );
        }
        if (showOptions) {
          const onMenuOpen = () => {
            context.selectComponent(lastComponent.id);
          };
          mActions.push(
            m(
              ".component-embed-file-type",
              m(FileTypeButton, {
                component: lastComponent,
                onMenuOpen,
                className: classNames({
                  button: true,
                  secondary,
                  "has-previous-button": showDownload,
                }),
              })
            )
          );
        }
      }

      return m(
        ".component-embed",
        {
          className: classNames({ "has-parameters": hasParameters }),
        },
        [
          m(".component-embed-wide-sticky", [
            m(".component-embed-narrow-sticky", [
              m(".component-embed-canvas", [
                m(PreviewCanvasAutosize, {
                  component,
                  marginBottom: context.hideActionsAndDimensions ? 0 : 56,
                  zoomViewport: !isDragging(),
                  hideRulers: context.hideRulers,
                }),
                !context.hideActionsAndDimensions &&
                  m(".component-embed-actions", [
                    m(".component-embed-actions-left-spacer"),
                    m(".component-embed-dimensions", m(ComponentDimensions, { component })),
                    mActions,
                  ]),
              ]),
            ]),
            showGallery &&
              m(".component-embed-under", [
                m(ComponentGalleryThumbnails, { componentIds, context, canEdit }),
              ]),
          ]),
          hasParameters &&
            m(
              ".component-embed-meta",
              {
                // Don't let key presses here propagate to doc editor
                onkeydown: handleMetaEvents,
                onkeyup: handleMetaEvents,
                onkeypress: handleMetaEvents,
                oncopy: handleMetaEvents,
                oncut: handleMetaEvents,
                onpaste: handleMetaEvents,
                // Clicking in inspector doesn't need to select the component embed
                onclick: handleMetaEvents,
                className: classNames({ "show-hint": showHint }),
              },
              [
                showHint && m(".customize-hint", { onclick }, "Customize this!"),
                m(".component-embed-inspector", m(Inspector, { component })),
              ]
            ),
        ]
      );
    },
  };
};

interface ComponentGalleryThumbnailsAttrs {
  componentIds: string[];
  context: ComponentEmbedContext;
  canEdit: boolean;
}
const ComponentGalleryThumbnails: m.Component<ComponentGalleryThumbnailsAttrs> = {
  view(vnode) {
    const { componentIds, context, canEdit } = vnode.attrs;

    return m(".component-gallery-thumbnails", [
      componentIds.map((componentId, index) => {
        const component = globalState.project.componentById(componentId);

        const mRemove =
          canEdit &&
          componentIds.length > 1 &&
          m(".component-gallery-remove", [
            m(".component-gallery-icon-background", [
              m(IconButton, {
                icon: "x",
                onclick: () => context.removeComponent(componentId),
              }),
            ]),
          ]);

        if (!component) {
          if (!canEdit) return;
          return m(
            Tooltipped,
            {
              message: () => "⚠️ Component not found. Remove this one from the gallery.",
              placement: "bottom",
            },
            m(".component-gallery-thumbnail.error", mRemove)
          );
        }

        // Only last in gallery will be downloaded
        const isLast = index === componentIds.length - 1;
        const hasDownload = isLast && component.defaultExportFormat !== "none";
        const showDownload = hasDownload && !context.hideActionsAndDimensions;
        const instanceTrace = globalState.tracesByDefinition.get(component);
        const graphic = instanceTrace?.result;
        return m(
          Tooltipped,
          { message: () => component.name, placement: "bottom" },
          m(
            ".component-gallery-thumbnail",
            {
              className: classNames({ selected: componentId === context.selectedComponentId }),
            },
            [
              m(
                "div",
                {
                  onclick: () => context.selectComponent(componentId),
                },
                m(ThumbnailColor, {
                  graphic,
                  width: 120,
                  height: 90,
                  padding: 8,
                }),
                showDownload && m(Icon16, { icon: "download_badge" })
              ),
              mRemove,
            ]
          )
        );
      }),
      canEdit && m(AddComponentToGallery, { componentIds, context }),
    ]);
  },
};

interface AddComponentToGalleryAttrs {
  componentIds: string[];
  context: ComponentEmbedContext;
}
const AddComponentToGallery: m.Component<AddComponentToGalleryAttrs> = {
  view(vnode) {
    const { componentIds, context } = vnode.attrs;

    const otherComponents = globalState.project.components.filter(
      (c) => !c.isImmutable && !componentIds.includes(c.id)
    );
    if (otherComponents.length === 0) return;

    const onclick = (e: MouseEvent) => {
      const componentMenuItems: MenuItem[] = [];
      for (const component of otherComponents) {
        const graphic = globalState.tracesByDefinition.get(component)?.result;
        componentMenuItems.push({
          label: component.name,
          icon: () =>
            m(
              ".doc-editor-line-menu-components-thumbnail",
              m(Thumbnail, { graphic, width: 20, height: 20, padding: 2 })
            ),
          action: () => {
            context.addComponent(component.id);
          },
        });
      }
      createPopupMenu({
        className: "doc-editor-line-menu-components",
        menuItems: componentMenuItems,
        spawnFrom: e.currentTarget as HTMLElement,
        placement: "right-start",
      });
    };

    // Not using a real button, because we need the click to select the node.
    return m(
      Tooltipped,
      {
        message: () => "Add Component…",
        placement: "right",
        className: "add-component-to-gallery",
      },
      m(IconButton, { onclick, icon: "plus" })
    );
  },
};

interface DownloadButtonAttrs {
  component: Component | CodeComponent;
  className?: string;
}
const DownloadButton: m.Component<DownloadButtonAttrs> = {
  view(vnode) {
    const { component, className } = vnode.attrs;

    const exportComponents = [component];
    let label = "Download " + component.defaultExportFormat.toUpperCase();
    const disabled = component.defaultExportFormat === "none";
    if (disabled) {
      label = "Download Hidden";
    }

    const isProTemplate = globalState.storage.isProTemplate();
    const usesProFont = globalState.project.usesProFont();
    const enableProCheck = isProTemplate || usesProFont;
    const proFeatureKey = isProTemplate ? "templates" : "pro-fonts";

    return m(
      ProFeatureButton,
      {
        onClick: () => {
          const options = exportOptionsForComponents(exportComponents);
          openExportOptionsModal(options);
        },
        className,
        enableProCheck,
        feature: proFeatureKey,
        disabled,
      },
      [m(Icon20, { icon: "download" }), label]
    );
  },
};

const FileTypeButton: m.Component<{
  component: Component | CodeComponent;
  onMenuOpen: () => void;
  className?: string;
}> = {
  view(vnode) {
    const { component, onMenuOpen, className } = vnode.attrs;
    const defaultExportFormat = component.defaultExportFormat;

    const formats: Array<{ label: string; format: ExportFormat }> = [
      { label: "SVG", format: "svg" },
      { label: "PDF", format: "pdf" },
      { label: "PNG", format: "png" },
      { label: "DXF", format: "dxf" },
    ];
    const menuItems: MenuItem[] = [];
    for (let format of formats) {
      menuItems.push({
        label: format.label,
        icon: () =>
          defaultExportFormat === format.format ? m(Icon20, { icon: "check" }) : undefined,
        action: () => {
          component.defaultExportFormat = format.format;
        },
      });
    }
    menuItems.push({ type: "separator" });
    menuItems.push({
      label: "Hide Download Button",
      icon: () => (defaultExportFormat === "none" ? m(Icon20, { icon: "check" }) : undefined),
      action: () => {
        component.defaultExportFormat = "none";
      },
      tooltip: () => "Hides the download button for this component in the packaged view.",
    });

    return m(IconButton, {
      icon: "chevron_down",
      className,
      label: "Export File Type",
      onclick: () => {
        onMenuOpen();
        createPopupMenu({
          menuItems,
          spawnFrom: domForVnode(vnode),
          placement: "bottom-start",
        });
      },
    });
  },
};
