import {
  AffineMatrix,
  Anchor,
  Axis,
  BoundingBox,
  ClosestPointResultWithTime,
  GEOMETRIC_TOLERANCE,
  Geometry,
  GeometryPrimitive,
  LineSegment,
  Path,
  Ray,
  Vec,
  approximatelyOne,
  approximatelyZero,
  approximatelyZeroToOne,
  assert,
  pointsAreCollinear,
} from "..";
import {
  closestPointOnCubic,
  legendreGauss24_Times,
  legendreGauss24_Weights,
} from "../misc/bezier";
import {
  axisCubicIntersections,
  isInternalIntersection,
  lineCubicIntersections,
  lineLineIntersection,
  rayCubicIntersections,
  swapAllIntersectionTimes,
} from "../op/intersection";
import { segmentOverlap } from "../op/overlap";
import {
  boundingBoxForCubic,
  pointAtTimeOnCubic,
  timeAtPointOnCubic,
  timeAtPointOnLine,
} from "./primitive-utils";

/**
 * A cubic bezier segment of a path.
 */
export class CubicSegment extends Geometry implements GeometryPrimitive {
  static displayName = "CubicSegment";

  /** The start position */
  s: Vec;

  /** The first cubic "handle" position */
  cs: Vec;

  /** The second cubic "handle" position */
  ce: Vec;

  /** The end position */
  e: Vec;

  /**
   * Source information is specified on segments that are extracted from paths
   * for the purpose of intersection. This allows us to avoid finding
   * intersections between the endpoints of adjacent segments.
   * @internal
   */
  source?: { path: Path; time: number };

  /**
   * Constructs a cubic segment.
   *
   * @param s
   * @param cs
   * @param ce
   * @param e
   */
  constructor(s = new Vec(), cs = new Vec(), ce = new Vec(), e = new Vec()) {
    super();
    this.s = s;
    this.cs = cs;
    this.ce = ce;
    this.e = e;
  }

  clone() {
    return new CubicSegment(this.s.clone(), this.cs.clone(), this.ce.clone(), this.e.clone());
  }

  isValid() {
    return (
      Vec.isValid(this.s) && Vec.isValid(this.cs) && Vec.isValid(this.ce) && Vec.isValid(this.e)
    );
  }

  /**
   * @returns `true` if this segment is linear. A cubic segment is linear if its
   * control points lie on the line segment between its start and end points,
   * and they are evenly spaced.
   */
  isLinear() {
    const { s, cs, ce, e } = this;

    // Control points must be evenly spaced.
    const csLinear = s.mix(e, 1 / 3);
    if (!cs._almostEquals(csLinear, GEOMETRIC_TOLERANCE)) return false;
    const ceLinear = s.mix(e, 2 / 3);
    if (!ce._almostEquals(ceLinear, GEOMETRIC_TOLERANCE)) return false;

    return true;
  }

  /**
   * @returns `true` if this segment is straight. A cubic segment is straight if
   * its control points lie on the line segment between its start and end
   * points.
   */
  isStraight() {
    const { s, cs, ce, e } = this;

    // Zero length segments can't be straight.
    if (s._almostEquals(e, GEOMETRIC_TOLERANCE)) return false;

    // Zero handle segments are straight.
    if (s._almostEquals(cs, GEOMETRIC_TOLERANCE) && e._almostEquals(ce, GEOMETRIC_TOLERANCE)) {
      return true;
    }

    // Handles must lie on the line segment between `s` and `e`.
    const tcs = timeAtPointOnLine(s, e, cs, GEOMETRIC_TOLERANCE);
    if (tcs === undefined || !approximatelyZeroToOne(tcs)) return false;
    const tce = timeAtPointOnLine(s, e, ce, GEOMETRIC_TOLERANCE);
    if (tce === undefined || !approximatelyZeroToOne(tce)) return false;

    return true;
  }

  isTangentToNext(segment: LineSegment | CubicSegment, tolerance?: number) {
    const p3 = segment instanceof CubicSegment ? segment.cs : segment.e;
    return pointsAreCollinear(this.ce, this.e, p3, tolerance);
  }

  /**
   * @returns `true` if this segment is exactly equal to `segment`.
   * @param segment Another cubic segment
   */
  equals(segment: CubicSegment) {
    return (
      this.s.equals(segment.s) &&
      this.cs.equals(segment.cs) &&
      this.ce.equals(segment.ce) &&
      this.e.equals(segment.e)
    );
  }

  _almostEquals(segment: CubicSegment, tolerance?: number) {
    return (
      this.s._almostEquals(segment.s, tolerance) &&
      this.cs._almostEquals(segment.cs, tolerance) &&
      this.ce._almostEquals(segment.ce, tolerance) &&
      this.e._almostEquals(segment.e, tolerance)
    );
  }
  _almostEqualsReverse(segment: CubicSegment, tolerance?: number) {
    return (
      this.s._almostEquals(segment.e, tolerance) &&
      this.cs._almostEquals(segment.ce, tolerance) &&
      this.ce._almostEquals(segment.cs, tolerance) &&
      this.e._almostEquals(segment.s, tolerance)
    );
  }

  affineTransform(affineMatrix: AffineMatrix) {
    this.s.affineTransform(affineMatrix);
    this.cs.affineTransform(affineMatrix);
    this.ce.affineTransform(affineMatrix);
    this.e.affineTransform(affineMatrix);
    return this;
  }
  affineTransformWithoutTranslation(affineMatrix: AffineMatrix) {
    this.s.affineTransformWithoutTranslation(affineMatrix);
    this.cs.affineTransformWithoutTranslation(affineMatrix);
    this.ce.affineTransformWithoutTranslation(affineMatrix);
    this.e.affineTransformWithoutTranslation(affineMatrix);
    return this;
  }

  /**
   * Sets the positions of this segments so that it equals `segment`.
   */
  copy(segment: CubicSegment) {
    this.s.copy(segment.s);
    this.cs.copy(segment.cs);
    this.ce.copy(segment.ce);
    this.e.copy(segment.e);
  }

  /**
   * Sets the positions of this segment from two anchors.
   *
   * @param startAnchor The first anchor
   * @param endAnchor The second anchor
   */
  setFromAnchors(startAnchor: Anchor, endAnchor: Anchor) {
    this.s = startAnchor.position;
    this.cs.copy(startAnchor.position).add(startAnchor.handleOut);
    this.ce.copy(endAnchor.position).add(endAnchor.handleIn);
    this.e = endAnchor.position;
    return this;
  }

  reverse() {
    [this.s, this.cs, this.ce, this.e] = [this.e, this.ce, this.cs, this.s];
    return this;
  }

  /**
   * @returns the approximate arc length of the segment.
   */
  length() {
    return this.distanceAtTime(1);
  }

  /**
   * @returns the approximate time at `distance` along the segment.
   *
   * If `distance` is greater than the length of the segment, this will return
   * 1. Negative distances will return 0.
   *
   * @param distance A positive distance
   */
  timeAtDistance(distance: number) {
    assert(distance >= 0, "Distance must be a positive number");

    if (distance === 0) return 0;

    let length = this.length();
    if (distance >= length) return 1;

    const sampleCount = 100;
    const sampleSegmentDuration = 1 / (sampleCount - 1);

    let p1 = this.positionAtTime(0);
    let p2: Vec;
    let t: number;
    let d: number;

    length = 0;
    for (let i = 1; i < sampleCount; ++i) {
      t = i / (sampleCount - 1);
      p2 = this.positionAtTime(t);
      d = p1.distance(p2);
      if (length + d > distance) {
        return (i - 1 + (distance - length) / d) * sampleSegmentDuration;
      }
      length += d;
      p1 = p2;
    }

    return 1;
  }

  /**
   * @returns the approximate distance along the segment at `time`.
   * @param time A segment time between 0 and 1
   */
  distanceAtTime(time: number) {
    // Calculate the approximate length of this cubic by integrating arc lengths
    // with Gaussian Quadrature
    const halfTime = time * 0.5;
    let length = 0;
    for (let i = 0; i < 24; i++) {
      const t = legendreGauss24_Times[i] * halfTime + halfTime;
      const d = this.derivativeAtTime(t);
      length += legendreGauss24_Weights[i] * d.length();
    }
    return length * halfTime;
  }

  /**
   * @returns the position on the segment at `time`.
   * @param time A segment time between 0 and 1
   */
  positionAtTime(time: number) {
    return pointAtTimeOnCubic(this.s, this.cs, this.ce, this.e, time, new Vec());
  }

  /**
   * @returns the first derivative of the segment at `time`.
   *
   * The first derivative can also be thought of as the "velocity" of a point
   * moving along the curve.
   *
   * @param time A segment time between 0 and 1
   */
  derivativeAtTime(time: number) {
    const timeSq = time * time;
    const a = -3 * timeSq + 6 * time - 3;
    const b = 9 * timeSq - 12 * time + 3;
    const c = -9 * timeSq + 6 * time;
    const d = 3 * timeSq;
    const { s, cs, ce, e } = this;
    return new Vec(
      a * s.x + b * cs.x + c * ce.x + d * e.x,
      a * s.y + b * cs.y + c * ce.y + d * e.y
    );
  }

  /**
   * @returns the second derivative of the segment at `time`.
   *
   * The second derivative can also be thought of as the "acceleration", or
   * change in velocity of a point moving along the curve.
   *
   * @param time A segment time between 0 and 1
   */
  secondDerivativeAtTime(time: number) {
    const a = -6 * time + 6;
    const b = 18 * time - 12;
    const c = -18 * time + 6;
    const d = 6 * time;
    const { s, cs, ce, e } = this;
    return new Vec(
      a * s.x + b * cs.x + c * ce.x + d * e.x,
      a * s.y + b * cs.y + c * ce.y + d * e.y
    );
  }

  /**
   * @returns the curvature of the segment at `time`.
   *
   * Curvature describes how "bent" a segment is at a given point. It can be
   * thought of as the reciprocal of the radius of a circle with the same
   * curvature. To find the radius, one can use the formula `1 / curvature`.
   *
   * @param time A segment time between 0 and 1
   */
  curvatureAtTime(time: number) {
    const d1 = this.derivativeAtTime(time);
    const d2 = this.secondDerivativeAtTime(time);
    const det = d1.x * d2.y - d2.x * d1.y;
    const speed = d1.length();
    return det / (speed * speed * speed);
  }

  /**
   * @returns a unit-length vector tangent to the segment at `time`.
   * @param time A segment time between 0 and 1
   */
  tangentAtTime(time: number) {
    // The derivative can be zero at the end of a cubic if s === cs or e === ce.
    // Special case these and look at consecutive points to find a non-zero
    // tangent.
    if (time === 0) {
      const s = this.s;
      let p = this.cs;
      if (s.equals(p)) {
        p = this.ce;
        if (s.equals(p)) p = this.e;
      }
      return p.clone().sub(s).normalize();
    }
    if (time === 1) {
      const e = this.e;
      let p = this.ce;
      if (e.equals(p)) {
        p = this.cs;
        if (e.equals(p)) p = this.s;
      }
      return e.clone().sub(p).normalize();
    }

    // For all other times, use the derivative.
    return this.derivativeAtTime(time).normalize();
  }

  /**
   * @returns a unit-length vector perpendicular to the segment at `time`.
   * @param time A segment time between 0 and 1
   */
  normalAtTime(time: number) {
    return this.tangentAtTime(time).rotate90();
  }

  timeAtPoint(point: Vec, tolerance?: number) {
    return timeAtPointOnCubic(this.s, this.cs, this.ce, this.e, point, tolerance);
  }

  closestPoint(point: Vec, areaOfInterest?: BoundingBox): ClosestPointResultWithTime | undefined {
    if (areaOfInterest && !this._looseOverlapsBoundingBox(areaOfInterest)) return;

    const result: ClosestPointResultWithTime = closestPointOnCubic(
      this.s,
      this.cs,
      this.ce,
      this.e,
      point
    );

    if (areaOfInterest && !areaOfInterest.containsPoint(result.position)) return;

    result.geometry = this;
    return result;
  }

  boundingBox() {
    const box = new BoundingBox();
    boundingBoxForCubic(this.s, this.cs, this.ce, this.e, box.min, box.max);
    return box;
  }
  looseBoundingBox() {
    const { s, cs, ce, e } = this;
    return new BoundingBox(
      new Vec(Math.min(s.x, cs.x, ce.x, e.x), Math.min(s.y, cs.y, ce.y, e.y)),
      new Vec(Math.max(s.x, cs.x, ce.x, e.x), Math.max(s.y, cs.y, ce.y, e.y))
    );
  }

  /**
   * @returns A new cubic segment that is the subset of the segment between
   * `startTime` and `endTime`.
   *
   * @param startTime A segment time between 0 and 1
   * @param endTime A segment time between 0 and 1 greater than `startTime`
   */
  slice(startTime: number, endTime: number) {
    let segment: CubicSegment = this;
    if (startTime > endTime) {
      [startTime, endTime] = [endTime, startTime];
    }
    if (!approximatelyZero(startTime)) {
      // Chop off the start of the segment.
      [, segment] = segment.splitAtTime(startTime);
    }
    if (!approximatelyOne(endTime)) {
      // Chop off the end of the segment.
      [segment] = segment.splitAtTime((endTime - startTime) / (1 - startTime));
    }
    return segment;
  }

  /**
   * Splits the segment at a specific `time`.
   *
   * @returns two cubic segments.
   * @param time A segment time between 0 and 1
   */
  splitAtTime(time: number) {
    const { s, cs, ce, e } = this;
    const m = cs.clone().mix(ce, time);
    const a1 = s.clone().mix(cs, time);
    const a2 = a1.clone().mix(m, time);
    const b2 = ce.clone().mix(e, time);
    const b1 = m.clone().mix(b2, time);
    const a3 = a2.clone().mix(b1, time);
    return [new CubicSegment(s, a1, a2, a3), new CubicSegment(a3, b1, b2, e)];
  }

  isContainedByBoundingBox(box: BoundingBox): boolean {
    const bounds = this.boundingBox();
    return Boolean(bounds && box.containsBoundingBox(bounds));
  }

  isIntersectedByBoundingBox(box: BoundingBox): boolean {
    if (this._looseOverlapsBoundingBox(box)) {
      const s = new Vec();
      const e = new Vec();
      const line = new LineSegment(s, e);

      // Top
      s.set(box.min.x, box.min.y);
      e.set(box.max.x, box.min.y);
      if (this._intersectionsWithLine(line).length > 0) return true;

      // Right
      s.set(box.max.x, box.min.y);
      e.set(box.max.x, box.max.y);
      if (this._intersectionsWithLine(line).length > 0) return true;

      // Bottom
      s.set(box.max.x, box.max.y);
      e.set(box.min.x, box.max.y);
      if (this._intersectionsWithLine(line).length > 0) return true;

      // Left
      s.set(box.min.x, box.max.y);
      e.set(box.min.x, box.min.y);
      if (this._intersectionsWithLine(line).length > 0) return true;
    }
    return false;
  }

  isOverlappedByBoundingBox(box: BoundingBox): boolean {
    return this.isContainedByBoundingBox(box) || this.isIntersectedByBoundingBox(box);
  }

  _looseOverlapsBoundingBox(box: BoundingBox) {
    const { s, cs, ce, e } = this;
    const minx = Math.min(s.x, cs.x, ce.x, e.x);
    const maxx = Math.max(s.x, cs.x, ce.x, e.x);
    const miny = Math.min(s.y, cs.y, ce.y, e.y);
    const maxy = Math.max(s.y, cs.y, ce.y, e.y);
    return maxx >= box.min.x && minx <= box.max.x && maxy >= box.min.y && miny <= box.max.y;
  }

  _intersectionsWithCubic(segment: CubicSegment) {
    // Optimization: try to exit early, especially if the cubics have overlap
    // which will make this algorithm blow up.
    if (this._almostEquals(segment)) {
      return [];
    }
    if (segmentOverlap(this, segment, GEOMETRIC_TOLERANCE)) {
      return [];
    }

    type Candidate = {
      time1: number;
      segment1: CubicSegment;
      time2: number;
      segment2: CubicSegment;
    };
    let candidates: Candidate[] = [{ time1: 0, segment1: this, time2: 0, segment2: segment }];
    let timeLength = 1;

    const maxIterations = 12;
    for (let i = 0; i < maxIterations; i++) {
      const nextCandidates: Candidate[] = [];
      const nextTimeLength = timeLength * 0.5;
      for (const { time1, segment1, time2, segment2 } of candidates) {
        const boundsOverlap = segment1
          .looseBoundingBox()
          .expandScalar(GEOMETRIC_TOLERANCE)
          .overlapsBoundingBox(segment2.looseBoundingBox());
        if (!boundsOverlap) continue;

        const [seg1a, seg1b] = segment1.splitAtTime(0.5);
        const [seg2a, seg2b] = segment2.splitAtTime(0.5);
        nextCandidates.push(
          { time1: time1, segment1: seg1a, time2: time2, segment2: seg2a },
          { time1: time1, segment1: seg1a, time2: time2 + nextTimeLength, segment2: seg2b },
          { time1: time1 + nextTimeLength, segment1: seg1b, time2: time2, segment2: seg2a },
          {
            time1: time1 + nextTimeLength,
            segment1: seg1b,
            time2: time2 + nextTimeLength,
            segment2: seg2b,
          }
        );
      }
      if (nextCandidates.length === 0) return [];
      candidates = nextCandidates;
      timeLength = nextTimeLength;
    }

    let intersections: { time1: number; time2: number }[] = [];
    for (let { time1, segment1, time2, segment2 } of candidates) {
      const intersection = lineLineIntersection(segment1.s, segment1.e, segment2.s, segment2.e);
      if (intersection) {
        intersections.push({
          time1: time1 + intersection.time1 * timeLength,
          time2: time2 + intersection.time2 * timeLength,
        });
      }
    }

    intersections = intersections.filter(
      (intersection) => !isInternalIntersection(this, segment, intersection)
    );
    return intersections;
  }

  _intersectionsWithLine(segment: LineSegment) {
    let results = lineCubicIntersections(segment.s, segment.e, this.s, this.cs, this.ce, this.e);
    results = results.filter((result) => {
      if (result.time1 < 0 || result.time1 > 1) return false;
      if (isInternalIntersection(segment, this, result)) return false;
      return true;
    });
    return swapAllIntersectionTimes(results);
  }

  _intersectionsWithAxis(axis: Axis) {
    const results = axisCubicIntersections(
      axis.origin,
      axis.direction,
      this.s,
      this.cs,
      this.ce,
      this.e
    );
    return swapAllIntersectionTimes(results);
  }

  _intersectionsWithRay(ray: Ray) {
    const results = rayCubicIntersections(
      ray.origin,
      ray.direction,
      this.s,
      this.cs,
      this.ce,
      this.e
    );
    return swapAllIntersectionTimes(results);
  }

  _intersectionsWithPrimitive(primitive: GeometryPrimitive) {
    if (primitive instanceof CubicSegment) {
      return this._intersectionsWithCubic(primitive);
    } else if (primitive instanceof LineSegment) {
      return this._intersectionsWithLine(primitive);
    } else if (primitive instanceof Axis) {
      return this._intersectionsWithAxis(primitive);
    } else if (primitive instanceof Ray) {
      return this._intersectionsWithRay(primitive);
    }
    return [];
  }

  _overlapWithPrimitive(primitive: GeometryPrimitive, tolerance: number) {
    if (primitive instanceof LineSegment || primitive instanceof CubicSegment) {
      return segmentOverlap(this, primitive, tolerance);
    }
    return undefined;
  }

  _primitives() {
    return [this];
  }

  /**
   * @returns the signed area between the segment and the origin.
   */
  _signedArea() {
    // From: http://web.archive.org/web/20160701040856/http://objectmix.com/graphics/133553-area-closed-bezier-curve.html
    const {
      s: { x: x0, y: y0 },
      cs: { x: x1, y: y1 },
      ce: { x: x2, y: y2 },
      e: { x: x3, y: y3 },
    } = this;
    return (
      (3 *
        ((y3 - y0) * (x1 + x2) -
          (x3 - x0) * (y1 + y2) +
          y1 * (x0 - x2) -
          x1 * (y0 - y2) +
          y3 * (x2 + x0 / 3) -
          x3 * (y2 + y0 / 3))) /
      20
    );
  }

  static isValid(a: unknown): a is CubicSegment {
    return a instanceof CubicSegment && a.isValid();
  }

  /**
   * Constructs a cubic segment from two anchors.
   *
   * The `handleOut` of `startAnchor` and `handleIn` of `endAnchor` will be
   * used, but the other handles will be ignored.
   *
   * @param startAnchor
   * @param endAnchor
   */
  static fromAnchors(startAnchor: Anchor, endAnchor: Anchor) {
    return new CubicSegment(
      startAnchor.position,
      startAnchor.position.clone().add(startAnchor.handleOut),
      endAnchor.position.clone().add(endAnchor.handleIn),
      endAnchor.position
    );
  }
}
