import {
  CompoundPath,
  Font,
  FontGlyph,
  FontSymbolRetriever,
  RichText,
  RichTextGlyph,
  Vec,
  isString,
} from "..";
import type { AlternateGlyphReplacements, StringGlyphRun } from "..";
import { logManager } from "../../log-manager";

interface SVGFontGlyph {
  index: number;
  d: string;
  width: number;
}

export class SVGFont extends Font {
  _family = "";
  _variant = "";

  ascenderHeight: number;
  descenderHeight: number;

  private unitsPerEm: number;

  private fontHorizAdvX: number;

  private glyphs: Record<string, SVGFontGlyph> = {};
  private glyphsArray: SVGFontGlyph[] = [];
  private missingGlyph: SVGFontGlyph;

  constructor(svgDoc: Document, symbolRetriever: FontSymbolRetriever) {
    super(symbolRetriever);

    const svgFont = svgDoc.getElementsByTagName("font")[0];
    this.fontHorizAdvX = parseFloatAttribute(svgFont, "horiz-adv-x", 300);

    const svgFontface = svgDoc.getElementsByTagName("font-face")[0];
    this.unitsPerEm = parseFloatAttribute(svgFontface, "units-per-em", 1000);

    // Save relative to 1em
    this.ascenderHeight = parseFloatAttribute(svgFontface, "ascent", 800) / this.unitsPerEm;
    this.descenderHeight = parseFloatAttribute(svgFontface, "descent", -200) / this.unitsPerEm;

    const svgMissingGlyph = svgDoc.getElementsByTagName("missing-glyph")[0];
    this.missingGlyph = {
      index: 0,
      d: "",
      width: parseFloatAttribute(svgMissingGlyph, "horiz-adv-x", 300),
    };
    this.glyphsArray[0] = this.missingGlyph;

    const svgGlyphs = svgDoc.getElementsByTagName("glyph");
    for (var i = 0; i < svgGlyphs.length; i++) {
      const svgGlyph = svgGlyphs[i];
      const unicode = svgGlyph.getAttribute("unicode");
      if (unicode === null) continue;

      const d = svgGlyph.getAttribute("d") ?? "";
      const width = parseFloatAttribute(svgGlyph, "horiz-adv-x", this.fontHorizAdvX);

      const index = i + 1; // 0 is reserved for missing glyph
      const glyph = { index, d, width };

      this.glyphs[unicode] = glyph;
      this.glyphsArray[index] = glyph;
    }
  }

  private glyphForCharacter(char: string | undefined) {
    if (isString(char) && this.glyphs.hasOwnProperty(char)) {
      return this.glyphs[char];
    }
    return this.missingGlyph;
  }

  _glyphsFromTextString(textString: string | StringGlyphRun) {
    const metricsScale = 1 / this.unitsPerEm;

    const glyphs: FontGlyph[] = [];
    let x = 0;
    let warning: string | undefined;

    function addGlyph(svgGlyph: SVGFontGlyph, char?: string) {
      if (!warning && char && svgGlyph.index === 0) {
        warning = `Text “${char}” is not supported by the selected font. Try searching in the font picker for the language of your text, for example “Arabic” or “Japanese”.`;
      }
      const geometry = CompoundPath.fromSVGPathString(svgGlyph.d);
      const advanceWidth = svgGlyph.width * metricsScale;
      const advanceX = advanceWidth;
      geometry.transform({
        scale: new Vec(metricsScale, metricsScale * -1),
      });
      glyphs.push({
        name: isString(char) ? char : "",
        geometry,
        advanceWidth,
        advanceX,
        x,
      });
      x += advanceX;
    }

    const stringGlyphRuns = textString instanceof Array ? textString : [textString];
    for (const item of stringGlyphRuns) {
      if (isString(item)) {
        for (const char of item) {
          const svgChar = this.glyphForCharacter(char);
          addGlyph(svgChar, char);
        }
      } else if (item instanceof RichTextGlyph) {
        const svgChar = this.glyphsArray[item.index];
        if (svgChar) {
          addGlyph(svgChar);
        } else if (!warning) {
          warning = `Glyph index ${item.index} not found in font.`;
        }
      }
    }

    if (warning) {
      logManager.consoleWarn(warning);
    }

    return glyphs;
  }

  replacePatternsWithGlyphAlternates(
    text: string | RichText,
    replacements: AlternateGlyphReplacements
  ): RichText {
    logManager.consoleWarn("Glyph alternates are not yet available for SVG fonts.");
    text = text instanceof RichText ? text : new RichText(text);
    return text;
  }
}

const parseFloatAttribute = (element: Element | undefined, name: string, defaultValue: number) => {
  if (element === undefined) return defaultValue;
  const attr = element.getAttribute(name);
  if (attr === null) return defaultValue;
  const value = parseFloat(attr);
  if (isNaN(value)) return defaultValue;
  return value;
};
