import { isArray, isString, toString } from "..";

/**
 * Types that can be passed to a `RichText` constructor.
 */
export type RichTextItem = string | RichTextGlyph | RichTextSymbol;

function sanitizeRichTextArg(arg: unknown): RichTextItem | Array<RichTextItem> {
  if (arg === undefined || arg === null) return "";
  if (isString(arg)) return arg;
  if (arg instanceof RichTextGlyph) return arg;
  if (arg instanceof RichTextSymbol) return arg;
  if (arg instanceof RichText) return arg.items;
  if (isArray(arg)) return arg.flatMap(sanitizeRichTextArg);
  return toString(arg);
}

/**
 * Collection of strings, `RichTextSymbol`, and `RichTextGlyph` that are
 * rendered together. Attempts to be compatible with JavaScript's
 * [`String`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String)
 * class.
 *
 * @implements {Iterable<string | RichTextSymbol>} to iterate over string
 * characters and symbols.
 */
export class RichText {
  /**
   * Array of strings, `RichTextSymbol`, and `RichTextGlyph`.
   */
  items: RichTextItem[];

  /**
   * Constructs a RichText object from strings and `RichTextSymbol`. Strings can
   * include newlines, which are handled by the Text component. Subsequent
   * string items will be concatenated together.
   *
   * ```
   * const rich = RichText("Before ", RichTextSymbol("https://..."), " after");
   * const lines = MyFont.render(rich);
   * // Result: Array<{ geometry: Group; advanceX: number; warning: string | undefined; }>
   * ```
   *
   * @param {...*} args Strings, `RichTextSymbol`, and `RichTextGlyph` to be rendered together.
   */
  constructor(...args: unknown[]) {
    this.items = args.flatMap(sanitizeRichTextArg);
    this.canonicalize();

    // To make numerical index access work, e.g. rich[0]
    return new Proxy(this, {
      get: function (target, prop, receiver) {
        if (typeof prop === "string") {
          const maybeIndex = +prop;
          if (isFinite(maybeIndex) && maybeIndex >= 0) {
            return target.charAt(maybeIndex);
          }
        }
        return Reflect.get(target, prop, receiver);
      },
    });
  }

  /**
   * Append an item to the end of the items. If the item to append and the last
   * item are both strings, they are combined.
   *
   * @returns this `RichText` object, with modified items.
   */
  append(item: RichTextItem) {
    const previousIndex = this.items.length - 1;
    if (previousIndex >= 0 && isString(item) && isString(this.items[previousIndex])) {
      this.items[previousIndex] += item;
    } else {
      this.items.push(item);
    }
    return this;
  }

  /**
   * @returns a copy of this `RichText` with all items cloned.
   */
  clone() {
    const items = this.items.map((item) => {
      if (isString(item)) return item;
      return item.clone();
    });
    return new RichText(items);
  }

  private _cloneAndMapOverStrings(callback: (s: string) => string) {
    const items = this.items.map((item) => {
      if (isString(item)) return callback(item);
      return item.clone();
    });
    return new RichText(items);
  }

  /**
   * Concatinates subsequent string items together.
   *
   * @returns this `RichText` object, with modified items.
   */
  canonicalize() {
    const items = this.items;
    const canonicalItems: RichTextItem[] = [];
    let concatinatedString = "";
    for (let item of items) {
      if (isString(item)) {
        concatinatedString += item;
      } else {
        if (concatinatedString.length > 0) {
          canonicalItems.push(concatinatedString);
          concatinatedString = "";
        }
        canonicalItems.push(item);
      }
    }
    if (concatinatedString.length > 0) {
      canonicalItems.push(concatinatedString);
    }
    this.items = canonicalItems;
    return this;
  }

  /**
   * @returns `true` if the RichText items are all equal, `false` otherwise.
   */
  equals(richText: RichText) {
    if (richText === this) return true;
    if (richText.items.length !== this.items.length) return false;
    return this.items.every((item, index) => {
      const otherItem = richText.items[index];
      if (isString(item)) {
        return item === otherItem;
      }
      if (item instanceof RichTextGlyph && otherItem instanceof RichTextGlyph) {
        return item.index === otherItem.index;
      }
      if (item instanceof RichTextSymbol && otherItem instanceof RichTextSymbol) {
        return item.source === otherItem.source;
      }
      return false;
    });
  }

  /**
   * First item gets leading whitespace removed. Last item gets trailing
   * whitespace removed.
   *
   * @returns a new `RichText` object, without modifying the original.
   */
  trim() {
    const clone = this.clone().canonicalize();
    if (clone.items.length === 0) return clone;
    const first = clone.items[0];
    if (isString(first)) {
      clone.items[0] = first.trimStart();
    }
    const last = clone.items[clone.items.length - 1];
    if (isString(last)) {
      clone.items[clone.items.length - 1] = last.trimEnd();
    }
    return clone;
  }

  /**
   * First item gets leading whitespace removed.
   *
   * @returns a new `RichText` object, without modifying the original.
   */
  trimStart() {
    const clone = this.clone().canonicalize();
    if (clone.items.length === 0) return clone;
    const first = clone.items[0];
    if (isString(first)) {
      clone.items[0] = first.trimStart();
    }
    return clone;
  }

  /**
   * Last item gets trailing whitespace removed.
   *
   * @returns a new `RichText` object, without modifying the original.
   */
  trimEnd() {
    const clone = this.clone().canonicalize();
    if (clone.items.length === 0) return clone;
    const last = clone.items[clone.items.length - 1];
    if (isString(last)) {
      clone.items[clone.items.length - 1] = last.trimEnd();
    }
    return clone;
  }

  /**
   * Makes a clone with all string items lowercase.
   *
   * @returns a new `RichText` object, without modifying the original.
   */
  toLowerCase() {
    return this._cloneAndMapOverStrings((s) => s.toLowerCase());
  }

  /**
   * Makes a clone with all string items uppercase.
   *
   * @returns a new `RichText` object, without modifying the original.
   */
  toUpperCase() {
    return this._cloneAndMapOverStrings((s) => s.toUpperCase());
  }

  /**
   * Makes a clone with all string items lowercase, taking into account the host
   * environment's current locale.
   *
   * @returns a new `RichText` object, without modifying the original.
   */
  toLocaleLowerCase(locales?: string | string[]) {
    return this._cloneAndMapOverStrings((s) => s.toLocaleLowerCase(locales));
  }

  /**
   * Makes a clone with all string items uppercase, taking into account the host
   * environment's current locale.
   *
   * @returns a new `RichText` object, without modifying the original.
   */
  toLocaleUpperCase(locales?: string | string[]) {
    return this._cloneAndMapOverStrings((s) => s.toLocaleUpperCase(locales));
  }

  /**
   * @param index The zero-based index of the desired character within the
   * items.
   * @returns the Unicode value of the character at the specified location. If
   * there is no character at the specified index, NaN is returned.
   */
  charCodeAt(index: number) {
    return this.toString().charCodeAt(index);
  }

  /**
   * Returns a portion of this RichText`, from the start to the end index,
   * including symbols.
   *
   * @returns a new `RichText` object, without modifying the original.
   */
  slice(start: number, end?: number) {
    const clone = this.clone().canonicalize();
    const length = clone.length;
    if (start >= length) {
      return new RichText();
    }
    if (start < 0) {
      start = Math.max(start + length, 0);
    }
    if (end === undefined || end > length) {
      end = length;
    }
    if (end < 0) {
      end = Math.max(end + length, 0);
    }
    if (end <= start) {
      return new RichText();
    }
    const newItems: RichTextItem[] = [];
    let index = 0;
    let newString = "";
    for (let char of clone) {
      if (index >= start && index < end) {
        if (isString(char)) {
          newString += char;
        } else {
          if (newString.length) {
            newItems.push(newString);
            newString = "";
          }
          newItems.push(char);
        }
      }
      index++;
    }
    if (newString.length) {
      newItems.push(newString);
    }
    clone.items = newItems;
    return clone;
  }

  /**
   * Returns a portion of this `RichText`, starting at the specified index and
   * extending for a given number of characters and symbols afterwards.
   *
   * @returns a new `RichText` object, without modifying the original.
   */
  substr(start: number, length?: number) {
    if (length === undefined) {
      length = this.length;
    }
    return this.slice(start, start + length);
  }

  /**
   * @returns a new `RichText` object, containing the indexed character or
   * symbol.
   */
  charAt(index: number) {
    let i = 0;
    for (let char of this) {
      if (index === i) return new RichText(char);
      i++;
    }
    return undefined;
  }

  /**
   * Replaces text in the items, using a regular expression or search string.
   *
   * @returns a new `RichText` object, without modifying the original.
   */
  replace(searchValue: string | RegExp, replaceValue: string) {
    return this._cloneAndMapOverStrings((s) => s.replace(searchValue, replaceValue));
  }

  /**
   * @returns items joined into one string, with RichTextSymbols represented as underscore.
   */
  toString(): string {
    return this.items.join("");
  }

  /**
   * Split a `RichText` items into multiple `RichText` using the specified
   * separator string.
   *
   * @returns an array of `RichText` objects
   */
  split(separator: string | RegExp): RichText[] {
    if (separator === "") {
      return Array.from(this).map((item) => new RichText(item));
    }

    const out: RichText[] = [];
    let current = new RichText();

    for (let item of this.items) {
      if (isString(item)) {
        const stringParts = item.split(separator);
        for (let i = 0; i < stringParts.length; i++) {
          if (i > 0) {
            out.push(current);
            current = new RichText();
          }
          current.append(stringParts[i]);
        }
      } else {
        current.append(item);
      }
    }
    if (current.items.length > 0) {
      out.push(current);
    }

    return out;
  }

  /**
   * @param searchString The characters to be searched for at the start of the
   * RichText.
   * @param position Optional. The start position at which searchString is
   * expected to be found (the index of searchString's first character).
   * Defaults to 0.
   *
   * @returns boolean `true` if the RichText starts with the specified string.
   * `RichTextSymbol`s are represented as underscore.
   */
  startsWith(searchString: string, position?: number) {
    return this.toString().startsWith(searchString, position);
  }

  /**
   * @param searchString The characters to be searched for at the end of the
   * RichText.
   * @param position Optional. The end position at which searchString is
   * expected to be found (the index of searchString's last character plus 1).
   * Defaults to this.length.
   *
   * @returns boolean `true` if the RichText ends with the specified string.
   * `RichTextSymbol`s are represented as underscore.
   */
  endsWith(searchString: string, position?: number) {
    return this.toString().endsWith(searchString, position);
  }

  /**
   * String length of all of the items, with each symbol counting as one character.
   */
  get length() {
    return this.toString().length;
  }

  // To be able to use for...of with characters and symbols
  *[Symbol.iterator]() {
    // TODO: consider iterating through graphemes instead of characters, for
    // compatibility with Korean, emoji, etc. Ref:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Segmenter/Segmenter
    // (Missing Firefox support as of 2024-02-28)
    // https://caniuse.com/mdn-javascript_builtins_intl_segmenter_segment
    for (let item of this.items) {
      if (isString(item)) {
        for (let char of item) {
          yield char;
        }
      } else {
        yield item;
      }
    }
  }
}

/**
 * Represents a symbol to be rendered inline with text. Used in `RichText`.
 */
export class RichTextSymbol {
  /**
   * URL pointing to a SVG file.
   */
  source: string;

  /**
   * Constructs a RichTextSymbol object from a `source`.
   *
   * @param source Currently this can only be a URL pointing to an SVG file. The
   * URL must start with `"https://assets.cuttle.xyz"` and the geometry in the
   * file should work as a `CompoundPath`. Other source types may be added in
   * the future. The source URL can be picked with the "Emoji" parameter type.
   */
  constructor(source: string) {
    this.source = source;
  }

  clone() {
    return new RichTextSymbol(this.source);
  }

  toString() {
    return "_";
  }
}

/**
 * Represents a glyph by index. Used in `RichText`.
 *
 * Created by `font.replacePatternsWithGlyphAlternates`.
 *
 * Glyphs are specific to each font, so a glyph index may represent a completely
 * different character in a different font. Some fonts contain alternate glyphs
 * for certain characters, and some even contain glyphs that don't represent any
 * character.
 */
export class RichTextGlyph {
  /**
   * Constructs a RichTextSymbol object from a glyph `index`.
   *
   * @param index Glyph index for a specific font file.
   */
  constructor(public index: number) {}

  clone() {
    return new RichTextGlyph(this.index);
  }

  toString() {
    return "_";
  }
}
