import m from "mithril";

import { AffineMatrix, assert, Axis, roundToFixed, Vec } from "../geom";
import { CanvasDrag, globalState } from "../global-state";
import { CanvasPoint } from "../model/canvas-point";
import { accountState } from "../shared/account";
import { CANVAS_WIDTH_MIN, LEFT_PANEL_WIDTH } from "./constants";

export const PANEL_TOP_HEIGHT = 28;

/*

For non-zooms, deltaX and deltaY are normalized to Chrome's units, which are
"how many pixels would this wheel event scroll a scrolling container".

For zooms, deltaY tells you zoomed. Positive number is pinch (zoom out),
negative number is anti-pinch (zoom in).

Note that OS can fire wheel events with momentum applied, so one "pan" gesture
will come through as several discrete events, with the delta values decreasing
to mimic deceleration.

Some resources about how wheel events are handled in browsers:

https://github.com/facebookarchive/fixed-data-table/blob/master/src/vendor_upstream/dom/normalizeWheel.js
https://medium.com/@auchenberg/detecting-multi-touch-trackpad-gestures-in-javascript-a2505babb10e
https://github.com/d4nyll/lethargy

*/
export interface WheelEventInfo {
  deltaX: number;
  deltaY: number;
}
export const wheelEventInfo = (event: WheelEvent): WheelEventInfo => {
  let { deltaX, deltaY, deltaMode } = event;

  // Chrome and Safari always send deltaMode === 0 (pixels). We'll include these
  // deltaMode scaling factors for completeness, for some OS settings and
  // browser combinations.
  if (deltaMode === 1 /* WheelEvent.DOM_DELTA_LINE */) {
    // Depending on OS, Firefox can send line units. This multiple was
    // empirically determined on a Mac with a mouse.
    const lineToPixel = 100 / 3;
    deltaX *= lineToPixel;
    deltaY *= lineToPixel;
  } else if (deltaMode === 2 /* WheelEvent.DOM_DELTA_PAGE */) {
    const pageToPixel = 800;
    deltaX *= pageToPixel;
    deltaY *= pageToPixel;
  }

  return { deltaX, deltaY };
};

// GestureEvent specific to Safari
// See: https://developer.apple.com/documentation/webkitjs/gestureevent
interface GestureEvent extends UIEvent {
  readonly clientX: number;
  readonly clientY: number;
  readonly scale: number;
  readonly rotation: number;
}

export interface ViewportGestureEvent {
  deltaX: number;
  deltaY: number;
  deltaScale: number;
}
export class ViewportGestureListener {
  element: HTMLElement;

  onWheelEvent: (event: WheelEvent) => void;
  onGestureStart: (event: Event) => void;
  onGestureChange: (event: Event) => void;
  onGestureEnd: (event: Event) => void;

  gesturePrevX = 0;
  gesturePrevY = 0;
  gesturePrevScale = 0;

  constructor(element: HTMLElement, onChange: (event: ViewportGestureEvent) => void) {
    this.element = element;

    let pointerInitModalShown = globalState.deviceStorage.pointerInitShown;
    const showPointerInitModal = () => {
      pointerInitModalShown = true;
      globalState.openPointerInitModal();
    };

    this.onWheelEvent = (event: WheelEvent) => {
      event.preventDefault();

      if (!pointerInitModalShown) {
        showPointerInitModal();
        return;
      }

      const info = wheelEventInfo(event);

      let deltaX = 0;
      let deltaY = 0;
      let deltaScale = 1;

      /* "Proposal 0.5" documented and discussed here:
       * https://www.notion.so/cuttlexyz/A-new-approach-to-Scroll-Wheel-945008ddddbf4592871af88c1ad673a6
       */

      // Chrome and Firefox send pinch gestures as wheel events with ctrlKey.
      // Since we keep track of key down events, we can infer if this is a pinch
      // or wheel zoom.
      const isPinchToZoom = event.ctrlKey && !globalState.isControlDown && !globalState.isMetaDown;
      // Default is scroll to pan, since this matches trackpad better, and there
      // isn't a good way across browsers and OS combinations to detect the
      // difference of scrolling with trackpad vs mouse wheel.
      const scrollToZoom = globalState.deviceStorage.scrollBehavior === "zoom";
      // Check event meta rather than globalState, because this works even
      // without first clicking to focus the window.
      const isAlternate = event.ctrlKey || event.metaKey;
      const isScrollToZoom = (!scrollToZoom && isAlternate) || (scrollToZoom && !isAlternate);

      if (isPinchToZoom || isScrollToZoom) {
        deltaScale = Math.pow(isPinchToZoom ? 1.01 : 1.001, -info.deltaY);
      } else {
        deltaX = info.deltaX;
        deltaY = info.deltaY;
      }

      onChange({ deltaX, deltaY, deltaScale });
      m.redraw();
    };
    this.onGestureStart = (event: Event) => {
      const ev = event as GestureEvent;
      ev.preventDefault();

      this.gesturePrevX = ev.clientX;
      this.gesturePrevY = ev.clientY;
      this.gesturePrevScale = 1;
    };
    this.onGestureChange = (event: Event) => {
      const ev = event as GestureEvent;
      ev.preventDefault();

      let deltaX = ev.clientX - this.gesturePrevX;
      let deltaY = ev.clientY - this.gesturePrevY;
      let deltaScale = ev.scale - this.gesturePrevScale + 1;

      this.gesturePrevX = ev.clientX;
      this.gesturePrevY = ev.clientY;
      this.gesturePrevScale = ev.scale;

      onChange({ deltaX, deltaY, deltaScale });
      m.redraw();
    };
    this.onGestureEnd = (event: Event) => {
      event.preventDefault();
    };

    this.element.addEventListener("wheel", this.onWheelEvent);
    this.element.addEventListener("gesturestart", this.onGestureStart);
    this.element.addEventListener("gesturechange", this.onGestureChange);
    this.element.addEventListener("gestureend", this.onGestureEnd);
  }

  dispose() {
    this.element.removeEventListener("wheel", this.onWheelEvent);
    this.element.removeEventListener("gesturestart", this.onGestureStart);
    this.element.removeEventListener("gesturechange", this.onGestureChange);
    this.element.removeEventListener("gestureend", this.onGestureEnd);
  }
}

// Note: this only handles vertical scroll and depends on the scrolling
// container having class `scrollable`.
export const scrollIntoView = (element: HTMLElement) => {
  const elementRect = element.getBoundingClientRect();
  const scroller = element.closest(".scrollable");
  if (scroller !== null) {
    const scrollerRect = scroller.getBoundingClientRect();

    // Scroll down if needed.
    const relativeBottom = elementRect.bottom - scrollerRect.top;
    const overshootBottom = relativeBottom - scrollerRect.height;
    if (overshootBottom > 0) {
      scroller.scrollTop += overshootBottom;
      return;
    }

    // Scroll up if needed.
    const relativeTop = elementRect.top - scrollerRect.top;
    if (relativeTop < 0) {
      scroller.scrollTop += relativeTop;
    }
  }
};

export const POINTER_EVENT_BUTTONS_NONE = 0;
export const POINTER_EVENT_BUTTONS_LEFT = 1;
export const POINTER_EVENT_BUTTONS_RIGHT = 2;

export const precisionInfoForContext = (inverseContextMatrix: AffineMatrix) => {
  const focusedComponent = globalState.project.focusedComponent();
  assert(focusedComponent, "A focused component is required for precision info.");

  const viewport = globalState.viewportManager.viewportForComponent(focusedComponent);
  const pixelScale = 1 / viewport.pixelsPerUnit;
  const exponent = Math.round(Math.log10(pixelScale));
  const increment = Math.pow(10, exponent);

  const { a, b, c, d } = inverseContextMatrix;
  const aa = Math.abs(a);
  const ab = Math.abs(b);
  const ac = Math.abs(c);
  const ad = Math.abs(d);
  let minComponent = Infinity;
  if (aa > 0 && aa < minComponent) minComponent = aa;
  if (ab > 0 && ab < minComponent) minComponent = ab;
  if (ac > 0 && ac < minComponent) minComponent = ac;
  if (ad > 0 && ad < minComponent) minComponent = ad;

  const fractionDigits =
    minComponent === Infinity ? 1 : Math.max(0, -Math.floor(Math.log10(minComponent * increment)));

  return { fractionDigits, increment, exponent };
};

export const idealUnitSquareScaleForContext = (inverseContextMatrix: AffineMatrix) => {
  const focusedComponent = globalState.project.focusedComponent();
  assert(focusedComponent, "A focused component is required for ideal unit square scale.");

  const idealSizePx = 100; // Arbitrary. Seems nice.
  const viewport = globalState.viewportManager.viewportForComponent(focusedComponent);
  const pixelScale = idealSizePx / viewport.pixelsPerUnit;
  return Math.pow(10, Math.round(Math.log10(pixelScale)));
};

export const minimumPrecisionWorldPositionsFromCanvasDrag = (canvasDrag: CanvasDrag) => {
  const focusedComponent = globalState.project.focusedComponent();
  assert(focusedComponent, "A focused component is required for precision info");

  const startPosition = canvasDrag.startPoint.worldPosition.clone();
  const currentPosition = canvasDrag.currentPoint.worldPosition.clone();
  const viewport = globalState.viewportManager.viewportForComponent(focusedComponent);
  const { fractionDigits } = viewport.precisionInfo();

  // NOTE: We round both the start and current positions so that the resulting
  // translation is at the correct level of precision. If we only rounded the
  // current position, then dragging a path (from a "Path" snapping point) would
  // almost always result in a too-precise transform.
  if (!canvasDrag.isPreciseH()) {
    startPosition.x = roundToFixed(startPosition.x, fractionDigits);
    currentPosition.x = roundToFixed(currentPosition.x, fractionDigits);
  }
  if (!canvasDrag.isPreciseV()) {
    startPosition.y = roundToFixed(startPosition.y, fractionDigits);
    currentPosition.y = roundToFixed(currentPosition.y, fractionDigits);
  }

  return { startPosition, currentPosition };
};

export const minimumPrecisionPositionForCanvasPointInContext = (
  canvasPoint: CanvasPoint,
  inverseContextMatrix: AffineMatrix
) => {
  const contextPosition = canvasPoint.worldPosition.clone().affineTransform(inverseContextMatrix);
  const { fractionDigits } = precisionInfoForContext(inverseContextMatrix);
  if (!canvasPoint.isPreciseH) {
    contextPosition.x = roundToFixed(contextPosition.x, fractionDigits);
  }
  if (!canvasPoint.isPreciseV) {
    contextPosition.y = roundToFixed(contextPosition.y, fractionDigits);
  }
  return { contextPosition, fractionDigits };
};

export const constrainPositionToGrid = (position: Vec) => {
  const gridIncrement = globalState.gridIncrement();
  return position.roundToMultiple(gridIncrement);
};

export interface ConstrainCanvasPointOptions {
  enableGridSnapping?: boolean;
  enableGeometrySnapping?: boolean;
}
export const constrainCanvasPoint = (
  point: CanvasPoint,
  {
    enableGridSnapping = globalState.deviceStorage.gridSnappingEnabled,
    enableGeometrySnapping = globalState.deviceStorage.geometrySnappingEnabled,
  }: ConstrainCanvasPointOptions = {}
) => {
  if (enableGeometrySnapping && globalState.snapping.currentPoint) {
    point.copy(globalState.snapping.currentPoint);
  } else if (enableGridSnapping) {
    constrainPositionToGrid(point.worldPosition);

    // Grid points must be considered precise in the case when snapping a
    // precise (geometry snapped) point to a grid point, otherwise the snapped
    // point may not line up with the grid after transformation.
    point.isPreciseH = true;
    point.isPreciseV = true;
    point.isSpecific = false;
  }
};

export const pixelPositionFromEvent = (event: MouseEvent) => {
  const { canvasRectPixels } = globalState;
  return new Vec(event.clientX - canvasRectPixels.x, event.clientY - canvasRectPixels.y);
};

export const alignToPixelCenter = (v: Vec) => v.floor().addScalar(0.5);

export const worldPositionFromPixelPosition = (pixelPosition: Vec) => {
  const focusedComponent = globalState.project.focusedComponent();
  assert(focusedComponent, "A focused component is required to convert from pixel position");

  const viewport = globalState.viewportManager.viewportForComponent(focusedComponent);
  const inverseViewMatrix = viewport
    .viewMatrixWithCanvasDimensions(globalState.canvasDimensions)
    .invert();
  return pixelPosition.clone().affineTransform(inverseViewMatrix);
};

export const worldPositionFromEvent = (event: MouseEvent) => {
  const focusedComponent = globalState.project.focusedComponent();
  assert(focusedComponent, "A focused component is required to convert from pixel position");

  const viewport = globalState.viewportManager.viewportForComponent(focusedComponent);
  const inverseViewMatrix = viewport
    .viewMatrixWithCanvasDimensions(globalState.canvasDimensions)
    .invert();
  return pixelPositionFromEvent(event).affineTransform(inverseViewMatrix);
};

const defaultDirections = [
  new Vec(1, 0),
  new Vec(0, 1),
  new Vec(1, 1).normalize(),
  new Vec(1, -1).normalize(),
];

export const axisFromOriginAndClosestDirectionToPoint = (
  origin: Vec,
  point: Vec,
  possibleDirections = defaultDirections
) => {
  let direction = point.clone().sub(origin);
  let closestDir = possibleDirections[0];
  let closestMag = -1;
  let closestDot = 0;
  for (let dir of possibleDirections) {
    const dot = dir.dot(direction);
    const mag = Math.abs(dot);
    if (mag > closestMag) {
      closestDir = dir;
      closestMag = mag;
      closestDot = dot;
    }
  }
  direction.copy(closestDir).mulScalar(closestDot);
  return new Axis(origin, direction);
};

interface PickFileOptions {
  accept: string;
  multiple?: boolean;
}
export const pickFile = (options: PickFileOptions): Promise<FileList | void> => {
  return new Promise((resolve) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", options.accept);
    if (options.multiple) {
      input.setAttribute("multiple", "true");
    }
    input.style.position = "fixed";
    input.style.top = "-1080px";
    document.body.appendChild(input);

    input.addEventListener("change", (event: Event) => {
      const files = (event.target as HTMLInputElement).files;
      if (files) {
        resolve(files);
      } else {
        resolve();
      }
      cleanUp();
    });
    input.click();

    // Because <input type=file> does not fire a cancel event, we have to also
    // clean up with any future clicks
    const cleanUp = () => {
      input.parentElement?.removeChild(input);
      window.removeEventListener("click", resolveAndCleanUp);
    };
    const resolveAndCleanUp = () => {
      resolve();
      cleanUp();
    };
    // Need timeout, otherwise the click event that calls pickFile also cleans it up
    setTimeout(() => {
      window.addEventListener("click", resolveAndCleanUp);
    });
  });
};

export const devicePixelRatio = () => window.devicePixelRatio || 1;

/**
 * Initialize the TextMeasurer with a CSS font value (e.g. "bold 48px serif").
 * All measurements will be cached for performance.
 */
export class TextMeasurer {
  private dummyCanvas: HTMLCanvasElement;
  private dummyCtx: CanvasRenderingContext2D;
  private cache: Record<string, number> = {};
  constructor(font: string) {
    this.dummyCanvas = document.createElement("canvas");
    this.dummyCtx = this.dummyCanvas.getContext("2d") as CanvasRenderingContext2D;
    this.dummyCtx.font = font;
  }
  getWidth(text: string) {
    if (this.cache.hasOwnProperty(text)) {
      return this.cache[text];
    }
    const metrics = this.dummyCtx.measureText(text);
    const width = metrics.width;
    return (this.cache[text] = width);
  }
}

export const rightSidebarWidth = (windowWidth: number) => {
  return Math.min(
    globalState.deviceStorage.rightSidebarWidth,
    windowWidth - CANVAS_WIDTH_MIN - LEFT_PANEL_WIDTH
  );
};

export const readFileAsText = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(reader.result as string);
    };
    reader.onerror = (e) => {
      reject();
    };
    reader.readAsText(file);
  });
};

/** Either logged in with an account with admin privileges, or running with
 * local storage. Includes localhost and pull request staging links. */
const canShowAdminFeatures = () => {
  return accountState.featureFlags.hasAdminFeatures || globalState.storage.type === "local";
};

export const showAdminFeatures = () => {
  return (
    canShowAdminFeatures() && (globalState.isAltDown || globalState.deviceStorage.showAdminFeatures)
  );
};

export const toggleAdminProTesting = () => {
  if (canShowAdminFeatures()) {
    accountState.featureFlags.hasProFeatures = !accountState.featureFlags.hasProFeatures;
  }
};

export const toggleShowAdmin = () => {
  if (canShowAdminFeatures()) {
    globalState.deviceStorage.showAdminFeatures = !globalState.deviceStorage.showAdminFeatures;
  }
};
