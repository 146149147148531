import { Vec, almostGreaterThanOrEqual, almostLessThanOrEqual, assert } from "..";

/**
 * An axis-aligned rectangle. Usually used to represent the minimum and maximum
 * extent of some geometry on the `x` and `y` axes.
 */
export class BoundingBox {
  static displayName = "BoundingBox";

  /**
   * The minimum position contained within the bounding box.
   */
  min: Vec;
  /**
   * The maximum position contained within the bounding box.
   */
  max: Vec;

  /**
   * Constructs an axis-aligned bounding box.
   *
   * @param min
   * @param max
   */
  constructor(min = new Vec(), max = new Vec()) {
    this.min = min;
    this.max = max;
  }

  /**
   * @returns a copy of the bounding box.
   */
  clone() {
    return new BoundingBox(this.min.clone(), this.max.clone());
  }

  isValid() {
    return this.min.isValid() && this.max.isValid();
  }

  /**
   * @returns the center point of the bounding box (the average of `min` and
   * `max`).
   */
  center() {
    return this.min.clone().add(this.max).mulScalar(0.5);
  }

  /**
   * @returns a vector representing the size (`Vec(width, height)`) of the
   * bounding box.
   */
  size() {
    return this.max.clone().sub(this.min);
  }

  /**
   * @returns the width of the bounding box.
   */
  width() {
    return this.max.x - this.min.x;
  }

  /**
   * @returns the height of the bounding box.
   */
  height() {
    return this.max.y - this.min.y;
  }

  /**
   * @returns the signed area of the bounding box.
   *
   * This is equivalent to `box.width() * box.height()`. If `min` is greater
   * than `max`, the area will be negative.
   */
  area() {
    const { min, max } = this;
    return (max.x - min.x) * (max.y - min.y);
  }

  /**
   * @returns `true` if the `min` and `max` of this bounding box are finite real
   * numbers (not `NaN` or `Infinity`).
   */
  isFinite() {
    return this.min.isFinite() && this.max.isFinite();
  }

  /**
   * Ensures that the `min` is less than `max`, and `max` is greater than `min`.
   * This special case is considered to be the "canonical" configuration.
   */
  canonicalize() {
    const { x: x1, y: y1 } = this.min;
    const { x: x2, y: y2 } = this.max;
    this.min.set(Math.min(x1, x2), Math.min(y1, y2));
    this.max.set(Math.max(x1, x2), Math.max(y1, y2));
    return this;
  }

  /**
   * Grows this bounding box so that `point` is contained within it.
   *
   * @param point The point to contain
   */
  expandToIncludePoint(point: Vec) {
    this.min.min(point);
    this.max.max(point);
    return this;
  }

  /**
   * Grows this bounding box to that `box` is contained within it.
   *
   * @param box The bounding box to contain
   */
  expandToIncludeBoundingBox(box: BoundingBox) {
    return this.expandToIncludePoint(box.min).expandToIncludePoint(box.max);
  }

  /**
   * Grows this bounding box by a scalar `distance` on every side.
   *
   * @param distance The distance to expand by. Negative values with "shrink" this box.
   */
  expandScalar(distance: number) {
    this.min.subScalar(distance);
    this.max.addScalar(distance);
    return this;
  }

  /**
   * @returns `true` if `point` is contained within this bounding box.
   */
  containsPoint(point: Vec) {
    const { x, y } = point;
    return x >= this.min.x && x <= this.max.x && y >= this.min.y && y <= this.max.y;
  }

  /**
   * @returns `true` is `point` is contained within this bounding box. Uses a
   * fuzzy comparison to account for accumulated floating point errors.
   */
  _almostContainsPoint(point: Vec) {
    const { x, y } = point;
    return (
      almostGreaterThanOrEqual(x, this.min.x) &&
      almostLessThanOrEqual(x, this.max.x) &&
      almostGreaterThanOrEqual(y, this.min.y) &&
      almostLessThanOrEqual(y, this.max.y)
    );
  }

  /**
   * @returns `true` if `box` is fully contained within the bounding box. If
   * `box` is exactly equal, it counts as being fully contained.
   */
  containsBoundingBox(box: BoundingBox) {
    const { min, max } = box;
    return min.x >= this.min.x && max.x <= this.max.x && min.y >= this.min.y && max.y <= this.max.y;
  }

  /**
   * @returns `true` if any part of `box` overlaps the bounding box.
   */
  overlapsBoundingBox(box: BoundingBox) {
    const { min, max } = box;
    return max.x >= this.min.x && min.x <= this.max.x && max.y >= this.min.y && min.y <= this.max.y;
  }

  /**
   * Constructs the smallest bounding box that contains `points`.
   *
   * @param points An array of points
   */
  static fromPoints(points: Vec[]) {
    assert(points.length > 0, "A least one point is required to construct a bounding box");
    const box = new BoundingBox(points[0].clone(), points[0].clone());
    for (let i = 1, n = points.length; i < n; ++i) {
      box.expandToIncludePoint(points[i]);
    }
    return box;
  }

  /**
   * Constructs a bounding box by intersecting two or more bounding boxes. The
   * resulting bounding box will enclose the area shared by the input bounding
   * boxes. If the input bounding boxes do not all intersect, this will return
   * `undefined`.
   *
   * @param boxes An array of bounding boxes to intersect
   */
  static booleanIntersect(boxes: BoundingBox[]) {
    assert(
      boxes.length > 1,
      "At least two bounding boxes are required to perform a boolean intersection"
    );
    let xMin = -Infinity;
    let yMin = -Infinity;
    let xMax = Infinity;
    let yMax = Infinity;
    for (const box of boxes) {
      xMin = Math.max(xMin, box.min.x);
      xMax = Math.min(xMax, box.max.x);
      if (xMin > xMax) return undefined;
      yMin = Math.max(yMin, box.min.y);
      yMax = Math.min(yMax, box.max.y);
      if (yMin > yMax) return undefined;
    }
    return new BoundingBox(new Vec(xMin, yMin), new Vec(xMax, yMax));
  }

  static isValid(box: unknown): box is BoundingBox {
    return box instanceof BoundingBox && box.isValid();
  }
}
