import { Color, assert, isArray, isObject, isString } from "../geom";
import { Expression } from "./expression";
import { ModelObject } from "./model-object";
import { builtinNameForEncoding, classNameForEncoding } from "./registry";

/*
 * This file contains reducer functions that use ModelObject's reducer cache.
 * They're co-located in this file so we can keep track of possible naming
 * conflicts and avoid circular imports due to importing subclasses of
 * ModelObject.
 */

/**
 * @returns a function that saves its result in the ModelObject's reducer cache.
 * If a result is already in the cache, it will be returned. Otherwise
 * `reducerFunc` will be called and its result will be cached.
 *
 * Reducer functions have different strategies for merging values, so they are
 * expected to recurse into `childModelObjects` internally.
 */
const makeCachedReducer = <ValueType>(
  key: string,
  reducerFunc: (modelObject: ModelObject) => ValueType
) => {
  return (modelObject: ModelObject) => {
    if (modelObject._reducerCache !== undefined) {
      // Check if the value is already cached.
      if (modelObject._reducerCache.hasOwnProperty(key)) {
        const cached = modelObject._reducerCache[key] as ValueType;
        return cached;
      }
    } else {
      // Initialize the cache.
      modelObject._reducerCache = {};
    }

    // Compute and store the value.
    const result = reducerFunc(modelObject);
    modelObject._reducerCache[key] = result;
    return result;
  };
};

export const modelObjectUsesProFont = makeCachedReducer(
  "usesProFont",
  (modelObject: ModelObject): boolean => {
    if (modelObject instanceof Expression) {
      const value = modelObject.literalValue();
      return (
        isString(value) &&
        value.startsWith("https://assets.cuttle.xyz/fonts") &&
        value.endsWith(".json")
      );
    }
    return modelObject.childModelObjects().some(modelObjectUsesProFont);
  }
);

export const modelObjectColors = makeCachedReducer("allColors", (modelObject: ModelObject) => {
  const colors: Color[] = [];
  const hasColor = (color: Color) => colors.some((c) => c.equals(color));
  if (modelObject instanceof Expression) {
    const color = modelObject.literalValue();
    if (color instanceof Color) {
      if (!hasColor(color)) colors.push(color);
    }
  }
  const childColors = modelObject.childModelObjects().flatMap(modelObjectColors);
  for (const color of childColors) {
    if (!hasColor(color)) colors.push(color);
  }
  return colors;
});

/**
 * Internal implementation for `encodeMaterial`. This function should not be
 * called directly. It's co-located with the cache reducder code becuase they
 * are part of the same implementation.
 */
export const encodedForOriginal = (
  original: unknown,
  indirectlyReferenceModelObjects?: boolean,
  bypassReducerCache?: boolean
): unknown => {
  if (isObject(original)) {
    const builtin = builtinNameForEncoding(original);
    if (builtin) {
      return { "@builtin": builtin };
    }
  }

  if (original instanceof ModelObject) {
    if (indirectlyReferenceModelObjects) {
      return { "@ref": original.id };
    }
    if (bypassReducerCache) {
      return modelObjectEncoded(original);
    }
    return modelObjectEncodedReducer(original);
  }

  if (isArray(original)) {
    return original.map((e) => encodedForOriginal(e, indirectlyReferenceModelObjects));
  }

  if (isObject(original)) {
    const result: Record<string, unknown> = {};
    const className = classNameForEncoding(original);
    if (className) {
      result["@class"] = className;
    }
    for (const key of Object.keys(original)) {
      result[key] = encodedForOriginal(original[key], indirectlyReferenceModelObjects);
    }
    return result;
  }

  return original;
};

const modelObjectEncoded = (modelObject: ModelObject, bypassReducerCache?: boolean) => {
  const result: Record<string, unknown> = {};

  // Assign the ModelObject id as "@id".
  result["@id"] = modelObject.id;

  // Assign a class name so we know which class to create for this object
  // when decoding.
  const className = classNameForEncoding(modelObject);
  assert(className !== undefined, "ModelObject does not have a registered class!");
  result["@class"] = className;

  // If we're encoding a ModelObject, we need to get information about
  // circular references.
  const indirectKeys = modelObject.indirectKeys();

  for (const key of modelObject.materialKeys()) {
    const value: unknown = (modelObject as any)[key];
    const isIndirect = indirectKeys?.includes(key);
    result[key] = encodedForOriginal(value, isIndirect, bypassReducerCache);
  }

  return Object.freeze(result);
};
const modelObjectEncodedReducer = makeCachedReducer("encoded", modelObjectEncoded);
