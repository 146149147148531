export type LogLevel = "log" | "info" | "error" | "warn" | "geometry" | "guide";

export class LogMessage {
  constructor(
    public message: unknown,
    public logLevel: LogLevel,
    public line?: number // Number 1 refers to the first line.
  ) {}

  withLineNumber(line: number) {
    return new LogMessage(this.message, this.logLevel, line);
  }

  static fromArgs(logLevel: LogLevel, args: unknown[]) {
    let message: unknown = args;
    if (args.length === 1) {
      message = args[0];
    }
    return new LogMessage(message, logLevel);
  }
}

export type LogMessageSubscriber = (message: LogMessage) => void;

class LogManager {
  subscribers: Set<LogMessageSubscriber> = new Set();

  subscribe(subscriber: LogMessageSubscriber) {
    this.subscribers.add(subscriber);
  }
  unsubscribe(subscriber: LogMessageSubscriber) {
    this.subscribers.delete(subscriber);
  }

  console(logLevel: LogLevel, ...args: unknown[]) {
    const message = LogMessage.fromArgs(logLevel, args);
    if (this.subscribers.size === 0) {
      console.warn("Message logged, but no one was listening! 🍃", ...args);
    }
    for (const subscriber of this.subscribers) {
      subscriber(message);
    }
  }

  consoleLog(...args: unknown[]) {
    this.console("log", ...args);
  }

  consoleInfo(...args: unknown[]) {
    this.console("info", ...args);
  }
  consoleWarn(...args: unknown[]) {
    this.console("warn", ...args);
  }
  consoleError(...args: unknown[]) {
    this.console("error", ...args);
  }

  consoleGeometry(...args: unknown[]) {
    this.console("geometry", ...args);
  }
  consoleGuide(...args: unknown[]) {
    this.console("guide", ...args);
  }
}

// We're trying to avoid this singleton instantiation pattern, but in this case
// we promise that this file is very decoupled and won't have any dependencies.
export const logManager = new LogManager();
