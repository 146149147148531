
var PathKitInit = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(PathKitInit) {
  PathKitInit = PathKitInit || {};


null;var g;g||(g=typeof PathKitInit !== 'undefined' ? PathKitInit : {});var aa=Object.assign,ba,ca;g.ready=new Promise(function(a,b){ba=a;ca=b});
(function(a){var b={};a.loadCmdsTypedArray=function(h){for(var k=0,m=0;m<h.length;m++)k+=h[m].length;if(b[k])var n=b[k];else n=new Float32Array(k),b[k]=n;var p=0;for(m=0;m<h.length;m++)for(var r=0;r<h[m].length;r++){var t=h[m][r];"string"===typeof t&&(t=a.SkBits2FloatUnsigned(parseInt(t)));n[p]=t;p++}h=a._malloc(n.length*n.BYTES_PER_ELEMENT);a.HEAPF32.set(n,h/n.BYTES_PER_ELEMENT);return[h,k]};a.FromCmds=function(h){h=a.loadCmdsTypedArray(h);var k=a._FromCmds(h[0],h[1]);a._free(h[0]);return k};var c,
d,e,f,l;a.cubicYFromX=function(h,k,m,n,p){c&&d===h&&e===k&&f===m&&l===n||(c&&c.delete(),c=new a._SkCubicMap([h,k],[m,n]),d=h,e=k,f=m,l=n);return c.computeYFromX(p)};a.cubicPtFromT=function(h,k,m,n,p){c&&d===h&&e===k&&f===m&&l===n||(c&&c.delete(),c=new a._SkCubicMap([h,k],[m,n]),d=h,e=k,f=m,l=n);return c.computePtFromT(p)}})(g);
(function(a){a.onRuntimeInitialized=function(){a.SkPath.prototype.addPath=function(){var b=arguments[0];if(1===arguments.length)this._addPath(b,1,0,0,0,1,0,0,0,1);else if(2===arguments.length){var c=arguments[1];this._addPath(b,c.a,c.c,c.e,c.b,c.d,c.f,0,0,1)}else if(7===arguments.length)c=arguments,this._addPath(b,c[1],c[3],c[5],c[2],c[4],c[6],0,0,1);else if(10===arguments.length)c=arguments,this._addPath(b,c[1],c[2],c[3],c[4],c[5],c[6],c[7],c[8],c[9]);else return console.La("addPath expected to take 1, 2, 7, or 10 args. Got "+
arguments.length),null;return this};a.SkPath.prototype.arc=function(b,c,d,e,f,l){this._arc(b,c,d,e,f,!!l);return this};a.SkPath.prototype.arcTo=function(b,c,d,e,f){this._arcTo(b,c,d,e,f);return this};a.SkPath.prototype.bezierCurveTo=function(b,c,d,e,f,l){this._cubicTo(b,c,d,e,f,l);return this};a.SkPath.prototype.close=function(){this._close();return this};a.SkPath.prototype.closePath=function(){this._close();return this};a.SkPath.prototype.conicTo=function(b,c,d,e,f){this._conicTo(b,c,d,e,f);return this};
a.SkPath.prototype.cubicTo=function(b,c,d,e,f,l){this._cubicTo(b,c,d,e,f,l);return this};a.SkPath.prototype.dash=function(b,c,d){return this._dash(b,c,d)?this:null};a.SkPath.prototype.ellipse=function(b,c,d,e,f,l,h,k){this._ellipse(b,c,d,e,f,l,h,!!k);return this};a.SkPath.prototype.lineTo=function(b,c){this._lineTo(b,c);return this};a.SkPath.prototype.moveTo=function(b,c){this._moveTo(b,c);return this};a.SkPath.prototype.op=function(b,c){return this._op(b,c)?this:null};a.SkPath.prototype.quadraticCurveTo=
function(b,c,d,e){this._quadTo(b,c,d,e);return this};a.SkPath.prototype.quadTo=function(b,c,d,e){this._quadTo(b,c,d,e);return this};a.SkPath.prototype.rect=function(b,c,d,e){this._rect(b,c,d,e);return this};a.SkPath.prototype.simplify=function(){return this._simplify()?this:null};a.SkPath.prototype.stroke=function(b){b=b||{};b.width=b.width||1;b.miter_limit=b.miter_limit||4;b.cap=b.cap||a.StrokeCap.BUTT;b.join=b.join||a.StrokeJoin.MITER;return this._stroke(b)?this:null};a.SkPath.prototype.transform=
function(){if(1===arguments.length)this._transform(arguments[0]);else if(9===arguments.length){var b=arguments;this._transform(b[0],b[1],b[2],b[3],b[4],b[5],b[6],b[7],b[8])}else return console.La("transform expected to take 1 or 9 arguments. Got "+arguments.length),null;return this};a.SkPath.prototype.trim=function(b,c,d){return this._trim(b,c,!!d)?this:null}}})(g);var da=aa({},g),ea="object"===typeof window,q="function"===typeof importScripts,v="",fa,ha,ia,fs,ja,ka;
if("object"===typeof process&&"object"===typeof process.versions&&"string"===typeof process.versions.node)v=q?require("path").dirname(v)+"/":__dirname+"/",ka=()=>{ja||(fs=require("fs"),ja=require("path"))},fa=function(a,b){ka();a=ja.normalize(a);return fs.readFileSync(a,b?null:"utf8")},ia=a=>{a=fa(a,!0);a.buffer||(a=new Uint8Array(a));return a},ha=(a,b,c)=>{ka();a=ja.normalize(a);fs.readFile(a,function(d,e){d?c(d):b(e.buffer)})},1<process.argv.length&&process.argv[1].replace(/\\/g,"/"),process.argv.slice(2),
process.on("uncaughtException",function(a){throw a;}),process.on("unhandledRejection",function(a){throw a;}),g.inspect=function(){return"[Emscripten Module object]"};else if(ea||q)q?v=self.location.href:"undefined"!==typeof document&&document.currentScript&&(v=document.currentScript.src),_scriptDir&&(v=_scriptDir),0!==v.indexOf("blob:")?v=v.substr(0,v.replace(/[?#].*/,"").lastIndexOf("/")+1):v="",fa=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},q&&(ia=a=>{var b=
new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),ha=(a,b,c)=>{var d=new XMLHttpRequest;d.open("GET",a,!0);d.responseType="arraybuffer";d.onload=()=>{200==d.status||0==d.status&&d.response?b(d.response):c()};d.onerror=c;d.send(null)};var la=g.print||console.log.bind(console),w=g.printErr||console.warn.bind(console);aa(g,da);da=null;var x;g.wasmBinary&&(x=g.wasmBinary);var noExitRuntime=g.noExitRuntime||!0;
"object"!==typeof WebAssembly&&z("no native wasm support detected");var ma,na=!1,oa="undefined"!==typeof TextDecoder?new TextDecoder("utf8"):void 0;
function pa(a,b,c){var d=b+c;for(c=b;a[c]&&!(c>=d);)++c;if(16<c-b&&a.subarray&&oa)return oa.decode(a.subarray(b,c));for(d="";b<c;){var e=a[b++];if(e&128){var f=a[b++]&63;if(192==(e&224))d+=String.fromCharCode((e&31)<<6|f);else{var l=a[b++]&63;e=224==(e&240)?(e&15)<<12|f<<6|l:(e&7)<<18|f<<12|l<<6|a[b++]&63;65536>e?d+=String.fromCharCode(e):(e-=65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023))}}else d+=String.fromCharCode(e)}return d}
function qa(a,b,c){var d=A;if(0<c){c=b+c-1;for(var e=0;e<a.length;++e){var f=a.charCodeAt(e);if(55296<=f&&57343>=f){var l=a.charCodeAt(++e);f=65536+((f&1023)<<10)|l&1023}if(127>=f){if(b>=c)break;d[b++]=f}else{if(2047>=f){if(b+1>=c)break;d[b++]=192|f>>6}else{if(65535>=f){if(b+2>=c)break;d[b++]=224|f>>12}else{if(b+3>=c)break;d[b++]=240|f>>18;d[b++]=128|f>>12&63}d[b++]=128|f>>6&63}d[b++]=128|f&63}}d[b]=0}}var ra="undefined"!==typeof TextDecoder?new TextDecoder("utf-16le"):void 0;
function sa(a,b){var c=a>>1;for(var d=c+b/2;!(c>=d)&&B[c];)++c;c<<=1;if(32<c-a&&ra)return ra.decode(A.subarray(a,c));c="";for(d=0;!(d>=b/2);++d){var e=C[a+2*d>>1];if(0==e)break;c+=String.fromCharCode(e)}return c}function ta(a,b,c){void 0===c&&(c=2147483647);if(2>c)return 0;c-=2;var d=b;c=c<2*a.length?c/2:a.length;for(var e=0;e<c;++e)C[b>>1]=a.charCodeAt(e),b+=2;C[b>>1]=0;return b-d}function ua(a){return 2*a.length}
function va(a,b){for(var c=0,d="";!(c>=b/4);){var e=E[a+4*c>>2];if(0==e)break;++c;65536<=e?(e-=65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023)):d+=String.fromCharCode(e)}return d}function wa(a,b,c){void 0===c&&(c=2147483647);if(4>c)return 0;var d=b;c=d+c-4;for(var e=0;e<a.length;++e){var f=a.charCodeAt(e);if(55296<=f&&57343>=f){var l=a.charCodeAt(++e);f=65536+((f&1023)<<10)|l&1023}E[b>>2]=f;b+=4;if(b+4>c)break}E[b>>2]=0;return b-d}
function xa(a){for(var b=0,c=0;c<a.length;++c){var d=a.charCodeAt(c);55296<=d&&57343>=d&&++c;b+=4}return b}var ya,za,A,C,B,E,F,Aa,Ba;function Ca(){var a=ma.buffer;ya=a;g.HEAP8=za=new Int8Array(a);g.HEAP16=C=new Int16Array(a);g.HEAP32=E=new Int32Array(a);g.HEAPU8=A=new Uint8Array(a);g.HEAPU16=B=new Uint16Array(a);g.HEAPU32=F=new Uint32Array(a);g.HEAPF32=Aa=new Float32Array(a);g.HEAPF64=Ba=new Float64Array(a)}var G,Da=[],Ea=[],Fa=[];function Ga(){var a=g.preRun.shift();Da.unshift(a)}
var H=0,Ha=null,I=null;g.preloadedImages={};g.preloadedAudios={};function z(a){if(g.onAbort)g.onAbort(a);a="Aborted("+a+")";w(a);na=!0;a=new WebAssembly.RuntimeError(a+". Build with -s ASSERTIONS=1 for more info.");ca(a);throw a;}function Ia(){return J.startsWith("data:application/octet-stream;base64,")}var J;J="editor/pathkit.wasm";if(!Ia()){var Ja=J;J=g.locateFile?g.locateFile(Ja,v):v+Ja}
function Ka(){var a=J;try{if(a==J&&x)return new Uint8Array(x);if(ia)return ia(a);throw"both async and sync fetching of the wasm failed";}catch(b){z(b)}}
function La(){if(!x&&(ea||q)){if("function"===typeof fetch&&!J.startsWith("file://"))return fetch(J,{credentials:"same-origin"}).then(function(a){if(!a.ok)throw"failed to load wasm binary file at '"+J+"'";return a.arrayBuffer()}).catch(function(){return Ka()});if(ha)return new Promise(function(a,b){ha(J,function(c){a(new Uint8Array(c))},b)})}return Promise.resolve().then(function(){return Ka()})}
function Ma(a){for(;0<a.length;){var b=a.shift();if("function"==typeof b)b(g);else{var c=b.Va;"number"===typeof c?void 0===b.xa?G.get(c)():G.get(c)(b.xa):c(void 0===b.xa?null:b.xa)}}}var Na={};function Oa(a){for(;a.length;){var b=a.pop();a.pop()(b)}}function K(a){return this.fromWireType(F[a>>2])}var M={},N={},Pa={};function Qa(a){if(void 0===a)return"_unknown";a=a.replace(/[^a-zA-Z0-9_]/g,"$");var b=a.charCodeAt(0);return 48<=b&&57>=b?"_"+a:a}
function Ra(a,b){a=Qa(a);return(new Function("body","return function "+a+'() {\n    "use strict";    return body.apply(this, arguments);\n};\n'))(b)}function Sa(a){var b=Error,c=Ra(a,function(d){this.name=a;this.message=d;d=Error(d).stack;void 0!==d&&(this.stack=this.toString()+"\n"+d.replace(/^Error(:[^\n]*)?\n/,""))});c.prototype=Object.create(b.prototype);c.prototype.constructor=c;c.prototype.toString=function(){return void 0===this.message?this.name:this.name+": "+this.message};return c}
var Ta=void 0;function Ua(a){throw new Ta(a);}function O(a,b,c){function d(h){h=c(h);h.length!==a.length&&Ua("Mismatched type converter count");for(var k=0;k<a.length;++k)Q(a[k],h[k])}a.forEach(function(h){Pa[h]=b});var e=Array(b.length),f=[],l=0;b.forEach(function(h,k){N.hasOwnProperty(h)?e[k]=N[h]:(f.push(h),M.hasOwnProperty(h)||(M[h]=[]),M[h].push(function(){e[k]=N[h];++l;l===f.length&&d(e)}))});0===f.length&&d(e)}var Va={};
function Wa(a){switch(a){case 1:return 0;case 2:return 1;case 4:return 2;case 8:return 3;default:throw new TypeError("Unknown type size: "+a);}}var Xa=void 0;function R(a){for(var b="";A[a];)b+=Xa[A[a++]];return b}var S=void 0;function T(a){throw new S(a);}
function Q(a,b,c={}){if(!("argPackAdvance"in b))throw new TypeError("registerType registeredInstance requires argPackAdvance");var d=b.name;a||T('type "'+d+'" must have a positive integer typeid pointer');if(N.hasOwnProperty(a)){if(c.Pa)return;T("Cannot register type '"+d+"' twice")}N[a]=b;delete Pa[a];M.hasOwnProperty(a)&&(b=M[a],delete M[a],b.forEach(function(e){e()}))}function Ya(a){T(a.ea.ha.fa.name+" instance already deleted")}var Za=!1;function $a(){}
function ab(a){--a.count.value;0===a.count.value&&(a.ka?a.ma.la(a.ka):a.ha.fa.la(a.ga))}function bb(a){if("undefined"===typeof FinalizationGroup)return bb=b=>b,a;Za=new FinalizationGroup(function(b){for(var c=b.next();!c.done;c=b.next())c=c.value,c.ga?ab(c):console.warn("object already deleted: "+c.ga)});bb=b=>{Za.register(b,b.ea,b.ea);return b};$a=b=>{Za.unregister(b.ea)};return bb(a)}var cb=void 0,db=[];function eb(){for(;db.length;){var a=db.pop();a.ea.pa=!1;a["delete"]()}}function U(){}
var fb={};function gb(a,b,c){if(void 0===a[b].ia){var d=a[b];a[b]=function(){a[b].ia.hasOwnProperty(arguments.length)||T("Function '"+c+"' called with an invalid number of arguments ("+arguments.length+") - expects one of ("+a[b].ia+")!");return a[b].ia[arguments.length].apply(this,arguments)};a[b].ia=[];a[b].ia[d.ua]=d}}
function hb(a,b,c){g.hasOwnProperty(a)?((void 0===c||void 0!==g[a].ia&&void 0!==g[a].ia[c])&&T("Cannot register public name '"+a+"' twice"),gb(g,a,a),g.hasOwnProperty(c)&&T("Cannot register multiple overloads of a function with the same number of arguments ("+c+")!"),g[a].ia[c]=b):(g[a]=b,void 0!==c&&(g[a].Wa=c))}function ib(a,b,c,d,e,f,l,h){this.name=a;this.constructor=b;this.qa=c;this.la=d;this.na=e;this.Na=f;this.ta=l;this.Ka=h;this.Sa=[]}
function jb(a,b,c){for(;b!==c;)b.ta||T("Expected null or instance of "+c.name+", got an instance of "+b.name),a=b.ta(a),b=b.na;return a}function kb(a,b){if(null===b)return this.Ba&&T("null is not a valid "+this.name),0;b.ea||T('Cannot pass "'+lb(b)+'" as a '+this.name);b.ea.ga||T("Cannot pass deleted object as a pointer of type "+this.name);return jb(b.ea.ga,b.ea.ha.fa,this.fa)}
function mb(a,b){if(null===b){this.Ba&&T("null is not a valid "+this.name);if(this.wa){var c=this.sa();null!==a&&a.push(this.la,c);return c}return 0}b.ea||T('Cannot pass "'+lb(b)+'" as a '+this.name);b.ea.ga||T("Cannot pass deleted object as a pointer of type "+this.name);!this.va&&b.ea.ha.va&&T("Cannot convert argument of type "+(b.ea.ma?b.ea.ma.name:b.ea.ha.name)+" to parameter type "+this.name);c=jb(b.ea.ga,b.ea.ha.fa,this.fa);if(this.wa)switch(void 0===b.ea.ka&&T("Passing raw pointer to smart pointer is illegal"),
this.Ua){case 0:b.ea.ma===this?c=b.ea.ka:T("Cannot convert argument of type "+(b.ea.ma?b.ea.ma.name:b.ea.ha.name)+" to parameter type "+this.name);break;case 1:c=b.ea.ka;break;case 2:if(b.ea.ma===this)c=b.ea.ka;else{var d=b.clone();c=this.Ta(c,V(function(){d["delete"]()}));null!==a&&a.push(this.la,c)}break;default:T("Unsupporting sharing policy")}return c}
function nb(a,b){if(null===b)return this.Ba&&T("null is not a valid "+this.name),0;b.ea||T('Cannot pass "'+lb(b)+'" as a '+this.name);b.ea.ga||T("Cannot pass deleted object as a pointer of type "+this.name);b.ea.ha.va&&T("Cannot convert argument of type "+b.ea.ha.name+" to parameter type "+this.name);return jb(b.ea.ga,b.ea.ha.fa,this.fa)}function ob(a,b,c){if(b===c)return a;if(void 0===c.na)return null;a=ob(a,b,c.na);return null===a?null:c.Ka(a)}var pb={};
function qb(a,b){for(void 0===b&&T("ptr should not be undefined");a.na;)b=a.ta(b),a=a.na;return pb[b]}function rb(a,b){b.ha&&b.ga||Ua("makeClassHandle requires ptr and ptrType");!!b.ma!==!!b.ka&&Ua("Both smartPtrType and smartPtr must be specified");b.count={value:1};return bb(Object.create(a,{ea:{value:b}}))}
function W(a,b,c,d){this.name=a;this.fa=b;this.Ba=c;this.va=d;this.wa=!1;this.la=this.Ta=this.sa=this.Ia=this.Ua=this.Ra=void 0;void 0!==b.na?this.toWireType=mb:(this.toWireType=d?kb:nb,this.ja=null)}function sb(a,b,c){g.hasOwnProperty(a)||Ua("Replacing nonexistant public symbol");void 0!==g[a].ia&&void 0!==c?g[a].ia[c]=b:(g[a]=b,g[a].ua=c)}
function tb(a,b){var c=[];return function(){c.length=arguments.length;for(var d=0;d<arguments.length;d++)c[d]=arguments[d];a.includes("j")?(d=g["dynCall_"+a],d=c&&c.length?d.apply(null,[b].concat(c)):d.call(null,b)):d=G.get(b).apply(null,c);return d}}function X(a,b){a=R(a);var c=a.includes("j")?tb(a,b):G.get(b);"function"!==typeof c&&T("unknown function pointer with signature "+a+": "+b);return c}var ub=void 0;function vb(a){a=wb(a);var b=R(a);Y(a);return b}
function xb(a,b){function c(f){e[f]||N[f]||(Pa[f]?Pa[f].forEach(c):(d.push(f),e[f]=!0))}var d=[],e={};b.forEach(c);throw new ub(a+": "+d.map(vb).join([", "]));}function yb(a,b){for(var c=[],d=0;d<a;d++)c.push(E[(b>>2)+d]);return c}
function Bb(a){var b=Function;if(!(b instanceof Function))throw new TypeError("new_ called with constructor type "+typeof b+" which is not a function");var c=Ra(b.name||"unknownFunctionName",function(){});c.prototype=b.prototype;c=new c;a=b.apply(c,a);return a instanceof Object?a:c}
function Cb(a,b,c,d,e){var f=b.length;2>f&&T("argTypes array size mismatch! Must at least get return value and 'this' types!");var l=null!==b[1]&&null!==c,h=!1;for(c=1;c<b.length;++c)if(null!==b[c]&&void 0===b[c].ja){h=!0;break}var k="void"!==b[0].name,m="",n="";for(c=0;c<f-2;++c)m+=(0!==c?", ":"")+"arg"+c,n+=(0!==c?", ":"")+"arg"+c+"Wired";a="return function "+Qa(a)+"("+m+") {\nif (arguments.length !== "+(f-2)+") {\nthrowBindingError('function "+a+" called with ' + arguments.length + ' arguments, expected "+
(f-2)+" args!');\n}\n";h&&(a+="var destructors = [];\n");var p=h?"destructors":"null";m="throwBindingError invoker fn runDestructors retType classParam".split(" ");d=[T,d,e,Oa,b[0],b[1]];l&&(a+="var thisWired = classParam.toWireType("+p+", this);\n");for(c=0;c<f-2;++c)a+="var arg"+c+"Wired = argType"+c+".toWireType("+p+", arg"+c+"); // "+b[c+2].name+"\n",m.push("argType"+c),d.push(b[c+2]);l&&(n="thisWired"+(0<n.length?", ":"")+n);a+=(k?"var rv = ":"")+"invoker(fn"+(0<n.length?", ":"")+n+");\n";if(h)a+=
"runDestructors(destructors);\n";else for(c=l?1:2;c<b.length;++c)f=1===c?"thisWired":"arg"+(c-2)+"Wired",null!==b[c].ja&&(a+=f+"_dtor("+f+"); // "+b[c].name+"\n",m.push(f+"_dtor"),d.push(b[c].ja));k&&(a+="var ret = retType.fromWireType(rv);\nreturn ret;\n");m.push(a+"}\n");return Bb(m).apply(null,d)}var Db=[],Z=[{},{value:void 0},{value:null},{value:!0},{value:!1}];function Eb(a){4<a&&0===--Z[a].Ca&&(Z[a]=void 0,Db.push(a))}
function Fb(a){a||T("Cannot use deleted val. handle = "+a);return Z[a].value}function V(a){switch(a){case void 0:return 1;case null:return 2;case !0:return 3;case !1:return 4;default:var b=Db.length?Db.pop():Z.length;Z[b]={Ca:1,value:a};return b}}
function Gb(a,b,c){switch(b){case 0:return function(d){return this.fromWireType((c?za:A)[d])};case 1:return function(d){return this.fromWireType((c?C:B)[d>>1])};case 2:return function(d){return this.fromWireType((c?E:F)[d>>2])};default:throw new TypeError("Unknown integer type: "+a);}}function Hb(a,b){var c=N[a];void 0===c&&T(b+" has unknown type "+vb(a));return c}function lb(a){if(null===a)return"null";var b=typeof a;return"object"===b||"array"===b||"function"===b?a.toString():""+a}
function Ib(a,b){switch(b){case 2:return function(c){return this.fromWireType(Aa[c>>2])};case 3:return function(c){return this.fromWireType(Ba[c>>3])};default:throw new TypeError("Unknown float type: "+a);}}
function Jb(a,b,c){switch(b){case 0:return c?function(d){return za[d]}:function(d){return A[d]};case 1:return c?function(d){return C[d>>1]}:function(d){return B[d>>1]};case 2:return c?function(d){return E[d>>2]}:function(d){return F[d>>2]};default:throw new TypeError("Unknown integer type: "+a);}}var Kb={};function Lb(a){var b=Kb[a];return void 0===b?R(a):b}var Mb=[];function Nb(){return"object"===typeof globalThis?globalThis:Function("return this")()}
function Ob(a){var b=Mb.length;Mb.push(a);return b}function Pb(a,b){for(var c=Array(a),d=0;d<a;++d)c[d]=Hb(E[(b>>2)+d],"parameter "+d);return c}var Qb=[],Rb={},Sb=[null,[],[]];Ta=g.InternalError=Sa("InternalError");for(var Tb=Array(256),Ub=0;256>Ub;++Ub)Tb[Ub]=String.fromCharCode(Ub);Xa=Tb;S=g.BindingError=Sa("BindingError");
U.prototype.isAliasOf=function(a){if(!(this instanceof U&&a instanceof U))return!1;var b=this.ea.ha.fa,c=this.ea.ga,d=a.ea.ha.fa;for(a=a.ea.ga;b.na;)c=b.ta(c),b=b.na;for(;d.na;)a=d.ta(a),d=d.na;return b===d&&c===a};
U.prototype.clone=function(){this.ea.ga||Ya(this);if(this.ea.ra)return this.ea.count.value+=1,this;var a=bb,b=Object,c=b.create,d=Object.getPrototypeOf(this),e=this.ea;a=a(c.call(b,d,{ea:{value:{count:e.count,pa:e.pa,ra:e.ra,ga:e.ga,ha:e.ha,ka:e.ka,ma:e.ma}}}));a.ea.count.value+=1;a.ea.pa=!1;return a};U.prototype["delete"]=function(){this.ea.ga||Ya(this);this.ea.pa&&!this.ea.ra&&T("Object already scheduled for deletion");$a(this);ab(this.ea);this.ea.ra||(this.ea.ka=void 0,this.ea.ga=void 0)};
U.prototype.isDeleted=function(){return!this.ea.ga};U.prototype.deleteLater=function(){this.ea.ga||Ya(this);this.ea.pa&&!this.ea.ra&&T("Object already scheduled for deletion");db.push(this);1===db.length&&cb&&cb(eb);this.ea.pa=!0;return this};W.prototype.Oa=function(a){this.Ia&&(a=this.Ia(a));return a};W.prototype.Ga=function(a){this.la&&this.la(a)};W.prototype.argPackAdvance=8;W.prototype.readValueFromPointer=K;W.prototype.deleteObject=function(a){if(null!==a)a["delete"]()};
W.prototype.fromWireType=function(a){function b(){return this.wa?rb(this.fa.qa,{ha:this.Ra,ga:c,ma:this,ka:a}):rb(this.fa.qa,{ha:this,ga:a})}var c=this.Oa(a);if(!c)return this.Ga(a),null;var d=qb(this.fa,c);if(void 0!==d){if(0===d.ea.count.value)return d.ea.ga=c,d.ea.ka=a,d.clone();d=d.clone();this.Ga(a);return d}d=this.fa.Na(c);d=fb[d];if(!d)return b.call(this);d=this.va?d.Ja:d.pointerType;var e=ob(c,this.fa,d.fa);return null===e?b.call(this):this.wa?rb(d.fa.qa,{ha:d,ga:e,ma:this,ka:a}):rb(d.fa.qa,
{ha:d,ga:e})};g.getInheritedInstanceCount=function(){return Object.keys(pb).length};g.getLiveInheritedInstances=function(){var a=[],b;for(b in pb)pb.hasOwnProperty(b)&&a.push(pb[b]);return a};g.flushPendingDeletes=eb;g.setDelayFunction=function(a){cb=a;db.length&&cb&&cb(eb)};ub=g.UnboundTypeError=Sa("UnboundTypeError");g.count_emval_handles=function(){for(var a=0,b=5;b<Z.length;++b)void 0!==Z[b]&&++a;return a};g.get_first_emval=function(){for(var a=5;a<Z.length;++a)if(void 0!==Z[a])return Z[a];return null};
var Wb={r:function(a){var b=Na[a];delete Na[a];var c=b.elements,d=c.length,e=c.map(function(h){return h.Aa}).concat(c.map(function(h){return h.Ea})),f=b.sa,l=b.la;O([a],e,function(h){c.forEach(function(k,m){var n=h[m],p=k.ya,r=k.za,t=h[m+d],u=k.Da,L=k.Fa;k.read=y=>n.fromWireType(p(r,y));k.write=(y,P)=>{var D=[];u(L,y,t.toWireType(D,P));Oa(D)}});return[{name:b.name,fromWireType:function(k){for(var m=Array(d),n=0;n<d;++n)m[n]=c[n].read(k);l(k);return m},toWireType:function(k,m){if(d!==m.length)throw new TypeError("Incorrect number of tuple elements for "+
b.name+": expected="+d+", actual="+m.length);for(var n=f(),p=0;p<d;++p)c[p].write(n,m[p]);null!==k&&k.push(l,n);return n},argPackAdvance:8,readValueFromPointer:K,ja:l}]})},u:function(a){var b=Va[a];delete Va[a];var c=b.sa,d=b.la,e=b.Ha,f=e.map(function(l){return l.Aa}).concat(e.map(function(l){return l.Ea}));O([a],f,function(l){var h={};e.forEach(function(k,m){var n=l[m],p=k.ya,r=k.za,t=l[m+e.length],u=k.Da,L=k.Fa;h[k.Ma]={read:function(y){return n.fromWireType(p(r,y))},write:function(y,P){var D=
[];u(L,y,t.toWireType(D,P));Oa(D)}}});return[{name:b.name,fromWireType:function(k){var m={},n;for(n in h)m[n]=h[n].read(k);d(k);return m},toWireType:function(k,m){for(var n in h)if(!(n in m))throw new TypeError('Missing field:  "'+n+'"');var p=c();for(n in h)h[n].write(p,m[n]);null!==k&&k.push(d,p);return p},argPackAdvance:8,readValueFromPointer:K,ja:d}]})},z:function(){},F:function(a,b,c,d,e){var f=Wa(c);b=R(b);Q(a,{name:b,fromWireType:function(l){return!!l},toWireType:function(l,h){return h?d:e},
argPackAdvance:8,readValueFromPointer:function(l){if(1===c)var h=za;else if(2===c)h=C;else if(4===c)h=E;else throw new TypeError("Unknown boolean type size: "+b);return this.fromWireType(h[l>>f])},ja:null})},l:function(a,b,c,d,e,f,l,h,k,m,n,p,r){n=R(n);f=X(e,f);h&&(h=X(l,h));m&&(m=X(k,m));r=X(p,r);var t=Qa(n);hb(t,function(){xb("Cannot construct "+n+" due to unbound types",[d])});O([a,b,c],d?[d]:[],function(u){u=u[0];if(d){var L=u.fa;var y=L.qa}else y=U.prototype;u=Ra(t,function(){if(Object.getPrototypeOf(this)!==
P)throw new S("Use 'new' to construct "+n);if(void 0===D.oa)throw new S(n+" has no accessible constructor");var zb=D.oa[arguments.length];if(void 0===zb)throw new S("Tried to invoke ctor of "+n+" with invalid number of parameters ("+arguments.length+") - expected ("+Object.keys(D.oa).toString()+") parameters instead!");return zb.apply(this,arguments)});var P=Object.create(y,{constructor:{value:u}});u.prototype=P;var D=new ib(n,u,P,r,L,f,h,m);L=new W(n,D,!0,!1);y=new W(n+"*",D,!1,!1);var Ab=new W(n+
" const*",D,!1,!0);fb[a]={pointerType:y,Ja:Ab};sb(t,u);return[L,y,Ab]})},i:function(a,b,c,d,e,f){0<b||z(void 0);var l=yb(b,c);e=X(d,e);O([],[a],function(h){h=h[0];var k="constructor "+h.name;void 0===h.fa.oa&&(h.fa.oa=[]);if(void 0!==h.fa.oa[b-1])throw new S("Cannot register multiple constructors with identical number of parameters ("+(b-1)+") for class '"+h.name+"'! Overload resolution is currently only performed using the parameter count, not actual type info!");h.fa.oa[b-1]=()=>{xb("Cannot construct "+
h.name+" due to unbound types",l)};O([],l,function(m){m.splice(1,0,null);h.fa.oa[b-1]=Cb(k,m,null,e,f);return[]});return[]})},a:function(a,b,c,d,e,f,l,h){var k=yb(c,d);b=R(b);f=X(e,f);O([],[a],function(m){function n(){xb("Cannot call "+p+" due to unbound types",k)}m=m[0];var p=m.name+"."+b;b.startsWith("@@")&&(b=Symbol[b.substring(2)]);h&&m.fa.Sa.push(b);var r=m.fa.qa,t=r[b];void 0===t||void 0===t.ia&&t.className!==m.name&&t.ua===c-2?(n.ua=c-2,n.className=m.name,r[b]=n):(gb(r,b,p),r[b].ia[c-2]=n);
O([],k,function(u){u=Cb(p,u,m,f,l);void 0===r[b].ia?(u.ua=c-2,r[b]=u):r[b].ia[c-2]=u;return[]});return[]})},I:function(a,b,c){a=R(a);O([],[b],function(d){d=d[0];g[a]=d.fromWireType(c);return[]})},E:function(a,b){b=R(b);Q(a,{name:b,fromWireType:function(c){var d=Fb(c);Eb(c);return d},toWireType:function(c,d){return V(d)},argPackAdvance:8,readValueFromPointer:K,ja:null})},h:function(a,b,c,d){function e(){}c=Wa(c);b=R(b);e.values={};Q(a,{name:b,constructor:e,fromWireType:function(f){return this.constructor.values[f]},
toWireType:function(f,l){return l.value},argPackAdvance:8,readValueFromPointer:Gb(b,c,d),ja:null});hb(b,e)},k:function(a,b,c){var d=Hb(a,"enum");b=R(b);a=d.constructor;d=Object.create(d.constructor.prototype,{value:{value:c},constructor:{value:Ra(d.name+"_"+b,function(){})}});a.values[c]=d;a[b]=d},q:function(a,b,c){c=Wa(c);b=R(b);Q(a,{name:b,fromWireType:function(d){return d},toWireType:function(d,e){return e},argPackAdvance:8,readValueFromPointer:Ib(b,c),ja:null})},f:function(a,b,c,d,e,f){var l=
yb(b,c);a=R(a);e=X(d,e);hb(a,function(){xb("Cannot call "+a+" due to unbound types",l)},b-1);O([],l,function(h){h=[h[0],null].concat(h.slice(1));sb(a,Cb(a,h,null,e,f),b-1);return[]})},e:function(a,b,c,d,e){b=R(b);-1===e&&(e=4294967295);e=Wa(c);var f=h=>h;if(0===d){var l=32-8*c;f=h=>h<<l>>>l}c=b.includes("unsigned")?function(h,k){return k>>>0}:function(h,k){return k};Q(a,{name:b,fromWireType:f,toWireType:c,argPackAdvance:8,readValueFromPointer:Jb(b,e,0!==d),ja:null})},b:function(a,b,c){function d(f){f>>=
2;var l=F;return new e(ya,l[f+1],l[f])}var e=[Int8Array,Uint8Array,Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array][b];c=R(c);Q(a,{name:c,fromWireType:d,argPackAdvance:8,readValueFromPointer:d},{Pa:!0})},p:function(a,b){b=R(b);var c="std::string"===b;Q(a,{name:b,fromWireType:function(d){var e=F[d>>2];if(c)for(var f=d+4,l=0;l<=e;++l){var h=d+4+l;if(l==e||0==A[h]){f=f?pa(A,f,h-f):"";if(void 0===k)var k=f;else k+=String.fromCharCode(0),k+=f;f=h+1}}else{k=Array(e);for(l=0;l<e;++l)k[l]=
String.fromCharCode(A[d+4+l]);k=k.join("")}Y(d);return k},toWireType:function(d,e){e instanceof ArrayBuffer&&(e=new Uint8Array(e));var f="string"===typeof e;f||e instanceof Uint8Array||e instanceof Uint8ClampedArray||e instanceof Int8Array||T("Cannot pass non-string to std::string");var l=(c&&f?()=>{for(var m=0,n=0;n<e.length;++n){var p=e.charCodeAt(n);55296<=p&&57343>=p&&(p=65536+((p&1023)<<10)|e.charCodeAt(++n)&1023);127>=p?++m:m=2047>=p?m+2:65535>=p?m+3:m+4}return m}:()=>e.length)(),h=Vb(4+l+1);
F[h>>2]=l;if(c&&f)qa(e,h+4,l+1);else if(f)for(f=0;f<l;++f){var k=e.charCodeAt(f);255<k&&(Y(h),T("String has UTF-16 code units that do not fit in 8 bits"));A[h+4+f]=k}else for(f=0;f<l;++f)A[h+4+f]=e[f];null!==d&&d.push(Y,h);return h},argPackAdvance:8,readValueFromPointer:K,ja:function(d){Y(d)}})},m:function(a,b,c){c=R(c);if(2===b){var d=sa;var e=ta;var f=ua;var l=()=>B;var h=1}else 4===b&&(d=va,e=wa,f=xa,l=()=>F,h=2);Q(a,{name:c,fromWireType:function(k){for(var m=F[k>>2],n=l(),p,r=k+4,t=0;t<=m;++t){var u=
k+4+t*b;if(t==m||0==n[u>>h])r=d(r,u-r),void 0===p?p=r:(p+=String.fromCharCode(0),p+=r),r=u+b}Y(k);return p},toWireType:function(k,m){"string"!==typeof m&&T("Cannot pass non-string to C++ string type "+c);var n=f(m),p=Vb(4+n+b);F[p>>2]=n>>h;e(m,p+4,n+b);null!==k&&k.push(Y,p);return p},argPackAdvance:8,readValueFromPointer:K,ja:function(k){Y(k)}})},t:function(a,b,c,d,e,f){Na[a]={name:R(b),sa:X(c,d),la:X(e,f),elements:[]}},s:function(a,b,c,d,e,f,l,h,k){Na[a].elements.push({Aa:b,ya:X(c,d),za:e,Ea:f,Da:X(l,
h),Fa:k})},v:function(a,b,c,d,e,f){Va[a]={name:R(b),sa:X(c,d),la:X(e,f),Ha:[]}},j:function(a,b,c,d,e,f,l,h,k,m){Va[a].Ha.push({Ma:R(b),Aa:c,ya:X(d,e),za:f,Ea:l,Da:X(h,k),Fa:m})},G:function(a,b){b=R(b);Q(a,{Qa:!0,name:b,argPackAdvance:0,fromWireType:function(){},toWireType:function(){}})},d:function(a,b,c,d){a=Mb[a];b=Fb(b);c=Lb(c);a(b,c,null,d)},J:Eb,x:function(a){if(0===a)return V(Nb());a=Lb(a);return V(Nb()[a])},c:function(a,b){var c=Pb(a,b),d=c[0];b=d.name+"_$"+c.slice(1).map(function(n){return n.name}).join("_")+
"$";var e=Qb[b];if(void 0!==e)return e;e=["retType"];for(var f=[d],l="",h=0;h<a-1;++h)l+=(0!==h?", ":"")+"arg"+h,e.push("argType"+h),f.push(c[1+h]);var k="return function "+Qa("methodCaller_"+b)+"(handle, name, destructors, args) {\n",m=0;for(h=0;h<a-1;++h)k+="    var arg"+h+" = argType"+h+".readValueFromPointer(args"+(m?"+"+m:"")+");\n",m+=c[h+1].argPackAdvance;k+="    var rv = handle[name]("+l+");\n";for(h=0;h<a-1;++h)c[h+1].deleteObject&&(k+="    argType"+h+".deleteObject(arg"+h+");\n");d.Qa||
(k+="    return retType.toWireType(destructors, rv);\n");e.push(k+"};\n");a=Bb(e).apply(null,f);e=Ob(a);return Qb[b]=e},n:function(a){4<a&&(Z[a].Ca+=1)},w:function(a,b,c,d){a=Fb(a);var e=Rb[b];if(!e){e="";for(var f=0;f<b;++f)e+=(0!==f?", ":"")+"arg"+f;var l="return function emval_allocator_"+b+"(constructor, argTypes, args) {\n";for(f=0;f<b;++f)l+="var argType"+f+" = requireRegisteredType(Module['HEAP32'][(argTypes >>> 2) + "+f+'], "parameter '+f+'");\nvar arg'+f+" = argType"+f+".readValueFromPointer(args);\nargs += argType"+
f+"['argPackAdvance'];\n";e=(new Function("requireRegisteredType","Module","valueToHandle",l+("var obj = new constructor("+e+");\nreturn valueToHandle(obj);\n}\n")))(Hb,g,V);Rb[b]=e}return e(a,c,d)},K:function(){return V([])},D:function(a){return V(Lb(a))},H:function(a,b){a=Hb(a,"_emval_take_value");a=a.readValueFromPointer(b);return V(a)},g:function(){z("")},B:function(a){var b=A.length;a>>>=0;if(2147483648<a)return!1;for(var c=1;4>=c;c*=2){var d=b*(1+.2/c);d=Math.min(d,a+100663296);d=Math.max(a,
d);0<d%65536&&(d+=65536-d%65536);a:{try{ma.grow(Math.min(2147483648,d)-ya.byteLength+65535>>>16);Ca();var e=1;break a}catch(f){}e=void 0}if(e)return!0}return!1},C:function(){return 0},y:function(){},o:function(a,b,c,d){for(var e=0,f=0;f<c;f++){var l=E[b>>2],h=E[b+4>>2];b+=8;for(var k=0;k<h;k++){var m=A[l+k],n=Sb[a];0===m||10===m?((1===a?la:w)(pa(n,0)),n.length=0):n.push(m)}e+=h}E[d>>2]=e;return 0},A:function(){}};
(function(){function a(e){g.asm=e.exports;ma=g.asm.L;Ca();G=g.asm._;Ea.unshift(g.asm.M);H--;g.monitorRunDependencies&&g.monitorRunDependencies(H);0==H&&(null!==Ha&&(clearInterval(Ha),Ha=null),I&&(e=I,I=null,e()))}function b(e){a(e.instance)}function c(e){return La().then(function(f){return WebAssembly.instantiate(f,d)}).then(function(f){return f}).then(e,function(f){w("failed to asynchronously prepare wasm: "+f);z(f)})}var d={a:Wb};H++;g.monitorRunDependencies&&g.monitorRunDependencies(H);if(g.instantiateWasm)try{return g.instantiateWasm(d,
a)}catch(e){return w("Module.instantiateWasm callback failed with error: "+e),!1}(function(){return x||"function"!==typeof WebAssembly.instantiateStreaming||Ia()||J.startsWith("file://")||"function"!==typeof fetch?c(b):fetch(J,{credentials:"same-origin"}).then(function(e){return WebAssembly.instantiateStreaming(e,d).then(b,function(f){w("wasm streaming compile failed: "+f);w("falling back to ArrayBuffer instantiation");return c(b)})})})().catch(ca);return{}})();
g.___wasm_call_ctors=function(){return(g.___wasm_call_ctors=g.asm.M).apply(null,arguments)};g.__Z6ToCmdsRK6SkPath=function(){return(g.__Z6ToCmdsRK6SkPath=g.asm.N).apply(null,arguments)};g.__Z8FromCmdsmi=function(){return(g.__Z8FromCmdsmi=g.asm.O).apply(null,arguments)};g.__Z7NewPathv=function(){return(g.__Z7NewPathv=g.asm.P).apply(null,arguments)};g.__Z8CopyPathRK6SkPath=function(){return(g.__Z8CopyPathRK6SkPath=g.asm.Q).apply(null,arguments)};
g.__Z6EqualsRK6SkPathS1_=function(){return(g.__Z6EqualsRK6SkPathS1_=g.asm.R).apply(null,arguments)};g.__Z11ToSVGStringRK6SkPath=function(){return(g.__Z11ToSVGStringRK6SkPath=g.asm.S).apply(null,arguments)};g.__Z13FromSVGStringNSt3__212basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEEE=function(){return(g.__Z13FromSVGStringNSt3__212basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEEE=g.asm.T).apply(null,arguments)};
g.__Z13ApplySimplifyR6SkPath=function(){return(g.__Z13ApplySimplifyR6SkPath=g.asm.U).apply(null,arguments)};g.__Z11ApplyPathOpR6SkPathRKS_8SkPathOp=function(){return(g.__Z11ApplyPathOpR6SkPathRKS_8SkPathOp=g.asm.V).apply(null,arguments)};g.__Z10MakeFromOpRK6SkPathS1_8SkPathOp=function(){return(g.__Z10MakeFromOpRK6SkPathS1_8SkPathOp=g.asm.W).apply(null,arguments)};g.__Z14ResolveBuilderR11SkOpBuilder=function(){return(g.__Z14ResolveBuilderR11SkOpBuilder=g.asm.X).apply(null,arguments)};
g.__Z8ToCanvasRK6SkPathN10emscripten3valE=function(){return(g.__Z8ToCanvasRK6SkPathN10emscripten3valE=g.asm.Y).apply(null,arguments)};g.__Z8ToPath2DRK6SkPath=function(){return(g.__Z8ToPath2DRK6SkPath=g.asm.Z).apply(null,arguments)};var Y=g._free=function(){return(Y=g._free=g.asm.$).apply(null,arguments)},Vb=g._malloc=function(){return(Vb=g._malloc=g.asm.aa).apply(null,arguments)},wb=g.___getTypeName=function(){return(wb=g.___getTypeName=g.asm.ba).apply(null,arguments)};
g.___embind_register_native_and_builtin_types=function(){return(g.___embind_register_native_and_builtin_types=g.asm.ca).apply(null,arguments)};g.dynCall_jiji=function(){return(g.dynCall_jiji=g.asm.da).apply(null,arguments)};var Xb;I=function Yb(){Xb||Zb();Xb||(I=Yb)};
function Zb(){function a(){if(!Xb&&(Xb=!0,g.calledRun=!0,!na)){Ma(Ea);ba(g);if(g.onRuntimeInitialized)g.onRuntimeInitialized();if(g.postRun)for("function"==typeof g.postRun&&(g.postRun=[g.postRun]);g.postRun.length;){var b=g.postRun.shift();Fa.unshift(b)}Ma(Fa)}}if(!(0<H)){if(g.preRun)for("function"==typeof g.preRun&&(g.preRun=[g.preRun]);g.preRun.length;)Ga();Ma(Da);0<H||(g.setStatus?(g.setStatus("Running..."),setTimeout(function(){setTimeout(function(){g.setStatus("")},1);a()},1)):a())}}g.run=Zb;
if(g.preInit)for("function"==typeof g.preInit&&(g.preInit=[g.preInit]);0<g.preInit.length;)g.preInit.pop()();Zb();


  return PathKitInit.ready
}
);
})();
export default PathKitInit;
