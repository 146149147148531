import {
  AffineMatrix,
  DEGREES_PER_RADIAN,
  RADIANS_PER_DEGREE,
  TransformArgs,
  almostEquals,
} from "..";

/**
 * A two-dimensional vector.
 *
 * Vectors can be used to represent positions, normals, tangents, offsets and
 * translations.
 */
export class Vec {
  static displayName = "Vec";

  /** The `x` component */
  x: number;
  /** The `y` component */
  y: number;

  /**
   * Constructs a vector from two numbers.
   *
   * ```
   * Vec(3, 5)
   * // Result: Vec(3, 5)
   * ```
   *
   * @param x The `x` component
   * @param y The `y` component
   */
  constructor(x?: number, y?: number) {
    this.x = x || 0;
    this.y = y === undefined ? this.x : y;
  }

  /**
   * @returns a copy of this vector.
   */
  clone() {
    return new Vec(this.x, this.y);
  }

  isValid() {
    return typeof this.x === "number" && typeof this.y === "number" && this.isFinite();
  }

  /**
   * Sets the the `x` and `y` components of this vector.
   * @param x
   * @param y
   * @chainable
   */
  set(x: number, y: number) {
    this.x = x;
    this.y = y;
    return this;
  }

  /**
   * Copies `x` and `y` components into this vector from `v`.
   * @param v
   * @chainable
   */
  copy(v: Vec) {
    this.x = v.x;
    this.y = v.y;
    return this;
  }

  /**
   * Transforms this vector by the affine matrix `affineMatrix`.
   *
   * Use this when transforming a `Vec` that represents a point or position.
   *
   * @param affineMatrix An affine matrix representing a transformation
   * @chainable
   */
  affineTransform(affineMatrix: AffineMatrix) {
    const { x, y } = this;
    const { a, b, c, d, tx, ty } = affineMatrix;
    this.x = a * x + c * y + tx;
    this.y = b * x + d * y + ty;
    return this;
  }
  /**
   * Transforms this vector by the affine matrix `affineMatrix`, ignoring
   * translation.
   *
   * Use this instead of `affineTransform()` when transforming a `Vec` that
   * represents a normal or a tangent.
   *
   * @param affineMatrix An affine matrix representing a transformation
   * @chainable
   */
  affineTransformWithoutTranslation(affineMatrix: AffineMatrix) {
    const { x, y } = this;
    const { a, b, c, d } = affineMatrix;
    this.x = a * x + c * y;
    this.y = b * x + d * y;
    return this;
  }

  /**
   * Transforms this vector.
   *
   * ```
   * vec.transform({
   *   position: Vec(1, 2),
   *   rotation: 45,
   *   scale: Vec(1, 0.5), // Scale can also be a number
   *   skew: 10,
   *   origin: Vec(0, 0), // The center of rotation and scale
   * })
   * ```
   *
   * @param transform An object reprenting a transform.
   * @chainable
   */
  transform(transform: TransformArgs) {
    return this.affineTransform(AffineMatrix.fromTransform(transform));
  }

  /**
   * Adds the vector `v` to this vector.
   * @param v
   * @chainable
   */
  add(v: Vec) {
    this.x += v.x;
    this.y += v.y;
    return this;
  }

  /**
   * Adds the scalar `x` to both components of this vector.
   * @param x
   * @chainable
   */
  addScalar(x: number) {
    this.x += x;
    this.y += x;
    return this;
  }

  /**
   * Subtracts the vector `v` from this vector.
   * @param v
   * @chainable
   */
  sub(v: Vec) {
    this.x -= v.x;
    this.y -= v.y;
    return this;
  }

  /**
   * Subtracts the scalar `x` from both components of this vector.
   * @param x
   * @chainable
   */
  subScalar(x: number) {
    this.x -= x;
    this.y -= x;
    return this;
  }
  /**
   * Multiplies this vector by the vector `v`.
   * @param v
   * @chainable
   */
  mul(v: Vec) {
    this.x *= v.x;
    this.y *= v.y;
    return this;
  }

  /**
   * Multiplies both components of this vector by the scalar `x`.
   * @chainable
   */
  mulScalar(x: number) {
    this.x *= x;
    this.y *= x;
    return this;
  }

  /**
   * Divides this vector by the vector `v`.
   * @chainable
   */
  div(v: Vec) {
    this.x /= v.x;
    this.y /= v.y;
    return this;
  }

  /**
   * Divides both components of this vector by the scalar `x`;
   * @chainable
   */
  divScalar(x: number) {
    this.x /= x;
    this.y /= x;
    return this;
  }

  /**
   * Multiplies both components of this vector by -1. This is functionally the
   * same as `.mulScalar(-1)`.
   * @chainable
   */
  negate() {
    this.x *= -1;
    this.y *= -1;
    return this;
  }

  /**
   * Tests if this vector _exactly_ equals the vector `v`.
   *
   * @returns `true` if the vectors are _exactly_ equal, `false` otherwise.
   */
  equals(v: Vec) {
    return this.x === v.x && this.y === v.y;
  }

  _almostEquals(v: Vec, tolerance?: number) {
    return almostEquals(this.x, v.x, tolerance) && almostEquals(this.y, v.y, tolerance);
  }

  /**
   * Rounds the components of this vector to the next-lowest integer.
   * ```
   * let v = Vec(3.141, -1.618);
   * v.floor();
   * // Result: Vec(3, -2)
   * ```
   * @chainable
   */
  floor() {
    this.x = Math.floor(this.x);
    this.y = Math.floor(this.y);
    return this;
  }

  /**
   * Rounds the components of this vector to the next-highest integer.
   * ```
   * let v = Vec(3.141, -1.618);
   * v.ceil();
   * // Result: Vec(4, -1)
   * ```
   * @chainable
   */
  ceil() {
    this.x = Math.ceil(this.x);
    this.y = Math.ceil(this.y);
    return this;
  }

  /**
   * Rounds the components of this vector to the closest integer.
   * ```
   * let v = Vec(3.141, 3.618);
   * v.round();
   * // Result: Vec(3, 4)
   * ```
   * @chainable
   */
  round() {
    this.x = Math.round(this.x);
    this.y = Math.round(this.y);
    return this;
  }

  roundToFixed(fractionDigits: number) {
    const scale = Math.pow(10, fractionDigits);
    const oneOverScale = 1 / scale;
    this.x = Math.round(this.x * scale) * oneOverScale;
    this.y = Math.round(this.y * scale) * oneOverScale;
    return this;
  }

  roundToMultiple(factor: number) {
    if (factor === 0) return this;
    const oneOverFactor = 1 / factor;
    this.x = Math.round(this.x * oneOverFactor) * factor;
    this.y = Math.round(this.y * oneOverFactor) * factor;
    return this;
  }

  /**
   * Compares the components of this vector and `v` and sets this vector's to
   * the lesser of the two.
   *
   * ```
   * let a = Vec(1, 4);
   * let b = Vec(2, 3);
   * a.min(b);
   * // Result: Vec(1, 3)
   * ```
   * @param v The vector to compare against
   * @chainable
   */
  min(v: Vec) {
    this.x = Math.min(this.x, v.x);
    this.y = Math.min(this.y, v.y);
    return this;
  }
  /**
   * Compares the components of this vector and `v` and sets this vector's to
   * the greater of the two.
   *
   * ```
   * let a = Vec(1, 4);
   * let b = Vec(2, 3);
   * a.max(b);
   * // Result: Vec(2, 4)
   * ```
   * @param v The vector to compare against
   * @chainable
   */
  max(v: Vec) {
    this.x = Math.max(this.x, v.x);
    this.y = Math.max(this.y, v.y);
    return this;
  }

  /**
   * Linearly interpolates this vector to the vector `v` by the mixing factor
   * `t`.
   *
   * @param v The vector to interpolate to
   * @param t The mixing factor
   * @chainable
   */
  mix(v: Vec, t: number) {
    this.x += (v.x - this.x) * t;
    this.y += (v.y - this.y) * t;
    return this;
  }

  /**
   * @returns the dot product between this vector and the vector `v`.
   * @param v
   */
  dot(v: Vec) {
    return this.x * v.x + this.y * v.y;
  }

  /**
   * @returns the cross product between this vector and the vector `v`.
   * @param v
   */
  cross(v: Vec) {
    return this.x * v.y - this.y * v.x;
  }

  /**
   * Scales this vector so that its length is equal to `1`.
   *
   * Note the this vector must already have a non-zero length.
   *
   * @chainable
   */
  normalize() {
    const lengthSq = this.lengthSquared();
    if (lengthSq > 0) {
      this.mulScalar(1 / Math.sqrt(lengthSq));
    }
    return this;
  }

  /**
   * Rotates this vector clockwise by an `angle` specified in degrees.
   *
   * @param angle An angle in degrees
   * @chainable
   */
  rotate(angle: number) {
    return this.rotateRadians(angle * RADIANS_PER_DEGREE);
  }
  /**
   * Rotates this vector clockwise by an `angle` specified in radians.
   *
   * @param angle An angle in radians
   * @chainable
   */
  rotateRadians(radians: number) {
    const ct = Math.cos(radians);
    const st = Math.sin(radians);
    const { x, y } = this;
    this.x = x * ct - y * st;
    this.y = x * st + y * ct;
    return this;
  }
  /**
   * Rotates this vector clockwise by exactly 90°.
   *
   * This is functionally the same as writing `vec.rotate(90)` but is
   * computationally simpler.
   *
   * @chainable
   */
  rotate90() {
    const { x, y } = this;
    this.x = -y;
    this.y = x;
    return this;
  }
  /**
   * Rotates this vector counter-clockwise by exactly 90°.
   *
   * This is functionally the same as writing `vec.rotate(-90)` but is
   * computationally simpler.
   *
   * @chainable
   */
  rotateNeg90() {
    const { x, y } = this;
    this.x = y;
    this.y = -x;
    return this;
  }

  /**
   * The vector projection of this vector onto a non-zero vector `v`, (also
   * known as the vector component or vector resolution of a in the direction of
   * b), is the orthogonal projection of this onto a straight line parallel to
   * `v`.
   *
   * @param v A vector representing a line to project onto
   * @chainable
   */
  projectOnto(v: Vec) {
    const vLengthSquared = v.lengthSquared();
    if (vLengthSquared > 0) {
      const m = this.dot(v) / vLengthSquared;
      this.x = v.x * m;
      this.y = v.y * m;
    }
    return this;
  }

  /**
   * A trivial closest point implementation to maintain a uniform interface with
   * Geometry.
   *
   * @returns a ClosestPointResult with itself as the position.
   */
  closestPoint(v: Vec) {
    return { position: this };
  }

  /**
   * @returns the angle of this vector in degrees.
   */
  angle() {
    return this.angleRadians() * DEGREES_PER_RADIAN;
  }
  /**
   * @returns the angle of this vector in radians.
   */
  angleRadians() {
    return Math.atan2(this.y, this.x);
  }

  /**
   * @returns `true` if this vector lies in the 180° region clockwise from `v`.
   * @param v The vector to compare against
   */
  isClockwiseFrom(v: Vec) {
    // Rotate 90 degrees
    const x = -v.y;
    const y = v.x;

    // Check for a positive dot product
    const d = this.x * x + this.y * y;
    return d > 0;
  }

  /**
   * @returns the length of this vector. The length of a vector is also
   * sometimes referred to as its "magnitude".
   */
  length() {
    const { x, y } = this;
    return Math.sqrt(x * x + y * y);
  }
  /**
   * @returns the squared length of this vector.
   *
   * This variation avoids the `sqrt()` used in `length()` and can be
   * computationally simpler when comparing the length of two vectors.
   */
  lengthSquared() {
    const { x, y } = this;
    return x * x + y * y;
  }

  /**
   * @returns the distance from this vector to `v`.
   */
  distance(v: Vec) {
    const dx = v.x - this.x;
    const dy = v.y - this.y;
    return Math.sqrt(dx * dx + dy * dy);
  }
  /**
   * @returns the squared distance from this vector to `v`.
   *
   * This variation avoids the `sqrt()` used in `distance()` and can be
   * computationally simpler when comparing distances.
   */
  distanceSquared(v: Vec) {
    const dx = v.x - this.x;
    const dy = v.y - this.y;
    return dx * dx + dy * dy;
  }

  /**
   * @returns `true` if both components of this vector are 0, `false` otherwise.
   */
  isZero() {
    return this.x === 0 && this.y === 0;
  }

  /**
   * @returns `true` if both components of this vector are finite real numbers,
   * meaning not `NaN` or `Infinity`.
   */
  isFinite() {
    return Number.isFinite(this.x) && Number.isFinite(this.y);
  }

  static add(a: Vec, b: Vec) {
    return a.clone().add(b);
  }
  static sub(a: Vec, b: Vec) {
    return a.clone().sub(b);
  }
  static mul(a: Vec, b: Vec) {
    return a.clone().mul(b);
  }
  static div(a: Vec, b: Vec) {
    return a.clone().div(b);
  }

  static min(a: Vec, b: Vec) {
    return a.clone().min(b);
  }
  static max(a: Vec, b: Vec) {
    return a.clone().max(b);
  }

  static mix(a: Vec, b: Vec, t: number) {
    return a.clone().mix(b, t);
  }

  static dot(a: Vec, b: Vec) {
    return a.dot(b);
  }

  /**
   * Constructs a unit-length vector from an `angle`.
   *
   * @param angle An angle specified in degrees
   */
  static fromAngle(angle: number) {
    return Vec.fromAngleRadians(angle * RADIANS_PER_DEGREE);
  }

  /**
   * Constructs a unit-length vector from an `angle`.
   *
   * @param angle An angle specified in radians
   */
  static fromAngleRadians(angle: number) {
    return new Vec(Math.cos(angle), Math.sin(angle));
  }

  static isValid(v: unknown): v is Vec {
    return v instanceof Vec && v.isValid();
  }
}
