import { isArray, isObject } from "../geom";
import { uuid } from "../shared/uuid-v4";

export type ModelObjectSubscriber = (
  event: "get" | "set",
  object: ModelObject,
  key: string,
  value: unknown
) => void;

/**
 * All our "source code" classes inherit from ModelObject.
 *
 * ---
 *
 * 2024-05-06: Note About our recent change to persistent model object IDs.
 *
 * We serialize the project to JSON for several reasons: edit history,
 * copy-paste, and saving projects. This can be slow to rebuild for large
 * projects.
 *
 * The material part of a Cuttle project is almost a tree, except for
 * Instance.definition which can be a circular reference. Most of the model does
 * not change on every action, so it would be nice to cache unchanged branches
 * of the tree. We do this by making sure that:
 *
 *   1. Instance.definition is always encoded as a reference.
 *   2. ModelObject IDs are universally unique and don't change between
 *      encodings.
 *   3. Immaterial project properties like selection and focus only encode
 *      ModelObjects as references.
 *
 * We give all ModelObjects a persistent `id`, and introduce the optional
 * `indirectKeys` method, which lets us force certain properties (like
 * Instance.definition) to be encoded as a reference.
 */
export abstract class ModelObject {
  _parentModelObject?: ModelObject;
  _reducerCache?: Record<string, unknown>;

  readonly id = uuid();

  /**
   * All keys that make a material difference to the output of the project. This
   * shouldn't include view-only things like the viewport, selection or focus.
   */
  abstract materialKeys(): string[];

  /**
   * All keys pointing to ModelObjects that should be referenced indirectly.
   * These will be replaced with `{ "@ref": this[key].id }`.
   */
  indirectKeys(): string[] {
    return [];
  }

  constructor() {
    // Instrument change tracking. This getter / setter strategy is also used by
    // Vue: https://github.com/vuejs/vue/blob/dev/src/core/observer/index.js
    //
    // WARNING: This will break with JS's new public class fields since they use
    // Object.defineProperty instead of assignment semantics. This means that
    // properties will override the getters/setters.
    for (const key of this.materialKeys()) {
      let value: unknown = undefined;
      Object.defineProperty(this, key, {
        configurable: true,
        enumerable: true,
        get() {
          ModelObject.triggerEvent("get", this, key, value);
          return value;
        },
        set(newValue: unknown) {
          // Proxy-wrap arrays and "plain" key-value objects. Note this wrapping
          // only goes one level deep because our model only ever uses one-level
          // arrays (e.g. element.children) and objects (e.g. instance.args). If
          // we add richer properties to our model we should make this
          // proxy-wrapping recursively go deep.
          if (isArray(newValue) || isPlainObject(newValue)) {
            newValue = makeNotifierProxy(newValue, () => {
              ModelObject.triggerEvent("set", this, key, newValue);
            });
          }

          ModelObject.triggerEvent("set", this, key, newValue);
          value = newValue;
        },
      });
    }
  }

  childModelObjects() {
    const indirectKeys = this.indirectKeys();
    const result: ModelObject[] = [];
    for (const key of this.materialKeys()) {
      // Skip indirect keys since these would result in an infinite recursion.
      if (indirectKeys.includes(key)) continue;

      const value = (this as any)[key] as unknown;
      if (value instanceof ModelObject) {
        result.push(value);
      } else if (isArray(value)) {
        for (const item of value) {
          if (item instanceof ModelObject) {
            result.push(item);
          }
        }
      } else if (isPlainObject(value)) {
        for (const key in value) {
          const item = value[key];
          if (item instanceof ModelObject) {
            result.push(item);
          }
        }
      }
    }
    return result;
  }

  resetReducerCache() {
    this._reducerCache = undefined;
    this._parentModelObject?.resetReducerCache();
  }

  // Static methods for tracking gets and sets.

  private static subscribers: Set<ModelObjectSubscriber> = new Set();
  static subscribe(subscriber: ModelObjectSubscriber) {
    this.subscribers.add(subscriber);
  }
  static unsubscribe(subscriber: ModelObjectSubscriber) {
    this.subscribers.delete(subscriber);
  }

  static triggerEvent(event: "get" | "set", object: ModelObject, key: string, value: unknown) {
    this.subscribers.forEach((subscriber) => subscriber(event, object, key, value));
  }
}

ModelObject.subscribe((event: "get" | "set", object: ModelObject, key: string, value: unknown) => {
  if (event !== "set") return;

  // Clear the cache.
  object.resetReducerCache();

  // Assign ourself as the parent of any ModelObjects that are set as children.
  const childKeys = object.materialKeys();
  const indirectKeys = object.indirectKeys();
  if (childKeys.includes(key) && !indirectKeys.includes(key)) {
    if (value instanceof ModelObject) {
      value._parentModelObject = object;
    } else if (isArray(value)) {
      for (const item of value) {
        if (item instanceof ModelObject) {
          item._parentModelObject = object;
        }
      }
    } else if (isPlainObject(value)) {
      for (const key in value) {
        const prop = value[key];
        if (prop instanceof ModelObject) {
          prop._parentModelObject = object;
        }
      }
    }
  }
});

// ============================================================================
// Utility
// ============================================================================

const plainObjectPrototype = Object.getPrototypeOf({});
const isPlainObject = (obj: unknown): obj is Record<string, unknown> => {
  return isObject(obj) && Object.getPrototypeOf(obj) === plainObjectPrototype;
};

/**
 * Given an object or array, this will return a Proxy around it. The Proxy will
 * call `onChange()` whenever it is mutated.
 */
const makeNotifierProxy = (o: any, onChange: () => void) => {
  return new Proxy(o, {
    set(obj, prop, value) {
      obj[prop] = value;
      onChange();
      return true; // Indicate success
    },
    deleteProperty(obj, prop) {
      delete obj[prop];
      onChange();
      return true;
    },
  });
};
