import { AffineMatrix, almostEquals, assert, Vec } from "../geom";
import { angularDistance } from "../geom/math/scalar-math";
import { globalState } from "../global-state";
import { isNumber } from "../shared/util";
import { constrainAnchorHandles } from "./anchor-constraint";
import { AnchorHandleConstraint, TransformDefinition } from "./builtin-primitives";
import { Expression } from "./expression";
import {
  expressionCodeForNumber,
  expressionCodeForValue,
  expressionCodeForVec,
} from "./expression-code";
import { Instance } from "./instance";
import { Node } from "./node";
import { PIAngle, PIDistance, PIImageTransform, PIVector } from "./parameter-interface";
import { SelectableComponentParameter, SelectableNode, SelectableParameter } from "./selectable";
import { Selection } from "./selection";
import {
  contextMatrixForInstance,
  contextMatrixForNode,
  handleOriginForParameter,
  transformMatrixForNode,
  transformOriginForNode,
} from "./transform-utils";

/**
 * Transformer is a tool designed to simplfiy the act of transforming a
 * selection one or more times.
 */
export interface Transformer {
  /**
   * Transforms the Selectable in world space
   * @param worldTransformMatrix The matrix to transform by, in world coordinate
   * space
   * @param options Specific options that only apply to some scenarios
   */
  transformWorld(worldTransformMatrix: AffineMatrix, options?: TransformerOptions): void;

  /**
   * Transforms the Selectable in its context space
   * @param transformMatrix The matrix to transform by, in the local coordinate
   * space
   * @param options Specific options that only apply to some scenarios
   */
  transformContext(transformMatrix: AffineMatrix, options?: TransformerOptions): void;

  /**
   * A transformer will save the transform of the Selectable to be transformed
   * on construction. Calling this function will revert the selection to this
   * initial transform.
   */
  revert(): void;
}

export interface TransformerOptions {
  /**
   * Preserve the existing rotation when applying the transform?
   */
  preserveRotation?: boolean;

  /**
   * Preserve the existing scale when applying the transform.
   */
  preserveScale?: boolean;

  /**
   * Prevents `transform` parameters of Fills from being transformed.
   */
  preserveImageTransforms?: boolean;

  overrideAnchorHandleConstraint?: AnchorHandleConstraint;
  assignAnchorHandleConstraint?: AnchorHandleConstraint;
}

export class SelectionTransformer implements Transformer {
  transformers: Transformer[] = [];

  constructor(selection: Selection) {
    const transformableSelection = selection.allTransformable();
    for (let item of transformableSelection.items) {
      if (item instanceof SelectableNode) {
        this.transformers.push(new NodeTransformer(item.node));
      } else if (item instanceof SelectableParameter) {
        let overrideAnchorConstraint: AnchorHandleConstraint | undefined;
        if (item.node.isAnchor()) {
          // If the opposing handle is selected we need to break the handle
          // constraint by default. Preserving it would overwrite the movement
          // of the opposing handle.
          const opposingHandleName = item.parameter.name === "handleIn" ? "handleOut" : "handleIn";
          const opposingHandleSelectable = SelectableParameter.fromName(
            item.node,
            item.instance,
            opposingHandleName
          );
          if (transformableSelection.contains(opposingHandleSelectable)) {
            overrideAnchorConstraint = "free";
          }
        }
        this.transformers.push(new ParameterTransformer(item, overrideAnchorConstraint));
      } else if (item instanceof SelectableComponentParameter) {
        this.transformers.push(new ComponentParameterTransformer(item));
      }
    }
  }

  transformWorld(worldTransformMatrix: AffineMatrix, options?: TransformerOptions) {
    for (let transformer of this.transformers) {
      transformer.transformWorld(worldTransformMatrix, options);
    }
  }

  transformContext(transformMatrix: AffineMatrix, options?: TransformerOptions) {
    for (let transformer of this.transformers) {
      transformer.transformContext(transformMatrix, options);
    }
  }

  revert() {
    for (let transformer of this.transformers) {
      transformer.revert();
    }
  }
}

export class NodeTransformer implements Transformer {
  readonly node: Node;
  readonly parameterTransformers?: ParameterTransformer[];

  readonly initialTransform?: Instance;
  readonly initialTransformMatrix: AffineMatrix;
  readonly initialContextMatrix: AffineMatrix;
  readonly initialRotation: number;

  readonly isPassThrough: boolean;

  transformOrigin: Vec;

  constructor(node: Node) {
    this.node = node;
    this.parameterTransformers = [];

    this.isPassThrough = node.source.isPassThrough();
    if (this.isPassThrough) {
      // Add transformers for all base parameters (ParameterTransformer will
      // sort out if the parameter is actually transformable).
      for (let parameter of node.source.base.definition.parameters) {
        const selectable = new SelectableParameter(node, node.source.base, parameter);
        const transformer = new ParameterTransformer(selectable, "free");
        this.parameterTransformers.push(transformer);
      }
    } else {
      this.initialTransform = node.source.transform?.clone();
    }

    // If we have a fill, add a transformer for the `transform` parameter,
    // which can be transformed if it's an AffineMatrix.
    if (node.source.fill) {
      const selectable = SelectableParameter.fromName(node, node.source.fill, "transform");
      const transformer = new ParameterTransformer(selectable);
      this.parameterTransformers.push(transformer);
    }

    this.initialTransformMatrix = transformMatrixForNode(node);
    this.initialContextMatrix = contextMatrixForNode(node);
    this.transformOrigin = transformOriginForNode(node);

    this.initialRotation = 0;
    const trace = globalState.traceForNode(node);
    const rotation = trace?.transform?.args.rotation?.evaluationResult;
    if (isNumber(rotation)) {
      this.initialRotation = rotation;
    }
  }

  transformWorld(worldTransformMatrix: AffineMatrix, options?: TransformerOptions) {
    const transformMatrix = worldTransformMatrix.clone().changeBasis(this.initialContextMatrix);
    this.transformContext(transformMatrix, options);
  }

  transformContext(transformMatrix: AffineMatrix, options?: TransformerOptions) {
    // TODO: Ensure that the transformMatrix is not degenerate (call ensureMinimumBasisLength to pop out zero length bases?)

    if (this.parameterTransformers) {
      for (let transformer of this.parameterTransformers) {
        transformer.transformContext(transformMatrix, options, true);
      }
    }
    if (!this.isPassThrough) {
      const nextTransformMatrix = this.initialTransformMatrix.clone().preMul(transformMatrix);
      if (nextTransformMatrix.isNaN()) {
        this.assignTransform(this.initialTransformMatrix, options);
      } else {
        this.assignTransform(nextTransformMatrix, options);
      }
    }
  }

  /**
   * Mutates element's transform args so that
   * `AffineMatrix.fromTransform(element's transform)` is equal to
   * `transformMatrix`. Also does not modify element's transform origin.
   */
  assignTransform(transformMatrix: AffineMatrix, options?: TransformerOptions) {
    // WARNING!: Assign transform currently doesn't work on pass-through
    // components.
    assert(!this.isPassThrough);

    const focusedComponent = globalState.project.focusedComponent();
    if (!focusedComponent) {
      return;
    }
    const element = this.node.source;

    const viewport = globalState.viewportManager.viewportForComponent(focusedComponent);
    const { fractionDigits } = viewport.precisionInfo();

    if (!element.transform?.isEnabled) {
      if (transformMatrix.isIdentity()) return; // Avoid adding no-op transforms

      // Set the origin to the transform center when adding a new Transform.
      // NOTE: We must get the transform center before adding a new instance,
      // otherwise the existing origin (default at 0,0) will be used.
      const transformCenter = globalState.project.transformCenter();

      element.transform = new Instance(TransformDefinition);

      if (!element.base.definition.isDefaultUniformScale) {
        element.transform.args.scale = new Expression(
          expressionCodeForVec(new Vec(1, 1), fractionDigits)
        );
      }

      if (transformCenter) {
        this.transformOrigin = transformCenter.worldPosition
          .clone()
          .affineTransform(this.initialContextMatrix.clone().invert());
      }

      if (!this.transformOrigin.isZero()) {
        element.transform.args.origin = new Expression(
          expressionCodeForVec(this.transformOrigin, fractionDigits)
        );
      }
    }

    const transform = transformMatrix.toTransformWithOrigin(this.transformOrigin);

    // It's possible that toTransform() will return the alternate
    // solution, rotated 180° from our original. Account for this by
    // flipping the scale.
    if (options?.preserveRotation) {
      // We need to compare angular distance in case we have 0 and 359.999.
      const diff = angularDistance(transform.rotation, this.initialRotation);
      const opposite = (transform.rotation + 180) % 360;
      const oppositeDiff = angularDistance(opposite, this.initialRotation);
      if (oppositeDiff < diff) {
        // Opposite is better, so flip it.
        transform.rotation = opposite;
        transform.scale.negate();
      }
    }

    const { args } = element.transform;

    if (args.position?.isLiteral() || (!args.position && !transform.position.isZero())) {
      args.position = new Expression(expressionCodeForVec(transform.position, fractionDigits));
    }
    if (
      !options?.preserveScale &&
      (args.scale?.isLiteral() ||
        (args.scale === undefined && !transform.scale._almostEquals(new Vec(1))))
    ) {
      if (element.isUniformScale() && almostEquals(transform.scale.x, transform.scale.y)) {
        // For nodes that default to uniform scale, if both components of scale
        // are the same just assign a scalar.
        args.scale = new Expression(expressionCodeForNumber(transform.scale.x, fractionDigits));
      } else {
        args.scale = new Expression(expressionCodeForVec(transform.scale, fractionDigits));
      }
    }
    if (args.rotation?.isLiteral() || (args.rotation === undefined && transform.rotation !== 0)) {
      args.rotation = new Expression(expressionCodeForNumber(transform.rotation, 0));
    }
    if (args.skew?.isLiteral() || (args.skew === undefined && transform.skew !== 0)) {
      args.skew = new Expression(expressionCodeForNumber(transform.skew, 0));
    }

    delete args.matrix;
  }

  revert() {
    if (this.initialTransform) {
      this.node.source.transform = this.initialTransform;
    } else {
      this.node.source.transform = null;
    }

    if (this.parameterTransformers) {
      for (let transformer of this.parameterTransformers) {
        transformer.revert();
      }
    }
  }
}

export class ParameterTransformer implements Transformer {
  readonly selectable: SelectableParameter;

  readonly initialArg: Expression | undefined;
  readonly initialValue: unknown;
  readonly initialContextMatrix?: AffineMatrix;

  // Anchor-specific properties
  readonly initialAnchorConstraintArg?: Expression;
  readonly initialAnchorConstraint?: string;
  readonly overrideAnchorHandleConstraint?: AnchorHandleConstraint;

  constructor(selectable: SelectableParameter, overrideAnchorConstraint?: AnchorHandleConstraint) {
    this.selectable = selectable;

    const { node, instance, parameter } = selectable;
    const arg = instance.args[parameter.name];
    this.initialArg = arg;

    this.overrideAnchorHandleConstraint = overrideAnchorConstraint;

    const trace = globalState.traceForNode(node);
    if (trace) {
      // Base parameter handles will be affected by their element's transform,
      // vs modifier parameters which are not. We need to use the transformed
      // context for base parameters to account for this.
      this.initialContextMatrix = contextMatrixForInstance(node, instance);

      const origin = handleOriginForParameter(selectable);
      if (origin) {
        this.initialContextMatrix = this.initialContextMatrix.clone().translate(origin);
      }

      const instanceTrace = trace.traceForInstance(instance);
      if (instanceTrace) {
        this.initialValue = instanceTrace.resultForArgName(parameter.name);
        if (node.isAnchor()) {
          const handleConstraint = instanceTrace.args.handleConstraint?.evaluationResult;
          if (typeof handleConstraint === "string") {
            this.initialAnchorConstraint = handleConstraint;
          }
          this.initialAnchorConstraintArg = instanceTrace.source.args.handleConstraint;
        }
      }
    } else {
      // If evaluation has not occurred, such as in the case of a definition
      // drag or a preview anchor, we might not have a trace yet. Try to get the
      // initial value from the literal expression.
      const initialExpression = selectable.expression();
      if (initialExpression) {
        if (initialExpression.isLiteral()) {
          const projectUnit = globalState.project.settings.units;
          this.initialValue = initialExpression.literalValueInUnit(projectUnit);
        }
      }
    }
  }

  transformWorld(worldTransformMatrix: AffineMatrix, options?: TransformerOptions) {
    if (!this.initialContextMatrix) return;
    const transformMatrix = worldTransformMatrix.clone().changeBasis(this.initialContextMatrix);
    this.transformContext(transformMatrix, options);
  }

  transformContext(
    transformMatrix: AffineMatrix,
    options?: TransformerOptions,
    isIndirect = false
  ) {
    if (!this.selectable.isTransformable()) return;
    if (this.initialValue instanceof Vec) {
      let nextValue: Vec;
      if (isIndirect && this.selectable.parameter.interface instanceof PIVector) {
        nextValue = this.initialValue.clone().affineTransformWithoutTranslation(transformMatrix);
      } else {
        nextValue = this.initialValue.clone().affineTransform(transformMatrix);
      }
      this.assignValue(nextValue, options);
    } else if (isNumber(this.initialValue)) {
      const parameterInterface = this.selectable.parameter.interface;
      if (parameterInterface instanceof PIAngle) {
        const transform = transformMatrix.toTransform();
        const nextValue = this.initialValue + transform.rotation;
        this.assignValue(nextValue, options, 0);
      } else if (parameterInterface instanceof PIDistance) {
        const transform = transformMatrix.toTransform();
        const nextValue = this.initialValue * transform.scale.x;
        this.assignValue(nextValue, options, 0);
      }
    } else if (this.initialValue instanceof AffineMatrix && !options?.preserveImageTransforms) {
      if (this.selectable.parameter.interface instanceof PIImageTransform) {
        const nextValue = this.initialValue.clone().preMul(transformMatrix);
        this.assignValue(nextValue, options);
      }
    }
  }

  revert() {
    if (this.initialArg) {
      this.selectable.instance.args[this.selectable.parameter.name] = this.initialArg;
    } else {
      this.selectable.revertToDefault();
    }
    if (this.selectable.node.isAnchor()) {
      if (this.initialAnchorConstraintArg) {
        this.selectable.instance.args.handleConstraint = this.initialAnchorConstraintArg;
      } else {
        delete this.selectable.instance.args.handleConstraint;
      }
    }
  }

  assignValue(
    value: AffineMatrix | Vec | number,
    options?: TransformerOptions,
    minimumFractionDigits?: number
  ) {
    if (minimumFractionDigits === undefined) {
      // If a minimum precision is not specified, assume the value is a distance
      // and get the precision from the focused component zoom.
      const focusedComponent = globalState.project.focusedComponent();
      if (!focusedComponent) return;
      const viewport = globalState.viewportManager.viewportForComponent(focusedComponent);
      minimumFractionDigits = viewport.precisionInfo().fractionDigits;
    }

    const { args } = this.selectable.instance;
    const { name } = this.selectable.parameter;
    args[name] = new Expression(expressionCodeForValue(value, minimumFractionDigits));

    // Anchor handles ("handleIn" and "handleOut") have special behavior. When
    // dragging one handle, the other is constrained according to the
    // "handleConstraint" parameter.
    if (
      this.selectable.node.isAnchor() &&
      this.selectable.instance === this.selectable.node.source.base &&
      value instanceof Vec
    ) {
      const trace = globalState.traceForNode(this.selectable.node);

      if (trace?.isSuccess()) {
        const useAnchorConstraint =
          this.overrideAnchorHandleConstraint ??
          options?.overrideAnchorHandleConstraint ??
          this.initialAnchorConstraint;
        if (typeof useAnchorConstraint === "string") {
          const isHandleIn = this.selectable.parameter.name === "handleIn";
          const handleIn = isHandleIn ? value : trace.base.args.handleIn?.evaluationResult;
          const handleOut = !isHandleIn ? value : trace.base.args.handleOut?.evaluationResult;
          if (handleIn instanceof Vec && handleOut instanceof Vec) {
            constrainAnchorHandles(
              this.selectable.node.source,
              useAnchorConstraint as AnchorHandleConstraint,
              handleIn,
              !isHandleIn,
              handleOut,
              isHandleIn,
              minimumFractionDigits
            );
          }
        }

        {
          // Always assign (or delete) the handle contstraint. This ensures that
          // the constraint will be set back to its original expression when,
          // for example, alt is held and released while dragging a "tangent"
          // anchor handle.
          let anchorConstraintArgToAssign = this.initialAnchorConstraintArg;

          const assignAnchorConstraint = options?.assignAnchorHandleConstraint;
          if (assignAnchorConstraint) {
            anchorConstraintArgToAssign = new Expression(`"${assignAnchorConstraint}"`);
          }

          if (anchorConstraintArgToAssign) {
            args.handleConstraint = anchorConstraintArgToAssign;
          } else {
            delete args.handleConstraint;
          }
        }
      }
    }
  }
}

export class ComponentParameterTransformer implements Transformer {
  selectable: SelectableComponentParameter;

  initialExpression: Expression;
  initialValue: unknown;

  constructor(selectable: SelectableComponentParameter) {
    this.selectable = selectable;
    this.initialExpression = selectable.expression();

    const expressionTrace = selectable.expressionTrace();
    if (expressionTrace) {
      this.initialValue = expressionTrace.evaluationResult;
    } else {
      // If evaluation has not occurred, such as in the case of a definition
      // drag or a preview anchor, we might not have a trace yet. Try to get the
      // initial expression's literal value.
      if (this.initialExpression.isLiteral()) {
        const projectUnit = globalState.project.settings.units;
        this.initialValue = this.initialExpression.literalValueInUnit(projectUnit);
      }
    }
  }

  transformWorld(worldTransformMatrix: AffineMatrix, options?: TransformerOptions) {
    this.transformContext(worldTransformMatrix, options);
  }

  transformContext(
    transformMatrix: AffineMatrix,
    options?: TransformerOptions,
    isIndirect = false
  ) {
    if (!this.selectable.isTransformable()) return;
    if (this.initialValue instanceof Vec) {
      let nextValue: Vec;
      if (isIndirect && this.selectable.parameter.interface instanceof PIVector) {
        nextValue = this.initialValue.clone().affineTransformWithoutTranslation(transformMatrix);
      } else {
        nextValue = this.initialValue.clone().affineTransform(transformMatrix);
      }
      this.assignValue(nextValue, options);
    }
  }

  revert() {
    this.selectable.parameter.expression = this.initialExpression;
  }

  assignValue(value: Vec | number, options?: TransformerOptions) {
    const focusedComponent = globalState.project.focusedComponent();
    if (!focusedComponent) return;

    const viewport = globalState.viewportManager.viewportForComponent(focusedComponent);
    const { fractionDigits } = viewport.precisionInfo();

    const { parameter } = this.selectable;
    if (value instanceof Vec) {
      parameter.expression = new Expression(expressionCodeForVec(value, fractionDigits));
    } else {
      parameter.expression = new Expression(expressionCodeForNumber(value, fractionDigits));
    }
  }
}
