import {
  AffineMatrix,
  BoundingBox,
  ClosestPointResultWithTime,
  CubicSegment,
  Geometry,
  GeometryPrimitive,
  LineSegment,
  Ray,
  Vec,
} from "..";
import {
  axisAxisIntersection,
  axisCubicIntersections,
  axisRayIntersection,
  lineAxisIntersection,
  swapIntersectionTimes,
} from "../op/intersection";

/**
 * An axis is a line that has no endpoints.
 */
export class Axis extends Geometry implements GeometryPrimitive {
  static displayName = "Axis";

  /**
   * A position that lies on the axis. The origin is the position at `time = 0`.
   */
  origin: Vec;

  /**
   * The direction of the axis from the origin.
   */
  direction: Vec;

  /**
   * Constructs an axis.
   *
   * @param origin
   * @param direction
   */
  constructor(origin: Vec, direction: Vec) {
    super();
    this.origin = origin;
    this.direction = direction;
  }

  clone() {
    return new Axis(this.origin.clone(), this.direction.clone());
  }

  isValid() {
    return Vec.isValid(this.origin) && Vec.isValid(this.direction);
  }

  isHorizontal() {
    return this.direction.y === 0;
  }
  isVertical() {
    return this.direction.x === 0;
  }

  affineTransform(matrix: AffineMatrix) {
    this.origin.affineTransform(matrix);
    this.direction.affineTransformWithoutTranslation(matrix);
    return this;
  }
  affineTransformWithoutTranslation(matrix: AffineMatrix) {
    this.direction.affineTransformWithoutTranslation(matrix);
    return this;
  }

  boundingBox() {
    // When an axis is vertical or horizontal we can produce a usable bounding
    // box that only extends to infinity in one dimension.
    if (this.isVertical()) {
      return new BoundingBox(new Vec(this.origin.x, -Infinity), new Vec(this.origin.x, Infinity));
    }
    if (this.isHorizontal()) {
      return new BoundingBox(new Vec(-Infinity, this.origin.y), new Vec(Infinity, this.origin.y));
    }
    return new BoundingBox(new Vec(-Infinity), new Vec(Infinity));
  }

  looseBoundingBox() {
    return this.boundingBox();
  }

  reverse() {
    this.direction.negate();
    return this;
  }

  closestPoint(point: Vec, areaOfInterest?: BoundingBox): ClosestPointResultWithTime | undefined {
    if (areaOfInterest && !this._looseOverlapsBoundingBox(areaOfInterest)) return;

    const { origin, direction } = this;
    const pax = point.x - origin.x;
    const pay = point.y - origin.y;
    const bax = direction.x;
    const bay = direction.y;
    const time = (pax * bax + pay * bay) / (bax * bax + bay * bay);

    const position = this.positionAtTime(time);

    if (areaOfInterest && !areaOfInterest.containsPoint(position)) return;

    return { position, time, geometry: this };
  }

  positionAtTime(time: number) {
    return this.direction.clone().mulScalar(time).add(this.origin);
  }

  _looseOverlapsBoundingBox(box: BoundingBox) {
    const ox = this.origin.x;
    const oy = this.origin.y;
    const px = this.direction.y;
    const py = -this.direction.x;
    const { min, max } = box;
    const minx = min.x - ox;
    const miny = min.y - oy;
    const maxx = max.x - ox;
    const maxy = max.y - oy;
    const s = Math.sign(px * minx + py * miny);
    if (s !== Math.sign(px * maxx + py * miny)) return true;
    if (s !== Math.sign(px * maxx + py * maxy)) return true;
    if (s !== Math.sign(px * minx + py * maxy)) return true;
    return false;
  }

  _intersectionWithLineSegment(segment: LineSegment) {
    const intersection = lineAxisIntersection(segment.s, segment.e, this.origin, this.direction);
    if (intersection) return swapIntersectionTimes(intersection);
    return undefined;
  }

  _intersectionsWithCubicSegment(segment: CubicSegment) {
    return axisCubicIntersections(
      this.origin,
      this.direction,
      segment.s,
      segment.cs,
      segment.ce,
      segment.e
    );
  }

  _intersectionWithAxis(axis: Axis) {
    return axisAxisIntersection(this.origin, this.direction, axis.origin, axis.direction);
  }

  _intersectionWithRay(ray: Ray) {
    return axisRayIntersection(this.origin, this.direction, ray.origin, ray.direction);
  }

  _intersectionsWithPrimitive(primitive: GeometryPrimitive) {
    if (primitive instanceof LineSegment) {
      const x = this._intersectionWithLineSegment(primitive);
      if (x) return [x];
    } else if (primitive instanceof CubicSegment) {
      return this._intersectionsWithCubicSegment(primitive);
    } else if (primitive instanceof Axis) {
      const x = this._intersectionWithAxis(primitive);
      if (x) return [x];
    } else if (primitive instanceof Ray) {
      const x = this._intersectionWithRay(primitive);
      if (x) return [x];
    }
    return [];
  }

  _overlapWithPrimitive(primitive: GeometryPrimitive) {
    return undefined;
  }

  _primitives() {
    return [this];
  }
}
