import m from "mithril";

import { globalState } from "../global-state";
import type { CodeComponent, Component } from "../model/component";
import { Selection } from "../model/selection";
import { printFocusedComponent } from "./export-utils";
import { checkUserCanEdit } from "./export-check";

const TOAST_TIMEOUT_TIME = 7 * 1000;

interface Toast {
  view: () => m.Children;
  /** Defaults to true */
  autoHide?: boolean;
}

class ToastState {
  toast: Toast | undefined;
  timeout: number | undefined;

  show(toast: Toast) {
    this.toast = toast;

    if (toast.autoHide !== false) {
      this.setTimeout();
    }

    // So we can show a toast in async codepaths.
    m.redraw();
  }

  showBasic(attrs: ToastMessageBasicAttrs) {
    this.show({ view: () => m(ToastMessageBasic, attrs) });
  }

  /** Toast message will not hide, for example with view `pointerover` event. */
  clearTimeout() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  /** Toast message will hide in 7s, for example with view `pointerout` event.
   * Resets the timeout, if a toast is already shown. */
  setTimeout() {
    this.clearTimeout();
    this.timeout = window.setTimeout(() => this.dismiss(), TOAST_TIMEOUT_TIME);
  }

  dismiss() {
    this.clearTimeout();
    this.toast = undefined;

    // So we can dismiss a toast in async codepaths.
    m.redraw();
  }
}

export const toastState = new ToastState();

// Dismiss when clicking outside of message
// TODO: don't hide when message is result of click, like top-menu-clipboard
// window.addEventListener("click", (event: MouseEvent) => {
//   toastState.dismiss();
// });
// Don't dismiss when clicking message
const stopPropagation = (event: MouseEvent) => {
  event.stopPropagation();
};

export const ToastContainer: m.Component<{}> = {
  view() {
    return m(
      ".toast-container",
      {
        onpointerover: () => toastState.clearTimeout(),
        onpointerout: () => toastState.setTimeout(),
        onclick: stopPropagation,
      },
      [toastState.toast?.view(), m(CopyProjectToast)]
    );
  },
};

interface ToastMessageBasicAttrs {
  type: "info" | "warning" | "error";
  message: m.Children;
  nextStep?: m.Children;
}

const ToastMessageBasic: m.Component<ToastMessageBasicAttrs> = {
  view(vnode) {
    const { type, message, nextStep } = vnode.attrs;
    return m(
      ".toast-message",
      {
        className: type,
      },
      [
        m(".message", message),
        nextStep && m(".next-step", m("span.next-step-icon", "👉"), nextStep),
      ]
    );
  },
};

export const ToastMessageClipboard: m.Component<{
  message: m.Children;
  copiedOrPasted: Selection | Component | CodeComponent;
}> = {
  view(vnode) {
    const { message, copiedOrPasted } = vnode.attrs;
    return m(".toast-message.clipboard", [
      m(".message", mClipboardMessage(message, copiedOrPasted)),
    ]);
  },
};

const mClipboardMessage = (
  message: m.Children,
  copiedOrPasted: Selection | Component | CodeComponent
): m.Children => {
  const mMessage: m.Children = [message];

  if (copiedOrPasted instanceof Selection) {
    const elements = copiedOrPasted
      .directlySelectedNodes()
      .sortInDocumentOrder()
      .items.map((selectable) => selectable.node.source);
    const instances = copiedOrPasted
      .allInstances()
      .sortInDocumentOrder()
      .items.map((selectable) => selectable.instance);

    if (instances.length) {
      mMessage.push(" ");
      const instanceNames = instances.map((instance) => instance.definition.name);
      mMessage.push(mStrongListAndMore(instanceNames));
    } else if (elements.length) {
      mMessage.push(" ");
      const elNames = elements.map((el) => el.name);
      mMessage.push(mStrongListAndMore(elNames));
    } else {
      // Don't add a space.
    }
  } else {
    // For the case when we copy/paste a component without any elements
    // selected. For now, we can only copy one component at a time.
    mMessage.push(" ", m("strong", copiedOrPasted.name));
  }

  mMessage.push(".");
  return mMessage;
};

/** Takes an array of strings and returns bolded items interspersed with
 * unbolded commas. */
const mStrongItemsCommas: (items: string[]) => m.Children = (items) => {
  return items
    .map((item) => m("strong", item))
    .flatMap((item) => [", ", item])
    .slice(1);
};

/** Makes a bold list with commas. With 3 or fewer, name them all. With 4 or
 * more, name the first 2 and add eg "and 2 more" */
export function mStrongListAndMore(items: string[]): m.ChildArray {
  const mMessage: m.ChildArray = [];

  if (items.length <= 3) {
    // With 3 or fewer, name them all
    mMessage.push(mStrongItemsCommas(items));
  } else {
    // With 4, name the first 2 and add "and 2 more"
    mMessage.push(mStrongItemsCommas(items.slice(0, 2)));
    mMessage.push(` and ${items.length - 2} more`);
  }

  return mMessage;
}

// Until we support touch input, show a message if we think that a user is on a
// touch screen device. This could be a phone, tablet, or PC with a touch
// screen, so we don't want to gate any functionality.
let isTouchScreenWarningToastShown = false;
export const showTouchScreenWarningToast = () => {
  if (isTouchScreenWarningToastShown) return;
  isTouchScreenWarningToastShown = true;
  const isOnTouchScreen = navigator.maxTouchPoints > 0;
  if (isOnTouchScreen) {
    toastState.showBasic({
      type: "warning",
      message: "Cuttle does not currently support touch screen input.",
      nextStep: "Try viewing this project on a computer with a mouse or trackpad.",
    });
  }
};

/** "Perma-toast" with interface to copy this project. Does not dismiss itself.
 * Shows below the other ephemeral toasts. */
const CopyProjectToast: m.Component = {
  view: () => {
    // Don't show if we own it.
    if (globalState.storage.hasWritePermission()) return;

    // Don't show during "Getting Started" flow.
    if (globalState.storage.sequenceToLoad()) return;

    // Only show in editing mode.
    if (!globalState.isEditingMode()) return;

    const owner = globalState.storage.getProjectOwner();
    return m(".toast-message.info.copy-project-toast", [
      m(
        ".message",
        `This project is owned by `,
        m("a", { href: "/@" + owner, target: "_blank" }, `@${owner}`),
        `. Make a copy to save your changes.`
      ),
      m(CopyButton),
    ]);
  },
};

const CopyButton: m.ClosureComponent = () => {
  let isCopying = false;
  const onclick = async () => {
    if (isCopying) return;
    if (!checkUserCanEdit()) return;
    isCopying = true;
    const snapshotPayload = await globalState.snapshotPayload();
    const success = await globalState.storage.saveCopy(snapshotPayload);
    isCopying = false;
    if (success) {
      // If saveCopy succeeds, CopyProjectToast will hide its contents. We want
      // to show a new ephemeral toast that the copy succeeded, because the URL
      // change alone is subtle.
      toastState.showBasic({
        type: "info",
        message: "You made a copy. Your changes will now save to your account.",
      });
    } else {
      // If saveCopy doesn't succeed, this toast will remain on screen, so you
      // will be able to try again.
      m.redraw();
    }
  };
  return {
    view() {
      return m(
        "button",
        { onclick, disabled: isCopying },
        isCopying ? "Copying..." : "Copy This Project"
      );
    },
  };
};

const showPrintHintToast = () => {
  // Don't show from packaged readme view.
  if (!globalState.isEditingMode()) return;

  toastState.show({
    view: () => {
      const onclick = (event: PointerEvent) => {
        event.preventDefault();
        printFocusedComponent();
        toastState.dismiss();
      };

      return m(".toast-message", [
        "Want to print your design? Select File > Print from ",
        m("em", "Cuttle’s"),
        " menu (not the browser’s menu) or ",
        m('span[role="link"]', { onclick }, "click here"),
        ".",
      ]);
    },
    autoHide: false,
  });
};

// Printing the Cuttle interface shows a toast with a button to print the component.
window.addEventListener("beforeprint", showPrintHintToast);
