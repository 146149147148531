import m from "mithril";

import { config } from "../../config";
import { assert } from "../../geom";
import { globalState } from "../../global-state";
import { ComponentFocus } from "../../model/focus";
import { Node } from "../../model/node";
import { SelectableNode } from "../../model/selectable";
import { Selection } from "../../model/selection";
import { EditableText } from "../../shared/editable-text";
import { IconButton } from "../../shared/icon";
import { classNames, isPointerEventDoubleClick, isPointerEventRightClick } from "../../shared/util";
import { Expander } from "../basic/expander";
import { startDrag } from "../start-drag";
import { createSelectionRightClickMenu } from "../top-menu";
import { POINTER_EVENT_BUTTONS_NONE } from "../util";
import { OutlineBadges } from "./outline-badges";
import { OutlineModifierReorderHitTarget } from "./outline-modifier-badge";

const rangeSelect = (clickedNode: Node) => {
  const project = globalState.project;

  const selection = project.selection.directlySelectedNodes();

  // If there's no selection, just select the clicked node.
  if (selection.isEmpty()) {
    project.selectNode(clickedNode);
    return;
  }

  // If more than one thing is selected, just treat it as a toggle select.
  if (selection.isMultiple()) {
    project.toggleSelectNode(clickedNode);
    return;
  }

  const currentSelectedNode = selection.items[0].node;
  // TYPE: You can't select the root of a component, therefore the selected node
  // must have a parent.
  const parent = currentSelectedNode.parent as Node;

  // If clickedNode and currentSelectedNode don't have the same parent, just
  // treat it as a toggle select.
  if (!parent.equalsNode(clickedNode.parent as Node)) {
    project.toggleSelectNode(clickedNode);
    return;
  }

  // Okay, do a real range select. Select all children of parent whose index is
  // between currentSelectedNode's and clickedNode's, inclusive.
  let index1 = currentSelectedNode.indexInParent();
  let index2 = clickedNode.indexInParent();

  // Ensure index1 < index2.
  if (index1 > index2) {
    [index1, index2] = [index2, index1];
  }

  const childNodes = parent.childNodes();
  const nodesToSelect = childNodes.slice(index1, index2 + 1);

  project.selectNodes(nodesToSelect);
};

interface OutlineItemAttrs {
  node: Node;
}
const OutlineItem: m.Component<OutlineItemAttrs> = {
  view(vnode) {
    const { node } = vnode.attrs;
    const element = node.source;

    const onpointerdown = (event: PointerEvent) => {
      const isDoubleClick = isPointerEventDoubleClick(event);
      const isRightClick = isPointerEventRightClick(event);
      const isRangeSelect = event.shiftKey;
      const isToggleSelect = event.ctrlKey || event.metaKey;

      if (isRightClick) {
        if (!globalState.project.selection.isNodeDirectlySelected(node)) {
          globalState.project.selectNode(node);
        }
        createSelectionRightClickMenu(event);
        return;
      }

      startDrag(event, {
        cursor() {
          return "grabbing";
        },
        onConsummate() {
          let selection: Selection;
          if (globalState.project.selection.isNodeDirectlySelected(node)) {
            selection = globalState.project.selection
              .directlySelectedNodes()
              .sortInDocumentOrder()
              .mutables();
          } else {
            selection = new Selection([new SelectableNode(node)]);
          }
          if (!selection.isEmpty()) {
            globalState.outlineReorder = { nodes: selection.toNodes() };
          }
        },
        onUp() {
          const { outlineReorder } = globalState;
          if (outlineReorder?.hoveredInsertionParent) {
            const nodes = project.reparentNodes(
              outlineReorder.nodes,
              outlineReorder.hoveredInsertionParent,
              outlineReorder.hoveredInsertionIndex
            );
            if (nodes) {
              globalState.project.selectNodes(nodes);
            }
          }
          globalState.outlineReorder = null;
        },
        onCancel() {
          globalState.outlineReorder = null;

          if (isRangeSelect) {
            rangeSelect(node);
            return;
          }
          if (isToggleSelect) {
            globalState.project.toggleSelectNode(node);
            return;
          }

          if (isDoubleClick && node.hasChildNodes() && !node.isImmutableChildren()) {
            globalState.project.clearSelection();
            globalState.project.focusNode(node);
            return;
          }

          globalState.project.selectNode(node);
        },
      });
    };
    const onpointerover = (event: PointerEvent) => {
      // We check that a node still exists before setting it hovered because it
      // may have been deleted in the same frame, for example when the pen
      // tool's preview anchor is removed.
      if (event.buttons === POINTER_EVENT_BUTTONS_NONE && globalState.project.nodeExists(node)) {
        globalState.project.hoveredItem = new SelectableNode(node);
      }
    };
    const onpointerleave = () => {
      globalState.project.hoveredItem = null;
    };

    const onExpanderPointerDown = (event: PointerEvent) => {
      event.stopPropagation();
      globalState.project.toggleExpandedNode(node);
    };

    const rename = (name: string) => {
      globalState.project.renameElement(node.source, name);
    };

    const onClickLockedToggle = (event: PointerEvent) => {
      event.stopPropagation();
      if (node.isImmutable()) return;
      element.isLocked = !element.isLocked;
    };
    const onClickVisibleToggle = (event: PointerEvent) => {
      event.stopPropagation();
      if (node.isImmutable()) return;
      element.isVisible = !element.isVisible;
    };
    const toggleShowGuides = (event: PointerEvent) => {
      event.stopPropagation();
      if (node.isImmutable()) return;
      // Only toggle between "show" and "show-all-as-guides" with clicks. If it
      // is "hide" then it click will go to "show"
      element.guidesDisplay = element.guidesDisplay === "show" ? "show-all-as-guides" : "show";
    };

    const { project } = globalState;
    assert(project.focus instanceof ComponentFocus);

    const isExpanded = project.isNodeExpanded(node);
    const isHidesSelection =
      !isExpanded &&
      project.selection.allNodes().items.some((item) => {
        return node.isAncestorOfNode(item.node);
      });

    const isDirectlySelected = project.selection.isNodeDirectlySelected(node);
    const isIndirectlySelected =
      !isDirectlySelected && project.selection.isNodeInderectlySelected(node);

    const isChildless = !node.hasChildNodes();

    const hideExpander = !isExpanded && !node.canAddChildren();

    const className = classNames({
      expanded: isExpanded,
      childless: isChildless,
      "hide-expander": hideExpander,
      selected: isDirectlySelected,
      focused: node.equalsNode(project.focus.node),
      "selected-indirect": isIndirectlySelected,
      "hides-selection": isHidesSelection,
      hovered: globalState.project.isNodeHovered(node),
      dragging: globalState.outlineReorder?.nodes.some((n) => n.equalsNode(node)),
      error: !globalState.traceForNode(node)?.isSuccess(),
    });

    return m(".outline-item", { onpointerdown, onpointerover, onpointerleave, class: className }, [
      m(OutlineModifierReorderHitTarget, {
        where: "inside",
        insertionNode: node,
        insertionIndex: node.source.modifiers.length,
      }),
      m(Expander, { expanded: isExpanded, onpointerdown: onExpanderPointerDown }),
      m(".outline-item-main", [
        m("span.outline-item-name", [
          node.isImmutable()
            ? element.name
            : m(EditableText, { value: element.name, onchange: rename }),
        ]),
        config.showMemoizationInOutline ? m(OutlineItemMemoizeIndicator, { node }) : null,
        m(OutlineBadges, { node }),
      ]),
      m(".extra", [
        m(IconButton, {
          icon:
            element.guidesDisplay === "show-all-as-guides"
              ? "all_guides"
              : element.guidesDisplay === "hide"
              ? "hide_guides"
              : "show_guides",
          label:
            element.guidesDisplay === "show-all-as-guides"
              ? "Show Shapes As Guides"
              : "Show Guides",
          onpointerdown: toggleShowGuides,
          className: classNames({
            "extra-hidden": element.guidesDisplay === "show",
          }),
        }),
        m(IconButton, {
          icon: element.isLocked ? "locked" : "unlocked",
          label: element.isLocked ? "Locked" : "Unlocked",
          onpointerdown: onClickLockedToggle,
          className: classNames({
            "extra-hidden": !element.isLocked,
          }),
        }),
        m(IconButton, {
          icon: element.isVisible ? "visible" : "invisible",
          label: element.isVisible ? "Visible" : "Hidden",
          onpointerdown: onClickVisibleToggle,
          className: classNames({
            "extra-hidden": element.isVisible,
          }),
        }),
      ]),
    ]);
  },
};

interface OutlineNodeAttrs {
  node: Node;
}
const OutlineNode: m.Component<OutlineNodeAttrs> = {
  view({ attrs: { node } }) {
    const isExpanded = globalState.project.isNodeExpanded(node);

    const className = classNames({
      "reorder-parent":
        globalState.outlineReorder &&
        globalState.outlineReorder.hoveredInsertionParent &&
        globalState.outlineReorder.hoveredInsertionParent.equalsNode(node),
    });

    // TYPE: We'll never draw the OutlineNode for the root, therefore node has a
    // parent.
    const parent = node.parent as Node;

    return m(".outline-node", { class: className }, [
      m(OutlineReorderHitTarget, {
        where: "after",
        insertionParent: parent,
        insertionIndex: node.indexInParent(),
      }),
      !isExpanded && node.canAddChildren()
        ? m(OutlineReorderHitTarget, {
            where: "inside",
            insertionParent: node,
            insertionIndex: node.childCount(),
          })
        : undefined,
      m(OutlineItem, { node }),
      isExpanded ? m(OutlineChildren, { node }) : undefined,
    ]);
  },
};

interface OutlineChildrenAttrs {
  node: Node;
}
const OutlineChildren: m.Component<OutlineChildrenAttrs> = {
  view({ attrs: { node } }) {
    const childNodes = node.childNodes();
    if (childNodes.length == 0) return undefined;

    // Sort front-to-back.
    childNodes.reverse();

    const className = classNames({
      "new-context": node.createsNewContext(),
      "within-focus": globalState.project.isNodeFocused(node),
    });

    return m(".outline-children", { class: className }, [
      childNodes.map((node) => m(OutlineNode, { node })),
      m(OutlineReorderHitTarget, {
        where: "before",
        insertionParent: node,
        insertionIndex: childNodes.length,
      }),
    ]);
  },
};

interface OutlineReorderHitTargetAttrs {
  where: "after" | "before" | "inside";
  insertionParent: Node;
  insertionIndex: number;
}
const OutlineReorderHitTarget: m.Component<OutlineReorderHitTargetAttrs> = {
  view({ attrs: { where, insertionParent, insertionIndex } }) {
    if (!globalState.outlineReorder) return undefined;
    if (insertionParent.isImmutableChildren() || insertionParent.isImmutable()) return undefined;

    // Don't allow inserting into your own tree.
    if (
      globalState.outlineReorder.nodes.some(
        (node) => node.equalsNode(insertionParent) || node.isAncestorOfNode(insertionParent)
      )
    ) {
      return undefined;
    }

    // Don't draw if we're already here.
    if (globalState.outlineReorder.nodes.length === 1) {
      const node = globalState.outlineReorder.nodes[0];
      if (node.hasParentEqualToNode(insertionParent)) {
        const oldIndex = node.indexInParent();
        if (insertionIndex === oldIndex || insertionIndex === oldIndex + 1) return undefined;
      }
    }

    const onpointerenter = () => {
      if (!globalState.outlineReorder) return;
      globalState.outlineReorder.hoveredInsertionParent = insertionParent;
      globalState.outlineReorder.hoveredInsertionIndex = insertionIndex;
    };
    const onpointerleave = () => {
      if (!globalState.outlineReorder) return;
      globalState.outlineReorder.hoveredInsertionParent = undefined;
      globalState.outlineReorder.hoveredInsertionIndex = undefined;
    };
    const className = classNames({
      hovered:
        globalState.outlineReorder?.hoveredInsertionParent?.equalsNode(insertionParent) &&
        globalState.outlineReorder?.hoveredInsertionIndex === insertionIndex,
      [where]: true,
    });
    return m(".outline-reorder-hit-target", { className, onpointerenter, onpointerleave });
  },
};

interface OutlineAttrs {
  heightFactor: number;
}
export const Outline: m.Component<OutlineAttrs> = {
  view({ attrs: { heightFactor } }) {
    const topNode = globalState.project.topNode();
    if (!topNode) {
      // Focused is probably code component: no outline
      return;
    }
    const className = classNames({
      reordering: globalState.outlineReorder !== null,
    });
    const onpointerdown = (event: PointerEvent) => {
      const element = event.target as HTMLElement;
      if (element.classList.contains("outline")) {
        // It's a background click.
        const project = globalState.project;
        assert(project.focus instanceof ComponentFocus);
        project.clearSelection();
        if (isPointerEventDoubleClick(event)) {
          if (project.focus.node.parent) {
            project.focusNode(project.focus.node.parent);
          }
        }
      }
    };
    const style = `flex-basis: ${(heightFactor * 100).toFixed(3)}vh`;
    return m(".outline.scrollable", { className, style, onpointerdown }, [
      m(OutlineChildren, { node: topNode }),
    ]);
  },
};

// Note: this is only used when `config.showMemoizationInOutline` is true.
interface OutlineItemMemoizeIndicatorAttrs {
  node: Node;
}
const OutlineItemMemoizeIndicator: m.Component<OutlineItemMemoizeIndicatorAttrs> = {
  view({ attrs: { node } }) {
    const trace = globalState.traceForNode(node);
    if (!trace) return;
    const entry = globalState.evaluateElementMemoizer.findEntryForTrace(trace);
    if (!entry) return;
    const computedLastFrame =
      globalState.evaluateElementMemoizer.entriesComputedLastFrame.has(entry);
    return m(
      "span",
      {
        style: {
          padding: "0 6px 0 2px",
          borderRadius: "40px",
          ...(computedLastFrame
            ? { backgroundColor: "red" }
            : {
                transition: "background-color 0.5s",
                backgroundColor: "transparent",
              }),
        },
        onclick: () => console.log(entry),
      },
      "⚡️"
    );
  },
};
