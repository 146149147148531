import {
  Anchor,
  Axis,
  BoundingBox,
  CompoundPath,
  CubicSegment,
  GEOMETRIC_TOLERANCE,
  Geometry,
  Group,
  IntersectionResult,
  LineSegment,
  Path,
  Ray,
  Vec,
  fract,
  roundToMultiple,
} from "../geom";
import { globalState } from "../global-state";
import { CanvasPoint } from "../model/canvas-point";
import { expressionCodeForNumber } from "../model/expression-code";
import { CanvasInterfaceElement } from "./canvas-ui/canvas-interface-element";
import { constrainPositionToGrid } from "./util";

export type SnappingPointCategory =
  | "Point"
  | "Anchor"
  | "Endpoint"
  | "Midpoint"
  | "Corner"
  | "Path"
  | "Axis"
  | "Intersection"
  | "Origin"
  | "Handle"
  | "Box";

const categoryPriorities: { [S in SnappingPointCategory]: number } = {
  // Lines
  Path: 0,
  Axis: 1,

  // Points
  Point: 100,
  Intersection: 110,
  Midpoint: 120,
  Anchor: 130,
  Corner: 140,
  Endpoint: 150,

  // Handles
  Box: 200,
  Handle: 210,
  Origin: 220,
};

interface SnappingPointSource {
  geometry: Geometry;
  time?: number;
}

export class SnappingPoint extends CanvasPoint {
  name: string;
  category: SnappingPointCategory;

  sources?: SnappingPointSource[];

  constructor(
    worldPosition: Vec,
    name: string,
    category: SnappingPointCategory,
    sources?: SnappingPointSource[]
  ) {
    let isPreciseH = true;
    let isPreciseV = true;
    if (sources) {
      isPreciseH = false;
      isPreciseV = false;
      for (let { geometry } of sources) {
        if (geometry instanceof Axis || geometry instanceof LineSegment) {
          isPreciseH ||= !geometry.isHorizontal();
          isPreciseV ||= !geometry.isVertical();
        } else {
          // Assume any other source geometry is fully precise.
          isPreciseH = true;
          isPreciseV = true;
          break;
        }
      }
    }

    const isSpecific = category !== "Path" && category !== "Axis";

    super(worldPosition, isPreciseH, isPreciseV, isSpecific);

    this.name = name;
    this.category = category;
    this.sources = sources;
  }

  clone() {
    return new SnappingPoint(this.worldPosition, this.name, this.category, this.sources?.slice());
  }

  message() {
    return this.name || this.category;
  }
}

export class SnappingGeometry {
  name: string;
  geometry: (Geometry | Vec)[];

  constructor(name: string, geometry: (Geometry | Vec)[] | Geometry | Vec) {
    this.name = name;
    if (!Array.isArray(geometry)) {
      geometry = [geometry];
    }
    this.geometry = geometryByFlatteningGroupsAndCompoundPaths(geometry);
  }
}

export class SnappingState {
  currentPoint?: SnappingPoint;

  points: SnappingPoint[] = [];
  geometry: SnappingGeometry[] = [];

  referencePoints: Vec[] = [];
  referenceGeometry: SnappingGeometry[] = [];

  intersectionGeometry?: Geometry[];

  constructor() {
    this.clear();
  }

  cacheSnappingDataForInterface(
    interfaceElements: CanvasInterfaceElement[],
    { isSource = false, skipReferencePoints = false, skipReferenceGeometry = false } = {}
  ) {
    this.points = snappingPointsForInterfaceElements(interfaceElements, isSource);
    this.geometry = snappingGeometryForInterfaceElements(interfaceElements, isSource);
    if (!skipReferencePoints) {
      this.referencePoints = referencePointsForInterfaceElements(interfaceElements, isSource);
    }
    if (!skipReferenceGeometry) {
      this.referenceGeometry = this.referencePoints.flatMap((point) =>
        referenceGeometryFromReferencePoint(this.geometry, point)
      );
    }
    this.intersectionGeometry = undefined;
  }

  cacheSnappingDataForHoveredInterface() {
    const { hoveredInterfaceElement } = globalState;
    if (hoveredInterfaceElement) {
      this.cacheSnappingDataForInterface([hoveredInterfaceElement], { isSource: true });
      this.intersectionGeometry = onlyGeometry(
        snappingGeometryForInterfaceElements(
          globalState.interfaceElements.filter((e) => e !== hoveredInterfaceElement),
          false
        )
      );
    }
  }

  addReferencePoint(point: Vec, referenceGeometry?: SnappingGeometry[]) {
    this.referencePoints.push(point);
    if (!referenceGeometry) {
      referenceGeometry = referenceGeometryFromReferencePoint(this.geometry, point);
    }
    this.referenceGeometry.push(...referenceGeometry);
  }

  updateSnappingPointNearWorldPosition(worldTargetPos: Vec, { isSource = false } = {}) {
    const distance = globalState.snappingDistanceWorld();
    const gridIncrement = globalState.deviceStorage.gridSnappingEnabled
      ? globalState.gridIncrement()
      : 0;

    // First check to see if we find a snapping point exactly at a grid
    // position. This is useful when snapping geometry such as the X and Y axes
    // line up with the grid.
    let gridPoint: SnappingPoint | undefined;
    if (globalState.deviceStorage.gridSnappingEnabled) {
      const gridPosition = constrainPositionToGrid(worldTargetPos.clone());
      gridPoint = findSnappingPoint(
        gridPosition,
        GEOMETRIC_TOLERANCE,
        GEOMETRIC_TOLERANCE,
        isSource,
        gridIncrement,
        this.points,
        this.geometry,
        this.intersectionGeometry
      );
    }

    const freePoint = findSnappingPoint(
      worldTargetPos,
      distance,
      distance,
      isSource,
      gridIncrement,
      this.points,
      this.geometry,
      this.intersectionGeometry
    );

    if (gridPoint && freePoint) {
      const gridPriority = categoryPriorities[gridPoint.category];
      const freePriority = categoryPriorities[freePoint.category];
      if (freePriority > gridPriority) {
        this.currentPoint = freePoint;
      } else {
        this.currentPoint = gridPoint;
      }
    } else if (gridPoint) {
      this.currentPoint = gridPoint;
    } else {
      this.currentPoint = freePoint;
    }
  }
  updateReferenceSnappingPointNearWorldPosition(worldTargetPos: Vec, { isSource = false } = {}) {
    const distance = globalState.snappingDistanceWorld();
    const gridIncrement = globalState.deviceStorage.gridSnappingEnabled
      ? globalState.gridIncrement()
      : 0;

    this.currentPoint = findSnappingPoint(
      worldTargetPos,
      Infinity,
      distance,
      isSource,
      gridIncrement,
      [],
      this.referenceGeometry,
      onlyGeometry(this.geometry)
    );
  }

  clear() {
    this.points = [];
    this.referencePoints = [];
    this.geometry = [];
    this.referenceGeometry = [];
    this.currentPoint = undefined;
    this.intersectionGeometry = undefined;
  }
  clearCurrentPoint() {
    this.currentPoint = undefined;
  }
  clearReference() {
    this.referencePoints = [];
    this.referenceGeometry = [];
  }
}

const findSnappingPoint = (
  worldTargetPos: Vec,
  distance: number,
  priorityOverrideDistance: number,
  isSource: boolean,
  gridIncrement: number,
  points: SnappingPoint[],
  geometry: SnappingGeometry[],
  intersectionGeometry?: Geometry[]
) => {
  const geometryPoints = snappingPointsForGeometryWithinDistanceToWorldPosition(
    geometry,
    distance,
    worldTargetPos,
    gridIncrement
  );
  points = points.concat(geometryPoints);

  if (intersectionGeometry) {
    // intersectionGeometry = intersectionGeometry.slice(); // Why are we slicing intersectionGeometry??
    const areaOfInterest = new BoundingBox(
      worldTargetPos.clone().subScalar(distance),
      worldTargetPos.clone().addScalar(distance)
    );
    const intersections = Geometry.intersectionsBetweenSets(
      onlyGeometry(geometry),
      intersectionGeometry,
      areaOfInterest
    );
    for (let x of intersections) {
      points.push(snappingPointFromIntersection(x));
    }
  }

  if (isSource) {
    // Only allow dragging from specific points.
    points = points.filter((point) => point.isSpecific);
  }

  return bestSnappingPointNearWorldPosition(
    worldTargetPos,
    distance,
    priorityOverrideDistance,
    points
  );
};

const bestSnappingPointNearWorldPosition = (
  worldTargetPos: Vec,
  distance: number,
  priorityOverrideDistance: number,
  points: SnappingPoint[]
) => {
  if (points.length === 0) return undefined;

  const distanceSq = distance * distance;
  const priorityOverrideDistanceSq = priorityOverrideDistance * priorityOverrideDistance;

  let bestPoint: SnappingPoint | undefined;
  let bestPointDistanceSq = Infinity;
  let bestPointPriority = -1;

  for (let point of points) {
    let pointDistanceSq = worldTargetPos.distanceSquared(point.worldPosition);

    if (point.category === "Box") {
      // Transform box snapping points are always considered to be "closest".
      // This is sort of a hack to ensure that transform box handle snapping
      // works correctly.
      pointDistanceSq = 0;
    }

    // Skip points outside of the search radius.
    if (pointDistanceSq > distanceSq) continue;

    // Skip lower priority points.
    const pointPriority = categoryPriorities[point.category];
    if (pointPriority < bestPointPriority) continue;

    if (
      pointDistanceSq < bestPointDistanceSq ||
      (pointPriority > bestPointPriority && pointDistanceSq < priorityOverrideDistanceSq)
    ) {
      bestPoint = point;
      bestPointPriority = pointPriority;
      bestPointDistanceSq = pointDistanceSq;
    }
  }

  return bestPoint;
};

const snappingGeometryForInterfaceElements = (
  elements: CanvasInterfaceElement[],
  isSource: boolean
) => {
  const snappingGeometry: SnappingGeometry[] = [];
  for (let element of elements) {
    if (element.snappingGeometry) {
      const geometry = element.snappingGeometry(isSource);
      if (Array.isArray(geometry)) {
        snappingGeometry.push(...geometry);
      } else if (geometry !== undefined) {
        snappingGeometry.push(geometry);
      }
    }
  }
  return snappingGeometry;
};

const geometryByFlatteningGroupsAndCompoundPaths = (geometry: (Geometry | Vec)[]) => {
  const flatGeometry: (Geometry | Vec)[] = [];
  const appendGeometry = (geom: Geometry | Vec) => {
    if (geom instanceof Group) {
      for (let item of geom.items) {
        appendGeometry(item);
      }
    } else if (geom instanceof CompoundPath) {
      for (let path of geom.paths) {
        appendGeometry(path);
      }
    } else {
      flatGeometry.push(geom);
    }
  };
  for (let geom of geometry) {
    appendGeometry(geom);
  }
  return flatGeometry;
};

const snappingPointsForInterfaceElements = (
  elements: CanvasInterfaceElement[],
  isSource: boolean
) => {
  const snappingPoints: SnappingPoint[] = [];
  for (let element of elements) {
    if (element.snappingPoints) {
      const result = element.snappingPoints(isSource);
      if (result instanceof SnappingPoint) {
        snappingPoints.push(result);
      } else if (result !== undefined) {
        snappingPoints.push(...result);
      }
    }
  }
  return snappingPoints;
};

const referencePointsForInterfaceElements = (
  elements: CanvasInterfaceElement[],
  isSource: boolean
) => {
  const snappingPoints: Vec[] = [];
  for (let element of elements) {
    if (element.snappingReferencePoints) {
      const result = element.snappingReferencePoints(isSource);
      if (result instanceof Vec) {
        snappingPoints.push(result);
      } else if (result !== undefined) {
        snappingPoints.push(...result);
      }
    }
  }
  return snappingPoints;
};

const snappingPointsForGeometryWithinDistanceToWorldPosition = (
  snappingGeometry: SnappingGeometry[],
  worldMaxDistance: number,
  worldPosition: Vec,
  gridIncrement: number
) => {
  const maxDistanceSq = worldMaxDistance * worldMaxDistance;
  const areaOfInterest = new BoundingBox(
    worldPosition.clone().subScalar(worldMaxDistance),
    worldPosition.clone().addScalar(worldMaxDistance)
  );

  const snappingPoints: SnappingPoint[] = [];
  for (let { name, geometry } of snappingGeometry) {
    for (let geom of geometry) {
      if (geom instanceof Vec) {
        const distanceSq = geom.distanceSquared(worldPosition);
        if (distanceSq <= maxDistanceSq) {
          snappingPoints.push(new SnappingPoint(geom, name, "Point"));
        }
      } else if (geom instanceof Anchor) {
        const { position } = geom;
        const distanceSq = position.distanceSquared(worldPosition);
        if (distanceSq <= maxDistanceSq) {
          snappingPoints.push(new SnappingPoint(position, name, "Anchor"));
        }
      } else if (geom instanceof Axis || geom instanceof Ray) {
        const result = geom.closestPoint(worldPosition, areaOfInterest);
        if (result) {
          const time = roundToMultiple(result.time, gridIncrement);
          const position = geom.positionAtTime(time);
          const distanceSq = worldPosition.distanceSquared(position);
          if (distanceSq <= maxDistanceSq) {
            snappingPoints.push(
              new SnappingPoint(position, name, "Axis", [{ geometry: geom, time }])
            );
          }
        }
      } else if (geom instanceof Path) {
        const { anchors, closed } = geom;

        // Anchor Point Snaps
        for (let i = 0, len = anchors.length; i < len; ++i) {
          const anchor = anchors[i];
          const distanceSq = anchor.position.distanceSquared(worldPosition);
          if (distanceSq <= maxDistanceSq) {
            let name = "Anchor";
            if (closed || (i > 0 && i < len - 1)) {
              if (!anchor.hasTangentHandles()) {
                name = "Corner";
              }
            } else {
              name = "Endpoint";
            }
            const snappingPoint = new SnappingPoint(anchor.position, name, "Anchor", [
              { geometry: geom.anchorAtTime(i) },
            ]);
            snappingPoints.push(snappingPoint);
          }
        }

        if (anchors.length >= 2) {
          // Closest Point
          const result = geom.closestPoint(worldPosition, areaOfInterest);
          if (result) {
            const distanceSq = worldPosition.distanceSquared(result.position);
            if (distanceSq <= maxDistanceSq) {
              const segment = geom.segmentAtTime(result.time);
              snappingPoints.push(
                new SnappingPoint(result.position, "Path", "Path", [
                  { geometry: segment, time: fract(result.time) },
                ])
              );
            }
          }

          // Straight Segment Midpoints
          const segments = geom.segments();
          for (let segment of segments) {
            if (segment instanceof LineSegment) {
              const position = segment.positionAtTime(0.5);
              const distanceSq = worldPosition.distanceSquared(position);
              if (distanceSq <= maxDistanceSq) {
                snappingPoints.push(
                  new SnappingPoint(position, "Midpoint", "Midpoint", [
                    { geometry: segment, time: 0.5 },
                  ])
                );
              }
            }
          }
        }
      } else if (geom instanceof LineSegment || geom instanceof CubicSegment) {
        // Closest Point
        const result = geom.closestPoint(worldPosition, areaOfInterest);
        if (result) {
          const distanceSq = worldPosition.distanceSquared(result.position);
          if (distanceSq <= maxDistanceSq) {
            snappingPoints.push(
              new SnappingPoint(result.position, "Path", "Path", [
                { geometry: geom, time: result.time },
              ])
            );
          }
        }

        // Midpoint
        if (geom instanceof LineSegment) {
          const position = geom.positionAtTime(0.5);
          const distanceSq = worldPosition.distanceSquared(position);
          if (distanceSq <= maxDistanceSq) {
            snappingPoints.push(
              new SnappingPoint(position, "Midpoint", "Midpoint", [{ geometry: geom, time: 0.5 }])
            );
          }
        }
      }
    }
  }

  const intersections = Geometry.intersectionsBetweenAll(
    onlyGeometry(snappingGeometry),
    areaOfInterest
  );
  for (let x of intersections) {
    snappingPoints.push(snappingPointFromIntersection(x));
  }

  return snappingPoints;
};

const referenceGeometryFromReferencePoint = (
  snappingGeometry: SnappingGeometry[],
  point: Vec
  // angleIncrement: number
) => {
  const referenceGeometry: SnappingGeometry[] = [];

  const tolerance = GEOMETRIC_TOLERANCE;
  const areaOfInterest = new BoundingBox(
    point.clone().subScalar(tolerance),
    point.clone().addScalar(tolerance)
  );
  const appendAxesForGeomAtTime = (geom: Path | LineSegment | CubicSegment, time: number) => {
    const tangent = geom.tangentAtTime(time);
    const tangentAxis = new Axis(point, tangent);
    const normal = tangent.clone().rotate90();
    const normalAxis = new Axis(point, normal);
    referenceGeometry.push(
      new SnappingGeometry("Normal", normalAxis),
      new SnappingGeometry("Tangent", tangentAxis)
    );
  };
  for (let { geometry } of snappingGeometry) {
    for (let geom of geometry) {
      if (geom instanceof Anchor) {
        if (areaOfInterest.containsPoint(geom.position)) {
          // Avoid adding two axes if the handles are tangent. Only add one for
          // handleOut.
          if (!geom.handleIn.isZero() && !geom.hasTangentHandles()) {
            const tangentAxis = new Axis(geom.position, geom.handleIn);
            const normalAxis = new Axis(geom.position, geom.handleIn.clone().rotate90());
            referenceGeometry.push(
              new SnappingGeometry("Normal", normalAxis),
              new SnappingGeometry("Tangent", tangentAxis)
            );
          }
          if (!geom.handleOut.isZero()) {
            const tangentAxis = new Axis(geom.position, geom.handleOut);
            const normalAxis = new Axis(geom.position, geom.handleOut.clone().rotate90());
            referenceGeometry.push(
              new SnappingGeometry("Normal", normalAxis),
              new SnappingGeometry("Tangent", tangentAxis)
            );
          }
        }
      } else if (geom instanceof Path) {
        if (geom.anchors.length >= 2) {
          const result = geom.closestPoint(point, areaOfInterest);
          if (result) {
            const { time } = result;
            if (Number.isInteger(time)) {
              if (geom.closed || time > 0) {
                const segment = geom.segmentAtTime(time - 1);
                appendAxesForGeomAtTime(segment, 1);
              }
              if (geom.closed || time < geom.anchors.length - 1) {
                const segment = geom.segmentAtTime(time);
                appendAxesForGeomAtTime(segment, 0);
              }
            } else {
              appendAxesForGeomAtTime(geom, time);
            }
          }
        }
      } else if (geom instanceof LineSegment || geom instanceof CubicSegment) {
        const result = geom.closestPoint(point, areaOfInterest);
        if (result) {
          appendAxesForGeomAtTime(geom, result.time);
        }
      } else if (geom instanceof Axis) {
        const result = geom.closestPoint(point, areaOfInterest);
        if (result) {
          const normalAxis = new Axis(point, geom.direction.clone().rotate90());
          referenceGeometry.push(new SnappingGeometry("Perpendicular", normalAxis));
        }
      }
    }
  }

  // Add horizontal and vertical axes.
  referenceGeometry.push(
    new SnappingGeometry("Horizontal", new Axis(point, new Vec(1, 0))),
    new SnappingGeometry("Vertical", new Axis(point, new Vec(0, 1)))
  );

  return referenceGeometry;
};

const onlyGeometry = (snappingGeometry: SnappingGeometry[]) => {
  return snappingGeometry.flatMap((snapGeom) => {
    return snapGeom.geometry.filter((g) => g instanceof Geometry) as Geometry[];
  });
};

const snappingPointFromIntersection = (x: IntersectionResult) => {
  return new SnappingPoint(x.position, "", "Intersection", [
    {
      geometry: x.primitive1,
      time: x.time1,
    },
    {
      geometry: x.primitive2,
      time: x.time2,
    },
  ]);
};

export const snappingRoseAtPoint = (point: Vec, angleZero: number, angleIncrement: number) => {
  const geometry: SnappingGeometry[] = [];

  // Add horizontal and vertical axes.
  geometry.push(
    new SnappingGeometry("Horizontal", new Axis(point, new Vec(1, 0))),
    new SnappingGeometry("Vertical", new Axis(point, new Vec(0, 1)))
  );

  // Add additional angle axes. NOTE: For this to work, 180 must be an integer
  // multiple of `angleIncrement`.
  for (let angle = angleIncrement - 180; angle <= 180; angle += angleIncrement) {
    const absoluteAngle = angleZero + angle;
    if (absoluteAngle % 90 === 0) continue; // Skip horizontal and vertical axes.
    const displayPrefix = angle > 0 ? "+" : "";
    const name = `${displayPrefix}${expressionCodeForNumber(angle)}°`;
    geometry.push(new SnappingGeometry(name, new Ray(point, Vec.fromAngle(absoluteAngle))));
  }

  return geometry;
};
