import m from "mithril";

import { BoundingBox, Path, Vec } from "../geom";
import { Geometry } from "../geom/geometry/geometry";
import { stringForNumber } from "../geom/io/to-string";
import { globalState } from "../global-state";
import { ContextSelectable } from "../model/selectable";
import { classNames } from "../shared/util";

const isArray = Array.isArray;
const isObject = (obj: unknown) => obj && !isArray(obj) && typeof obj === "object";

const getObjectName = (object: object) => {
  return (object.constructor as any).displayName || object.constructor.name;
};

const getChildEntries = (object: any) => {
  if (isArray(object)) {
    return object.map((value, index) => {
      return { name: index.toFixed(0), object: value };
    });
  }
  if (isObject(object)) {
    return Object.keys(object).map((key) => {
      const value = object[key];
      return { name: key, object: value };
    });
  }
  return [];
};

const getFunctionArgumentNames = (fn: Function) => {
  const argsMatch = fn.toString().match(/^\s*(?:function\s+\w+)?\s*\(([^\)]*)\)/);
  if (argsMatch) {
    return argsMatch[1].split(",").map((arg) => arg.trim());
  }
  return [];
};

interface ObjectInspectorAttrs {
  object: any;
  name?: string;
  numberUnits?: string;
  selectable?: ContextSelectable;
}
export const ObjectInspector: m.ClosureComponent<ObjectInspectorAttrs> = () => {
  let isExpanded = false;

  return {
    view({ attrs: { name, object, numberUnits, selectable } }) {
      const childEntries = getChildEntries(object);

      if (name === undefined && childEntries.length === 0) {
        return m(OneLine, { object, numberUnits, selectable });
      }

      const expanderClassName = classNames({
        expanded: isExpanded,
        childless: childEntries.length === 0,
      });
      const toggleExpanded = () => {
        isExpanded = !isExpanded;
      };

      return m(".oi-node", [
        m(".oi-item", [
          m(".oi-expander", { onpointerdown: toggleExpanded, className: expanderClassName }),
          m(".oi-item-content", [
            name === undefined ? null : m("span.oi-name", name),
            m(OneLine, { object, numberUnits, selectable }),
          ]),
        ]),
        isExpanded
          ? m(".oi-children", [
              childEntries.map(({ name, object }) =>
                m(ObjectInspector, { name, object, numberUnits, selectable })
              ),
            ])
          : null,
      ]);
    },
  };
};

const OneLine: m.Component<ObjectInspectorAttrs> = {
  view({ attrs: { object, numberUnits, selectable } }) {
    if (isArray(object)) {
      // TODO: limit and ... it
      return m("span.oi-type-array", [
        object.map((item) => {
          return m("span.oi-type-array-item", [
            m(OneWord, { object: item, numberUnits, selectable }),
          ]);
        }),
      ]);
    }
    if (isObject(object)) {
      // TODO: limit and ... it
      return m("span.oi-type-object", [
        selectable && isLoggableGeometry(object)
          ? m(
              "span.oi-type-object-class",
              hoverHandlerAttrs(object, selectable),
              getObjectName(object)
            )
          : m("span.oi-type-object-class", getObjectName(object)),
        m("span.oi-type-object-entries", [
          Object.keys(object).map((key) => {
            const value = object[key];
            return m("span.oi-type-object-entry", [
              m("span.oi-type-object-key", key),
              m("span.oi-type-object-value", [
                m(OneWord, { object: value, numberUnits, selectable }),
              ]),
            ]);
          }),
        ]),
      ]);
    }
    return m(OneWord, { object, numberUnits, selectable });
  },
};

const OneWord: m.Component<ObjectInspectorAttrs> = {
  view({ attrs: { object, numberUnits, selectable } }) {
    if (object === undefined) return m("span.oi-type-other", "undefined");
    if (object === null) return m("span.oi-type-other", "null");
    if (typeof object === "string") return m("span.oi-type-string", JSON.stringify(object));
    if (typeof object === "number") {
      return m(OneNumber, { object, numberUnits });
    }
    if (typeof object === "function") {
      return m("span.oi-type-function", getFunctionArgumentNames(object).join(", "));
    }
    if (isArray(object)) {
      if (object.length === 0) {
        return m("span.oi-type-array", "");
      }
      return m("span.oi-type-array", "...");
    }
    if (isObject(object)) {
      if (selectable && isLoggableGeometry(object)) {
        return m(
          "span.oi-type-object-class",
          hoverHandlerAttrs(object, selectable),
          getObjectName(object)
        );
      } else {
        return m("span.oi-type-object-class", getObjectName(object));
      }
    }
    return m("span.oi-type-other", object.toString());
  },
};

interface NumberInspectorAttrs extends ObjectInspectorAttrs {
  object: number;
}
const OneNumber: m.Component<NumberInspectorAttrs> = {
  view({ attrs: { object, numberUnits } }) {
    const detailStr = object.toString();
    const shortStr = stringForNumber(object, 6);
    const isDetailed = detailStr.length > shortStr.length;
    return m(
      "span.oi-type-number",
      {
        className: classNames({ "oi-detailed": isDetailed }),
      },
      [
        m("span.oi-detail-number", detailStr),
        isDetailed && m("span.oi-short-number", shortStr),
        numberUnits && m("span.oi-number-units", " " + numberUnits),
      ]
    );
  },
};

const hoverHandlerAttrs = (
  geometry: Geometry | Vec | BoundingBox,
  selectable: ContextSelectable
) => {
  return {
    onpointerover: () => {
      const matrix = selectable.contextMatrix();
      if (geometry instanceof BoundingBox) {
        geometry = Path.fromBoundingBox(geometry);
      }
      globalState.hoveredObjectInspectorGeometry = geometry.clone().affineTransform(matrix);
    },
    onpointerout: () => {
      globalState.hoveredObjectInspectorGeometry = undefined;
    },
  };
};

type LoggableGeometry = Geometry | Vec | BoundingBox;
const isLoggableGeometry = (object: unknown): object is LoggableGeometry => {
  return Geometry.isValid(object) || Vec.isValid(object) || BoundingBox.isValid(object);
};
