import { globalEnvironmentKeys } from "../environment/global-environment";
import {
  Anchor,
  assert,
  CompoundPath,
  DEFAULT_PRECISION,
  DEFAULT_TOLERANCE,
  Fill,
  Geometry,
  Group,
  ImageFill,
  Path,
  rotateArray,
  Unit,
  units,
} from "../geom";
import { globalState } from "../global-state";
import { deviceStorage } from "../storage/device-storage";
import { sanitizeName } from "../util";
import { creationPanelState, PAGE_PROJECT } from "../view/components-query";
import { allBuiltins } from "./builtin";
import { defaultCodeComponentJsCode } from "./builtin-defaults";
import {
  AnchorDefinition,
  CompoundPathDefinition,
  FillDefinition,
  GroupDefinition,
  PathDefinition,
  StrokeDefinition,
} from "./builtin-primitives";
import { CanvasPoint } from "./canvas-point";
import { CodeComponent, Component } from "./component";
import { DependencyGraph } from "./dependency-graph";
import { Documentation, makeInitialDoc } from "./documentation";
import { Element } from "./element";
import { Expression } from "./expression";
import { expressionCodeForValue } from "./expression-code";
import {
  CodeComponentFocus,
  CodeEditorSelection,
  ComponentFocus,
  DocumentationFocus,
  ExpressionFocus,
  SettingsFocus,
} from "./focus";
import { Instance } from "./instance";
import { InstanceDefinition } from "./instance-definition";
import { ModelObject } from "./model-object";
import { modelObjectColors, modelObjectUsesProFont } from "./model-object-reducer";
import { Modifier } from "./modifier";
import {
  DefinitionNameGenerator,
  ElementNameGenerator,
  NameHash,
  ParameterNameGenerator,
} from "./name-generator";
import { Node } from "./node";
import { Parameter, ParameterFolder } from "./parameter";
import { registerClass } from "./registry";
import {
  Selectable,
  SelectableComponentParameter,
  SelectableInstance,
  SelectableNode,
  SelectableParameter,
  SelectableSegment,
} from "./selectable";
import { Selection } from "./selection";
import {
  contextMatrixForNode,
  contextTransformMatrixForNode,
  transformBoxForSelection,
  transformOriginForNode,
} from "./transform-utils";
import { NodeTransformer } from "./transformer";

export class ProjectSettings extends ModelObject {
  units: Unit = "in";
  gridDivisions: "decimal" | "fractional" = "decimal";

  hairlineStrokeWidth = 0.01;
  tolerance = DEFAULT_TOLERANCE;
  precision = DEFAULT_PRECISION;

  rasterizeExportedImages = true;
  rasterizeExportedImagesPixelsPerInch = 300;

  materialKeys() {
    return [
      "units",
      "gridDivisions",
      "hairlineStrokeWidth",
      "tolerance",
      "precision",
      "rasterizeExportedImages",
      "rasterizeExportedImagesPixelsPerInch",
    ];
  }
}
registerClass("ProjectSettings", ProjectSettings);

export class Project extends InstanceDefinition {
  /**
   * The ID for this project in Cuttle's database. Typically populated from
   * Storage on creation.
   */
  projectId = "";

  settings = new ProjectSettings();

  components: (Component | CodeComponent)[];
  modifiers: Modifier[] = [];

  documentation: Documentation;

  focus: ComponentFocus | CodeComponentFocus | DocumentationFocus | SettingsFocus;
  expressionFocus: ExpressionFocus | null = null;
  selection = new Selection();

  // TODO: Move hoveredItem out of project, as it is ephemeral state and doesn't
  // need to be saved.
  hoveredItem: Selectable | null = null;

  expandedNodes: Node[] = [];
  instancesEditingCode: Instance[] = [];

  private _transformCenter: CanvasPoint | null = null;

  isTransformBoxEnabled = true;
  isGridEnabled = true;

  materialKeys() {
    return [
      // NOTE: We're not storing all InstanceDefinition keys here, only the
      // Project relevant stuff. We should probably move that stuff out of
      // InstanceDefinition...
      "name",
      "parameters",
      "parameterFolders",

      // Project
      "components",
      "modifiers",
      "documentation",
      "settings",
    ];
  }

  /**
   * Project specifically contains some UI state that we want to store in the
   * editHistory. These keys are not included in project saves, only in the edit
   * history.
   */
  immaterialKeys() {
    return [
      "focus",
      "selection",

      "expandedNodes",
      "instancesEditingCode",

      "_transformCenter",

      "isTransformBoxEnabled",
      "isGridEnabled",
    ];
  }

  constructor() {
    super();

    // Note: To get and set the project name, use
    // `globalState.storage.getProjectName()` and
    // `globalState.storage.setProjectName(newName)`.

    // Project is initialized during globalState initialization, so we can't
    // read globalStorage here. Directly import and read deviceStorage. Defaults
    // to "in" in both Project and DeviceStorage.
    this.settings.units = deviceStorage.projectUnits;

    const component = new Component(new Element(new Instance(GroupDefinition)));
    component.name = "Component A";

    this.components = [component];
    this.focus = new ComponentFocus(component, new Node(null, component.element));

    this.documentation = new Documentation("Read Me", makeInitialDoc());
  }

  topNode() {
    if (this.focus instanceof ComponentFocus) {
      return new Node(null, this.focus.component.element);
    }
    return undefined;
  }

  /**
   * @returns true if this project is the one contained in globalState. This
   * should be used very sparingly, but sometimes changing things in a project,
   * like the focus, requires a side-effect. In those rare cases the side effect
   * should only be performed when the project is the global one.
   */
  isGlobalProject() {
    return this === globalState.project;
  }

  focusItem(item: Component | CodeComponent | Documentation | ProjectSettings) {
    if (this.isGlobalProject()) {
      globalState.activateTool("Select");
    }

    if (item instanceof Documentation) {
      this.focus = new DocumentationFocus(item);
    } else if (item instanceof CodeComponent) {
      this.focus = new CodeComponentFocus(item);
    } else if (item instanceof Component) {
      this.focus = new ComponentFocus(item, new Node(null, item.element));
    } else if (item instanceof ProjectSettings) {
      this.focus = new SettingsFocus(item);
    }

    this.blurExpression();
    this.clearSelection();
    this.hoveredItem = null;
  }
  ensureValidFocusedComponent() {
    // When a project is created from a material-subset snapshot it will still
    // have the default component focus. For this reason we also need to check
    // that the focused component actually exists.
    if (
      (this.focus instanceof ComponentFocus || this.focus instanceof CodeComponentFocus) &&
      this.components.includes(this.focus.component)
    ) {
      return;
    }
    this.focusItem(this.mainComponent());
  }
  hasFocusedComponent() {
    return this.focus instanceof ComponentFocus || this.focus instanceof CodeComponentFocus;
  }
  focusedComponent() {
    if (this.focus instanceof ComponentFocus || this.focus instanceof CodeComponentFocus) {
      return this.focus.component;
    }
    return undefined;
  }
  exportComponent() {
    return this.focusedComponent() ?? this.mainComponent();
  }
  mainComponent() {
    const firstDocComponentID = this.documentation.firstComponentEmbedId();
    if (firstDocComponentID) {
      const firstDocComponent = this.components.find((c) => c.id === firstDocComponentID);
      if (firstDocComponent) {
        return firstDocComponent;
      }
    }
    const firstMutableComponent = this.components.find((c) => !c.isImmutable);
    if (firstMutableComponent) {
      return firstMutableComponent;
    }
    // If we reach this point there are no mutable components in the project,
    // so we should make a new one to be able to focus it.
    console.warn("No mutable components found, creating a new one.");
    return this.createComponent();
  }

  componentById(componentId: string) {
    return this.components.find((c) => c.id === componentId);
  }
  componentByName(name: string) {
    return this.components.find((c) => c.name === name);
  }
  modifierByName(name: string) {
    return this.modifiers.find((c) => c.name === name);
  }

  focusedDocumentation() {
    if (this.focus instanceof DocumentationFocus) {
      return this.focus.documentation;
    }
    return undefined;
  }
  createComponent(name?: string, insertionIndex?: number) {
    const component = new Component(new Element(new Instance(GroupDefinition)));
    component.projectId = this.projectId;
    component.name = name ?? "Component A";

    this.insertComponent(component, insertionIndex);

    return component;
  }
  insertComponent(component: Component | CodeComponent, insertionIndex?: number) {
    // Ensure the component's name is unique.
    const definitionNameGenerator = this.makeDefinitionNameGenerator();
    component.name = definitionNameGenerator.generate(component.name);

    if (insertionIndex !== undefined) {
      this.components.splice(insertionIndex, 0, component);
    } else {
      this.components.push(component);
    }
  }
  removeComponent(component: Component | CodeComponent) {
    // TODO: deal with dependencies on the component we're removing?
    let index = this.components.indexOf(component);
    this.components.splice(index, 1);

    // Ensure we have at least one component
    if (this.components.length === 0) {
      this.createComponent();
    }

    const nextMutableComponentToFocus = (index: number) => {
      let component: Component | CodeComponent;
      for (let i = index; i < this.components.length; ++i) {
        component = this.components[i];
        if (!component.isImmutable) return component;
      }
      for (let i = index - 1; i >= 0; --i) {
        component = this.components[i];
        if (!component.isImmutable) return component;
      }
    };

    // Ensure we have a focused component in the project.
    const focusedComponent = this.focusedComponent();
    if (focusedComponent === component) {
      const nextComponent = nextMutableComponentToFocus(index);
      if (nextComponent) {
        this.focusItem(nextComponent);
      } else {
        this.focusItem(this.createComponent());
      }
    }

    const graph = new DependencyGraph(this);
    graph.removeUnusedImmutableComponents();

    this.ensureProjectDataExists();
  }
  duplicateComponent(component: Component | CodeComponent) {
    const newComponent = component.clone();

    const insertionIndex = this.components.indexOf(component) + 1;
    this.insertComponent(newComponent, insertionIndex);

    return newComponent;
  }

  createVariationOfInstance(instance: Instance) {
    let { definition } = instance;

    if (definition instanceof Modifier) {
      definition = this.duplicateModifier(definition);
    } else if (definition instanceof Component || definition instanceof CodeComponent) {
      definition = this.duplicateComponent(definition);
    }

    // Creating a variation of a built-in makes it editable.
    definition.isImmutable = false;

    // Swapping out the instance definition for the duplicated one, keeping
    // the existing args and position in element.modifiers
    instance.definition = definition;

    if (definition instanceof CodeComponent || definition instanceof Modifier) {
      this.setEditingCode(instance, true);
    }
    // Switch panel to project, to be able to rename a variant
    creationPanelState.goTo(PAGE_PROJECT);
    globalState.autoSelectForRename = definition;
    this.ensureProjectDataExists();
  }

  setDefinitionIsPassThrough(definition: Component | CodeComponent, isPassThrough: boolean) {
    definition.isPassThrough = isPassThrough;
    // Remove transforms for existing instances of that component
    if (isPassThrough) {
      this.allElements().forEach((element) => {
        if (element.base.definition === definition) {
          element.transform = null;
        }
      });
    }
  }

  createModifier(name?: string) {
    const definitionNameGenerator = this.makeDefinitionNameGenerator();
    if (name === undefined) name = "Modifier A";
    name = definitionNameGenerator.generate(name);
    const modifier = new Modifier();
    modifier.projectId = this.id;
    modifier.name = name;
    modifier.code.jsCode = `console.log(input);
return input;`;
    this.modifiers.push(modifier);
    return modifier;
  }
  duplicateModifier(modifier: Modifier) {
    const newModifier = modifier.clone();

    const definitionNameGenerator = this.makeDefinitionNameGenerator();
    newModifier.name = definitionNameGenerator.generate(modifier.name);

    this.modifiers.push(newModifier);

    return newModifier;
  }

  removeModifier(modifier: Modifier) {
    const index = this.modifiers.indexOf(modifier);
    assert(index >= 0, "Modifier not found.");
    this.modifiers.splice(index, 1);
    this.ensureProjectDataExists();
  }

  createCodeComponent(name?: string, insertionIndex?: number) {
    const definitionNameGenerator = this.makeDefinitionNameGenerator();
    if (name === undefined) name = "Code Component A";
    name = definitionNameGenerator.generate(name);
    const codeComponent = new CodeComponent();
    codeComponent.projectId = this.id;
    codeComponent.name = name;
    codeComponent.code.jsCode = defaultCodeComponentJsCode;
    if (insertionIndex !== undefined) {
      this.components.splice(insertionIndex, 0, codeComponent);
    } else {
      this.components.push(codeComponent);
    }
    return codeComponent;
  }

  private updateExpandedNodes() {
    assert(this.focus instanceof ComponentFocus);
    this.expandUpToNode(this.focus.node);
    if (!this.focus.node.isPath()) {
      this.expandNode(this.focus.node);
    }
    const selection = this.selection.allNodes();
    for (let item of selection.items) {
      this.expandUpToNode(item.node);
    }
  }

  // In the outline, we always want the focused node to be reachable and
  // expanded, and every selected node to be reachable.
  focusNode(node: Node) {
    if (this.focus instanceof ComponentFocus) {
      this.focus.node = node;
      this.blurExpression();
    }
    this.updateExpandedNodes();
    this.hoveredItem = null;
  }

  focusExpression(expression: Expression, expressionSelections?: CodeEditorSelection[] | "all") {
    this.expressionFocus = new ExpressionFocus(expression, expressionSelections);
  }
  blurExpression() {
    this.expressionFocus = null;
  }

  private updateFocusedNodeFromSelection() {
    const commonAncestor = this.selection.allNodes().commonAncestorNode();
    if (commonAncestor) {
      this.focusNode(commonAncestor);
    }
  }

  selectItems(items: Selectable[]) {
    this.selection = new Selection(items);
    this.updateSelection();
  }
  selectItem(item: Selectable) {
    this.selectItems([item]);
  }
  toggleSelectItems(items: Selectable[], selected?: boolean) {
    for (let item of items) {
      this.selection.toggle(item, selected);
    }
    this.updateSelection();
  }
  toggleSelectItem(item: Selectable, selected?: boolean) {
    this.selection.toggle(item, selected);
    this.updateSelection();
  }
  clearSelection() {
    this.selection.clear();
    this.updateSelection();
  }

  updateSelection() {
    this.updateFocusedNodeFromSelection();
    this.clearOverrideTransformCenter();

    // Clear the browser's text selection when the project selection is changed.
    // Stray text selections can interfere with the browser's copy command.
    window.getSelection()?.removeAllRanges();
  }

  // TODO: Node specific selection methods should be removed
  selectNode(node: Node) {
    this.selectItem(new SelectableNode(node));
  }
  selectNodes(nodes: Node[]) {
    this.selectItems(nodes.map((node) => new SelectableNode(node)));
  }
  toggleSelectNode(node: Node, selected?: boolean) {
    this.toggleSelectItem(new SelectableNode(node), selected);
  }

  isItemHovered(item: Selectable) {
    return this.hoveredItem !== null && this.hoveredItem.equals(item);
  }

  isNodeHovered(node: Node) {
    return this.hoveredItem instanceof SelectableNode && node.equalsNode(this.hoveredItem.node);
  }
  isNodeFocused(node: Node) {
    return this.focus instanceof ComponentFocus && this.focus.node.equalsNode(node);
  }
  isNodeVisibleForEditing(node: Node): boolean {
    if (node.parent === null) return true;
    if (
      this.isNodeFocused(node) ||
      this.isNodeHovered(node) ||
      this.selection.isNodeDirectlySelected(node)
    ) {
      return true;
    }
    return node.source.isVisible && this.isNodeVisibleForEditing(node.parent);
  }
  isNodeSelectable(node: Node) {
    return this.focus instanceof ComponentFocus && node.hasParentEqualToNode(this.focus.node);
  }
  isElementHovered(element: Element) {
    return this.hoveredItem instanceof SelectableNode && this.hoveredItem.node.source === element;
  }

  insertionPoint(options?: { forbidParentPath?: boolean; forbidCollapsedParentPath?: boolean }) {
    let insertionParent: Node | undefined;
    let insertionIndex: number | undefined;
    if (this.focus instanceof ComponentFocus) {
      const selection = this.selection.allNodes().topNodes().mutables().sortInDocumentOrder();
      if (selection.isEmpty()) {
        insertionParent = this.focus.node;
        while (insertionParent.isImmutable() || insertionParent.isImmutableChildren()) {
          insertionParent = insertionParent.parent!; // The top node will never be immutable, so it's safe to assume this parent is non-null
        }
      } else {
        const lastSelectedNode = selection.items[selection.items.length - 1].node;
        insertionParent = lastSelectedNode.parent!;
        insertionIndex = lastSelectedNode.indexInParent() + 1;
      }
      if (
        options &&
        insertionParent.isPath() &&
        (options.forbidParentPath ||
          (options.forbidCollapsedParentPath && !this.isNodeExpanded(insertionParent)))
      ) {
        insertionParent = insertionParent.parent!; // The top node will not be a path
        insertionIndex = insertionParent.indexInParent() + 1;
      }
    }
    return { insertionParent, insertionIndex };
  }

  interactableNodesToShow() {
    const selection = this.selection.directlySelectedNodes();
    if (this.focus instanceof ComponentFocus) {
      const showInvisibleNodes = !this.focus.node.isVisible();
      for (let node of this.focus.node.childNodes()) {
        if (showInvisibleNodes || node.isVisible()) {
          selection.addUnique(new SelectableNode(node));
        }
      }
    }
    return selection.sortInDocumentOrder();
  }

  isNodeExpanded(node: Node) {
    if (node.parent === null) return true;
    return this.expandedNodes.some((n) => n.equalsNode(node));
  }
  isNodeReachable(node: Node): boolean {
    if (node.parent === null) return true;
    if (this.isNodeExpanded(node.parent)) return this.isNodeReachable(node.parent);
    return false;
  }
  toggleExpandedNode(node: Node) {
    if (this.isNodeExpanded(node)) {
      this.collapseNode(node);
    } else {
      this.expandNode(node);
    }
  }
  expandNode(node: Node) {
    if (node.parent === null) return;
    const index = this.expandedNodes.findIndex((n) => n.equalsNode(node));
    if (index === -1) {
      this.expandedNodes.push(node);
    }
  }
  collapseNode(node: Node) {
    assert(this.focus instanceof ComponentFocus);

    if (node.parent === null) return;

    const index = this.expandedNodes.findIndex((n) => n.equalsNode(node));
    if (index === -1) return;
    this.expandedNodes.splice(index, 1);

    // Move up focusedNode until it is reachable
    let focusedNode = this.focus.node;
    while (focusedNode.parent && !this.isNodeReachable(focusedNode)) {
      focusedNode = focusedNode.parent;
    }
    if (focusedNode !== this.focus.node) {
      this.focusNode(focusedNode);
    }
  }
  expandUpToNode(node: Node) {
    if (node.parent === null) return;
    if (!node.isAnchor() || !node.parent.isPath()) {
      this.expandNode(node.parent);
    }
    this.expandUpToNode(node.parent);
  }
  /**
   * Will find any expanded state whose path begins with oldNode and set that
   * expanded state for a path beginning with newNode. Used when we move nodes
   * around in the outline and want to preserve expanded state.
   */
  transferExpandedState(oldNode: Node, newNode: Node) {
    const newExpandedNodes: Node[] = [];
    this.expandedNodes.forEach((expandedNode) => {
      if (oldNode.equalsNode(expandedNode)) {
        newExpandedNodes.push(newNode);
      } else if (oldNode.isAncestorOfNode(expandedNode)) {
        const oldPath = oldNode.sourcePath();
        const newPath = newNode.sourcePath();
        const expandedNodePath = expandedNode.sourcePath();
        expandedNodePath.splice(0, oldPath.length, ...newPath);
        const newExpandedNode = Node.fromSourcePath(expandedNodePath);
        if (newExpandedNode !== null) {
          newExpandedNodes.push(newExpandedNode);
        }
      }
    });
    for (let newExpandedNode of newExpandedNodes) {
      this.expandNode(newExpandedNode);
    }
  }

  isEditingCode(instance: Instance) {
    return this.instancesEditingCode.includes(instance);
  }
  setEditingCode(instance: Instance, isEditing: boolean) {
    const index = this.instancesEditingCode.indexOf(instance);
    if (isEditing && index === -1) {
      this.instancesEditingCode.push(instance);
    } else if (!isEditing && index !== -1) {
      this.instancesEditingCode.splice(index, 1);
    }
  }

  setOverrideTransformCenter(transformCenter: CanvasPoint) {
    this._transformCenter = transformCenter;
  }
  clearOverrideTransformCenter() {
    this._transformCenter = null;
  }
  hasOverrideTransformCenter() {
    return this._transformCenter !== null;
  }

  transformCenter() {
    if (this._transformCenter !== null) {
      return this._transformCenter;
    }
    const nodesSelection = this.selection.allNodes();
    if (nodesSelection.isSingle()) {
      const node = nodesSelection.items[0].node;
      // Only return origins for nodes that already have a transform.
      // Skip pass-through nodes as they cannot have transforms.
      if (node.source.transform?.isEnabled && !node.source.base.definition.isPassThrough) {
        const contextTransformMatrix = contextTransformMatrixForNode(node);
        const origin = transformOriginForNode(node).clone().affineTransform(contextTransformMatrix);
        return new CanvasPoint(origin);
      }
    }
    const { boundingBox, boundingBoxTransform } = transformBoxForSelection(this.selection);
    if (boundingBox?.isFinite()) {
      return new CanvasPoint(boundingBox.center().affineTransform(boundingBoxTransform));
    }
    return undefined;
  }

  focusedComponentParametersToShow() {
    const component = this.focusedComponent();
    assert(component);
    return new Selection(
      component.parameters.map(
        (parameter) => new SelectableComponentParameter(component, parameter)
      )
    );
  }
  selectionParametersToShow() {
    return this.selection.allParametersToShow();
  }

  isParameterDerived(definition: InstanceDefinition, parameter: Parameter) {
    return parameter.expression.compiled().references.some((ref) => {
      return (
        definition.hasParameterWithName(ref.name) ||
        (definition !== this && this.hasParameterWithName(ref.name))
      );
    });
  }
  isParameterVisible(definition: InstanceDefinition, parameter: Parameter) {
    return !parameter.hidden && !this.isParameterDerived(definition, parameter);
  }
  definitionHasVisibleParameter(definition: InstanceDefinition) {
    return definition.allParameters().some((param) => this.isParameterVisible(definition, param));
  }

  makeElementNameGenerator() {
    const names: NameHash = {};
    for (let element of this.allElements()) {
      names[element.name] = true;
    }
    return new ElementNameGenerator(names);
  }
  makeDefinitionNameGenerator() {
    const names: NameHash = {};
    for (const builtin of allBuiltins) {
      names[builtin.name] = true;
    }
    for (const component of this.components) {
      names[component.name] = true;
    }
    for (const modifier of this.modifiers) {
      names[modifier.name] = true;
    }
    if (this.documentation) {
      names[this.documentation.name] = true;
    }
    for (const envKey of globalEnvironmentKeys) {
      names[envKey] = true;
    }
    return new DefinitionNameGenerator(names);
  }
  makeParameterNameGenerator(parameters: Parameter[]) {
    const names: NameHash = {};
    for (let parameter of parameters) {
      names[parameter.name] = true;
    }
    for (let envKey of globalEnvironmentKeys) {
      names[envKey] = true;
    }
    return new ParameterNameGenerator(names);
  }

  allElements() {
    const elements: Element[] = [];
    const recurse = (element: Element) => {
      elements.push(element);
      element.children.forEach(recurse);
    };
    for (let component of this.components) {
      if (component instanceof Component) {
        recurse(component.element);
      }
    }
    return elements;
  }
  allInstances() {
    return this.allElements().flatMap((element) => element.allInstances());
  }

  usesProFont() {
    return modelObjectUsesProFont(this);
  }

  allColors() {
    return modelObjectColors(this);
  }

  renameElement(
    element: Element,
    newName: string,
    elementNameGenerator = this.makeElementNameGenerator()
  ) {
    const graph = new DependencyGraph(this);
    graph.renameElement(element, newName, elementNameGenerator);
  }

  renameDefinition(definition: Component | CodeComponent | Modifier, newName: string) {
    const definitionNameGenerator = this.makeDefinitionNameGenerator();
    const elementNameGenerator = this.makeElementNameGenerator();
    const graph = new DependencyGraph(this);
    graph.renameDefinition(definition, newName, definitionNameGenerator, elementNameGenerator);
  }

  createParameter(definition: InstanceDefinition, jsCode: string, desiredName: string = "p1") {
    // Ensure name is unique.
    const parameterNameGenerator = this.makeParameterNameGenerator(definition.allParameters());
    const name = parameterNameGenerator.generate(desiredName);

    const parameter = new Parameter(name, jsCode);
    definition.parameters.push(parameter);
    return parameter;
  }

  moveComponentParameterToProject(selectable: SelectableComponentParameter) {
    const focusedComponent = globalState.project.focusedComponent();
    if (!focusedComponent) return;

    const { component, parameter } = selectable;
    assert(component instanceof Component || component instanceof CodeComponent);

    let parameterName = parameter.name;

    // Ensure name is unique to project.
    if (this.hasParameterWithName(parameter.name)) {
      const parameterNameGenerator = this.makeParameterNameGenerator(this.parameters);
      parameterName = parameterNameGenerator.generate(parameter.name);
      // Ensure this new name is also unique to the component, and update references before moving.
      this.renameDefinitionParameter(component, parameter, parameterName);
      parameterName = parameter.name;
    }

    // Remove parameter from component.
    this.removeDefinitionParameter(component, parameter);

    // Add parameter to project.
    const newParameter = this.createParameter(this, parameter.expression.jsCode, parameterName);
    if (parameter.interface) {
      newParameter.interface = parameter.interface.clone();
    }

    globalState.isProjectParameterInspectorOpen = true;
    globalState.autoSelectForRename = newParameter;
  }

  removeDefinitionParameter(definition: InstanceDefinition, parameter: Parameter) {
    definition.removeParameter(parameter);

    const { name } = parameter;

    // Remove arg for elements that have the definition.
    for (let instance of this.allInstances()) {
      if (instance.definition === definition) {
        if (instance.args.hasOwnProperty(name)) {
          delete instance.args[name];
        }
      }
    }

    // Clean up any references to deleted parameters.
    this.ensureProjectDataExists();

    // We won't do anything about references to the parameter.
    // TODO: Deleted references should be replaced with their literal values
  }
  removeDefinitionParameterFolder(definition: InstanceDefinition, folder: ParameterFolder) {
    for (let parameter of folder.parameters) {
      this.removeDefinitionParameter(definition, parameter);
    }
    const index = definition.parameterFolders.indexOf(folder);
    definition.parameterFolders.splice(index, 1);
  }

  renameDefinitionParameter(definition: InstanceDefinition, parameter: Parameter, newName: string) {
    const parameterNameGenerator = this.makeParameterNameGenerator(definition.allParameters());
    const graph = new DependencyGraph(this);
    graph.renameParameter(parameter, newName, parameterNameGenerator);

    // Parameters starting with "_" (such as "_filename") are magic and marked
    // hidden by default.
    if (parameter.name.startsWith("_")) {
      parameter.hidden = true;
    }

    // Clear out any other references to the renamed parameter, such as the
    // hovered item.
    this.ensureProjectDataExists();
  }

  createRepeatIndexVariable(selectable: SelectableInstance) {
    this.renameRepeatIndexVariable(selectable, "rep");
  }

  renameRepeatIndexVariable(selectable: SelectableInstance, newName: string) {
    const graph = new DependencyGraph(this);
    graph.renameRepeatIndexVariable(selectable.instance, newName);
  }

  contextComponentForNode(node: Node) {
    while (node.parent) {
      node = node.parent;
      const definition = node.source.base.definition;
      if (definition instanceof Component) return definition;
    }
    return this.focusedComponent();
  }

  extractAsComponent(nodes: Node[]) {
    if (nodes.length === 0) return;

    const component = this.createComponent();
    const contextComponent = this.contextComponentForNode(nodes[0]);
    if (!contextComponent) return;

    // Ensure all nodes have the same contextNode, otherwise we can't thread
    // parameters.
    if (nodes.some((node) => contextComponent !== this.contextComponentForNode(node))) {
      console.warn("Cannot extract as component. Nodes all need to be in the same context.");
      return;
    }

    const contextParameters = contextComponent.allParameters();

    // Find which of contextComponent's parameters we'll need to thread.
    // threadParameters will be a subset of contextComponent's parameters.
    const threadParameters: Parameter[] = [];
    const examineExpression = (expression: Expression) => {
      expression.compiled().references.forEach((reference) => {
        const sanitizedName = reference.name;
        if (threadParameters.some((parameter) => parameter.sanitizedName() === sanitizedName)) {
          return; // Already found the name.
        }
        for (const parameter of contextParameters) {
          if (parameter.sanitizedName() === sanitizedName) {
            threadParameters.push(parameter);
            // Follow references in this parameter recursively.
            examineExpression(parameter.expression);
          }
        }
      });
    };
    const recurse = (element: Element) => {
      element.allExpressions().forEach(examineExpression);
      element.children.forEach(recurse);
    };
    for (let node of nodes) {
      recurse(node.source);
    }

    // Sort threadParameters in the same order as contextComponent's parameters.
    threadParameters.sort((a, b) => {
      const aIndex = contextParameters.indexOf(a);
      const bIndex = contextParameters.indexOf(b);
      return aIndex - bIndex;
    });

    // Set up parameters on the new component that mirror the parameters we're
    // threading.
    component.parameters = threadParameters.map((threadParameter) => threadParameter.clone());

    // Set up children by moving Elements.
    component.element = new Element(new Instance(GroupDefinition));
    component.element.children = nodes.map((node) => node.source);

    // Create an element whose definition is our new component.
    const element = this.createElementWithDefinition(component);

    // Set up element's args so that they're threaded.
    threadParameters.forEach((threadParameter) => {
      if (threadParameter.expression.isLiteral()) {
        const name = threadParameter.name;
        const sanitizedName = threadParameter.sanitizedName();
        element.base.args[name] = new Expression(sanitizedName);
      }
    });

    // Splice to remove old Nodes and replace them with element pointing at
    // extracted component.
    const { insertionParent, insertionIndex } = this.insertionPoint();
    this.spliceNodes(nodes, [element], insertionParent, insertionIndex);
  }

  ensureUniqueNames(element: Element, elementNameGenerator?: ElementNameGenerator) {
    if (!elementNameGenerator) elementNameGenerator = this.makeElementNameGenerator();
    const newName = elementNameGenerator.preview(element.name);
    this.renameElement(element, newName, elementNameGenerator);
    for (let childElement of element.children) {
      this.ensureUniqueNames(childElement, elementNameGenerator);
    }
  }

  createElementWithDefinition(
    definition: InstanceDefinition,
    elementNameGenerator?: ElementNameGenerator
  ) {
    if (!elementNameGenerator) elementNameGenerator = this.makeElementNameGenerator();
    const element = new Element(new Instance(definition));
    const name = elementNameGenerator.generate(definition.name + " 1");
    element.name = name;
    return element;
  }
  bakeGeometry(geometry: Geometry, elementNameGenerator?: ElementNameGenerator) {
    const expressionForValue = (val: unknown) => new Expression(expressionCodeForValue(val));
    const bakeStylesOntoElement = (geometry: Path | CompoundPath, element: Element) => {
      // TODO: Don't set args if they're the default value.
      if (geometry.fill instanceof Fill) {
        element.fill = new Instance(FillDefinition);
        element.fill.args.color = expressionForValue(geometry.fill.color);
      } else if (geometry.fill instanceof ImageFill) {
        element.fill = new Instance(FillDefinition);
        element.fill.args.type = new Expression(`"image"`);
        element.fill.args.image = expressionForValue(geometry.fill.image);
        element.fill.args.transform = expressionForValue(geometry.fill.transform);
      }
      if (geometry.stroke) {
        element.stroke = new Instance(StrokeDefinition);
        element.stroke.args.color = expressionForValue(geometry.stroke.color);
        element.stroke.args.hairline = expressionForValue(geometry.stroke.hairline);
        element.stroke.args.width = expressionForValue(geometry.stroke.width);
        element.stroke.args.alignment = expressionForValue(geometry.stroke.alignment);
        element.stroke.args.cap = expressionForValue(geometry.stroke.cap);
        element.stroke.args.join = expressionForValue(geometry.stroke.join);
        element.stroke.args.miterLimit = expressionForValue(geometry.stroke.miterLimit);
      }
    };

    if (!elementNameGenerator) elementNameGenerator = this.makeElementNameGenerator();
    if (geometry instanceof Anchor) {
      const element = this.createElementWithDefinition(AnchorDefinition, elementNameGenerator);
      element.base.args.position = expressionForValue(geometry.position);
      if (!geometry.handleIn.isZero()) {
        element.base.args.handleIn = expressionForValue(geometry.handleIn);
      }
      if (!geometry.handleOut.isZero()) {
        element.base.args.handleOut = expressionForValue(geometry.handleOut);
      }
      if (geometry.hasTangentHandles()) {
        element.base.args.handleConstraint = expressionForValue("tangent");
      } else {
        element.base.args.handleConstraint = expressionForValue("free");
      }
      return element;
    } else if (geometry instanceof Path) {
      const element = this.createElementWithDefinition(PathDefinition, elementNameGenerator);
      element.children = [];
      for (let anchor of geometry.anchors) {
        const bakedAnchor = this.bakeGeometry(anchor, elementNameGenerator);
        if (bakedAnchor) {
          element.children.push(bakedAnchor);
        }
      }
      element.base.args.closed = expressionForValue(geometry.closed);
      bakeStylesOntoElement(geometry, element);
      return element;
    } else if (geometry instanceof CompoundPath) {
      const element = this.createElementWithDefinition(
        CompoundPathDefinition,
        elementNameGenerator
      );
      element.children = [];
      for (let path of geometry.paths) {
        const bakedPath = this.bakeGeometry(path, elementNameGenerator);
        if (bakedPath) {
          // Convert to Paths should clear out the styles on paths within compound paths
          bakedPath.fill = null;
          bakedPath.stroke = null;
          element.children.push(bakedPath);
        }
      }
      bakeStylesOntoElement(geometry, element);
      return element;
    } else if (geometry instanceof Group) {
      const element = this.createElementWithDefinition(GroupDefinition, elementNameGenerator);
      element.children = [];
      for (let item of geometry.items) {
        const bakedItem = this.bakeGeometry(item, elementNameGenerator);
        if (bakedItem) {
          element.children.push(bakedItem);
        }
      }
      return element;
    }
    return undefined;
  }

  selectionElements() {
    const selection = this.selection.sortInDocumentOrder();
    if (selection.hasAny(SelectableSegment)) {
      // When copying segments we create a dummy path for each chain of selected
      // segments. These won't be added to the project and are only created for
      // the puropose of making a snapshot.
      return duplicatePathElementsFromSegmentsSelection(selection);
    }
    return selection.allNodes().items.map((item) => item.node.source);
  }
  selectionPortableProjectData() {
    // This lets you copy elements, directly selected modifier badges, and
    // modifiers via their parameters. When you paste, it applies the modifiers
    // if you have a selection. Otherwise it pastes the whole element.
    const graph = new DependencyGraph(this);
    return graph.portableProjectDataForSelection(this.selection);
  }
  componentPortableProjectData(component: Component | CodeComponent) {
    const graph = new DependencyGraph(this);
    return graph.portableProjectDataForComponent(component);
  }

  duplicateElement(element: Element) {
    const duplicateElement = element.clone();
    this.ensureUniqueNames(duplicateElement);
    return duplicateElement;
  }

  duplicateSelection() {
    const selection = this.selection.mutables().sortInDocumentOrder();

    if (selection.hasAny(SelectableSegment)) {
      const duplicatedElements = duplicatePathElementsFromSegmentsSelection(selection);
      for (let element of duplicatedElements) {
        this.ensureUniqueNames(element);
      }

      const { insertionParent, insertionIndex } = this.insertionPoint({ forbidParentPath: true });
      const duplicatedNodes = this.spliceNodes(
        [],
        duplicatedElements,
        insertionParent,
        insertionIndex
      );

      this.selectNodes(duplicatedNodes);
    } else {
      // TODO: We allow duplicating inside of collapsed paths here, whereas
      // pasting will insert outside collapsed paths. This feels like the right
      // thing for now, but we may want to revisit this decision once
      // copy/pasting segments is implemented.

      const duplicatedElements = selection.allNodes().items.map((item) => {
        return this.duplicateElement(item.node.source);
      });

      const { insertionParent, insertionIndex } = this.insertionPoint();
      const duplicatedNodes = this.spliceNodes(
        [],
        duplicatedElements,
        insertionParent,
        insertionIndex
      );

      this.selectNodes(duplicatedNodes);
    }
  }

  deleteSelection() {
    // We'll remove paths that we leave empty.
    const removeIfEmptyPath = (pathNode: Node | null) => {
      if (pathNode?.isPath()) {
        if (pathNode.childCount() === 0) {
          this.spliceNodes([pathNode]);
        }
      }
    };

    const selection = this.selection.mutables().sortInDocumentOrder();

    // Note: Path has special behavior when one of its child nodes is deleted.
    // - First or last node: Select the adjacent node. This allows the user to
    //   hit delete multiple times to delete anchors from the end of a path.
    // - Last remaining node: Empty paths are also removed.
    if (selection.isSingle()) {
      const item = selection.items[0];
      if (item instanceof SelectableNode) {
        const node = item.node;
        if (node.parent?.isPath()) {
          const count = node.parent.childCount();
          if (count > 1) {
            // If an endpoint is deleted select the closest adjacent anchor.
            const index = node.indexInParent();
            if (index === 0) {
              this.selectNode(node.parent.childNodeAtIndex(index + 1));
            } else if (index === count - 1) {
              this.selectNode(node.parent.childNodeAtIndex(count - 2));
            }
          }
        }
      }
    }

    if (selection.hasAny(SelectableSegment)) {
      // If a segment is selected, only delete segments. Other selected items
      // will be left in the selection after this operation and can be deleted
      // by calling this function again.
      for (let i = 0; i < selection.items.length; ++i) {
        const item = selection.items[i];
        if (!this.selectableExistsAndIsValid(item)) continue;

        if (item instanceof SelectableSegment) {
          // Splicing anchors into new path nodes will invalidate any subsequent
          // items in the selection. This custom splice function will iterate
          // over the selection and replace any nodes that refer to anchor
          // elements that we're inserting.
          const splice = (
            nodesToRemove: Node[],
            elementsToInsert?: Element[],
            insertionParent?: Node,
            insertionIndex?: number
          ) => {
            const insertedNodes = this.spliceNodes(
              nodesToRemove,
              elementsToInsert,
              insertionParent,
              insertionIndex
            );

            // Replace any removed nodes further into the selection
            if (elementsToInsert) {
              for (let j = i + 1; j < selection.items.length; ++j) {
                for (let node of selection.items[j].nodes()) {
                  const matchingNode = insertedNodes.find(
                    (insertedNode) => insertedNode.source === node.source
                  );
                  if (matchingNode) {
                    node.copy(matchingNode);
                  }
                }
              }
            }
          };

          const parentNode = item.node.parent!; // Segments must have a parent
          const parentChildCount = parentNode.childCount();

          const parentFirstChild = parentNode.childNodeAtIndex(0);
          const includesFirstChild = item.node.equalsNode(parentFirstChild);
          const parentLastChild = parentNode.childNodeAtIndex(parentChildCount - 1);
          const includesLastChild = item.nextNode.equalsNode(parentLastChild);

          const pathArgs = parentNode.source.base.args;

          const nodesToRemove: Node[] = [];
          let elementsToInsert: Element[] | undefined;
          let insertionParent: Node | undefined;

          let isClosed = false;
          // Check for a literal first, in case we set closed to false earlier.
          if (pathArgs.closed?.isLiteral()) {
            isClosed = Boolean(pathArgs.closed.literalValue());
          } else {
            const trace = globalState.traceForNode(parentNode);
            if (trace?.isSuccess()) {
              isClosed = Boolean(trace.base.args.closed?.evaluationResult);
            }
          }

          // Always open the path. We should not allow deletion of segments from
          // paths with non-literal closed arg expressions.
          pathArgs.closed = new Expression("false");

          if (isClosed) {
            // When deleting a segment from a closed path, we only need to
            // rotate the "gap" to the end of the array.
            rotateArray(parentNode.sourceChildren(), item.nextNode.indexInParent());
          } else {
            if (includesFirstChild) {
              // We're at the beginning
              nodesToRemove.push(parentFirstChild);
            }
            if (includesLastChild) {
              // We're at the end
              nodesToRemove.push(parentLastChild);
            }
            if (!includesFirstChild && !includesLastChild) {
              // The segment lies in the middle of the path, so we need to split
              // that path into two parts.
              const newPath = parentNode.source.cloneWithoutChildren();
              this.ensureUniqueNames(newPath);

              // Insert the path right away so we can insert its children using
              // our custom splice function.
              const [newPathNode] = this.spliceNodes(
                [],
                [newPath],
                parentNode.parent!,
                parentNode.indexInParent() + 1
              );

              const startIndex = item.nextNode.indexInParent();
              elementsToInsert = parentNode.source.children.splice(startIndex);
              insertionParent = newPathNode;
            }

            splice(nodesToRemove, elementsToInsert, insertionParent);

            removeIfEmptyPath(parentNode);
          }
        }
      }

      // Just remove segments from the selection here. Cleaning them up by
      // validity would complicate the SelectableSegment isValid() logic too
      // much since it would need to determine if its containing path is closed,
      // and if the nodes it straddles are adjacent.
      this.selection = this.selection.filter((item) => !(item instanceof SelectableSegment));
    } else {
      // There are no segments in the selection. Delete everything else.
      for (let item of selection.items) {
        if (item instanceof SelectableNode) {
          this.spliceNodes([item.node]);
          removeIfEmptyPath(item.node.parent);
        } else if (item instanceof SelectableParameter) {
          item.revertToDefault();
        } else if (item instanceof SelectableInstance) {
          item.node.source.removeModifier(item.instance);
        }
      }
    }

    const graph = new DependencyGraph(this);
    graph.removeUnusedImmutableComponents();

    this.ensureProjectDataExists();
  }

  /**
   * If you pass elementsToInsert you must also pass parent. If, for an insert,
   * index is not specified, we'll insert at the end of parent. We've made
   * splice a single operation so that if we remove nodes within parent we can
   * keep the insertion index synchronized, which is useful for Outline
   * reordering.
   */
  spliceNodes(
    nodesToRemove: Node[],
    elementsToInsert?: Element[],
    insertionParent?: Node,
    insertionIndex?: number
  ) {
    if (!elementsToInsert || !insertionParent) {
      // Just doing a remove.
      for (let node of nodesToRemove) {
        // TYPE: We assume you're not removing the root node, so node has a parent.
        const parent = node.parent as Node;
        const index = parent.childNodes().findIndex((n) => n.equalsNode(node));
        const parentSourceChildren = parent.sourceChildren();
        parentSourceChildren.splice(index, 1);
      }
      this.ensureProjectDataExists();
      return [];
    }

    const insertionParentSourceChildren = insertionParent.sourceChildren();
    if (insertionIndex === undefined) {
      insertionIndex = insertionParentSourceChildren.length;
    }

    for (let node of nodesToRemove) {
      // TYPE: We assume you're not removing the root node, so node has a parent.
      const parent = node.parent as Node;
      const index = parent.childNodes().findIndex((n) => n.equalsNode(node));
      if (parent.equalsNode(insertionParent) && index < insertionIndex) {
        insertionIndex--;
      }
      const parentSourceChildren = parent.sourceChildren();
      parentSourceChildren.splice(index, 1);
    }

    insertionParentSourceChildren.splice(insertionIndex, 0, ...elementsToInsert);

    const insertedNodes = elementsToInsert.map((element) => new Node(insertionParent, element));

    // We'll try to keep expanded state the same by matching up insertedNodes
    // with nodesToRemove and then transferring expanded state appropriately.
    // This is ad hoc, but we'll only look for matches on insertedNodes and
    // direct children of insertedNodes. This will enable the use case of doing
    // a simple outline reorder and doing a wrap (which adds a single layer).
    const newNodes = insertedNodes.slice();
    for (let insertedNode of insertedNodes) {
      newNodes.push(...insertedNode.childNodes());
    }
    for (let newNode of newNodes) {
      for (let oldNode of nodesToRemove) {
        if (oldNode.source === newNode.source) {
          this.transferExpandedState(oldNode, newNode);
          break;
        }
      }
    }

    this.ensureProjectDataExists();

    return insertedNodes;
  }

  reparentNodes(nodes: Node[], insertionParent: Node, insertionIndex?: number) {
    const canFixupTransform = nodes.every((node) => {
      return node.source.modifiers.length === 0 && !node.source.isAnyTransformLocked();
    });
    if (canFixupTransform) {
      for (let node of nodes) {
        const nodeContextMatrix = contextMatrixForNode(node);
        const insertionContextMatrix = contextTransformMatrixForNode(insertionParent);
        const compensationMatrix = insertionContextMatrix.clone().invert().mul(nodeContextMatrix);
        const transformer = new NodeTransformer(node);
        transformer.transformContext(compensationMatrix);
      }
    }
    const elementsToInsert = nodes.map((node) => node.source);
    return globalState.project.spliceNodes(
      nodes,
      elementsToInsert,
      insertionParent,
      insertionIndex
    );
  }

  customizedRepeatModifiersInInstanceScope(selectable: SelectableInstance) {
    const instancesInScope: SelectableInstance[] = [];
    const addRepeatModifiersFromIndex = (node: Node, index: number) => {
      const { modifiers } = node.source;
      for (; index < modifiers.length; ++index) {
        const modifier = modifiers[index];
        if (modifier.isRepeatModifier() && modifier.repeatIndexVariableName) {
          instancesInScope.push(new SelectableInstance(node, modifier));
        }
      }
    };

    let { node } = selectable;

    const modifierIndex = node.source.modifiers.indexOf(selectable.instance);
    addRepeatModifiersFromIndex(node, modifierIndex + 1);

    while (node.parent && !node.parent.createsNewContext()) {
      node = node.parent;
      addRepeatModifiersFromIndex(node, 0);
    }

    return instancesInScope;
  }

  // Expression Scope

  categorizedNamesInExpressionScope(expression: Expression) {
    const graph = new DependencyGraph(this);
    const namesByCategory = graph.categorizedNamesInExpressionScope(expression);
    return {
      repeats: namesByCategory.repeats,
      parameters: namesByCategory.parameters,
      components: namesByCategory.components,
      modifiers: namesByCategory.modifiers,
      builtins: allBuiltins
        // TODO: less fragile way to exclude deprecated components?
        .filter(({ name }) => !name.endsWith("(old)"))
        .map(({ name }) => sanitizeName(name)),
      units: units,
      globals: [...globalEnvironmentKeys, "cuttle", "toProjectUnits"],
    };
  }
  allNamesInExpressionScope(expression: Expression) {
    const categorizedNames = this.categorizedNamesInExpressionScope(expression);
    return [
      ...categorizedNames.repeats,
      ...categorizedNames.parameters,
      ...categorizedNames.components,
      ...categorizedNames.modifiers,
      ...categorizedNames.builtins,
      ...categorizedNames.units,
      ...categorizedNames.globals,
    ];
  }

  // Validation

  ensureProjectDataExists() {
    this.expandedNodes = this.expandedNodes.filter(
      (node) => this.nodeExists(node) && node.hasChildNodes()
    );
    this.selection.items = this.selection.items.filter((item) =>
      this.selectableExistsAndIsValid(item)
    );
    if (this.focus instanceof ComponentFocus) {
      let focusedNode = this.focus.node;
      while (focusedNode.parent && !this.nodeExists(focusedNode)) {
        focusedNode = focusedNode.parent;
      }
      if (focusedNode !== this.focus.node) {
        this.focusNode(focusedNode);
      }
    }
    if (this.hoveredItem && !this.selectableExistsAndIsValid(this.hoveredItem)) {
      this.hoveredItem = null;
    }
  }

  assertIsValid() {
    for (let node of this.expandedNodes) {
      console.assert(this.nodeExists(node), "Node does not exist", node);
    }
    for (let item of this.selection.items) {
      console.assert(this.selectableExistsAndIsValid(item), "Selected item does not exist", item);
    }
    if (this.focus instanceof ComponentFocus) {
      console.assert(this.nodeExists(this.focus.node), "Node does not exist", this.focus.node);
    }
    if (this.hoveredItem) {
      console.assert(
        this.selectableExistsAndIsValid(this.hoveredItem),
        "Hovered item does not exist",
        this.hoveredItem
      );
    }

    if (this.focus instanceof DocumentationFocus) {
      console.assert(
        this.documentation === this.focus.documentation,
        "Focused Documentation is not Project documentation (only one for now)",
        this.focus.documentation
      );
    } else if (this.focus instanceof ComponentFocus || this.focus instanceof CodeComponentFocus) {
      console.assert(
        this.components.includes(this.focus.component),
        "Focused Component is not in Project components",
        this.focus.component
      );
    }
  }
  nodeExists(node: unknown): boolean {
    // We're checking that node really is a Node as a defensive measure in case
    // we update built-in and a reference no longer makes sense.
    if (!(node instanceof Node)) return false;
    if (node.parent === null) {
      return this.components.some((component) => {
        return component instanceof Component && component.element === node.source;
      });
    }
    return this.nodeExists(node.parent) && node.parent.sourceChildren().includes(node.source);
  }
  selectableExistsAndIsValid(item: Selectable): boolean {
    return item.isValid() && item.nodes().every((node) => this.nodeExists(node));
  }

  assertIdsAreUnique() {
    const ids = new Set<string>();
    const recurse = (modelObject: ModelObject) => {
      assert(!ids.has(modelObject.id));
      ids.add(modelObject.id);
      modelObject.childModelObjects().forEach(recurse);
    };
    recurse(this);
  }

  /**
   * Should be called by globalState.enterViewingMode
   */
  setParameterDefaults() {
    // Set all parameter default expressions.
    for (let parameter of this.allParameters()) {
      parameter.setDefault();
    }
    for (let component of this.components) {
      for (let parameter of component.allParameters()) {
        parameter.setDefault();
      }
    }
  }

  /**
   * Should be called by globalState.enterEditingMode
   */
  revertParametersToDefault() {
    // Revert all parameters and clear their default expressions.
    for (let parameter of this.allParameters()) {
      parameter.revertToDefault();
      parameter.clearDefault();
    }
    for (let component of this.components) {
      for (let parameter of component.allParameters()) {
        parameter.revertToDefault();
        parameter.clearDefault();
      }
    }
  }
}
registerClass("Project", Project);

//
// Utilities
//

const duplicatePathElementsFromSegmentsSelection = (selection: Selection) => {
  const segmentChains: SelectableSegment[][] = [];
  for (let item of selection.items) {
    if (item instanceof SelectableSegment) {
      const node = item.node; // Make node local to appease TS
      const existingChain = segmentChains.find((chain) => {
        const lastNode = chain[chain.length - 1].nextNode;
        return node.equalsNode(lastNode);
      });
      if (existingChain) {
        existingChain.push(item);
      } else {
        segmentChains.push([item]);
      }
    }
  }

  return segmentChains.map((chain) => {
    const firstNode = chain[0].node;
    const lastNode = chain[chain.length - 1].nextNode;
    const pathElement = firstNode.parent!.source.cloneWithoutChildren();

    for (let segment of chain) {
      const anchorElement = segment.node.source.clone();
      pathElement.children.push(anchorElement);
    }

    if (!firstNode.equalsNode(lastNode)) {
      // Avoid duplicating the same node twice in closed paths.
      let anchorElement = lastNode.source.clone();
      pathElement.children.push(anchorElement);

      // Duplicating segments will always result in an open path unless all
      // segments were selected in a closed path.
      delete pathElement.base.args.closed;
    }

    return pathElement;
  });
};
