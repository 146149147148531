import { CubicSegment, DistanceToIntersectOptions, Geometry, LineSegment, Vec, clamp } from "..";
import { pointAtTimeOnCubic } from "../geometry/primitive-utils";

interface DistanceToOverlapBin {
  index: number;
  distance: number;
  leftPos: Vec;
  rightPos: Vec;
}

export const distanceToIntersect = (
  toolGeom: Geometry,
  targetGeom: Geometry,
  direction: Vec,
  options?: DistanceToIntersectOptions
) => {
  const minOverlap = options?.minOverlap ?? 0;

  const rotationAngle = (direction.angle() + 180) % 360;
  if (rotationAngle !== 0) {
    targetGeom = targetGeom.clone().transform({ rotation: -rotationAngle });
    toolGeom = toolGeom.clone().transform({ rotation: -rotationAngle });
  }

  const leftBox = targetGeom.looseBoundingBox();
  const rightBox = toolGeom.looseBoundingBox();

  if (!(leftBox?.isFinite() && rightBox?.isFinite())) return undefined;

  // Only search where the boxes overlap (in the y axis).
  const yMin = Math.max(leftBox.min.y, rightBox.min.y);
  const yMax = Math.min(leftBox.max.y, rightBox.max.y);
  const height = yMax - yMin;

  if (height < 0) return undefined;

  const binSize = minOverlap > 0 ? minOverlap / 8 : 0.01;
  const binCount = clamp(Math.round(height / binSize), 1, 128);
  const yStep = height / binCount;

  const leftBins = horizontalMinMaxBins(targetGeom, yMin, yStep, binCount);
  const rightBins = horizontalMinMaxBins(toolGeom, yMin, yStep, binCount);
  const bins = new Array<DistanceToOverlapBin>(binCount);
  for (let i = 0; i < binCount; ++i) {
    const leftBin = leftBins[i];
    const rightBin = rightBins[i];
    bins[i] = {
      index: i,
      distance: rightBin.xMin - leftBin.xMax,
      leftPos: new Vec(leftBin.xMax, leftBin.y),
      rightPos: new Vec(rightBin.xMin, rightBin.y),
    };
  }

  const binsSortedByDistance = bins
    .filter((bin) => Number.isFinite(bin.distance))
    .sort((a, b) => a.distance - b.distance);

  // No possible overlap was found.
  if (binsSortedByDistance.length < 1) return undefined;

  for (let i = 1; i < binsSortedByDistance.length; ++i) {
    const longerBinsSortedByIndex = binsSortedByDistance
      .slice(0, i + 1)
      .sort((a, b) => a.index - b.index);

    let startBin = longerBinsSortedByIndex[0];
    let prevBin = startBin;
    for (let j = 1; j < longerBinsSortedByIndex.length; ++j) {
      const bin = longerBinsSortedByIndex[j];
      if (prevBin.index === bin.index - 1) {
        const overlap = startBin.leftPos.distance(bin.leftPos);
        if (overlap >= minOverlap) {
          return binsSortedByDistance[i].distance;
        }
        prevBin = bin;
      } else {
        prevBin = startBin = bin;
      }
    }
  }

  return binsSortedByDistance[binsSortedByDistance.length - 1].distance;
};

const horizontalMinMaxBins = (
  geometry: Geometry,
  yStart: number,
  yStep: number,
  yCount: number
) => {
  const yHalfStep = 0.5 * yStep;

  const bins: { xMin: number; xMax: number; y: number }[] = new Array(yCount);
  for (let i = 0; i < yCount; ++i) {
    bins[i] = {
      xMin: Infinity,
      xMax: -Infinity,
      y: yStart + yStep * i + yHalfStep,
    };
  }

  const cubicYIntercepts: number[] = [];
  const primitives = geometry._primitives();
  for (const prim of primitives) {
    const box = prim.looseBoundingBox();
    if (!box) continue;

    // Loop over all the bins and expand their mins and maxes.
    for (let i = 0; i < yCount; ++i) {
      const y = yStart + yStep * i + yHalfStep;

      // Early out if there can be no intersections
      if (box.min.y > y) continue;
      if (box.max.y < y) continue;

      const bin = bins[i];
      if (prim instanceof LineSegment) {
        // Find x by finding the x intercept.
        const x = lineXIntercept(prim.s, prim.e, y);
        if (x < bin.xMin) bin.xMin = x;
        if (x > bin.xMax) bin.xMax = x;
      } else if (prim instanceof CubicSegment) {
        const { s, cs, ce, e } = prim;
        const count = cubicApproximateXIntercepts(s, cs, ce, e, y, cubicYIntercepts);
        for (let j = 0; j < count; ++j) {
          const x = cubicYIntercepts[j];
          if (x < bin.xMin) bin.xMin = x;
          if (x > bin.xMax) bin.xMax = x;
        }
      }
    }
  }

  return bins;
};

const lineXIntercept = (s: Vec, e: Vec, y: number) => {
  if (s.x === e.x) return s.x;
  const m = (e.y - s.y) / (e.x - s.x);
  const b = s.y - m * s.x;
  return (y - b) / m;
};

const cubicApproximateXIntercepts = (s: Vec, cs: Vec, ce: Vec, e: Vec, y: number, xs: number[]) => {
  const divisions = 8;
  const divisionIndexToTime = 1 / (divisions - 1);
  const ls = new Vec();
  const le = s.clone();
  let count = 0;
  for (let i = 1; i < divisions; ++i) {
    const t = i * divisionIndexToTime;
    ls.copy(le);
    pointAtTimeOnCubic(s, cs, ce, e, t, le);
    if (y >= Math.min(ls.y, le.y) && y <= Math.max(ls.y, le.y)) {
      const x = lineXIntercept(ls, le, y);
      xs[count++] = x;
    }
  }
  return count;
};
