import m from "mithril";

import { globalState } from "../global-state";
import { fontManager, hashForCustomFontURL } from "../io/font-manager";
import {
  ALL_FONTS_CATEGORY,
  CUSTOM_FONTS_CATEGORY,
  FontFamily,
  fontList,
  FontVariant,
  isFontComplex,
  isFontFileNameInFontURLArray,
} from "../model/font-list";
import { CreatedPopup, createPopup, createPopupMenu, Tooltipped } from "../shared/popup";
import { classNames, domForVnode } from "../shared/util";
import { FontUploader } from "./font-uploader";
import { checkUserHasProFeature, ProFeatureTag } from "../shared/feature-check";
import { GroupAttrs, SelectListGroupWithQuery, SelectListOption } from "./basic/select-list";
import { IconButton } from "../shared/icon";
import { modalState } from "../shared/modal";
import { SimpleModal } from "./modal";

export const RECOMMENDED_FONTS_CATEGORY = "Recommended";

interface FontPickerAttrs {
  value: string;
  onchange: (string: string) => void;
  recommendedValues: ReadonlyArray<string>;
}
export const FontPicker: m.Component<FontPickerAttrs> = {
  oninit() {
    // Will async trigger a redraw if this is the first time builtin font info
    // is loaded.
    fontList.ensureBuiltInFontsLoaded();
  },
  view(vnode) {
    const { value, onchange, recommendedValues } = vnode.attrs;
    let familyAndVariant = familyAndVariantByValue(value);
    let label =
      familyAndVariant?.fontVariant?.label ||
      fontManager.getLabel(value) ||
      fontManager.getError(value) ||
      value;

    const isFontInfoLoaded = fontList.isFontInfoLoaded();
    if (!isFontInfoLoaded) {
      label = "Loading…";
    }
    const isDisabled = !isFontInfoLoaded;

    const openFontPickerPopup = () => {
      if (isDisabled) return;

      const initialValue = value;
      let latestValue = value;

      const fontPickerPopupView = () => {
        return m(FontPickerPopup, {
          value,
          familyAndVariant,
          onchange: (newValue: string) => {
            latestValue = newValue;
            onchange(newValue);
          },
          recommendedValues,
          createdPopup,
        });
      };

      const spawnFrom = domForVnode(vnode);
      globalState.isPickerOpen = true;
      const gestureId = globalState.startGesture("Font Picker");
      const createdPopup = createPopup({
        spawnFrom,
        view: fontPickerPopupView,
        placement: "top-start",
        offset: 8,
        onclose: () => {
          if (globalState.isEditingMode()) {
            // In editing mode, if a pro font is selected and the user doesn't
            // have the feature, show upgrade modal and revert to previous font.
            // This allows people to preview pro fonts while the picker is open,
            // but not save the change.
            const res = fontList.familyAndVariantByValue(latestValue);
            if (res && res.fontFamily.pro) {
              if (!checkUserHasProFeature("pro-fonts")) {
                onchange(initialValue);
              }
            }
          }
          globalState.isPickerOpen = false;
          globalState.stopGesture(gestureId);
        },
        closeOnEnter: true,
      });
    };

    const onpointerdown = (event: PointerEvent) => {
      event.stopPropagation();
      openFontPickerPopup();
    };

    const onkeydown = (event: KeyboardEvent) => {
      if (event.code === "Enter" || event.code === "Space") {
        event.preventDefault();
        event.stopPropagation();
        openFontPickerPopup();
      }
    };

    const className = classNames({ disabled: isDisabled });

    return m(".font-picker.select", { onpointerdown, tabIndex: 0, onkeydown, className }, label);
  },
};

interface FontPickerPopupAttrs {
  value: string;
  familyAndVariant?: { fontFamily?: FontFamily; fontVariant?: FontVariant };
  onchange: (string: string) => void;
  recommendedValues: ReadonlyArray<string>;
  createdPopup: CreatedPopup;
}

const FontPickerPopup: m.ClosureComponent<FontPickerPopupAttrs> = (initialVnode) => {
  // Will be set on create, update
  let latestVnode = initialVnode;

  let { value, familyAndVariant, recommendedValues } = initialVnode.attrs;

  let category = ALL_FONTS_CATEGORY;
  let family = familyAndVariant?.fontFamily?.family;
  let variant = familyAndVariant?.fontVariant?.variant;

  // Open to category, except with default family or complex fonts.
  if (
    family !== "Noto Sans" &&
    familyAndVariant?.fontFamily &&
    !isFontComplex(familyAndVariant.fontFamily)
  ) {
    category = familyAndVariant.fontFamily.category;
  }

  // Open to recommended fonts if selected font is recommended.
  if (isFontFileNameInFontURLArray(value, recommendedValues)) {
    category = RECOMMENDED_FONTS_CATEGORY;
  }

  let filterQuery = "";
  let familyNamesInfo: { label: string; value: string; pro?: boolean }[] = [];

  /** Filters list of family names with category and/or search query change. */
  const filterFamilyNames = () => {
    familyNamesInfo = fontList.filteredFontFamilyNames(
      category,
      filterQuery,
      familyAndVariant?.fontFamily?.family
    );
  };
  filterFamilyNames();

  const onQuery = (query: string) => {
    category = ALL_FONTS_CATEGORY;
    filterQuery = query;
    filterFamilyNames();
  };

  const sendChange = () => {
    if (family && variant) {
      const foundFamilyAndVariant = fontList.familyAndVariantByNames(family, variant);
      familyAndVariant = foundFamilyAndVariant;
      if (!foundFamilyAndVariant || !foundFamilyAndVariant.fontVariant) return;
      const newValue = foundFamilyAndVariant.fontVariant.value;
      latestVnode.attrs.onchange(newValue);
      value = newValue;
    }
  };

  const onSelectCategory = (value: string) => {
    category = value;
    filterFamilyNames();
  };

  const onSelectFamily = (value: string) => {
    // Only choose default variant if this is a "new" family
    if (value === family) return;
    family = value;
    if (family) {
      const defaultVariantName = fontList.familyDefaultVariant(family)?.variant;
      if (defaultVariantName) {
        variant = defaultVariantName;
      }
    }
    sendChange();
  };

  const onSelectVariant = (value: string) => {
    variant = value;
    sendChange();
  };

  const makeFontUploader = () => {
    return m(FontUploader, {
      onSuccess: async (eagerFontURL: string) => {
        // Select the newly-uploaded font
        const familyAndVariant = fontList.familyAndVariantByValue(eagerFontURL);
        if (familyAndVariant) {
          console.log("Focusing and selecting newly-uploaded font option.", familyAndVariant);
          onSelectCategory(CUSTOM_FONTS_CATEGORY);
          onSelectFamily(familyAndVariant.fontFamily.family);
          onSelectVariant(familyAndVariant.fontVariant.variant);
        } else {
          console.warn("Didn't find newly-uploaded font option.", eagerFontURL);
        }
      },
      onFail: (err: any) => {
        console.warn("Font upload failed.", err);
      },
    });
  };

  return {
    view(vnode) {
      latestVnode = vnode;

      let categories = fontList.categories;
      if (recommendedValues.length > 0) {
        categories = [
          ...categories.slice(0, 1),
          RECOMMENDED_FONTS_CATEGORY,
          ...categories.slice(1),
        ];
      }

      const groups: GroupAttrs[] = [
        {
          key: "categories",
          className: "categories",
          options: categories,
          value: category,
          onSelect: onSelectCategory,
        },
      ];

      if (familyNamesInfo && familyNamesInfo.length > 0) {
        groups.push({
          key: "families",
          className: "families",
          options: familyNamesInfo.map((familyInfo) => {
            return {
              label: familyInfo.label,
              value: familyInfo.value,
              render: () => [m(".label", familyInfo.label), familyInfo.pro && m(ProFeatureTag)],
            };
          }),
          value: family,
          onSelect: onSelectFamily,
        });
      }

      if (familyAndVariant?.fontFamily?.variants && category !== RECOMMENDED_FONTS_CATEGORY) {
        const variantOptions: undefined | SelectListOption[] =
          familyAndVariant?.fontFamily?.variants.map((fontVariant) =>
            listItemForVariant({
              fontVariant,
              fontPickerPopup: latestVnode.attrs.createdPopup,
              fontPickerValue: value,
              fontPickerOnChange: latestVnode.attrs.onchange,
            })
          );

        if (variantOptions && variantOptions.length > 0) {
          groups.push({
            key: "variants",
            className: "variants",
            options: variantOptions,
            value: variant,
            onSelect: onSelectVariant,
          });
        }
      }

      if (category === CUSTOM_FONTS_CATEGORY && familyNamesInfo.length === 0) {
        groups.push(() => m(MyFontsEmpty, { key: "my-fonts-empty" }, makeFontUploader()));
      } else if (category === RECOMMENDED_FONTS_CATEGORY) {
        const recommendedGroup = makeRecommendedGroup({
          recommendedValues,
          selectedValue: value,
          onSelect: (newValue) => {
            value = newValue;
            vnode.attrs.onchange(value);
          },
        });
        if (recommendedGroup) {
          groups.push(recommendedGroup);
        }
      }

      return m(
        ".font-picker-popup",
        m(SelectListGroupWithQuery, {
          groups,
          primaryGroupKey: "families",
          onQuery,
          headerExtra: () => {
            return [
              m(".divider"),
              makeFontUploader(),
              m(
                ".help-link-wrapper",
                m(
                  Tooltipped,
                  {
                    message: () => ["Help with adding your own fonts."],
                    placement: "bottom",
                  },
                  m(
                    "a.help-link.secondary",
                    {
                      href: "/learn/common-questions/adding-your-own-fonts",
                      target: "_blank",
                      rel: "noreferrer noopener nofollow",
                      role: "button",
                      "aria-label": "Help with adding your own fonts.",
                    },
                    "?"
                  )
                )
              ),
            ];
          },
        })
      );
    },
  };
};

const MyFontsEmpty: m.Component<{}> = {
  view({ children }) {
    return m(".my-fonts-empty", [
      m(".note", [
        "Choose a font to add to your account. Supported font types: ",
        m("br"),
        "ttc, ttf, otf, woff.",
      ]),
      children,
      m(
        ".note",
        m(
          "a",
          {
            href: "/learn/common-questions/adding-your-own-fonts",
            target: "_blank",
            rel: "noreferrer noopener nofollow",
          },
          "Help with adding your own fonts."
        )
      ),
    ]);
  },
};

interface makeRecommendedGroupArgs {
  recommendedValues: ReadonlyArray<string>;
  selectedValue: string;
  onSelect: (value: string) => void;
}
function makeRecommendedGroup(args: makeRecommendedGroupArgs) {
  const { recommendedValues, selectedValue, onSelect } = args;
  // In case recommended font URLs are not in the font list, keep them functional.
  const options = recommendedValues.map((value) => {
    const familyAndVariant = familyAndVariantByValue(value);
    const label =
      familyAndVariant?.fontVariant?.label ||
      fontManager.getLabel(value) ||
      familyAndVariant?.fontFamily.family ||
      value;
    return {
      label,
      value,
      render: () => [m(".label", label), familyAndVariant?.fontFamily?.pro && m(ProFeatureTag)],
    };
  });
  if (options.length > 0) {
    options.sort((a, b) => a.label.localeCompare(b.label));
    return {
      key: "recommended",
      className: "recommended",
      options,
      value: selectedValue,
      onSelect,
      shouldFocusSelectedOnCreate: true,
    };
  }
  return undefined;
}

/**
 * Searches font list for values, with loaded font manager as a backup.
 */
function familiesAndVariantsByValues(values: Array<string>) {
  const familiesAndVariants: Array<{ fontFamily: FontFamily; fontVariant?: FontVariant }> = [];
  for (let value of values) {
    let familyAndVariant = familyAndVariantByValue(value);
    if (familyAndVariant) {
      familiesAndVariants.push(familyAndVariant);
    }
  }
  return familiesAndVariants;
}

/**
 * Searches font list for value, and looks up by loaded font naming info from manager as a backup.
 */
function familyAndVariantByValue(value: string) {
  let familyAndVariant:
    | {
        fontFamily: FontFamily;
        fontVariant?: FontVariant;
      }
    | undefined = fontList.familyAndVariantByValue(value);
  if (!familyAndVariant) {
    const names = fontManager.getNames(value);
    if (names && names.family && names.variant) {
      familyAndVariant = fontList.familyAndVariantByNames(names.family, names.variant);
    } else {
      // We don't have info for this font in font list or manager. Try loading it.
      try {
        fontManager.getFontFromURL(value);
      } catch (error) {}
    }
  }
  return familyAndVariant;
}

const listItemForVariant = (options: {
  fontVariant: FontVariant;
  fontPickerPopup: CreatedPopup;
  fontPickerValue: string;
  fontPickerOnChange: (value: string) => void;
}) => {
  const isCustomFont = Boolean(hashForCustomFontURL(options.fontVariant.value));
  if (!isCustomFont) {
    // Built-in fonts don't have extra menu.
    return { label: options.fontVariant.variant, value: options.fontVariant.variant };
  }

  const { fontVariant, fontPickerPopup, fontPickerValue, fontPickerOnChange } = options;

  const openExtraMenu = (e: PointerEvent) => {
    // Don't select the variant when opening the extra menu.
    e.stopPropagation();

    fontPickerPopup.registerChildPopup(
      createPopupMenu({
        menuItems: [
          {
            label: `Remove “${fontVariant.label}”`,
            action: () => {
              modalState.open({
                modalView: () =>
                  m(
                    SimpleModal,
                    {
                      primaryAction: {
                        label: "Remove",
                        action: async () => {
                          // If the font to be removed is currently selected, select the
                          // next variant or family in list.
                          if (fontPickerValue === fontVariant.value) {
                            const nextValue = fontList.nextCustomFontValue(fontVariant.value);
                            if (nextValue) {
                              fontPickerOnChange(nextValue);
                            }
                          }
                          await fontList.removeCustomFont(fontVariant.value);
                        },
                      },
                    },
                    `Are you sure you want to remove the font “${fontVariant.label}” from your font list?`
                  ),
              });
            },
          },
        ],
        spawnFrom: e.target as HTMLElement,
        placement: "top-end",
        // So you can click the font picker to close just this menu.
        overlay: "closeOnOutsidePointerDown",
      })
    );
  };

  return {
    label: fontVariant.variant,
    value: fontVariant.variant,
    render: () => [
      m(".label", fontVariant.variant),
      m(".extra", [m(IconButton, { icon: "dotdotdot", onclick: openExtraMenu })]),
    ],
  };
};
