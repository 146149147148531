import { AffineMatrix, Vec } from "../../geom";
import { CanvasDrag, globalState } from "../../global-state";
import { TransformDefinition } from "../../model/builtin-primitives";
import { CanvasPoint } from "../../model/canvas-point";
import { Expression } from "../../model/expression";
import { expressionCodeForVec } from "../../model/expression-code";
import { Instance } from "../../model/instance";
import { contextTransformMatrixForNode, transformOriginForNode } from "../../model/transform-utils";
import { NodeTransformer } from "../../model/transformer";
import { SnappingPoint } from "../snapping";
import styleConstants from "../style-constants";
import { toastState } from "../toast-message";
import { startCanvasDrag } from "../tool/canvas-drag";
import {
  alignToPixelCenter,
  minimumPrecisionWorldPositionsFromCanvasDrag,
  precisionInfoForContext,
} from "../util";
import { CanvasInterfaceElement } from "./canvas-interface-element";

const TRANSFORM_CENTER_HIT_RADIUS = 6;

export class TransformCenterUI implements CanvasInterfaceElement {
  transformCenter: CanvasPoint | undefined;
  isPositionLocked: boolean;

  constructor() {
    this.transformCenter = globalState.project.transformCenter();
    this.isPositionLocked = globalState.project.selection.allTransformable().isPositionLocked();
  }

  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    if (!this.transformCenter) return undefined;

    const pixelCenterPos = this.transformCenter.worldPosition.clone().affineTransform(viewMatrix);
    alignToPixelCenter(pixelCenterPos);

    // Center-align the graphic
    pixelCenterPos.subScalar(15 / 2);

    ctx.translate(pixelCenterPos.x, pixelCenterPos.y);
    ctx.beginPath();
    ctx.moveTo(8, 3.02746);
    ctx.bezierCurveTo(10.0858, 3.25804, 11.742, 4.91419, 11.9725, 7);
    ctx.lineTo(15, 7);
    ctx.lineTo(15, 8);
    ctx.lineTo(11.9725, 8);
    ctx.bezierCurveTo(11.742, 10.0858, 10.0858, 11.742, 8, 11.9725);
    ctx.lineTo(8, 15);
    ctx.lineTo(7, 15);
    ctx.lineTo(7, 11.9725);
    ctx.bezierCurveTo(4.91419, 11.742, 3.25804, 10.0858, 3.02746, 8);
    ctx.lineTo(0, 8);
    ctx.lineTo(0, 7);
    ctx.lineTo(3.02746, 7);
    ctx.bezierCurveTo(3.25804, 4.91419, 4.91419, 3.25804, 7, 3.02746);
    ctx.lineTo(7, 0);
    ctx.lineTo(8, 0);
    ctx.lineTo(8, 3.02746);
    ctx.closePath();
    ctx.moveTo(11, 7.5);
    ctx.bezierCurveTo(11, 9.433, 9.433, 11, 7.5, 11);
    ctx.bezierCurveTo(5.567, 11, 4, 9.433, 4, 7.5);
    ctx.bezierCurveTo(4, 5.567, 5.567, 4, 7.5, 4);
    ctx.bezierCurveTo(9.433, 4, 11, 5.567, 11, 7.5);
    ctx.closePath();

    ctx.fillStyle = styleConstants.blue63;
    ctx.fill("evenodd");
  }

  hitTest(worldPosition: Vec, pixelsPerUnit: number) {
    if (!this.transformCenter) return undefined;
    const worldHitRadius = TRANSFORM_CENTER_HIT_RADIUS / pixelsPerUnit;
    const distance = this.transformCenter.worldPosition.distance(worldPosition) - worldHitRadius;
    return { distance, isHit: distance <= 0 };
  }

  cursor() {
    if (this.isPositionLocked) {
      return "select-locked";
    }
    return "select";
  }

  snappingPoints(isSource: boolean) {
    if (isSource && this.transformCenter) {
      return new SnappingPoint(this.transformCenter.worldPosition, "Transform Center", "Origin");
    }
  }

  onPointerDown(event: PointerEvent) {
    if (this.isPositionLocked) {
      toastState.showBasic({
        type: "error",
        message:
          "Can't move Transform Center because a formula is being used in the shape's Transform.",
        nextStep: "Try changing the origin directly in the Inspector.",
      });
      return;
    }
    startCanvasDrag(event, {
      onMove(event: PointerEvent, canvasDrag: CanvasDrag) {
        if (!globalState.project.hasOverrideTransformCenter()) {
          // If only one node is selected (directly or indirectly) try to
          // reposition its origin
          const selection = globalState.project.selection.allNodes();
          if (selection.isSingle() && !selection.isPassThrough()) {
            const node = selection.items[0].node;

            const trace = globalState.traceForNode(node);
            if (trace?.isSuccess()) {
              const { currentPosition } = minimumPrecisionWorldPositionsFromCanvasDrag(canvasDrag);

              const inverseContextTransformMatrix = contextTransformMatrixForNode(node)
                .clone()
                .invert();
              const { fractionDigits } = precisionInfoForContext(inverseContextTransformMatrix);
              const nextOrigin = currentPosition
                .clone()
                .affineTransform(inverseContextTransformMatrix);

              // Round imprecise drags to the local precision
              if (!canvasDrag.isPrecise()) {
                nextOrigin.roundToFixed(fractionDigits);
              }

              const origin = transformOriginForNode(node);
              const offset = nextOrigin
                .clone()
                .sub(origin)
                .affineTransformWithoutTranslation(trace.transformMatrix());
              const transformer = new NodeTransformer(node);
              transformer.transformContext(AffineMatrix.fromTranslation(offset), {
                preserveImageTransforms: true,
              });

              // We should have a transform at this point, but it's possible
              // that something went wrong, so check anyway.
              if (node.source.transform) {
                node.source.transform.args.origin = new Expression(
                  expressionCodeForVec(nextOrigin, fractionDigits)
                );
              }

              return;
            }
          }
        }

        globalState.project.setOverrideTransformCenter(canvasDrag.currentPoint);
      },
    });
  }
}
