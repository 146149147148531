import m from "mithril";

import { graphicFromSVGString, scaleFactorForUnitConversion } from "../geom";
import { simplifyHierarchy } from "../geom-internal";
import { globalState } from "../global-state";
import { fontManager } from "../io/font-manager";
import { TransformDefinition } from "../model/builtin-primitives";
import { ImageFrameDefinition } from "../model/builtin-shapes";
import { Expression } from "../model/expression";
import { expressionCodeForNumber, expressionCodeForString } from "../model/expression-code";
import { fontList } from "../model/font-list";
import { Instance } from "../model/instance";
import { checkUserHasProFeature } from "../shared/feature-check";
import {
  isSupportedFontFile,
  isSupportedSVGFile,
  isSupportedUploadableImageFile,
} from "../shared/file-types";
import { LocalFontInfo, getInfoFromLocalFont } from "./font-info";
import { uploadImage } from "./image-upload";
import { mStrongListAndMore, toastState } from "./toast-message";

type SupportedFileType = "svg" | "image" | "font";

export const importFilesToProject = async (files: File[]) => {
  const filesByType = filesGroupedBySupportedType(files);

  const keys = Object.keys(filesByType);
  if (keys.length < 1) {
    toastState.showBasic({
      type: "error",
      message: "Can’t import files of this type.",
      nextStep: "Cuttle can import SVGs, images, and fonts.",
    });
    return;
  }
  if (keys.length > 1) {
    toastState.showBasic({
      type: "error",
      message: "Can’t import mixed file types.",
      nextStep: "Try importing files of a single type. (SVGs, images, or fonts)",
    });
    return;
  }

  const type = keys[0];
  const filesOfType = filesByType[type];

  if (type === "svg") {
    importSVGFilesToProject(filesOfType);
  }
  if (type === "image") {
    // When the Read Me is focused, append imported images there rather than on
    // the canvas.
    if (globalState.project.focusedDocumentation()) {
      importImageFilesToDocumentation(filesOfType);
    } else {
      importImageFilesToProject(filesOfType);
    }
    return;
  }
  if (type === "font") {
    importFontFilesToProject(filesOfType);
    return;
  }
};

const filesGroupedBySupportedType = (files: File[]) => {
  const filesByType: { [type: string]: File[] } = {};
  for (let file of files) {
    const type = supportedTypeForFile(file);
    if (type) {
      if (!filesByType[type]) filesByType[type] = [];
      filesByType[type].push(file);
    }
  }
  return filesByType;
};

const supportedTypeForFile = (file: File): SupportedFileType | undefined => {
  if (isSupportedSVGFile(file)) return "svg";
  if (isSupportedUploadableImageFile(file)) return "image";
  if (isSupportedFontFile(file)) return "font";
};

const importImageFilesToDocumentation = (files: File[]) => {
  if (globalState.activeDocEditor) {
    for (let file of files) {
      globalState.activeDocEditor.chain().focus().cuttleImageUpload({ file }).run();
    }
  }
};

const importSVGFilesToProject = async (files: File[]) => {
  for (let file of files) {
    await importSVGFileToProject(file);
  }
};

const importSVGFileToProject = (file: File) => {
  return new Promise<void>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      // TYPE: Not sure the intricacies of FileReader; this seems to work.
      const text = e.target!.result as string;
      const loaded = globalState.loadProjectFromSVG(text);
      if (!loaded) {
        const imported = importSVGStringToProject(text, file.name);
        if (!imported) {
          reject();
        }
      }
      globalState.checkpointAfterNextEvaluation();
      m.redraw();
      resolve();
    };
    reader.onerror = (e) => {
      reject();
    };
    reader.readAsText(file);
  });
};

/**
 * Returns true if SVG parse is successful and something was imported to project.
 */
export const importSVGStringToProject = (svg: string, fileName: string): boolean => {
  const focusedComponent = globalState.project.focusedComponent();
  if (!focusedComponent) {
    toastState.showBasic({
      type: "error",
      message: "Can’t import SVGs into the Read Me.",
      nextStep: "Try selecting a component on the left hand side first.",
    });
    return false;
  }

  const parsedGeometry = graphicFromSVGString(svg, {
    units: globalState.project.settings.units,
  });
  if (!parsedGeometry) {
    toastState.showBasic({
      type: "error",
      message: "Could not parse this SVG.",
    });
    return false;
  }

  const geometry = simplifyHierarchy(parsedGeometry);

  // Collapsed geometry can be undefined, eg an SVG with only empty groups
  if (!geometry) {
    toastState.showBasic({
      type: "error",
      message: "Could not find any geometry in this SVG.",
    });
    return false;
  }

  const project = globalState.project;
  const element = project.bakeGeometry(geometry);
  if (!element) {
    return false;
  }

  if (fileName) {
    project.renameElement(element, elementNameForFileName(fileName));
  }

  const nodes = project.spliceNodes([], [element], project.topNode());
  project.selectNodes(nodes);

  // Zoom to fit newly-imported SVG, if needed.
  const geometryBoundingBox = geometry.boundingBox();
  const viewport = globalState.viewportManager.viewportForComponent(focusedComponent);
  const worldBoundingBox = viewport.worldBoundingBox(globalState.canvasDimensions);
  if (geometryBoundingBox && !worldBoundingBox.containsBoundingBox(geometryBoundingBox)) {
    viewport.scaleToFitBoundingBox(
      worldBoundingBox.expandToIncludeBoundingBox(geometryBoundingBox),
      globalState.canvasDimensions
    );
  }
  return true;
};

export const importImageFilesToProject = async (files: File[], filename?: string) => {
  if (!checkUserHasProFeature("images")) throw new Error("Images feature not available.");

  for (let file of files) {
    toastState.showBasic({
      type: "info",
      message: ["Uploading image ", m("strong", file.name), "."],
    });
    await importImageFileToProject(file, filename);
  }

  toastState.showBasic({
    type: "info",
    message: [
      `Uploaded ${files.length} ${files.length === 1 ? "image" : "images"}: `,
      mStrongListAndMore(files.map((file) => file.name)),
      ".",
    ],
  });
};

const importImageFileToProject = async (file: File, filename = file.name) => {
  const url = await uploadImage(file);

  const { project } = globalState;

  const element = project.createElementWithDefinition(ImageFrameDefinition);
  element.base.args.image = new Expression(expressionCodeForString(url));

  element.transform = new Instance(TransformDefinition);
  const scale = scaleFactorForUnitConversion("px", project.settings.units);
  element.transform.args.scale = new Expression(expressionCodeForNumber(scale));

  if (filename) {
    project.renameElement(element, elementNameForFileName(filename));
  }

  const nodes = project.spliceNodes([], [element], project.topNode());
  project.selectNodes(nodes);
};

/** Handles checking feature flag, uploading multiple font files to account, and
 * showing toast messages during the process. */
export async function importFontFilesToProject(files: File[]) {
  if (!checkUserHasProFeature("fonts")) {
    throw new Error("Fonts feature not available.");
  }

  try {
    const infos: LocalFontInfo[][] = [];

    // Parse and hash fonts (and extracted font collection variants)
    // into a nested list
    for (let file of files) {
      const info = await getInfoFromLocalFont(file);
      if (!info.length) {
        throw new Error("Font info not found.");
      }
      infos.push(info);
    }

    const infosFlat = infos.flat();

    // Flatten list and upload them
    for (let fontInfo of infosFlat) {
      toastState.showBasic({
        type: "info",
        message: ["Uploading font ", m("strong", fontInfo.label), "."],
      });
      await globalState.storage.addFontForLoggedInUser(fontInfo);
    }

    // Finish up, signal out to picker
    if (infosFlat.length) {
      const labels = infosFlat.map((font) => font.label);
      toastState.showBasic({
        type: "info",
        message: [
          `Uploaded ${labels.length} ${labels.length === 1 ? "font" : "fonts"}: `,
          mStrongListAndMore(labels),
          ".",
        ],
      });
      fontManager.resetErrors();
      await fontList.refreshCustomFonts();
    } else {
      throw new Error("Font import failed.");
    }

    return infosFlat;
  } catch (err) {
    toastState.showBasic({
      type: "error",
      message: String(err),
      nextStep: "Contact us if you think this font should work.",
    });
    throw err;
  }
}

const elementNameForFileName = (filename: string) => {
  const extIndex = filename.lastIndexOf(".");
  if (extIndex > 0) {
    // Remove the file extension
    return filename.substring(0, extIndex);
  }
  return filename;
};
