import {
  AffineMatrix,
  Axis,
  BoundingBox,
  ClosestPointResultWithTime,
  CubicSegment,
  Geometry,
  GeometryPrimitive,
  LineSegment,
  Vec,
} from "..";
import {
  axisRayIntersection,
  lineRayIntersection,
  rayCubicIntersections,
  rayRayIntersection,
  swapIntersectionTimes,
} from "../op/intersection";

/**
 * A ray is a line extending infinitely in one direction from an origin point.
 */
export class Ray extends Geometry implements GeometryPrimitive {
  static displayName = "Ray";

  /**
   * The start position of the ray. The origin is the position at `time = 0`.
   */
  origin: Vec;

  /**
   * The direction that the ray point at from the origin.
   */
  direction: Vec;

  /**
   * Constructs a ray.
   *
   * @param origin
   * @param direction
   */
  constructor(origin: Vec, direction: Vec) {
    super();
    this.origin = origin;
    this.direction = direction;
  }

  clone() {
    return new Ray(this.origin.clone(), this.direction.clone());
  }

  isValid() {
    return Vec.isValid(this.origin) && Vec.isValid(this.direction);
  }

  isHorizontal() {
    return this.direction.y === 0;
  }
  isVertical() {
    return this.direction.x === 0;
  }

  affineTransform(matrix: AffineMatrix) {
    this.origin.affineTransform(matrix);
    this.direction.affineTransformWithoutTranslation(matrix);
    return this;
  }
  affineTransformWithoutTranslation(matrix: AffineMatrix) {
    this.direction.affineTransformWithoutTranslation(matrix);
    return this;
  }

  reverse() {
    this.direction.negate();
    return this;
  }

  closestPoint(point: Vec, areaOfInterest?: BoundingBox): ClosestPointResultWithTime | undefined {
    if (areaOfInterest && !this._looseOverlapsBoundingBox(areaOfInterest)) return;

    const { origin, direction } = this;
    const pax = point.x - origin.x;
    const pay = point.y - origin.y;
    const bax = direction.x;
    const bay = direction.y;
    const time = Math.max(0, (pax * bax + pay * bay) / (bax * bax + bay * bay));

    const position = this.positionAtTime(time);

    if (areaOfInterest && !areaOfInterest.containsPoint(position)) return;

    return { position, time, geometry: this };
  }

  positionAtTime(time: number) {
    return this.direction.clone().mulScalar(time).add(this.origin);
  }

  _looseOverlapsBoundingBox(box: BoundingBox) {
    // TODO: This implementation is not correct and fails when one bounding box
    // point has a negative dot product vs the direction.
    return true;

    const ox = this.origin.x;
    const oy = this.origin.y;
    const dx = this.direction.x;
    const dy = this.direction.y;

    // Perpendicular to direction
    const px = dy;
    const py = -dx;

    const { min, max } = box;
    const minx = min.x - ox;
    const miny = min.y - oy;
    const maxx = max.x - ox;
    const maxy = max.y - oy;

    // Find the sign of the dot product of the 4 corner points vs the
    // perpendicular. Only use points that have a positive dot product with the
    // ray direction.
    const s1 = dx * minx + dy * miny >= 0 ? Math.sign(px * minx + py * miny) : 0;
    const s2 = dx * maxx + dy * miny >= 0 ? Math.sign(px * maxx + py * miny) : 0;
    const s3 = dx * maxx + dy * maxy >= 0 ? Math.sign(px * maxx + py * maxy) : 0;
    const s4 = dx * minx + dy * maxy >= 0 ? Math.sign(px * minx + py * maxy) : 0;

    // If any of the non-zero signs are unequal, we have an
    const firstNonzeroSign = s1 || s2 || s3 || s4;
    if (firstNonzeroSign === 0) return false;
    if (s1 !== 0 && s1 !== firstNonzeroSign) return true;
    if (s2 !== 0 && s2 !== firstNonzeroSign) return true;
    if (s3 !== 0 && s3 !== firstNonzeroSign) return true;
    if (s4 !== 0 && s4 !== firstNonzeroSign) return true;

    return false;
  }

  _intersectionWithLineSegment(segment: LineSegment) {
    const intersection = lineRayIntersection(segment.s, segment.e, this.origin, this.direction);
    if (intersection) return swapIntersectionTimes(intersection);
    return undefined;
  }

  _intersectionsWithCubicSegment(segment: CubicSegment) {
    return rayCubicIntersections(
      this.origin,
      this.direction,
      segment.s,
      segment.cs,
      segment.ce,
      segment.e
    );
  }

  _intersectionWithAxis(axis: Axis) {
    const intersection = axisRayIntersection(
      axis.origin,
      axis.direction,
      this.origin,
      this.direction
    );
    if (intersection) return swapIntersectionTimes(intersection);
    return undefined;
  }

  _intersectionWithRay(ray: Ray) {
    return rayRayIntersection(this.origin, this.direction, ray.origin, ray.direction);
  }

  _intersectionsWithPrimitive(primitive: GeometryPrimitive) {
    if (primitive instanceof LineSegment) {
      const x = this._intersectionWithLineSegment(primitive);
      if (x) return [x];
    } else if (primitive instanceof CubicSegment) {
      return this._intersectionsWithCubicSegment(primitive);
    } else if (primitive instanceof Axis) {
      const x = this._intersectionWithAxis(primitive);
      if (x) return [x];
    } else if (primitive instanceof Ray) {
      const x = this._intersectionWithRay(primitive);
      if (x) return [x];
    }
    return [];
  }

  _overlapWithPrimitive(primitive: GeometryPrimitive) {
    return undefined;
  }

  _primitives() {
    return [this];
  }
}
