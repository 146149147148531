import { RichText, Vec } from "../geom";
import { Element } from "./element";
import { Env, EnvSubscriber } from "./env";
import { ModelObject, ModelObjectSubscriber } from "./model-object";
import { ProjectEvaluator } from "./project-evaluator";
import { ElementTrace } from "./trace";

/**
 * An EvaluateElementEntry is the basic unit of the cache.
 *
 * It represents what happened during an evaluateElementInner call. It keeps
 * track of the inputs (what was read / looked at) and the final result of the
 * call.
 */
interface EvaluateElementEntry {
  // The element that was evaluated.
  element: Element;

  // Any of the Env keys that were looked at and what their values were when
  // they were looked at.
  argsGets: Map<string, unknown>;
  globalGets: Map<string, unknown>;

  // Any ModelObjects that were looked at in the course of evaluating. We'll
  // listen for every ModelObject mutation and when a ModelObject mutates we'll
  // remove any entry that looked at that ModelObject.
  lookedAt: Set<ModelObject>;

  // Our evaluateElement calls are nested, and we keep track of "child entries"
  // — entries which were computed or used to compute this entry. We use this in
  // the logic for marking that an entry was used: If an entry was used, then
  // we'll say any entries used to compute that entry (i.e. child entries,
  // recursively) were also used.
  childEntries: Set<EvaluateElementEntry>;

  // The result of evaluation
  result: ElementTrace;
}

export class EvaluateElementMemoizer {
  // The cache.
  entries: Set<EvaluateElementEntry> = new Set();

  // Optimization: We keep these extra lookup tables to make it faster to find
  // an entry.
  lookupEntriesByElement: WeakMap<Element, Set<EvaluateElementEntry>> = new Map();
  lookupEntriesByLookedAt: WeakMap<ModelObject, Set<EvaluateElementEntry>> = new Map();

  // This is state that's built up during a project evaluation. It's cleaned
  // back to this initial state and the end of project evaluation (when we call
  // `this.clean()`).
  entriesUsed: Set<EvaluateElementEntry> = new Set();
  childEntriesStack: Set<EvaluateElementEntry>[] = [];

  // This isn't used by the memoizer logic, but we keep track of which entries
  // were recently computed for the Outline ⚡️ debug view.
  entriesJustComputed: Set<EvaluateElementEntry> = new Set();
  entriesComputedLastFrame: Set<EvaluateElementEntry> = new Set();

  constructor() {
    ModelObject.subscribe(this.modelObjectSubscriber.bind(this));
  }

  evaluateElement(element: Element, argsEnv: Env, globalEnv: Env, evaluator: ProjectEvaluator) {
    // If an existing entry in the cache matches the call, we can just return
    // that.
    entryLoop: for (let entry of this.entriesByElement(element)) {
      // Check that everything in the Envs that the entry looked at is still the
      // same.
      for (let [key, value] of entry.argsGets) {
        if (!isEqual(value, argsEnv.peek(key))) continue entryLoop;
      }
      for (let [key, value] of entry.globalGets) {
        if (this.globalEnvKeyIsNeverEqual(key)) continue entryLoop;
        if (!isEqual(value, globalEnv.peek(key))) continue entryLoop;
      }
      // Cache hit!

      // We need to get from our Envs so that it's logged.
      for (let [key] of entry.argsGets) {
        argsEnv.get(key);
      }
      for (let [key] of entry.globalGets) {
        globalEnv.get(key);
      }

      // We need to look at all the ModelObjects so that it's logged.
      for (let o of entry.lookedAt) {
        ModelObject.triggerEvent("get", o, "SYNTHETIC", undefined);
      }

      // Mark that this entry is a child of its parent.
      if (this.childEntriesStack.length > 0) {
        this.childEntriesStack[this.childEntriesStack.length - 1].add(entry);
      }

      // We note that we used this entry.
      this.markEntryWasUsed(entry);
      return entry.result;
    }

    // Since we didn't find a cache entry, we'll call evaluateElementInner and
    // keep track of what we read.
    const { subscriber: argsSubscriber, gets: argsGets } = makeEnvSubscriber();
    argsEnv.subscribe(argsSubscriber);
    const { subscriber: globalSubscriber, gets: globalGets } = makeEnvSubscriber();
    globalEnv.subscribe(globalSubscriber);
    const { subscriber: lookedAtSubscriber, gets: lookedAt } = makeModelObjectSubscriber();
    ModelObject.subscribe(lookedAtSubscriber);

    // Set up childEntries and put it on the stack.
    const childEntries: Set<EvaluateElementEntry> = new Set();
    this.childEntriesStack.push(childEntries);

    // We're all set up. Call it.
    const result = evaluator.evaluateElementInner(element, argsEnv, globalEnv);

    // Clean up.
    argsEnv.unsubscribe(argsSubscriber);
    globalEnv.unsubscribe(globalSubscriber);
    ModelObject.unsubscribe(lookedAtSubscriber);
    this.childEntriesStack.pop();

    // Save the entry.
    const entry = {
      element,
      argsGets,
      globalGets,
      lookedAt,
      childEntries,
      result,
    };
    this.addEntry(entry);

    // Mark that this entry is a child of its parent.
    if (this.childEntriesStack.length > 0) {
      this.childEntriesStack[this.childEntriesStack.length - 1].add(entry);
    }

    // Mark that this entry was used.
    this.markEntryWasUsed(entry);

    // For debug view, mark that this entry was just computed.
    this.entriesJustComputed.add(entry);

    return result;
  }

  private markEntryWasUsed(entry: EvaluateElementEntry) {
    this.entriesUsed.add(entry);
    // Recursively mark that all our child entries were also used.
    for (let childEntry of entry.childEntries) {
      this.markEntryWasUsed(childEntry);
    }
  }

  /**
   * Call this when we're done evaluating the project.
   */
  clean() {
    // Delete any entries that weren't used. We clone with Array.from to be
    // defensive while we delete.
    for (let entry of Array.from(this.entries)) {
      if (!this.entriesUsed.has(entry)) {
        this.deleteEntry(entry);
      }
    }
    // Clear our entriesUsed so we're ready for another project evaluation.
    this.entriesUsed.clear();

    // For debug view, shift entries.
    this.entriesComputedLastFrame = this.entriesJustComputed;
    this.entriesJustComputed = new Set();
  }

  /**
   * For debugging / understanding. This will find an entry in the cache for a
   * Trace, if one exists.
   */
  findEntryForTrace(trace: ElementTrace) {
    for (let entry of this.entriesByElement(trace.source)) {
      if (entry.result === trace) return entry;
    }
  }

  private entriesByElement(element: Element) {
    let entries = this.lookupEntriesByElement.get(element);
    if (!entries) {
      entries = new Set();
      this.lookupEntriesByElement.set(element, entries);
    }
    return entries;
  }
  private entriesByLookedAt(o: ModelObject) {
    let entries = this.lookupEntriesByLookedAt.get(o);
    if (!entries) {
      entries = new Set();
      this.lookupEntriesByLookedAt.set(o, entries);
    }
    return entries;
  }

  private addEntry(entry: EvaluateElementEntry) {
    this.entries.add(entry);
    // Update lookup tables
    this.entriesByElement(entry.element).add(entry);
    entry.lookedAt.forEach((o) => {
      this.entriesByLookedAt(o).add(entry);
    });
  }

  private deleteEntry(entry: EvaluateElementEntry) {
    this.entries.delete(entry);
    // Update lookup tables
    this.entriesByElement(entry.element).delete(entry);
    entry.lookedAt.forEach((o) => {
      this.entriesByLookedAt(o).delete(entry);
    });
  }

  /**
   * This will get called any time any ModelObject changes. It will then
   * invalidate (i.e. remove from the cache) any entry that looked at that
   * ModelObject.
   */
  private modelObjectSubscriber(event: "get" | "set", object: ModelObject) {
    if (event === "set") {
      // Delete any entries that looked at the object that just changed. We
      // clone with Array.from as defensiveness while we delete things.
      for (let entry of Array.from(this.entriesByLookedAt(object))) {
        this.deleteEntry(entry);
      }
    }
  }

  /**
   * Call this any time a global changes in a way that can't be determined with
   * object equality (`===`).
   *
   * We test that an environment is the same using object equality. Usually this
   * is fine, but sometimes a function is the "same" function but it gives new
   * results because some state that it closed over changes. For example:
   * `getFontFromURL` will give new results every time a new font finishes
   * loading.
   */
  globalChanged(key: string) {
    for (let entry of Array.from(this.entries)) {
      if (entry.globalGets.has(key)) {
        this.deleteEntry(entry);
      }
    }
  }

  /**
   * Some keys in globalEnv we assert are never equal — specifically all the
   * random functions. Thus any entry that depends on random will never be a
   * cache hit.
   *
   * In the future, when we have better semantics around how the random seed
   * works, I'd like to eliminate this. -Toby
   */
  globalEnvKeyIsNeverEqual(key: string) {
    return (
      key === "random" ||
      key === "randomInt" ||
      key === "randomDirection" ||
      key === "randomPointInDisk"
    );
  }
}

/**
 * For testing equality, we mostly use `===` but also have a few other custom
 * checks.
 */
const isEqual = (a: unknown, b: unknown) => {
  // Basic equality.
  if (a === b) return true;

  // Vec equality.
  if (a instanceof Vec && b instanceof Vec && a.equals(b)) {
    return true;
  }

  // RichText equality.
  if (a instanceof RichText && b instanceof RichText && a.equals(b)) {
    return true;
  }

  // Explicit id equality. We use this for when we have different objects but we
  // assert that they're the same because they have the same
  // `_memoizeEqualityId`.
  if (
    a &&
    b &&
    (a as any)._memoizeEqualityId &&
    (a as any)._memoizeEqualityId === (b as any)._memoizeEqualityId
  ) {
    return true;
  }

  return false;
};

/**
 * For subscribing to an Env and logging every key that was read and what the
 * value was when it was read.
 */
const makeEnvSubscriber = () => {
  const gets: Map<string, unknown> = new Map();
  const sets: Set<string> = new Set();
  const subscriber: EnvSubscriber = (event, key, value) => {
    // Note: We only want to keep track of "original" gets. So if we set some
    // key and then later get that key, that get doesn't count.
    if (event === "get") {
      if (!gets.has(key) && !sets.has(key)) {
        gets.set(key, value);
      }
    } else if (event === "set") {
      sets.add(key);
    }
  };
  return { subscriber, gets };
};

/**
 * For subscribing to all ModelObject reads.
 */
const makeModelObjectSubscriber = () => {
  const gets: Set<ModelObject> = new Set();
  const subscriber: ModelObjectSubscriber = (event, object, key, value) => {
    if (event === "get") {
      gets.add(object);
    }
  };
  return { subscriber, gets };
};
