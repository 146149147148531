import { AffineMatrix, Color } from "..";

export type StrokeAlignment = "centered" | "inner" | "outer";
export type StrokeCap = "butt" | "round" | "square";
export type StrokeJoin = "miter" | "round" | "bevel";

/**
 * Defines a stroke style.
 */
export class Stroke {
  static displayName = "Stroke";

  /** The color of the stroke */
  color: Color;

  /**
   * Hairline strokes represent a "very thin" stroke. They're usually used for
   * cut or score lines. When exported, hairline strokes are converted to a
   * stroke with a width defined in the project settings.
   *
   * Note that when `hairline` is `true` the following stroke properties will
   * have no effect.
   */
  hairline: boolean;

  /** The width of the stroke */
  width: number;

  /**
   * The alignment of the stroke in relation to the inside or outside of the
   * path. This property only affects closed paths.
   */
  alignment: StrokeAlignment;

  /** The shape to use at the endpoints of open paths. */
  cap: StrokeCap;

  /** The shape to use at the corners of the path. */
  join: StrokeJoin;

  /**
   * When `join` is set to `"miter"`, this limits the ratio of the miter length
   * to the stroke `width`. When the limit is exceeded, a `"bevel"` join will be
   * used.
   */
  miterLimit: number;

  /**
   * Constructs a stroke style.
   *
   * @param color
   * @param hairline
   * @param width
   * @param alignment
   * @param cap
   * @param join
   * @param miterLimit
   */
  constructor(
    color = new Color(),
    hairline = true,
    width = 0.1,
    alignment: StrokeAlignment = "centered",
    cap: StrokeCap = "butt",
    join: StrokeJoin = "miter",
    miterLimit = 4
  ) {
    this.color = color;
    this.hairline = hairline;
    this.width = width;
    this.alignment = alignment;
    this.cap = cap;
    this.join = join;
    this.miterLimit = miterLimit;
  }

  /**
   * @returns a copy of this stroke.
   */
  clone() {
    return new Stroke(
      this.color.clone(),
      this.hairline,
      this.width,
      this.alignment,
      this.cap,
      this.join,
      this.miterLimit
    );
  }

  /**
   * @param stroke Another stroke to compare against.
   *
   * @returns `true` if this stroke is equal to `stroke`, `false` otherwise.
   */
  equals(stroke: Stroke) {
    return (
      this.color.equals(stroke.color) &&
      this.hairline === stroke.hairline &&
      this.width === stroke.width &&
      this.alignment === stroke.alignment &&
      this.cap === stroke.cap &&
      this.join === stroke.join &&
      this.miterLimit === stroke.miterLimit
    );
  }

  static isValidAlignment(alignment: unknown): alignment is StrokeAlignment {
    return alignment === "centered" || alignment === "inner" || alignment === "outer";
  }
  static isValidCap(cap: unknown): cap is StrokeCap {
    return cap === "butt" || cap === "round" || cap === "square";
  }
  static isValidJoin(join: unknown): join is StrokeJoin {
    return join === "miter" || join === "round" || join === "bevel";
  }

  static isValid(stroke: unknown): stroke is Stroke {
    return (
      stroke instanceof Stroke &&
      Color.isValid(stroke.color) &&
      typeof stroke.hairline === "boolean" &&
      typeof stroke.width === "number" &&
      typeof stroke.miterLimit === "number" &&
      Stroke.isValidAlignment(stroke.alignment) &&
      Stroke.isValidCap(stroke.cap) &&
      Stroke.isValidJoin(stroke.join)
    );
  }
}

/**
 * Defines a fill style.
 */
export class Fill {
  static displayName = "Fill";

  /**
   * The color inside of the fill
   */
  color: Color;

  /**
   * Constructs a fill style.
   *
   * @param color
   */
  constructor(color = new Color(0, 0, 0, 1)) {
    this.color = color;
  }

  /**
   * @returns a copy of this fill.
   */
  clone() {
    return new Fill(this.color.clone());
  }

  /**
   * @param fill Another fill to compare against.
   *
   * @returns `true` if this fill is equal to `fill`, `false` otherwise.
   */
  equals(fill: Fill | ImageFill) {
    if (!(fill instanceof Fill)) return false;
    return this.color.equals(fill.color);
  }

  static isValid(fill: unknown): fill is Fill {
    return fill instanceof Fill && Color.isValid(fill.color);
  }
}

/**
 * Defines an image fill style.
 */
export class ImageFill {
  static displayName = "ImageFill";

  /**
   * The URL of the image.
   */
  image: string;

  /**
   * The opacity of the image, from `0` (fully transparent) to `1` (fully
   * opaque).
   */
  opacity: number;

  /**
   * The transform matrix of the image fill.
   */
  transform: AffineMatrix;

  /**
   * Constructs an image fill style.
   *
   * @param image
   * @param opacity
   * @param transform
   */
  constructor(image: string, opacity = 1, transform = new AffineMatrix()) {
    this.image = image;
    this.opacity = opacity;
    this.transform = transform;
  }

  /**
   * @returns a copy of this image fill.
   */
  clone() {
    return new ImageFill(this.image, this.opacity, this.transform.clone());
  }

  /**
   * @param fill Another fill to compare against.
   *
   * @returns `true` if this fill is equal to `fill`, `false` otherwise.
   */
  equals(fill: Fill | ImageFill) {
    if (!(fill instanceof ImageFill)) return false;
    return this.image === fill.image && this.transform.equals(fill.transform);
  }

  /**
   * Transforms this image fill by the affine matrix `affineMatrix`.
   *
   * @param affineMatrix
   * @chainable
   */
  affineTransform(affineMatrix: AffineMatrix) {
    this.transform.preMul(affineMatrix);
    return this;
  }

  /**
   * Transforms this image fill by the affine matrix `affineMatrix`, ignoring
   * translation.
   *
   * @param affineMatrix
   * @chainable
   */
  affineTransformWithoutTranslation(affineMatrix: AffineMatrix) {
    this.transform.preMulWithoutTranslation(affineMatrix);
    return this;
  }

  static isValid(fill: unknown) {
    return (
      fill instanceof ImageFill &&
      typeof fill.image === "string" &&
      AffineMatrix.isValid(fill.transform)
    );
  }
}
