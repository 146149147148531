import m from "mithril";

import { Vec } from "../../geom";
import { globalState } from "../../global-state";
import { expressionCodeForNumber } from "../../model/expression-code";
import { LiteralNumber, parseLiteral } from "../../model/parse-literal";
import { EditableCode } from "../editable-code";
import { PreciseNumber } from "../number-scrubber";

interface TransformBoxLabelAttrs {
  position: Vec;
  normal: Vec;
  rotation?: number;
}
export const TransformBoxLabel: m.Component<TransformBoxLabelAttrs> = {
  view({ attrs: { position, normal, rotation }, children }) {
    const angle = (normal.angle() - 90).toFixed(1);
    const tx = Math.round(position.x);
    const ty = Math.round(position.y);
    const transform = `translate(-50%,-50%) translate(${tx}px,${ty}px) rotate(${angle}deg) translate(0,20px) rotate(${
      rotation ?? 0
    }deg)`;

    return m("div.transform-box-label", { style: { transform } }, children);
  },
};

interface TransformBoxDimensionAttrs {
  value: number;
  fractionDigits: number;
  onChange: (literal: LiteralNumber) => void;
}
export const TransformBoxDimension: m.Component<TransformBoxDimensionAttrs> = {
  view({ attrs: { value, fractionDigits, onChange } }) {
    const stringValue = expressionCodeForNumber(value, fractionDigits);
    return m(
      EditableCode,
      {
        value: stringValue,
        onChange: (newValue: string) => {
          const literal = parseLiteral(newValue);
          if (literal && literal.type === "Number") {
            onChange(literal);
          }
        },
      },
      m(".dimension-editor", [
        m(PreciseNumber, {
          stringValue,
          fractionDigits,
        }),
        m("span.number-unit", globalState.project.settings.units),
      ])
    );
  },
};
