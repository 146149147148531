import m from "mithril";

import { SelectableInstance } from "../../model/selectable";
import { Icon20 } from "../../shared/icon";

interface InstanceInspectorMessagesAttrs {
  selectable: SelectableInstance;
}
export const InstanceInspectorMessages: m.Component<InstanceInspectorMessagesAttrs> = {
  view({ attrs: { selectable } }) {
    const instanceTrace = selectable.instanceTrace();
    if (!instanceTrace) return;

    const messages = instanceTrace
      .expressionTraces()
      .flatMap((expressionTrace) => expressionTrace.messages);

    if (!messages.length) return;

    const mMessages: m.Children[] = [];

    for (let message of messages) {
      if (message.logLevel === "error") {
        mMessages.push(
          m(".instance-inspector-message-error", [
            m(
              ".instance-inspector-message-icon",
              { "aria-label": "Error" },
              m(Icon20, { icon: "error" })
            ),
            m(
              ".instance-inspector-message-text",
              makeFriendlyErrorMessage(String(message.message))
            ),
          ])
        );
      } else if (message.logLevel === "warn") {
        mMessages.push(
          m(".instance-inspector-message-warning", [
            m(
              ".instance-inspector-message-icon",
              { "aria-label": "Warning" },
              m(Icon20, { icon: "warning" })
            ),
            m(".instance-inspector-message-text", message.message + ""),
          ])
        );
      } else if (message.logLevel === "info") {
        mMessages.push(
          m(".instance-inspector-message-info", [
            m(
              ".instance-inspector-message-icon",
              { "aria-label": "Info" },
              m(Icon20, { icon: "info" })
            ),
            m(".instance-inspector-message-text", message.message + ""),
          ])
        );
      }
    }

    if (mMessages.length === 0) return;

    return m(".inspector-row.instance-inspector-messages", mMessages);
  },
};

function makeFriendlyErrorMessage(message: string) {
  if (message.includes("MALLOC")) {
    return "Cuttle has run out of memory. Please reduce the number of shapes in your design. You might have to refresh the browser to continue.";
  }
  return message;
}
