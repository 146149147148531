import { BoundingBox, Vec } from "../../geom";
import { CanvasDrag, globalState } from "../../global-state";
import { CanvasPoint } from "../../model/canvas-point";
import {
  Selectable,
  SelectableComponentParameter,
  SelectableParameter,
  SelectableSegment,
} from "../../model/selectable";
import { Selection } from "../../model/selection";
import { Viewport } from "../../model/viewport";
import { snappingRoseAtPoint } from "../snapping";
import { startDrag } from "../start-drag";
import { ConstrainCanvasPointOptions, constrainCanvasPoint, worldPositionFromEvent } from "../util";

export interface CanvasDragOptions extends ConstrainCanvasPointOptions {
  disableDisplay?: boolean;
  disableGhost?: boolean;
  disableSnappingCache?: boolean;

  // Should be used when the user is performing a rotation operation. Customizes
  // snapping for rotation uses.
  rotationTransformCenter?: Vec;
  rotationReferenceStartAngle?: number;

  onConsummate?: (event: PointerEvent, canvasDrag: CanvasDrag) => void;
  onMove?: (event: PointerEvent, canvasDrag: CanvasDrag) => void;
  onUp?: (event: PointerEvent) => void;
  onCancel?: () => void;

  isValid?: () => boolean;

  cursor?: () => string;
}
export const startCanvasDrag = (
  downEvent: PointerEvent,
  {
    enableGridSnapping,
    enableGeometrySnapping,
    disableDisplay,
    disableGhost,
    disableSnappingCache,
    rotationTransformCenter,
    rotationReferenceStartAngle,
    onConsummate,
    onMove,
    onUp,
    onCancel,
    isValid,
    cursor,
  }: CanvasDragOptions
) => {
  const startWorldPosition = worldPositionFromEvent(downEvent);
  const canvasDrag = new CanvasDrag(new CanvasPoint(startWorldPosition));
  if (!disableDisplay) {
    globalState.canvasDrag = canvasDrag;
  }

  // Constrain the start point before waiting for consummation. This ensures
  // that the current snapping point won't be cleared first.
  constrainCanvasPoint(canvasDrag.startPoint, {
    enableGridSnapping,
    enableGeometrySnapping,
  });

  const endCanvasDrag = () => {
    globalState.clearGhostGeometry();

    // Find the snapping point for the next drag (in case there are no
    // intervening pointer events).
    globalState.snapping.cacheSnappingDataForHoveredInterface();
    globalState.snapping.updateSnappingPointNearWorldPosition(
      canvasDrag.currentPoint.worldPosition
    );

    globalState.canvasDrag = undefined;
  };

  // Disable snapping until the user drags a certain distance. This is intended
  // to help when you just want to slightly tweak the position of an anchor, for
  // example, without snapping to its ghost.
  const smallDragDistance = globalState.snappingDistanceWorld() * 2;
  let hasExceededSmallDragDistance = false;

  startDrag(downEvent, {
    isValid,
    cursor,
    onConsummate(event) {
      if (!disableGhost) {
        globalState.updateGhostGeometry();
      }

      if (!disableSnappingCache && globalState.deviceStorage.geometrySnappingEnabled) {
        // Tweak some settings that are more convenient for rotations.
        if (rotationTransformCenter) {
          globalState.snapping.referencePoints = [rotationTransformCenter];
          globalState.snapping.referenceGeometry = snappingRoseAtPoint(
            rotationTransformCenter,
            rotationReferenceStartAngle ?? 0,
            15
          );
        }
        const isRotation = Boolean(rotationTransformCenter);

        // Cache snapping state for the duration of the drag.
        globalState.snapping.cacheSnappingDataForInterface(globalState.interfaceElements, {
          skipReferencePoints: isRotation,
          skipReferenceGeometry: isRotation,
        });
      }

      onConsummate?.(event, canvasDrag);
    },
    onMove(event) {
      const worldPosition = worldPositionFromEvent(event);
      canvasDrag.currentPoint = new CanvasPoint(worldPosition);

      if (!hasExceededSmallDragDistance) {
        hasExceededSmallDragDistance = canvasDrag.distance() > smallDragDistance;
      }

      // Snap to geometry and points
      if (hasExceededSmallDragDistance) {
        if (event.shiftKey) {
          globalState.snapping.updateReferenceSnappingPointNearWorldPosition(worldPosition);
        } else if (canvasDrag.startPoint.isSpecific) {
          globalState.snapping.updateSnappingPointNearWorldPosition(worldPosition);
        } else {
          globalState.snapping.clearCurrentPoint();
        }
        constrainCanvasPoint(canvasDrag.currentPoint, {
          enableGridSnapping,
          enableGeometrySnapping,
        });
      } else {
        globalState.snapping.clearCurrentPoint();
      }

      onMove?.(event, canvasDrag);
    },
    onUp(event) {
      endCanvasDrag();
      onUp?.(event);
    },
    onCancel() {
      endCanvasDrag();
      onCancel?.();
    },
  });
};

export const startCanvasPan = (
  downEvent: PointerEvent,
  onCancel: (event: PointerEvent) => void,
  viewport: Viewport
) => {
  const worldStartPosition = worldPositionFromEvent(downEvent);

  startDrag(downEvent, {
    onConsummate() {
      globalState.isCanvasPanning = true;
    },
    onMove(moveEvent) {
      const worldCurrentPosition = worldPositionFromEvent(moveEvent);
      const delta = worldStartPosition.clone().sub(worldCurrentPosition);
      viewport.center.add(delta);
    },
    onCancel(upEvent) {
      onCancel(upEvent);
    },
    onUp() {
      globalState.isCanvasPanning = false;
    },
    cursor() {
      return "grab";
    },
  });
};

export const startCanvasBoxSelectionDrag = (downEvent: PointerEvent) => {
  const startWorldPosition = worldPositionFromEvent(downEvent);

  const { project } = globalState;

  const initialSelection = project.selection;
  const initialParameters = project.selection.isEmpty()
    ? project.focusedComponentParametersToShow()
    : project.selectionParametersToShow();

  startDrag(downEvent, {
    onConsummate() {
      globalState.selectionBoxParameters = initialParameters;
    },
    onMove(moveEvent) {
      const currentWorldPosition = worldPositionFromEvent(moveEvent);
      globalState.selectionBox = new BoundingBox(startWorldPosition, currentWorldPosition);

      const selectionBoundingBox = globalState.selectionBox.clone().canonicalize();

      // When option (alt) is held, Cuttle selects items that are _contained_
      // withing the selection box, otherwise we select _overlapped_ items.
      const isOverlap = !moveEvent.altKey;

      let selection = new Selection();

      for (let uiElement of globalState.interfaceElements) {
        let selectables = uiElement.selectables?.();
        if (!selectables) continue;
        if (selectables.length === 0) continue;

        const isSelected = isOverlap
          ? uiElement.isOverlappedByBoundingBox?.(selectionBoundingBox)
          : uiElement.isContainedByBoundingBox?.(selectionBoundingBox);
        if (isSelected) {
          selection.add(...selectables);
        }
      }

      // Only allow box selecting component parameters if nothing else was
      // selected at the same time. If any other selectable type was selected,
      // remove all component parameters.
      const isParameter = (item: Selectable) => {
        return item instanceof SelectableParameter || item instanceof SelectableComponentParameter;
      };
      const isAllParameters = selection.items.every(isParameter);
      if (!isAllParameters) {
        selection = selection.filter((item) => !isParameter(item));
      }

      // Unless the selection only contains path segments, remove segments that
      // don't also have both anchors selected.
      if (!selection.hasOnly(SelectableSegment)) {
        selection = selection.filter((item) => {
          if (item instanceof SelectableSegment) {
            return (
              selection.isNodeDirectlySelected(item.node) &&
              selection.isNodeDirectlySelected(item.nextNode)
            );
          }
          return true;
        });
      }

      const { project } = globalState;

      project.selection = moveEvent.shiftKey ? initialSelection.clone() : new Selection();
      project.selection.addUnique(...selection.items);

      project.updateSelection();
    },
    onUp(upEvent) {
      globalState.selectionBox = null;
      globalState.selectionBoxParameters = undefined;
    },
    onCancel() {
      globalState.project.clearSelection();
    },
  });
};
