import m from "mithril";

import { globalState } from "../../global-state";
import { CodeComponent, Component } from "../../model/component";
import { DocumentationFocus } from "../../model/focus";
import { Modifier } from "../../model/modifier";
import { SelectableInstance } from "../../model/selectable";
import { CodeInspector } from "./code-inspector";
import { InstanceInspectorMessages } from "./instance-inspector-messages";
import { InstanceParameterInspector } from "./instance-parameter-inspector";
import { InstanceParameterFolderInspector } from "./parameter-folder-inspector";
import {
  ParameterFolderReorderHitTarget,
  ParameterReorderHitTarget,
} from "./parameter-reorder-hit-target";

interface InstanceInspectorContentsAttrs {
  selectables: SelectableInstance[];
  rootComponent: Component | CodeComponent;
  isEditingDefinition: boolean;
}
export const InstanceInspectorContents: m.Component<InstanceInspectorContentsAttrs> = {
  view({ attrs: { selectables, rootComponent, isEditingDefinition } }) {
    const firstSelectable = selectables[0];
    const definition = firstSelectable.definition();

    const isEmbed = globalState.project.focus instanceof DocumentationFocus;

    const onClickCreateVariation = (event: MouseEvent) => {
      event.preventDefault();
      globalState.project.createVariationOfInstance(firstSelectable.instance);
    };

    const mParameterInspectors = definition.parameters.map((parameter, parameterIndex) => {
      return [
        m(ParameterReorderHitTarget, { definition, insertionIndex: parameterIndex }),
        m(InstanceParameterInspector, {
          selectables,
          parameter,
          isEditingDefinition,
        }),
        m(ParameterReorderHitTarget, { definition, insertionIndex: parameterIndex + 1 }),
      ];
    });

    const mFolderInspectors = definition.parameterFolders.map((folder, folderIndex) => {
      const isLastFolder = folderIndex === definition.parameterFolders.length - 1;
      return [
        m(ParameterFolderReorderHitTarget, { definition, insertionIndex: folderIndex }),
        m(InstanceParameterFolderInspector, {
          selectables,
          rootComponent,
          folder,
          isEditingDefinition,
        }),
        isLastFolder &&
          m(ParameterFolderReorderHitTarget, { definition, insertionIndex: folderIndex + 1 }),
      ];
    });

    return [
      mParameterInspectors.length === 0 &&
        m(ParameterReorderHitTarget, { definition, insertionIndex: 0 }),
      mParameterInspectors,
      mFolderInspectors,
      m(InstanceInspectorMessages, { selectable: firstSelectable }),
      isEditingDefinition &&
        !isEmbed &&
        (definition instanceof CodeComponent || definition instanceof Modifier) &&
        m(CodeInspector, {
          selectable: firstSelectable,
          onClickCreateVariation,
        }),
    ];
  },
};
